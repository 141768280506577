import React from 'react';
import { Row, Col } from 'reactstrap';
import { t, strings } from 'language';
import { MatrixFooterContainer, Text, Value } from './styles';

export default function MatrixFooter({ matrix }) {
  const totalElements = matrix.map(i => i.value).reduce((a, b) => a + b, 0);
  return (
    <MatrixFooterContainer>
      <Row>
        <Col>
          <Text>
            {`${t(strings.total_elements)}:`}
            <Value>{totalElements}</Value>
          </Text>
        </Col>
      </Row>
    </MatrixFooterContainer>
  );
}
