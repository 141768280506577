import React from 'react';
export const Types = {
  TOOLTIP_OPEN: 'tooltip/TOOLTIP_OPEN'
};

const INITIAL_STATE = {
  show: false,
  url: null,
  timeout: null,
  content: <></>
};

export function showTooltip(content) {
  return {
    type: Types.TOOLTIP_OPEN,
    payload: {
      url: window.location.href,
      show: content ? true : false,
      content: content
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.TOOLTIP_OPEN:
      return { ...state, ...payload };

    default:
      return state;
  }
}
