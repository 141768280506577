import React, { useState, useEffect } from 'react';
import useGlobal from 'store/global';

import './styles.css';

import Menu from './components/Menu';
import NotificationPanel from './components/NotificationPanel';
import ProfileDisplay from './components/ProfileDisplay';
import { InternalContainer, GlobalContainer, Logo, MenuButton, SideMenuContainer } from './styles';
import NotificationBadgeContainer from './components/NotificationBadgeContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'store/ducks/theme';
import { Icon } from 'components/Icon';
function Header(props) {
  const { noContainer } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [modalActive, setModalActive] = useGlobal(
    state => state.modalActive,
    actions => actions.setModalActive
  );
  const { theme } = useSelector(state => state.theme);
  const dispatch = useDispatch();
  const handleChangeTheme = () => {
    if (theme === 'dark') {
      dispatch(setTheme('light'));
    } else {
      dispatch(setTheme('dark'));
    }
    return false;
  };

  return (
    <GlobalContainer>
      <NotificationPanel
        isOpen={notificationIsOpen}
        setModalActive={setModalActive}
        closeMenu={() => {
          setNotificationIsOpen(false);
          setModalActive(false);
        }}
      />
      <Menu
        isOpen={isOpen}
        closeMenu={() => {
          setIsOpen(false);
          setModalActive(false);
        }}
      />

      <InternalContainer
        style={{
          filter: `${modalActive ? 'blur(5px)' : 'none'}`,
          maxWidth: `${noContainer ? '96%' : 'auto'}`
        }}
      >
        <div className="d-flex flex-row ">
          <MenuButton
            href="#menu"
            onClick={() => {
              setIsOpen(!isOpen);
              setModalActive(true);
            }}
          >
            <img src={require('./assets/img/menu.svg')} alt="menu" />
          </MenuButton>
          <h1>
            <Logo src={require('assets/images/logo.svg')} />
          </h1>
        </div>

        <SideMenuContainer>
          <a href="#" onClick={handleChangeTheme} className="changeThemeContainer">
            <Icon width="150" height="50" name={theme === 'dark' ? 'dark' : 'light'} />
          </a>
          <ProfileDisplay />
          <NotificationBadgeContainer
            className="badgeIcon"
            setModalActive={setModalActive}
            setNotificationIsOpen={setNotificationIsOpen}
          />
        </SideMenuContainer>
      </InternalContainer>
    </GlobalContainer>
  );
}
export default Header;
