import React from 'react';
import globalHook from 'use-global-hook';

const annotateState = {
  deleteMode: false,
  modalActive: false,
  modal: false,
  modalRotation: false,
  selectedAnnotate: [],
  annotateList: [],
  deleteModeList: [],
  doing: -1,
  controlTag: null,
  oldTag: null,
  disableEditor: false,
  disableSelector: false,
  disableOverlay: false,
  disableContent: false,
  index: 0,
  zoom: 0,
  fitZoom: 0,
  color: '#000',
  position: {
    x: -10000,
    y: -10000,
    width: 0,
    height: 0
  },
  controlEditor: false,
  showToolTip: {
    open: false,
    left: -100,
    title: '',
    text: '',
    tags: ''
  },
  widthDraw: 0,
  heightDraw: 0,
  tagType: 'RECTANGLE',
  polygon: { polygonId: -1, corner: 0, data: [], geometry: [] },
  polygons: [],
  activePolygon: -1,
  editablePolygon: { polygonId: -1, corner: 0, data: [], geometry: [] },
  editablePoint: null,
  heightImages: 136,
  widthImages: 100,
  actionTagWidth: 540,
  editMode: false,
  showTags: true,
  floatTags: false,
  floatPos: {
    x: 10,
    y: -20,
    width: 400,
    height: 600
  },
  brightness: 100,
  contrast: 100,
  hue: 0,
  hideTags: {
    box: [],
    polygon: []
  },
  tagsDataset: [],
  dataset: 0,
  titleModal: '',
  textModal: '',
  delItem: { value: '', type: '' },
  relaodAnnotate: false,
  inferenceID: 0,
  inferenceDetails: null,
  maskPolygon: null,
  showTrace: false,
  trace: false,
  cancelTrace: false,
  tag: [],
  handlePosition: null,
  newMask: 0,
  keyPressing: null,
  imagePage: 1,
  showTagsName: true,
  loadingPosition: 10,
  showTagType: 'none',
  showEffects: 'none',
  showAllTags: true,
  tagToDelete: [],
  editorIsOpen: false,
  hotkeys:[],
  cursor: false,
  viewerDialog: false,
  language: 'en'
};

const actions = {
  setModalActive: (store, modalActive) => {
    store.setState({ modalActive });
  },
  setModal: (store, modal) => {
    store.setState({ modal });
  },
  setModalRotation: (store, modalRotation) => {
    store.setState({ modalRotation });
  },
  setDeleteMode: (store, deleteMode) => {
    store.setState({ deleteMode });
  },
  setSelectedAnnotate: (store, selectedAnnotate) => {
    store.setState({ selectedAnnotate });
  },
  setControlTag: (store, controlTag) => {
    store.setState({ controlTag });
  },
  setOldTag: (store, oldTag) => {
    store.setState({ oldTag });
  },
  setDisableEditor: (store, disableEditor) => {
    store.setState({ disableEditor });
  },
  setDisableSelector: (store, disableSelector) => {
    store.setState({ disableSelector });
  },
  setDisableOverlay: (store, disableOverlay) => {
    store.setState({ disableOverlay });
  },
  setDisableContent: (store, disableContent) => {
    store.setState({ disableContent });
  },
  setZoom: (store, zoom) => {
    store.setState({ zoom });
  },
  addZoom: (store, amount) => {
    const zoom = store.state.zoom + amount;
    if (zoom > 0) store.setState({ zoom });
  },
  setFitZoom: (store, fitZoom) => {
    store.setState({ fitZoom });
  },
  setColor: (store, color) => {
    store.setState({ color });
  },
  setIndex: (store, index) => {
    store.setState({ index });
  },
  editingAnnotation: (store, x, y, width, height) => {
    const position = { x, y, width, height };
    store.setState({ position });
  },
  addAnnotateList: (store, newValue) => {
    const annotateList = store.state.annotateList.concat(newValue);
    store.setState({ annotateList });
  },
  addDeleteModeList: (store, newValue) => {
    const deleteModeList = store.state.deleteModeList.concat(newValue);
    store.setState({ deleteModeList });
  },
  clearDeleteModeList: store => {
    const deleteModeList = [];
    store.setState({ deleteModeList });
  },
  replaceDeleteModeList: (store, value) => {
    const deleteModeList = value;
    store.setState({ deleteModeList });
  },
  addDoing: (store, amount) => {
    const doing = store.state.doing + amount;
    store.setState({ doing });
  },
  setControlEditor: (store, controlEditor) => {
    store.setState({ controlEditor });
  },
  setShowToolTip: (store, showToolTip) => {
    store.setState({ showToolTip });
  },
  setWidthDraw: (store, widthDraw) => {
    store.setState({ widthDraw });
  },
  setHeightDraw: (store, heightDraw) => {
    store.setState({ heightDraw });
  },
  setTagType: (store, tagType) => {
    store.setState({ tagType });
  },
  setPolygon: (store, polygon) => {
    store.setState({ polygon });
  },
  setPolygons: (store, polygons) => {
    store.setState({ polygons });
  },
  setActivePolygon: (store, activePolygon) => {
    store.setState({ activePolygon });
  },
  setEditablePolygon: (store, editablePolygon) => {
    store.setState({ editablePolygon });
  },
  setEditablePoint: (store, editablePoint) => {
    store.setState({ editablePoint });
  },
  setHeightImages: (store, heightImages) => {
    store.setState({ heightImages });
  },
  setWidthImages: (store, widthImages) => {
    store.setState({ widthImages });
  },
  setActionTagWidth: (store, actionTagWidth) => {
    store.setState({ actionTagWidth });
  },
  setEditMode: (store, editMode) => {
    store.setState({ editMode });
  },
  setShowTags: (store, showTags) => {
    store.setState({ showTags });
  },
  setFloatTags: (store, floatTags) => {
    store.setState({ floatTags });
  },
  editingfloatPos: (store, x, y, width, height) => {
    const floatPos = { x, y, width, height };
    store.setState({ floatPos });
  },
  setBrightness: (store, brightness) => {
    store.setState({ brightness });
  },
  setContrast: (store, contrast) => {
    store.setState({ contrast });
  },
  setHue: (store, hue) => {
    store.setState({ hue });
  },
  setHideTags: (store, hideTags) => {
    store.setState({ hideTags });
  },
  setTagsDataset: (store, tagsDataset) => {
    store.setState({ tagsDataset });
  },
  setDataset: (store, dataset) => {
    store.setState({ dataset });
  },
  setTitleModal: (store, titleModal) => {
    store.setState({ titleModal });
  },
  setTextModal: (store, textModal) => {
    store.setState({ textModal });
  },
  setDelItem: (store, delItem) => {
    store.setState({ delItem });
  },
  setRelaodAnnotate: (store, relaodAnnotate) => {
    store.setState({ relaodAnnotate });
  },
  setInferenceID: (store, inferenceID) => {
    store.setState({ inferenceID });
  },
  setInferenceDetails: (store, inferenceDetails) => {
    store.setState({ inferenceDetails });
  },
  setMaskPolygon: (store, maskPolygon) => {
    store.setState({ maskPolygon });
  },
  setShowTrace: (store, showTrace) => {
    store.setState({ showTrace });
  },
  setTrace: (store, trace) => {
    store.setState({ trace });
  },
  setCancelTrace: (store, cancelTrace) => {
    store.setState({ cancelTrace });
  },
  setTag: (store, tag) => {
    store.setState({ tag });
  },
  setHandlePosition: (store, handlePosition) => {
    store.setState({ handlePosition });
  },
  setNewMask: (store, amount) => {
    let newMask = store.state.newMask + amount;
    if (store.state.newMask === amount) newMask = 0;
    if (store.state.newMask !== amount && newMask === 0) newMask = amount;
    if (newMask > 1) newMask = 0;
    if (newMask < -1) newMask = 0;
    store.setState({ newMask });
  },
  setKeyPressing: (store, keyPressing) => {
    store.setState({ keyPressing });
  },
  setImagePage: (store, imagePage) => {
    store.setState({ imagePage });
  },
  setShowTagsName: (store, showTagsName) => {
    store.setState({ showTagsName });
  },
  setLoadingPosition: (store, loadingPosition) => {
    store.setState({ loadingPosition });
  },
  setShowTagType: (store, showTagType) => {
    store.setState({ showTagType });
  },
  setShowEffects: (store, showEffects) => {
    store.setState({ showEffects });
  },
  setShowAllTags: (store, showAllTags) => {
    store.setState({ showAllTags });
  },
  setTagToDelete: (store, tagToDelete) => {
    store.setState({ tagToDelete });
  },
  setEditorIsOpen: (store, editorIsOpen) => {
    store.setState({ editorIsOpen });
  },
  setHotkeys: (store, hotkeys) => {
    store.setState({ hotkeys });
  },
  setCursor: (store, cursor) => {
    store.setState({ cursor });
  },
  setViewerDialog: (store, viewerDialog) => {
    store.setState({ viewerDialog });
  },
  setLanguage: (store, language) => {
    store.setState({ language });
  }
};
const useGlobal = globalHook(React, annotateState, actions);

export default useGlobal;
