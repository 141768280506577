import styled, { keyframes } from 'styled-components';

export const Header = styled.div`
  margin-top: 50px;
  cursor: default;
`;

export const Container = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
`;

export const fadeInScale = keyframes`
from {
  opacity: 0;
  transform: scale(0);
}

to {
  opacity: 1;
  transform: scale(1);
}
`;

export const Box = styled.div`
  background: transparent;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-botton: 0px;
  margin-right: 0px;
  transform-origin: botton right;
  animation: ${fadeInScale} 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
`;

export const BoxContent = styled.div`
  background: ${props => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 10px;
  color: #a2a2a7;
  white-space: nowrap;
  cursor: default;
`;

export const Title = styled.small`
  color: #909090;
  cursor: default;
`;

export const BoxDiv = styled.div`
  border: 1px solid ${props => props.theme.white};
  height: 104px;
  cursor: default;
  padding: 10px;
`;

export const TitleGlobal = styled.small`
  float: left;
  position: relative;
  top: 5px;
  left: 5px;
  color: #909090;
  cursor: default;
`;
