import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dialog from 'components/Dialog';
import DatasetTooltip from 'components/GlobalTooltip/datasetTooltip';

import { DatasetAPI } from 'services/Api/Dataset';
import { loading } from 'store/ducks/general';
import { showTooltip } from 'store/ducks/tooltip';
import { Label, InputField } from 'components/DatasetBrowser/styles';
import imageIcon from 'assets/images/icon_image.png';

import {
  Container,
  Name,
  Image,
  InternalContainer,
  EditButton,
  Tags,
  ImageIcon,
  ContainerTop,
  TextItemDetail,
  TextContainer
} from './styles';
import { TypeItem } from 'utils/constants';
import Favorite from 'components/Favorite';
import Marker from 'components/Marker';
import { Markers, StarContainer } from 'components/FolderItem/styles';
import { setMarkers } from 'store/ducks/filter';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';

function DatasetItem(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const { data, children, markers, updateMarkers, onResetData, onChangeFilter } = props;

  const [datasetName, setdatasetName] = useState(children);
  const [tooltipInterval, setTooltipInterval] = useState();
  const [mouseOver, setmouseOver] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [editDatasetName, setEditDatasetName] = useState('');
  const [editDatasetId, setEditDatasetId] = useState('');
  const [markerList, setMarkerList] = useState([]);

  function getLink(value) {
    if (value.hasSubfolder) {
      if (value.root) {
        return `/datasets`;
      }
      return `/datasets/${value.id}`;
    }
    return `/dataset/${value.id}?count=${data.totalImages ? data.totalImages : '0'}`;
  }

  function openEditModel(event, name, id) {
    event.preventDefault();
    setShowDialog(true);
    setEditDatasetName(name);
    setEditDatasetId(id);
  }

  async function editName() {
    dispatch(loading(true));
    await DatasetAPI.editDataset(editDatasetId, editDatasetName);
    closeModal();
    setdatasetName(editDatasetName);
    dispatch(loading(false));
  }

  function closeModal() {
    setShowDialog(false);
  }

  function scheduleTooltip() {
    const show = showTooltip(<DatasetTooltip {...data} />);
    setTooltipInterval(setTimeout(() => dispatch(show), 1000));
  }

  const onMarkerClick = (e, item) => {
    e.preventDefault();
    // setMarkerList([item.id]);
    // onChangeFilter([item.id]);
    // onResetData();
    dispatch(setMarkers([{ label: item.name, value: item.id }]));
  };

  return (
    <Container
      key={props.itemkey}
      onMouseEnter={() => scheduleTooltip()}
      onMouseLeave={() => {
        clearInterval(tooltipInterval);
        dispatch(showTooltip(false));
      }}
      className=""
    >
      {data.newItem ? (
        <Name>
          <Image src={require('assets/images/newFolder.svg')} />
          <h3>{datasetName}</h3>
        </Name>
      ) : (
        <Link
          onClick={() => clearInterval()}
          to={data.newItem ? null : getLink(data)}
          onMouseEnter={() => setmouseOver(true)}
          onMouseLeave={() => setmouseOver(false)}
        >
          <ContainerTop>
            <Markers>
              <div className="d-flex flex-row w-100 justify-content-end">
                {data?.markers?.slice(0, 2).map((item, index) => (
                  <a key={index} onClick={e => onMarkerClick(e, item)}>
                    <p title={item.name}>{item.name}</p>
                  </a>
                ))}
                <StarContainer>
                  <Favorite data={data} type={TypeItem.DATASET} />
                </StarContainer>
              </div>
            </Markers>

            <Tags>
              <Icon name="folder" width="30" height="30" />
              <div className="d-flex flex-row mr-1 justify-content-between">
                {/* <div className="leftTags d-flex flex-row justify-content-end">
              {data?.totalTags > 0 && (
                <span>
                  {data?.totalTags} <ClassIcon style={{ height: 12, width: 12 }} />
                </span>
              )}
            </div> */}

                <div className="rightTags d-flex flex-row justify-content-end">
                  {data?.polygon > 0 && (
                    <span>
                      {data?.polygon} <Icon name="polygon_icon" style={{ height: 10, width: 10 }} />
                    </span>
                  )}
                  {data?.boundingBox > 0 && (
                    <span>
                      {data?.boundingBox} <Icon name="tag_icon" style={{ height: 10, width: 10 }} />
                    </span>
                  )}
                </div>
              </div>
            </Tags>

            <TextContainer>
              <TextItemDetail>
                <strong>{t(strings.images)}</strong>: {data.totalImages ? data.totalImages : '0'}
              </TextItemDetail>

              <TextItemDetail>
                <strong>{t(strings.class)}</strong>: {data.totalTags ? data.totalTags : '0'}
              </TextItemDetail>
            </TextContainer>
          </ContainerTop>

          <Name>
            {!viewer && (
              <EditButton
                className="editButton"
                mouseOver={mouseOver || showDialog}
                onClick={event => openEditModel(event, children, data.id)}
              ></EditButton>
            )}
            <h3>{children}</h3>
            {!viewer && (
              <Marker
                markers={markers}
                onResetData={onResetData}
                updateMarkers={updateMarkers}
                data={data}
                type={TypeItem.DATASET}
              />
            )}
          </Name>
        </Link>
      )}
      <Dialog
        title={t(strings.edit_name)}
        open={showDialog}
        close={() => closeModal}
        actions={[
          { text: t(strings.cancel), action: closeModal },
          { text: t(strings.save), action: () => editName() }
        ]}
      >
        <Label>{t(strings.edit_folder)}</Label>
        <InputField
          defaultValue={editDatasetName}
          type="text"
          placeholder={t(strings.enter_folder_name)}
          onChange={e => setEditDatasetName(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && editName()}
        ></InputField>
      </Dialog>
    </Container>
  );
}

export default DatasetItem;
