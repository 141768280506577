import styled from 'styled-components';

export const Container = styled.div.attrs(props => ({
  style: {
    visibility: props.visible ? 'visible' : 'hidden',
    opacity: props.visible ? 1 : 0
  }
}))`
  transition: visibility 0s, opacity 0.3s ease;
  ${'' /* min-height: 150px; */}
  background-color: ${props => props.theme.secondary};
  border: 1px solid ${props => props.theme.default};
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  z-index: 1051;
  padding: 10px;
  font-size: 12px;
`;

export const LabelContainer = styled.div`
  display: flex;
  & > label {
    font-weight: 700;
    margin-right: 3px;
    margin-bottom: 2px;
  }
  & > p {
    margin-bottom: 2px;
  }
`;
export const TagList = styled.ul`
  padding-inline-start: 20px;
  font-size: 14px;
  list-style: circle;
  margin-bottom: 10px;
`;
