import React from 'react';

import {
  Container,
  TableContainer,
  Table,
  TD,
  FalseTD,
  RecallTD,
  ColorBar,
  ColorBarContainer,
  ColorValueContainer,
  ColorValue
} from './styles';

function ConfusionMatrix({ data, labels, precisions, recalls }) {
  const FP = [];
  labels.forEach(label => FP.push(data.filter(i => i.x === 'FP' && i.y === label)[0]));
  const max = Math.max(...data.map(i => i.value));
  const min = Math.min(...data.map(i => i.value));
  const opacityMultiplier = 1 / max;
  
  return (
    <Container>
      <TableContainer>
        <Table>
          <tbody>
            {labels.map(label => (
              <>
                <tr>
                  <th>{label}</th>
                  {/* DADO */}
                  {data
                    .filter(i => i.x === label && i.y !== 'FN')
                    .map(column => (
                      <TD
                        id={column.x + column.y}
                        key={column.x + column.y}
                        opacity={column.x === column.y ? column.f1 : 0}
                      >
                        {column.value}
                      </TD>
                    ))}
                  {/* FALSOS */}
                  {data
                    .filter(i => i.x === label && i.y === 'FN')
                    .map(column => (
                      <FalseTD key={column.x + column.y}>{column.value}</FalseTD>
                    ))}
                  {/* PRECISION / RECALL*/}
                  <RecallTD>{precisions.filter(i => i.name === label)[0]?.value}</RecallTD>
                  <RecallTD>{recalls.filter(i => i.name === label)[0]?.value}</RecallTD>
                </tr>
              </>
            ))}
            {
              <tr>
                <th>FP</th>
                {FP.map(column => (
                  <FalseTD key={column.x + column.y}>{column.value}</FalseTD>
                ))}
              </tr>
            }
          </tbody>
          <tfoot>
            <tr>
              <th> </th>
              {labels.map(label => (
                <th>
                  <span>{label}</span>
                </th>
              ))}
              <th>
                <span>FN</span>
              </th>
              <th>
                <span>Precision</span>
              </th>
              <th>
                <span>Recall</span>
              </th>
            </tr>
          </tfoot>
        </Table>
        <ColorBarContainer>
          <ColorBar />
          <ColorValueContainer>
            <ColorValue>F1 100%</ColorValue>
            <ColorValue>F1 50%</ColorValue>
            <ColorValue>F1 0%</ColorValue>
          </ColorValueContainer>
        </ColorBarContainer>
      </TableContainer>
    </Container>
  );
}

export default ConfusionMatrix;
