/* eslint-disable no-shadow */
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetSelectImage, SetAnnotation } from 'store/ducks/annotate';
import { deleteTag } from 'services/Api/Dataset/dataset';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import ButtonIcon from './components/ButtonIcon';
import { ReactComponent as EditIcon } from './assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from './assets/images/delete.svg';
import { ReactComponent as TagIcon } from './assets/images/imageTag.svg';
import { ReactComponent as AddIcon } from './assets/images/add.svg';
//import { ReactComponent as HideIcon } from './assets/images/eye-off.svg';
import { Container, ContentBox, ContainerButtons } from './styles';

function ContentInference(props) {
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  const confidence = selectedImage.tags.find(x => {
    return x.id === props.annotation.data.imageTagId;
  });
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const { annotation, className, style } = props;
  const { geometry, data } = annotation;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const [zoom] = useGlobal(state => state.zoom);
  const [widthDraw] = useGlobal(state => state.widthDraw);
  const [heightDraw] = useGlobal(state => state.heightDraw);
  const testSize = geometry.width * zoom * 10 < 10 || geometry.height * zoom * 10 < 10 ? 1 : 10;
  const padding = geometry.type === 'RECTANGLE' ? testSize : 1;

  const posX = (geometry.x * imgWidth) / 100;
  const screenX = imgWidth * zoom < widthDraw ? widthDraw : imgWidth * zoom;
  const leftOut =
    geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom < 0
      ? (padding * 100) / imgWidth / zoom
      : geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom;
  const left =
    width && posX * zoom + width < screenX
      ? geometry.x + (padding * 100) / imgWidth / zoom
      : leftOut;
  const posY = (geometry.y * imgHeight) / 100;
  const screenY = imgHeight * zoom < heightDraw ? heightDraw : imgHeight * zoom;
  const topOut =
    geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom < 0
      ? geometry.y - ((height + padding) * 100) / imgHeight / zoom
      : geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom;
  const top =
    height && posY * zoom + height < screenY
      ? geometry.y + (padding * 100) / imgHeight / zoom
      : topOut;

  if (!geometry) return null;

  return (
    <Container
      ref={div}
      style={{
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        transformOrigin: 'top left',
        transform: `scale(${1 / zoom})`,
        zIndex: 10,
        ...style
      }}
      className={className}
      geometry={geometry}
    >
      <ContentBox>
        {data && `${data.description}: ${parseInt(confidence?.score * 100, 10)}%`}
      </ContentBox>
    </Container>
  );
}

ContentInference.defaultProps = {
  style: {},
  className: ''
};

export default ContentInference;
