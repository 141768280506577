/* eslint-disable react/jsx-curly-newline */
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useGlobal from 'store/global';
import { addTags } from 'services/Api/Tag/tag';
import { GetSelectImage } from 'store/ducks/annotate';
import { putTag, putTagPolygon } from 'services/Api/Dataset/dataset';
import TextEditorTag from '../TextEditorTag';
import { Container } from './styles';
import { randomHex } from 'utils/helpers';
function ContentTag(props) {
  const { annotation, className, style } = props;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const { geometry, data } = annotation;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(253);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const [zoom] = useGlobal(state => state.zoom);
  const [widthDraw] = useGlobal(state => state.widthDraw);
  const [heightDraw] = useGlobal(state => state.heightDraw);
  const padding = 1;
  const posX = (geometry.x * imgWidth) / 100;
  const screenX = imgWidth * zoom < widthDraw ? widthDraw : imgWidth * zoom;
  const leftOut =
    geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom < 0
      ? (padding * 100) / imgWidth / zoom
      : geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom;
  const left =
    width && posX * zoom + width < screenX
      ? geometry.x + (padding * 100) / imgWidth / zoom
      : leftOut;
  const posY = (geometry.y * imgHeight) / 100;
  const screenY = imgHeight * zoom < heightDraw ? heightDraw : imgHeight * zoom;
  const topOut =
    geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom < 0
      ? geometry.y - ((height + padding) * 100) / imgHeight / zoom
      : geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom;
  const top =
    height && posY * zoom + height < screenY
      ? geometry.y + (padding * 100) / imgHeight / zoom
      : topOut;
  const [selectedAnnotate, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const [, setDisableEditor] = useGlobal(
    state => state.disableEditor,
    actions => actions.setDisableEditor
  );
  const [, setDisableContent] = useGlobal(
    state => state.disableContent,
    actions => actions.setDisableContent
  );
  const dispatch = useDispatch();
  const [polygons] = useGlobal(state => state.polygons);
  if (!geometry) return null;

  function onSubmit(item) {
    //debugger;
    const selected =
      item && item.description
        ? {
            ...selectedAnnotate,
            data: {
              ...data,
              tagId: item.id,
              description: item.description,
              color: item.color
            }
          }
        : {
            ...selectedAnnotate,
            data: {
              ...data,
              tagId: '',
              description: selectedAnnotate.data.description,
              color: ''
            }
          };

    setSelectedAnnotate([]);
    setDisableEditor(false);
    setDisableContent(false);
    if (geometry.type === 'POINT') {
      updatePolygon(selected);
    } else {
      updateBox(selected);
    }
  }

  async function updateBox(selected) {
    const { geometry, data } = selected;
    const color = randomHex();
    const newTag =
      !data.tagId &&
      (await addTags({
        color,
        description: data.description
      }));
    const tag = {
      tagId: newTag.id ? newTag.id : data.tagId,
      xmax: geometry.x + geometry.width,
      xmin: geometry.x,
      ymax: geometry.y + geometry.height,
      ymin: geometry.y
    };

    await putTag(data.imageTagId, tag);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  async function updatePolygon(selected) {
    const polygon = polygons.find(x => {
      return x.polygonId === selected.data.imageTagId;
    });
    const tag = polygon && {
      tagId: selected.data.tagId,
      points: polygon.geometry.filter(x => {
        return x.x > -1;
      })
    };
    await putTagPolygon(polygon.data.imageTagId, tag);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  return (
    <Container
      ref={div}
      className={className}
      style={{
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        transformOrigin: 'top left',
        transform: `scale(${1 / zoom})`,
        zIndex: 10,
        ...style
      }}
    >
      <TextEditorTag
        onChange={e => {
          setSelectedAnnotate({
            ...annotation,
            data: {
              ...data,
              tagId: '',
              description: e.target.value,
              color: ''
            }
          });
        }}
        onSelect={value => {
          setSelectedAnnotate({
            ...annotation,
            data: {
              ...data,
              tagId: value.id,
              description: value.description,
              color: value.color
            }
          });
        }}
        onSubmit={item => onSubmit(item)}
        value={data && data.description}
        controller={data}
      />
    </Container>
  );
}

ContentTag.defaultProps = {
  className: '',
  style: {}
};

export default ContentTag;
