import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

* {
    font-family: 'metropolisregular';
    color: ${props => props.theme.default};
}
.nav-item a {
    color:${props => props.theme.white};
    
    &:hover{
        color:${props => props.theme.default}  ;  
        font-weight:bold
    }
}
a:hover{
  color:${props => props.theme.default}
}
select option:hover, select option:active{
  color:${props => props.theme.white};
  background:${props => props.theme.default}!important;
}

div.Toastify__toast--info{
    background:${props => props.theme.secondary};
}
#global_container ._loading_overlay_overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2060 !important;
}
._loading_overlay_overlay {
  z-index: 2060 !important;
}
.nopadding {
  padding: 0;
}
.pagetitle {
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 70px;
  color: ${props => props.theme.white};
}

h4.subtitle {
  font-size: 16px;
  color: ${props => props.theme.default};
  padding: 10px;
}
button,
a {
  outline: none;
}
.Toastify__toast-body {
  color: ${props => props.theme.white};
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

a:hover {
  text-decoration: none !important;
}

div::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 2px #707070; */
  border: 1px solid #707070;
  background-color: ${props => props.theme.secondary};
}

div::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background-color: ${props => props.theme.secondary};
}

div::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: ${props => props.theme.default};
  border: 2px solid ${props => props.theme.default};
}

div::-webkit-scrollbar-corner,
body::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.1);
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8);
}
.react-confirm-alert-body {
  background: ${props => props.theme.primary};
  color: ${props => props.theme.default};
}
.react-confirm-alert-body > h1 {
  margin-top: 0;
  color: ${props => props.theme.white};
  font-size: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: ${props => props.theme.secondary};
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  color: ${props => props.theme.default};
}
.react-confirm-alert-button-group > button:hover {
  background: ${props => props.theme.white};
  color: ${props => props.theme.secondary};
}
ul.nav-tabs .nav-item.show a.nav-link,
ul.nav-tabs a.nav-link.active {
  color: white;
  background-color: ${props => props.theme.default};
  border-color: 1px solid ${props => props.theme.default};
}

`;

export default GlobalStyle;
