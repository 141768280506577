import React, { useEffect, useState } from 'react';

import {
  Document,
  Logo,
  LogoSV,
  Header,
  Page,
  InvoiceNumberHeaderContainer,
  InvoiceNumberHeader,
  FirstContainer,
  SecondContainer,
  Text,
  Label,
  HeaderSeparation,
  HeaderBottomLine,
  Body,
  H2,
  H4,
  DefaultText,
  TableData,
  TableRow,
  Total,
  TotalContainer,
  TotalLine,
  TotalValue,
  H1,
  H3,
  Currency,
  ValueContainer,
  PixContainer,
  PaymentDetails,
  PaymentDetailsContainer,
  PageHeader,
  PageHeaderContainer,
  Pix,
  Qrcode
} from './styles';
//import { PDFViewer } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import { getIdFromUrl } from 'utils/helpers';
import { SubscriptionApi } from 'services/Api/Subscriptions';
import moment from 'moment';
import { formatCurrency } from 'utils/money';

export function InvoicePDF(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const id = getIdFromUrl(props.history.location.pathname, 4);
    getData(id);
  }, []);

  const getData = async id => {
    const dataReturn = await SubscriptionApi.getInvoice(id);
    console.log(dataReturn);
    setData(dataReturn);
    return dataReturn;
  };
  if (data) {
    return <></>;
    // return (
    //   <PDFViewer style={{ width: '100%', height: '100vh' }}>
    //     <InvoiceDetail data={data} />
    //   </PDFViewer>
    // );
  } else {
    return <></>;
  }
}
{
  /* <PDFDownloadLink document={<InvoiceDetail />} fileName={'FileName'}>
      <button> Download </button>
    </PDFDownloadLink> */
}
const getPeriodDate = (lastClosedDate, closedDate) => {
  if (lastClosedDate) {
    return `${moment(lastClosedDate).format('DD/MM/YYYY')} a ${moment(closedDate).format(
      'DD/MM/YYYY'
    )} `;
  } else {
    return '-';
  }
};

export function InvoiceDetail(props) {
  const { data } = props;
  return (
    <Document>
      <Page size="A4">
        <Header>
          <FirstContainer>
            <HeaderSeparation>
              <Logo src={require('assets/images/logo_fu2re_invoice.png')} />
            </HeaderSeparation>
            <Label>Fu2re Smart Solutions</Label>
            <Label>Av Rio Branco 1</Label>
            <Label>Sala 1802</Label>
            <Label>Rio de Janeiro rj</Label>
            <Label>20090-003</Label>
            <Label>Brasil</Label>
          </FirstContainer>
          <SecondContainer>
            <HeaderSeparation>
              <LogoSV src={require('assets/images/logo-smartvision_invoice.png')} />
            </HeaderSeparation>
            <Label>Período de Apuração</Label>
            <Text>{getPeriodDate(data?.lastClosedDate, data?.closedDate)}</Text>
            <Label style={{ marginTop: 10 }}>Data de Fatura</Label>
            <Text>{moment(data?.closedDate).format('DD/MM/YYYY')}</Text>
            <Label style={{ marginTop: 10 }}>Tipo de Pagamento</Label>
            <Text>Depósito</Text>
          </SecondContainer>
          <InvoiceNumberHeaderContainer>
            <HeaderSeparation>
              <Label>Nº de Fatura : {data?.invoiceNumber}</Label>
            </HeaderSeparation>
            <Label>Documento</Label>
            <Text>{data?.owner.document}</Text>
            <Label style={{ marginTop: 10 }}>Nome</Label>
            <Text>{data?.owner.name}</Text>
            <Label style={{ marginTop: 10 }}>E-mail</Label>
            <Text>{data?.owner.email}</Text>
          </InvoiceNumberHeaderContainer>
        </Header>

        <HeaderBottomLine style={{ marginLeft: 20, width: '93%' }} />

        <Body>
          <Label style={{ padding: 5, paddingTop: 0 }}>Resumo da Fatura</Label>
          <H2>{data?.subscription?.name}</H2>
          <TableData>
            <TableRow>
              <DefaultText>Saldo Anterior</DefaultText>
              <Label>R$ 1.332,00</Label>
            </TableRow>
            <TableRow>
              <DefaultText>Pagamento</DefaultText>
              <Label>R$ 1.332,00</Label>
            </TableRow>
            <TableRow>
              <DefaultText>Saldo por pagar (do ciclo de faturação anterior)</DefaultText>
              <Label>R$ 0,00</Label>
            </TableRow>
          </TableData>
          <HeaderBottomLine height={'2px'} />

          <Label style={{ padding: 10 }}>Encargos Atuais</Label>
          <TableData>
            <TableRow>
              <DefaultText>Encargos de Utilização</DefaultText>
              <Label>{formatCurrency(data?.total)}</Label>
            </TableRow>
          </TableData>
          <HeaderBottomLine height={'2px'} />

          <Total>
            <TotalLine />
            <TotalContainer>
              <H1>Total</H1>
              <ValueContainer>
                <TotalValue>{formatCurrency(data?.total, true).slice(2)}</TotalValue>
                <Currency>BRL</Currency>
              </ValueContainer>
            </TotalContainer>
          </Total>
          <PaymentDetailsContainer>
            <PaymentDetails>
              <Label style={{ padding: 10, fontSize: 15, paddingBottom: 17 }}>
                Detalhes para Pagamento
              </Label>
              <TableData paddingLeft={'10px'}>
                <TableRow>
                  <Label>Banco</Label>
                  <DefaultText>Bradesco</DefaultText>
                </TableRow>
                <TableRow>
                  <Label>Agência</Label>
                  <DefaultText>001</DefaultText>
                </TableRow>
                <TableRow>
                  <Label>Nome da Empresa</Label>
                  <DefaultText>Fu2re Smart Solutions</DefaultText>
                </TableRow>
                <TableRow>
                  <Label>CNPJ</Label>
                  <DefaultText>22.000.000/0001-45</DefaultText>
                </TableRow>
              </TableData>
            </PaymentDetails>
            <PixContainer>
              <Qrcode src={require('assets/images/qrcode.png')} />
              <Pix src={require('assets/images/pix.png')} />
            </PixContainer>
          </PaymentDetailsContainer>
        </Body>
      </Page>

      <Page size="A4">
        <PageHeader fixed>
          <PageHeaderContainer>
            <FirstContainer>
              <Label style={{ marginBottom: 5 }}>Fu2re Smart Solutions</Label>
              <Text>Nº de Fatura {data?.invoiceNumber}</Text>
            </FirstContainer>

            <SecondContainer>
              <Logo src={require('assets/images/logo_fu2re_invoice.png')} />
            </SecondContainer>
          </PageHeaderContainer>

          <TotalLine />
        </PageHeader>

        <Body padding={'5px'}>
          <H3 paddingLeft={'10px'} paddingBottom={'20px'}>
            Detalhes da Fatura
          </H3>

          <H4 paddingLeft={'10px'}>Inferências</H4>
          <TableData>
            <TableRow>
              <DefaultText>Quantidade</DefaultText>
              <Label>{data?.totalInferenceCount}</Label>
            </TableRow>
            <TableRow>
              <DefaultText>Valor Total</DefaultText>
              <Label>{formatCurrency(data?.totalInferenceValue)}</Label>
            </TableRow>
          </TableData>

          <H4 paddingLeft={'10px'}>Treinamentos</H4>

          {data?.trainingsByMachine.map(({ machineGroup, price, total, gpuTime }) => {
            if (gpuTime <= 0) {
              return;
            }
            return (
              <>
                <Label style={{ padding: 10 }}>Máquina {machineGroup}</Label>
                <TableData>
                  <TableRow>
                    <DefaultText>Valor Unitário</DefaultText>
                    <Label>{formatCurrency(price)}</Label>
                  </TableRow>
                  <TableRow>
                    <DefaultText>Quantidade</DefaultText>
                    <Label>{Math.ceil(gpuTime)}h</Label>
                  </TableRow>
                  <TableRow>
                    <DefaultText>Valor Total</DefaultText>
                    <Label>{formatCurrency(total)}</Label>
                  </TableRow>
                </TableData>
                <HeaderBottomLine height={'2px'} />
              </>
            );
          })}
        </Body>
      </Page>
    </Document>
  );
}

export default InvoicePDF;
