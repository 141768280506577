import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import { BrowserType } from 'utils/constants';
import { getFolders } from 'services/Api/Dataset/dataset';
// import { t, strings } from 'language';
import FolderBrowser from 'components/FolderBrowser';

function Datasets() {
  return (
    <Container>
      <TitleBreadcrumb position={2} name={'Folders'} url="/folders" />
      <FolderBrowser type={BrowserType.FOLDER} />
    </Container>
  );
}

export default Datasets;
