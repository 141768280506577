import { DEFAULT_LANGUAGE } from 'utils/constants';
import { ptbr } from './ptbr';
import { strings } from './default';

export { strings };

export function t(string) {
  const key = getKeyByValue(strings, string);
  const key1 = string;
  switch (DEFAULT_LANGUAGE) {
    case 'pt':
      if (ptbr[key]) {
        return ptbr[key];
      }
      console.log(`WARNING: Translation not found on ptbr array - ${string}`);

      if (strings[key] === undefined) {
        throw new Error(`Translation not found on default array.`);
      }
      return strings[key];

    default:
      if (strings[key] === undefined) {
        // throw new Error(`Translation not found on default array.`);
        return key1;
      }
      return strings[key];
  }
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
