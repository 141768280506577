import React, { useState } from 'react';
import 'react-rangeslider/lib/index.css';
import { SliderContainer, Slider } from './styles';

export default function(props) {
  const [value, setValue] = useState(props.value);

  const { labels, min, max, step, label = 'steps' } = props;

  const formatSteps = value => value + ' ' + label;

  return (
    <SliderContainer>
      <Slider
        className="w-100"
        onChange={e => {
          setValue(e);
          props.onChange(value);
        }}
        onChangeComplete={() => props.onChange(value)}
        value={value}
        min={min}
        max={max}
        step={step}
        labels={labels}
        format={formatSteps}
      />
    </SliderContainer>
  );
}
