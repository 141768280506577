import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import { t, strings } from 'language';
import { connect } from 'services/SignalR/TrainingHub';
import TrainingSession from 'pages/Models/components/Historic/HistoricDetails';
import GraphBar from './GraphBar';
import './styles.css';

function LogItens(props) {
  const { data } = props;
  const [modal, setModal] = useState(false);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    async function connectHub() {
      const hubConnection = await connect();
      setConnection(hubConnection);
    }
    if (data.status === 'Processing') {
      connectHub();
    }
  }, []);

  function openDetatils(event) {
    setModal(true);
    event.preventDefault();
  }

  function closeDetatils() {
    setModal(false);
  }

  return (
    <>
      <Row
        style={{ width: '100%', cursor: 'pointer', marginLeft: '0px' }}
        onClick={e => openDetatils(e)}
      >
        <Col className="ts_log_tr">
          <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
            {data.startedAt}
          </Moment>
        </Col>
        <Col className="ts_log_tr">
          <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
            {data.finishedAt}
          </Moment>
        </Col>
        <Col className="ts_log_tr" style={{ textAlign: 'right' }}>
          {`${data.usedTagsTraining}/${data.usedTagsEval}`}
        </Col>
        <Col
          className="ts_log_tr"
          style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}
        >
          <GraphBar
            data1={data.usedImagesTraining}
            data2={data.usedImagesEval}
            unit1={t(strings.training).toLowerCase()}
            unit2={t(strings.eval).toLowerCase()}
          />
        </Col>
        <Col
          className="ts_log_tr"
          style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}
        >
          <GraphBar
            data1={data.usedBoxesTraining}
            data2={data.usedBoxesEval}
            unit1={t(strings.training).toLowerCase()}
            unit2={t(strings.eval).toLowerCase()}
          />
        </Col>
        <Col className="ts_log_tr">{data.machineGroup}</Col>
        <Col className="ts_log_tr">{data.steps}</Col>
        <Col className="ts_log_tr">{data.status}</Col>
      </Row>
      <TrainingSession
        open={modal}
        data={data}
        closeDetatils={closeDetatils}
        hubConnection={connection}
      />
    </>
  );
}

export default LogItens;
