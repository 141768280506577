import { apiGet, apiPost, apiDelete, apiPut, resolveFilter } from '../index';

export async function getModels(page, size = 16, filter) {
  // console.log('filteruery', filter, resolveFilter(filter));
  // console.log('apiget', `Model?size=${size}&page=${page}${resolveFilter(filter)}`);
  return apiGet(`Model?size=${size}&page=${page}${resolveFilter(filter)}`);
}

export async function checkIfModelExists(query) {
  return apiGet(`Model?size=1&page=1&q=${query}`);
}

export async function getModel(id) {
  return apiGet(`Model/${id}`);
}

export async function createModel(body) {
  return apiPost(`Model`, body);
}
export async function deleteModel(array) {
  return apiDelete(`Model`, array);
}

export async function editModel(id, body) {
  return apiPut(`Model/${id}`, body);
}
export async function changeFavorite(id) {
  return apiPut(`Model/${id}/favorite`);
}
