import React, { useState, useEffect, useContext } from 'react';
import { Matrix, VerticalLabel, HorizontalLabel, ColorCell, MatrixContainer } from './styles';
import { ThemeContext } from 'styled-components';
import { t, strings } from 'language';
export default function(props) {
  const [zoom] = useState(20);
  const theme = useContext(ThemeContext);
  const colorsAlternative = {
    outerBackground: '#d9d9d9',
    labelText: 'black',
    normalCell: 'white',
    normalCellText: 'black',
    falsePositiveCell: '#d9d9d9',
    falsePositiveCellText: 'black',
    intersetcCell: '#ffc000',
    intersetcCellText: 'black',
    precisionCell: '#63c384',
    precisionCellText: 'black',
    recallCell: '#ffc000',
    recallCellText: 'black'
  };
  const colorsDefault = {
    outerBackground: theme.primary,
    labelText: '#917355',
    normalCell: '#262245',
    normalCellText: '#917355',
    falsePositiveCell: theme.primary,
    falsePositiveCellText: '917355',
    intersetcCell: '#50FEF2',
    intersetcCellText: 'black',
    precisionCell: '#50FEF2',
    precisionCellText: 'black',
    recallCell: '#50FEF2',
    recallCellText: 'black'
  };

  const [colors, setColors] = useState(colorsDefault);

  useEffect(() => {
    switch (props.theme) {
      case '1':
        setColors(colorsDefault);
        // console.log('color Selected', colorsDefault);
        break;
      case '2':
        setColors(colorsAlternative);
        // console.log('color Selected', colorsAlternative);
        break;
      default:
        setColors(colorsDefault);
        // console.log('color Selected Default', colorsDefault);
        break;
    }
    // eslint-disable-next-line
  }, [props.theme]);

  const { matrix = [], labels, showIndex, parentRef, noscroll, precisions, recalls } = props;
  return (
    <MatrixContainer backgroundColor={colors.outerBackground} noscroll={noscroll} ref={parentRef}>
      <Matrix zoom={zoom}>
        {matrix.map((row, rowIndex) => {
          if (!showIndex.includes(rowIndex) && rowIndex !== row.length - 1) {
            return <></>;
          }
          return (
            <tr>
              {row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  if (!labels[rowIndex])
                    return (
                      <>
                        <HorizontalLabel textColor={colors.labelText}>FP</HorizontalLabel>

                        <ColorCell
                          backgroundColor={colors.falsePositiveCell}
                          textColor={colors.falsePositiveCellText}
                        >
                          {cell}
                        </ColorCell>
                      </>
                    );
                  return (
                    <>
                      <HorizontalLabel textColor={colors.labelText}>
                        {labels[rowIndex]}
                      </HorizontalLabel>
                      {rowIndex === 0 ? (
                        <ColorCell
                          backgroundColor={colors.intersetcCell}
                          textColor={colors.intersetcCellText}
                        >
                          {cell}
                        </ColorCell>
                      ) : (
                        <ColorCell
                          backgroundColor={colors.normalCell}
                          textColor={colors.normalCellText}
                        >
                          {cell}
                        </ColorCell>
                      )}
                    </>
                  );
                } else if (row.length - 1 === cellIndex) {
                  return (
                    <>
                      <ColorCell
                        backgroundColor={colors.falsePositiveCell}
                        textColor={colors.falsePositiveCellText}
                      >
                        {cell}
                      </ColorCell>
                      {rowIndex !== row.length - 1 && (
                        <>
                          <ColorCell
                            backgroundColor={colors.precisionCell}
                            textColor={colors.precisionCellText}
                          >
                            {(precisions[rowIndex] * 100).toFixed(1)}%
                          </ColorCell>
                          <ColorCell
                            backgroundColor={colors.recallCell}
                            textColor={colors.recallCellText}
                          >
                            {(recalls[rowIndex] * 100).toFixed(1)}%
                          </ColorCell>
                        </>
                      )}
                    </>
                  );
                } else if (row.length - 1 === rowIndex) {
                  return (
                    <>
                      <ColorCell
                        backgroundColor={colors.falsePositiveCell}
                        textColor={colors.falsePositiveCellText}
                      >
                        {cell}
                      </ColorCell>
                    </>
                  );
                } else if (cellIndex === rowIndex) {
                  return (
                    <>
                      <ColorCell
                        backgroundColor={colors.intersetcCell}
                        textColor={colors.intersetcCellText}
                      >
                        {cell}
                      </ColorCell>
                    </>
                  );
                } else {
                  return (
                    <ColorCell
                      backgroundColor={colors.normalCell}
                      textColor={colors.normalCellText}
                    >
                      {cell}
                    </ColorCell>
                  );
                }
              })}
            </tr>
          );
        })}
        <tr>
          <VerticalLabel textColor={colors.labelText}>
            <p></p>
          </VerticalLabel>
          {labels.map((i, index) => {
            return (
              <VerticalLabel textColor={colors.labelText} rowspan={3}>
                <span>{i}</span>
              </VerticalLabel>
            );
          })}
          <VerticalLabel textColor={colors.labelText} rowspan={3}>
            <span>{t(strings.fn)}</span>
          </VerticalLabel>
          <VerticalLabel textColor={colors.labelText} rowspan={3}>
            <span>{t(strings.precisions)}</span>
          </VerticalLabel>
          <VerticalLabel textColor={colors.labelText} rowspan={3}>
            <span>{t(strings.recalls)}</span>
          </VerticalLabel>
        </tr>
      </Matrix>
    </MatrixContainer>
  );
}
