import { useEffect, useState } from 'react';
import useGlobal from 'store/global';

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const setFromEvent = e => setPosition({ x: e.clientX, y: e.clientY });
  const [disableEditor] = useGlobal(state => state.disableEditor);

  useEffect(() => {
    if (!disableEditor) {
      window.addEventListener('mousemove', setFromEvent);

      return () => {
        window.removeEventListener('mousemove', setFromEvent);
      };
    }
  }, [disableEditor]);

  return position;
};
