import { apiGet, apiPost, apiDelete, apiPut, resolveFilter, resolveParameters } from '../index';

export async function getFolders(page, size, filter, hideEmpty = false) {
  // console.log(
  //   'filter',
  //   `folder?size=16&page=${page}${resolveFilter(filter)}&hideEmpty=${hideEmpty}`
  // );
  if (!size) {
    size = 16;
  }
  if (!page) {
    page = 1;
  }
  return apiGet(`folder?size=${size}&page=${page}${resolveFilter(filter)}&hideEmpty=${hideEmpty}`);
  // return apiGet(`folder?size=16&page=${page}${resolveFilter(filter)}&hideEmpty=${hideEmpty}`);
  // return apiGet(`folder?size=${size}&page=${page}&q=${search}&hideEmpty=${hideEmpty}`);
}

export async function getDatasets(page, size = 40, folder, filter) {
  if (folder)
    return apiGet(`Dataset?size=${size}&folder=${folder}&page=${page}${resolveFilter(filter)}`);

  return apiGet(`Dataset?size=40&page=${page}${resolveFilter(filter)}`);
}
export async function getDatasetImages(page, size, datasetId, filter) {
  return apiGet(
    `Dataset/${datasetId}/images?size=${size}${resolveFilter(filter)}&page=${page}`
  );
}
export async function getAnnotateImages(id, page, search = '', param = '') {
  return apiGet(`Dataset/${id}/images?size=50${resolveParameters(param)}&page=${page}&q=${search}`);
}
export async function getDataset(id) {
  return apiGet(`Dataset/${id}`);
}
export async function getDatasetTitle(id) {
  return apiGet(`Dataset/${id}/title`);
}
export async function getFolder(id) {
  return apiGet(`folder/${id}`);
}
export async function createDataset(body) {
  return apiPost(`Dataset`, body);
}
export async function createFolder({ name }) {
  return apiPost(`folder`, {
    name
  });
}

export async function deleteFolder(folderArray) {
  return apiDelete(`Folder`, folderArray);
}

export async function editDataset(id, name) {
  return apiPut(`Dataset/${id}`, {
    description: name
  });
}

export async function editFolder(id, name) {
  return apiPut(`folder/${id}`, {
    name
  });
}

export async function addDatasetImages(datasetId, image) {
  return apiPost(`Dataset/${datasetId}/images`, {
    filename: image.filename,
    fileSize: image.fileSize,
    height: image.height,
    width: image.width,
    imageType: image.imageType,
    fileHash: image.fileHash,
    overrideMode: image.overrideMode ? image.overrideMode : image.override
  });
}

export async function checkImagesNames(datasetId, filenames) {
  return apiPost(`Dataset/${datasetId}/images/checknames`, filenames);
}

export async function getImages(datasetid, page, size) {
  return apiGet('Dataset/' + datasetid + '/images?page=' + page + '&size=' + size);
}

export async function getImage(imageId) {
  return apiGet('Dataset/images/' + imageId);
}

export async function postTag(imageId, tag) {
  return apiPost(`Dataset/images/${imageId}/imagetag/boundingbox`, {
    tagId: tag.tagId,
    xmax: tag.xmax,
    xmin: tag.xmin,
    ymax: tag.ymax,
    ymin: tag.ymin
  });
}

export async function putTag(imageTagId, tag) {
  return apiPut(`Dataset/imagetag/${imageTagId}/boundingbox`, {
    tagId: tag.tagId,
    xmax: tag.xmax,
    xmin: tag.xmin,
    ymax: tag.ymax,
    ymin: tag.ymin
  });
}

export async function deleteImages(imageArray) {
  return apiDelete(`Dataset/images`, imageArray);
}

export async function deleteDataset(datasetArray) {
  return apiDelete(`Dataset`, datasetArray);
}

export async function deleteTag(imageTagId) {
  return apiDelete(`Dataset/imagetag/${imageTagId}`);
}

export async function deleteAllTags(imageId) {
  return apiDelete(`Dataset/images/${imageId}/allboxes`);
}

export async function postTagPolygon(imageId, tag) {
  return apiPost(`Dataset/images/${imageId}/imagetag/polygon`, tag);
}

export async function putTagPolygon(tagId, tag) {
  return apiPut(`Dataset/imagetag/${tagId}/polygon`, tag);
}

export async function rotateBoxs(tags) {
  return apiPut(`Dataset/imagetag/boundingbox/editmulti`, tags);
}

export async function rotatePolygons(tags) {
  return apiPut(`Dataset/imagetag/polygon/editmulti`, tags);
}

export async function videoSource(datasetId, data) {
  // console.log('datasetId', datasetId);
  // console.log('overrideMode', data.overrideMode);
  // console.log('parts', data.parts);
  // console.log('partialName', data.partialName);
  // console.log('fileUrl', data.fileUrl);
  // console.log('fileName', data.fileName);
  return apiPost(`Dataset/${datasetId}/images/videoSouce`, {
    overrideMode: data.overrideMode,
    parts: data.parts,
    partialName: data.partialName,
    fileUrl: data.fileUrl,
    fileName: data.fileName
  });
}

export async function getAllFolders(folder) {
  return apiGet(`Dataset?Page=1&Size=1000&folder=${folder}`);
}

export async function getAllDatasets() {
  return apiGet(`folder?Page=1&Size=1000`);
}
