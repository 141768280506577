import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;

export const Label = styled.small`
  width: 85px;
  color: #909090;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: #a2a2a7;
`;

export const Image = styled.div`
  float: left;
  margin: auto;
  width: 85px;
  height: 67px;
  background: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 85px auto;
`;

export const ContainerImage = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
  width: 95px;
  height: 90px;
`;
