import React, { useState, useEffect } from 'react';
//import MultSelectSearchable from '../../MultSelectSearchable';
import { Collapse, Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
//import { getTags } from 'services/Api/Tag/tag';
import { setTags, clearFilter, setTagType } from 'store/ducks/filter';
//import { getMarkersByText } from 'services/Api/Markers';
//import MultiSelectSearchab from '../../MultSelectSearchable';
import MultiSelect from 'components/MultSelect';
import { t, strings } from 'language';

const TagTypeFilter = () => {
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const options = [
    { value: 'BoundingBox', label: t(strings.bounding_box) },
    { value: 'Class', label: t(strings.class) },
    { value: 'Polygon', label: t(strings.polygon) }
  ];

  const handleSelect = e => {
    //setSelectedFilters(e);
    console.log(e);

    dispatch(setTagType(e));
  };

  return (
    <Container>
      <br />
      <Label>{t(strings.status)}</Label>

      <MultiSelect
        placeholder={t(strings.select)}
        defaultValue={filterState.tagType}
        selected={filterState.tagType}
        onChange={handleSelect}
        options={options}
      />
    </Container>
  );
};

export default TagTypeFilter;
