import { apiGet } from '../index';

export async function getMetrics(modelid, grouptype) {
  return apiGet(`metrics/${modelid}?metric=${encodeURIComponent(grouptype)}`);
}

export async function getMetricsDetail(modelid, iou, confidence, step) {
  return apiGet(
    `metrics/${modelid}/detail?iou=${iou}&confidence=${confidence}&step=${step ? step : ''}`
  );
}

export async function getMetricsMatrix(modelid, iou, confidence, step) {
  return apiGet(
    `metrics/${modelid}/matrix?iou=${iou ?? ''}&confidence=${confidence ?? ''}&step=${step ? step : ''
    }`
  );
}

export async function getAvaiableMetrics(modelid) {
  return apiGet(`metrics/${modelid}/available`);
}

export async function getMetricsPrecisionRecall(modelid, iou, confidence, evalmode) {
  return apiGet(
    `metrics/${modelid}/PrecisionRecall?iou=${iou}&confidence=${confidence}&eval=${evalmode}`
  );
}

export async function getBestMetrics(modelid, best, evalmode) {
  return apiGet(`metrics/${modelid}/BestStep?orderBy=${best}&eval=${evalmode}`);
}