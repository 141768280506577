import React from 'react';
import HistoricTrainingItem from './TrainingItem';
import HistoricInferenceItem from './InferenceItem';

// import { Container } from './styles';

export default function Historic(props) {
  const { data, type } = props;
  return (
    <div>
      {data &&
        data.map(item => {
          return type === 'training' ? (
            <HistoricTrainingItem key={item.id} data={item} />
          ) : (
            <HistoricInferenceItem key={item.id} data={item} />
          );
        })}
    </div>
  );
}
