import React, { useState, useEffect } from 'react';
import 'react-rangeslider/lib/index.css';
import { t, strings } from 'language';
import { SliderContainer, Slider, StepInput } from './styles';

export default function(props) {
  const [value, setValue] = useState(props.value);
  const [editingValue, setEditingValue] = useState(props.value);

  const labels = {
    2000: '2k',
    10000: '10k',
    20000: '20k',
    40000: '40k',
    60000: '60k',
    80000: '80k',
    100000: '100k'
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function changeValue(editingValue) {
    if (editingValue < 2000) {
      props.onChange(2000);
      setEditingValue(2000);
    } else if (editingValue > 100000) {
      props.onChange(100000);
      setEditingValue(100000);
    } else props.onChange(editingValue);
  }

  const formatSteps = value => value + ' ' + t(strings.steps);

  return (
    <SliderContainer>
      <Slider
        className="w-100"
        onChange={e => {
          setValue(e);
          setEditingValue(e);
        }}
        onChangeComplete={() => props.onChange(value)}
        value={value}
        min={2000}
        max={100000}
        labels={labels}
        format={formatSteps}
      />
      <StepInput
        type="number"
        defaultValue={value}
        onBlur={e => {
          const value = parseInt(e.target.value);
          changeValue(value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            const value = parseInt(e.target.value);

            changeValue(value);
          }
        }}
        value={editingValue}
        onChange={e => {
          const value = parseInt(e.target.value);
          setEditingValue(value);
        }}
      ></StepInput>
    </SliderContainer>
  );
}
