import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getIdFromUrl } from 'utils/helpers';
import { useSelector } from 'react-redux';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import Dialog from 'components/Dialog';
import { putTags } from 'services/Api/Tag/tag';
import { ChromePicker, HuePicker } from 'react-color';
import { resolveParameters } from 'services/Api';
import { Icon } from 'components/Icon';
import { ThemeContext } from 'styled-components';
import useCookie from 'components/useCookie';
import {
  TagCard,
  Box,
  TagName,
  TagDetails,
  InputField,
  ContainerColor,
  ContainerText,
  ContainerPickers,
  ContainerPicker,
  ContainerColorPicker,
  ColorPicker,
  HotKeysNum
} from './styles';
import TagType from './TagType';
import ButtonIcon from './ButtonIcon';

function TagItem(props) {
  const { item, tagsPasta, dataset, selectedImage } = props;
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const location = history.location.pathname;
  const datasetID = getIdFromUrl(history.location.pathname, 2);
  const auth = useSelector(state => state.auth);
  const { email, activeSubscription } = auth.user;
  const subscription = auth.user.subscriptions.find(element => element.id === activeSubscription);
  const viewer = subscription.role === 'VIEWER';
  const [, setViewerDialog] = useGlobal(
    state => state.viewerDialog,
    actions => actions.setViewerDialog
  );
  const userSubscription = email + activeSubscription;
  const [cookie, setCookie] = useCookie(userSubscription);
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const isInference = !!(inferenceID > 0 && getIdFromUrl(location, 3) === 'inference');
  const [modal, setModal] = useState(false);
  const [modalColor, setModalColor] = useState(false);
  const [newTagName, setNewTagName] = useState(false);
  const [newColor, setNewColor] = useState({
    hex: '#333333',
    rgb: { r: 51, g: 51, b: 51, a: 1 },
    hsl: { h: 0, s: 0, l: 0.2, a: 1 }
  });
  const [handleColor, setHandleColor] = useState({
    hex: '#333333',
    rgb: { r: 51, g: 51, b: 51, a: 1 },
    hsl: { h: 0, s: 0, l: 0.2, a: 1 }
  });
  const [hideTags, setHideTags] = useGlobal(
    state => state.hideTags,
    actions => actions.setHideTags
  );
  const [, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const control =
    item.type === 'Polygon'
      ? hideTags.polygon.indexOf(parseInt(item.tagId, 10)) > -1
      : hideTags.box.indexOf(parseInt(item.tagId, 10)) > -1;
  const paramState = useSelector(state => state.parameters);
  const [iconHotkey, setIconHotkey] = useState('checkbox-blank-outline');
  const [displayHotkey, setDisplayHotkey] = useState('none');
  const [, setHotkeys] = useGlobal(
    state => state.hotkeys,
    actions => actions.setHotkeys
  );
  useEffect(() => {
    hotKeyTest();
    // eslint-disable-next-line
  }, [item.name, cookie]);

  function hotKeyTest() {
    setIconHotkey('checkbox-blank-outline');
    if (cookie) {
      for (let i = 0; i < cookie.tags.length; i += 1) {
        if (parseInt(item.tagId, 10) === cookie.tags[i].id) {
          setIconHotkey(`numeric-${i}-box-outline`);
        }
      }
    }

  }

  function CustomPointer() {
    return (
      <div
        style={{
          transform: 'translate(20%, -10px)',
          cursor: 'pointer',
          fontSize: 20,
          width: '20px',
          height: '20px',
          background: handleColor.hex,
          borderRadius: '50%',
          border: '1px solid #111'
        }}
      />
    );
  }

  function handleEditTag(e) {
    const hex = item.color;
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
    }
    const r = (c >> 16) & 255;
    const g = (c >> 8) & 255;
    const b = c & 255;
    const cmin = Math.min(r / 255, g / 255, b / 255);
    const cmax = Math.max(r / 255, g / 255, b / 255);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;
    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    const color = {
      hex: item.color,
      rgb: { r, g, b, a: 1 },
      hsl: { h, s, l, a: 1 }
    };

    setNewTagName(item.name);
    setNewColor(color);
    setHandleColor(color);
    setModal(true);
    e.preventDefault();
  }

  function handleTags() {
    const tags = hideTags;
    const tagItem = parseInt(item.tagId, 10);
    if (control) {
      switch (item.type) {
        case 'Polygon':
          if (tags.polygon.indexOf(tagItem) > -1) {
            tags.polygon.splice(tags.polygon.indexOf(tagItem), 1);
          }
          break;
        default:
          if (tags.box.indexOf(tagItem) > -1) {
            tags.box.splice(tags.box.indexOf(tagItem), 1);
          }
      }
    } else {
      switch (item.type) {
        case 'Polygon':
          if (tags.polygon.indexOf(tagItem) === -1) {
            tags.polygon.push(tagItem);
          }
          break;
        default:
          if (tags.box.indexOf(tagItem) === -1) {
            tags.box.push(tagItem);
          }
      }
    }

    setHideTags(tags);
    setSelectedAnnotate([]);
  }

  function handleEditTagChange(text) {
    setNewTagName(text);
  }

  function saveChanges() {
    const newTag = { id: item.tagId, description: newTagName, color: newColor.hex };
    const params = `${resolveParameters({
      page: paramState?.page,
      query: paramState?.search,
      markers: paramState?.markers,
      orderBy: paramState?.orderby,
      tags: paramState?.tags,
      withoutTags: paramState?.withoutTags
    })}`;
    putTags(newTag);
    if (isInference) {
      history.push(
        `/dataset/${datasetID}/inference/${inferenceID}/annotate/${selectedImage.id}${params}`
      );
    } else {
      history.push(`/dataset/${datasetID}/annotate/${selectedImage.id}${params}`);
    }
    setModal(false);
  }

  function handleChange(color) {
    setHandleColor(color);
  }

  function changeColor() {
    setNewColor(handleColor);
    setModalColor(false);
    setModal(true);
  }

  function handleHotKey(e) {
    setDisplayHotkey('block');
    e.preventDefault();
  }

  function handleHoverOff() {
    setDisplayHotkey('none');
  }

  function saveHotkey(newHotkey) {
    const date = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
    const newCookie = cookie || {
      tags: [
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' },
        { id: 0, description: '', color: '' }
      ]
    };
    for (let i = 0; i < newCookie.tags.length; i += 1) {
      if (parseInt(item.tagId, 10) === parseInt(newCookie.tags[i].id, 10)) {
        newCookie.tags[i].id = 0;
        newCookie.tags[i].description = '';
        newCookie.tags[i].color = '';
      }
    }
    if (newHotkey !== 'clear') {
      newCookie.tags[newHotkey].id = parseInt(item.tagId, 10);
      newCookie.tags[newHotkey].description = item.name;
      newCookie.tags[newHotkey].color = item.color;
    }
    setCookie(newCookie, { path: '/', expires: date });
    setHotkeys(newCookie);
    hotKeyTest();
    setDisplayHotkey('none');
  }

  return (
    <>
      <TagCard
        style={{
          boxShadow: tagsPasta ? `inset 0 0 0 3px ${theme.white}` : 'none',
          minHeight: tagsPasta ? '110px' : '20px'
        }}
      >
        <div className="float-right" style={{ width: '50px' }}>
          {!viewer && (
            <ButtonIcon
              disabled={isInference}
              style={{ opacity: isInference ? 0.5 : 1, background: theme.primary }}
              onClick={e => handleEditTag(e)}
            >
              <Icon name="edit_icon" style={{ width: 20, height: 20 }} />
            </ButtonIcon>
          )}
          {viewer && (
            <ButtonIcon
              disabled={isInference}
              style={{ opacity: isInference ? 0.5 : 1, background: theme.primary }}
              onClick={() => setViewerDialog(true)}
            >
              <Icon name="edit_icon" style={{ width: 20, height: 20 }} />
            </ButtonIcon>
          )}
          {tagsPasta ? (
            <ButtonIcon style={{ background: theme.primary }} onClick={() => handleTags()}>
              {control ? (
                <Icon name="show" style={{ width: 20, height: 20 }} />
              ) : (
                <Icon name="hide" style={{ width: 20, height: 20 }} />
              )}
            </ButtonIcon>
          ) : (
            ''
          )}
        </div>
        <TagType type={item.type} color={item.color} />
        <TagName
          style={{
            width: 'calc(100% - 30px)'
          }}
        >
          {item.name}
        </TagName>
        <TagDetails>{`${tagsPasta ? tagsPasta.count : 0} ${t(strings.tag_images)}`}</TagDetails>
        <TagDetails>{`${item.count} ${t(strings.tag_folder)} ${dataset?.title}`}</TagDetails>
        {!viewer && (
          <TagDetails style={{ cursor: 'pointer' }} onClick={e => handleHotKey(e)}>
            [hotkey]
            <Icon name={iconHotkey} style={{ width: 24, height: 24, marginLeft: 10 }} />
          </TagDetails>
        )}
        {!viewer && (
          <div style={{ position: 'relative', top: '-60px', left: '25px' }}>
            <HotKeysNum style={{ display: displayHotkey }} onMouseLeave={() => handleHoverOff()}>
              <Icon
                name="numeric-9-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(9)}
              />
              <Icon
                name="numeric-8-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(8)}
              />
              <Icon
                name="numeric-7-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(7)}
              />
              <Icon
                name="numeric-6-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(6)}
              />
              <Icon
                name="numeric-5-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(5)}
              />
              <Icon
                name="numeric-4-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(4)}
              />
              <Icon
                name="numeric-3-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(3)}
              />
              <Icon
                name="numeric-2-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(2)}
              />
              <Icon
                name="numeric-1-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(1)}
              />
              <Icon
                name="numeric-0-box-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey(0)}
              />
              <Icon
                name="checkbox-blank-outline"
                style={{ width: 24, height: 24, cursor: 'pointer' }}
                onClick={() => saveHotkey('clear')}
              />
            </HotKeysNum>
          </div>
        )}
      </TagCard>

      <Dialog
        title={t(strings.edit_tag)}
        open={modal}
        actions={[
          { text: `${t(strings.cancel)}`, action: () => setModal(false) },
          { text: `${t(strings.save)}`, action: () => saveChanges() }
        ]}
      >
        <InputField
          type="text"
          value={newTagName}
          placeholder={item.name}
          onChange={e => handleEditTagChange(e.target.value)}
        />
        <ContainerColor
          onClick={() => {
            setModalColor(true);
            setModal(false);
          }}
        >
          <Box style={{ background: newColor.hex, width: '30px', height: '30px' }} />
          <ContainerText>{t(strings.edit_color)}</ContainerText>
        </ContainerColor>
      </Dialog>

      <Dialog
        title={t(strings.edit_tag_color)}
        open={modalColor}
        actions={[
          {
            text: `${t(strings.cancel)}`,
            action: () => {
              setModalColor(false);
              setModal(true);
            }
          },
          { text: `${t(strings.select)}`, action: () => changeColor() }
        ]}
      >
        <ContainerPickers>
          <ContainerColorPicker>
            <ColorPicker
              style={{ background: newColor.hex, cursor: 'pointer' }}
              onClick={() => handleChange(newColor)}
            />
            <ColorPicker
              style={{ background: handleColor.hex }}
              onClick={() => handleChange(handleColor)}
            />
          </ContainerColorPicker>
          <ContainerPicker>
            <ChromePicker
              color={handleColor}
              onChange={color => handleChange(color)}
              pointer={CustomPointer}
              width="280px"
            />
          </ContainerPicker>
          <HuePicker
            color={handleColor}
            onChange={color => handleChange(color)}
            direction="vertical"
            width="29px"
            height="154px"
            pointer={CustomPointer}
          />
        </ContainerPickers>
      </Dialog>
    </>
  );
}

export default TagItem;
