import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { t, strings } from 'language';
import uuid from 'uuid/v4';
import { useDispatch } from 'react-redux';
import { showTooltip } from 'store/ducks/tooltip';
import Dialog from 'components/Dialog';
import { GetStorageVideo } from 'services/Api/Storage';
import { DatasetAPI } from 'services/Api/Dataset';
import Azure from 'services/Azure/azure-storage.blob.min';
import { createDataset, getAllFolders } from 'services/Api/Dataset/dataset';
import { addToQueue, setUploadedFiles, clearQueue } from 'store/ducks/uploadQueue';
import { loading } from 'store/ducks/general';
import { Icon } from 'components/Icon';
//import { uploadBrowserDataToBlockBlob } from '@azure/storage-blob';
import { ButtonItem, Label, InputField, LabelAlert } from './styles';
import UploadQueue from './UploadQueue';

function VideoToImage(props) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(false);
  const [newDataset, setNewDataset] = useState('');
  const [totalImage, setTotalImage] = useState('');
  const [initialName, setInitialName] = useState('');
  const [datasetId, setDatasetId] = useState();
  const saveUploadedVideo = useCallback(async (datasetid, data) => {
    return DatasetAPI.videoSource(datasetid, data);
  }, []);
  const handleClick = event => {
    hiddenFileInput.current.click();
    event.preventDefault();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
    setModal(true);
  };
  const [folders, setFolders] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const alert = showAlert ? (
    <LabelAlert>{t(strings.name_exists2)}</LabelAlert>
  ) : (
    <div style={{ height: '30px' }} />
  );

  async function getAllSets(folderId) {
    const resultFolders = await getAllFolders(folderId);
    setFolders(resultFolders.results);
  }

  useEffect(() => {
    dispatch(clearQueue());
  }, [newDataset, dispatch]);

  useEffect(() => {
    if (props?.folderId) {
      getAllSets(props.folderId);
    }
  }, [props]);

  function sameNameTest(name) {
    setShowAlert(false);
    // eslint-disable-next-line
    folders.map(item => {
      if (item.title === name) {
        setDatasetId(item.id);
        setShowAlert(true);
      }
    })
  }

  async function createNewFolder() {
    const result = await createDataset({
      description: newDataset,
      folderId: parseInt(props.folderId)
    });
    return result;
  }

  function testFields() {
    if (newDataset.length > 0 && totalImage.length > 0 && initialName.length > 0) {
      createNewImages();
    }
  }

  async function createNewImages() {
    dispatch(loading(true));
    dispatch(clearQueue());
    dispatch(addToQueue(file.name, 0));
    const dataset = showAlert ? { id: datasetId } : await createNewFolder();
    // console.log('createNewImages.dataset', dataset);
    const result = await GetStorageVideo(dataset.id);
    // console.log('createNewImages.result', result);
    let uploadsetviceInstance = null;
    if (result.provider === 'AZURE') {
      uploadsetviceInstance = Azure.createBlobServiceWithSas(result.url, result.token);
    }
    if (file.size === 0) {
      toast.warn(`${t(strings.image)} ${file.name} ${t(strings.is_corrupted)}`, {
        autoClose: false
      });
      return;
    }
    const filename = file.name;
    const fileExtension = file.name.split('.').pop();
    const fileHash = `${uuid()}.${fileExtension}`;
    const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    uploadsetviceInstance.singleBlobPutThresholdInBytes = customBlockSize;
    const speedSummary = uploadsetviceInstance.createBlockBlobFromBrowserFile(
      result.bucket,
      `${result.path}/${fileHash}`,
      file,
      { blockSize: customBlockSize },
      // eslint-disable-next-line func-names
      async function(error) {
        if (error) {
          toast.warn(`${t(strings.error_image)} ${filename}`, {
            autoClose: false
          });
        } else {
          await saveUploadedVideo(dataset.id, {
            overrideMode: 'Override',
            parts: parseInt(totalImage),
            partialName: initialName,
            fileUrl: `${result.path}/${fileHash}`,
            fileName: filename
          });
        }
      }
    );

    let status = 0;
    speedSummary.on('progress', function() {
      status = speedSummary.getCompletePercent();
      displayProcess(status);
    });

    const displayProcess = function(status) {
      dispatch(addToQueue(filename, status));
      if (status === '100.0') {
        dispatch(setUploadedFiles(filename));
      }
    };

    setModal(false);
    dispatch(loading(false));
  }

  function handleNewFolder(text) {
    sameNameTest(text);
    setNewDataset(text);
  }

  function handleTotalImage(text) {
    setTotalImage(text);
  }

  function handleInitialName(text) {
    setInitialName(text);
  }

  return (
    <>
      <ButtonItem
        onClick={handleClick}
        onMouseEnter={() => dispatch(showTooltip(t(strings.convert_video_to_image)))}
        onMouseLeave={() => dispatch(showTooltip(false))}
      >
        <Icon name="video_image" />
      </ButtonItem>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="video/*"
        style={{ display: 'none' }}
      />

      <Dialog
        title={t(strings.convert_video_to_image)}
        open={modal}
        close={() =>  {
          setModal(false);
          setShowAlert(false);
        }}
        actions={[
          {
            text: t(strings.cancel),
            action: () => {
              setModal(false);
              setShowAlert(false);
            }
          },
          { text: t(strings.create), action: () => testFields() }
        ]}
      >
        <Label>{t(strings.selected_video)}</Label>
        <InputField readOnly type="text" value={file && file.name} />
        <br />
        <br />
        <Label>{t(strings.name_of_new_dataset_sent)}</Label>
        <InputField
          type="text"
          placeholder={t(strings.enter_new_dataset_name)}
          onChange={e => handleNewFolder(e.target.value)}
        />
        {alert}
        <Label>{t(strings.total_image)}</Label>
        <InputField
          type="number"
          placeholder={t(strings.enter_total_image)}
          onChange={e => handleTotalImage(e.target.value)}
        />
        <br />
        <br />
        <Label>{t(strings.initial_name_image)}</Label>
        <InputField
          type="text"
          placeholder={t(strings.enter_initial_name_image)}
          onChange={e => handleInitialName(e.target.value)}
        />
      </Dialog>
      <UploadQueue
        onFinish={() => {
          // console.log('finish UploadQueue');
          props.onResetData();
        }}
      />
    </>
  );
}

export default VideoToImage;
