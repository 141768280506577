/* eslint-disable react/jsx-curly-newline */
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useGlobal from 'store/global';
import TextEditor from '../TextEditor';
import { Container } from './styles';

function Editor(props) {
  const { downPoint, className, style, onSubmit } = props;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  //const { geometry, data } = annotation;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      //setHeight(node.getBoundingClientRect().height);
      setHeight(253);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const [zoom] = useGlobal(state => state.zoom);
  const [widthDraw] = useGlobal(state => state.widthDraw);
  const [heightDraw] = useGlobal(state => state.heightDraw);
  const padding = 1;
  const [tag, setTag] = useGlobal(
    state => state.tag,
    actions => actions.setTag
  );

  if (!downPoint) return null;

  const posX = (downPoint.x * imgWidth) / 100;
  const screenX = imgWidth * zoom < widthDraw ? widthDraw : imgWidth * zoom;
  const leftOut =
    downPoint.x - ((width + padding) * 100) / imgWidth / zoom < 0
      ? (padding * 100) / imgWidth / zoom
      : downPoint.x - ((width + padding) * 100) / imgWidth / zoom;
  const left =
    width && posX * zoom + width < screenX
      ? downPoint.x + (padding * 100) / imgWidth / zoom
      : leftOut;
  const posY = (downPoint.y * imgHeight) / 100;
  const screenY = imgHeight * zoom < heightDraw ? heightDraw : imgHeight * zoom;
  const topOut =
    downPoint.y - ((height + padding) * 100) / imgHeight / zoom < 0
      ? downPoint.y - ((height + padding) * 100) / imgHeight / zoom
      : downPoint.y - ((height + padding) * 100) / imgHeight / zoom;
  const top =
    height && posY * zoom + height < screenY
      ? downPoint.y + (padding * 100) / imgHeight / zoom
      : topOut;

  return (
    <Container
      ref={div}
      className={className}
      style={{
        position: 'absolute',
        // left: `${left}%`,
        // top: `${top}%`,
        left: `${left}px`,
        top: `${top}px`,
        transformOrigin: 'top left',
        transform: `scale(${1 / zoom})`,
        zIndex: 10,
        ...style
      }}
    >
      <TextEditor
        onChange={e =>
          setTag({
            tagId: '',
            description: e.target.value
          })
        }
        onSelect={value => {
          setTag({
            tagId: value.id,
            description: value.description
          });
        }}
        onSubmit={onSubmit}
        value={tag && tag.description}
        controller={tag}
      />
    </Container>
  );
}

Editor.defaultProps = {
  className: '',
  style: {}
};

export default React.memo(Editor);
