/* eslint-disable no-shadow */
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useGlobal from 'store/global';
import { Container } from './styles';

function TagName(props) {
  const { geometry, data } = props;
  const [zoom] = useGlobal(state => state.zoom);
  const [showTagsName] = useGlobal(state => state.showTagsName);
  const showDisplay = showTagsName ? 'block' : 'none';

  return (
    <Container
      style={{
        position: 'absolute',
        left: `${0}%`,
        bottom: `${0}%`,
        transformOrigin: 'bottom left',
        transform: `scale(${(1 / zoom) * 0.8})`,
        zIndex: 10,
        color: data?.color,
        display: showDisplay
      }}
    >
      {data?.description}
    </Container>
  );
}

TagName.defaultProps = {
  style: {},
  className: ''
};

export default TagName;
