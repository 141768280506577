import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Star } from './styles';
import { ModelAPI } from 'services/Api/Model';
import { loading } from 'store/ducks/general';

import star_off from 'assets/images/favorite_off.svg';
import star_on from '../../assets/images/favorite_on.svg';
import { changeFavorite } from 'services/Api/Favorite';
import { Icon } from 'components/Icon';
function Favorite({ data, type }, props) {
  const [favorite, setFavorite] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setFavorite(data?.favorite);
  }, []);

  async function handleChangeFavorite() {
    dispatch(loading(true));
    const item = await changeFavorite(data.id, type);
    setFavorite(item?.favorite);
    dispatch(loading(false));
  }

  return (
    <Icon
      name={favorite ? 'favorite_on' : 'favorite_off'}
      onClick={e => {
        e.preventDefault();
        handleChangeFavorite();
        e.stopPropagation();
      }}
      style={{ alignSelf: 'flex-end', margin: 5 }}
      width="20"
      height="20"
    />
  );
}

export default Favorite;
