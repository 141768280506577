import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const Button = styled.button`
  background: ${props => props.theme.white};
  border: none;
  display: block;
  padding: 8px 25px;
  border-radius: 5px;
  color: ${props => props.theme.primary};
`;
