import { all } from 'redux-saga/effects';

import { sagas as AuthSaga } from './ducks/auth';
import { sagas as AnnotateSaga } from './ducks/annotate';
import { sagas as InferenceSaga } from './ducks/inference';
import { sagas as InferenceDetailsSaga } from './ducks/inferenceDetails';
import { sagas as TagSaga } from './ducks/annotateTag';

const sagas = []
  .concat(AuthSaga)
  .concat(AnnotateSaga)
  .concat(InferenceSaga)
  .concat(InferenceDetailsSaga)
  .concat(TagSaga);
export default function* rootSaga() {
  yield all(sagas);
}
