import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import InsideLoading from 'components/Loading/insideLoading';
import { InfiniteScrollWrap } from './styles';
import useGlobal from 'store/global';

const Scroll = forwardRef((props, ref) => {
  useEffect(() => {
    const searchbarRect = document.getElementById('searchbar');
    const rectSearchBar = searchbarRect ? searchbarRect.getBoundingClientRect() : { bottom: 0 };
    const rectContent = document.getElementById('global_container').getBoundingClientRect();
    const searchBarBottom = parseInt(rectSearchBar.bottom, 0);
    let rectContentBottom = parseInt(rectContent.bottom * 0.9, 0);
    rectContentBottom = rectContentBottom > 800 ? 800 : rectContentBottom;
    setHeight(rectContentBottom - searchBarBottom);
  }, [document.getElementById('searchbar'), document.getElementById('content')]);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(null);
  const [dataStarted, setDataStarted] = useState(false);

  const { getData, hasMore, length, forceHeight, style } = props;

  const [, setImagePage] = useGlobal(
    state => state.imagePage,
    actions => actions.setImagePage
  );

  useEffect(() => {
    if (forceHeight) setHeight(forceHeight);
  }, [forceHeight]);

  const startData = useCallback(async () => {
    if (page === 0 && hasMore === true) {
      setLoading(true);
      await getData(1);

      setPage(1);
      setImagePage(1);
      setLoading(false);
      //debugger;
    }
  }, [getData, page]);

  useEffect(() => {
    if (!dataStarted) {
      startData();
      return;
    }
    setDataStarted(true);
  }, [startData, dataStarted]);

  useImperativeHandle(ref, () => ({
    resetScroll() {
      setPage(0);
      setImagePage(0);

      getData('reset');
      setDataStarted(false);
    }
  }));
  return !height ? (
    <></>
  ) : (
    <InfiniteScrollWrap
      dataLength={length}
      next={async () => {
        setPage(page + 1);
        setImagePage(page + 1);
        setLoading(true);
        await getData(page + 1);
        setLoading(false);
      }}
      hasMore={hasMore}
      height={`${height}px`}
      style={style}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {props.children}
        {loading && <InsideLoading key={'InsideLoading'} />}
      </div>
    </InfiniteScrollWrap>
  );
});

export default React.memo(Scroll);
