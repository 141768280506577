import React, { useRef, useState, useEffect } from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import { uniqBy } from 'lodash';
import { t, strings } from 'language';
import Moment from 'react-moment';
import InfiniteScroll from 'components/InfiniteScroll';
import { getAllTrainings } from 'services/Api/Training';
import { Link } from 'react-router-dom';
import useWindowSize from './components/useWindowSize';
import LogItens from './LogItens';
import ButtonIcon from './ButtonIcon';
import { ReactComponent as RestartIcon } from './assets/images/restart.svg';
import {
  StatusContainer,
  ButtonContainer,
  FolderContainer,
  FolderTitle,
  FolderButton,
  Container
} from './styles';
import { Icon } from 'components/Icon';

function TrainingSessionLogs(props) {
  const { order } = props;
  const [data, setData] = useState([]);
  const size = useWindowSize();
  const { height, width } = size;
  const [hasMore, setHasmore] = useState(true);
  const scrollRef = useRef();
  const [collapseStatus, setCollapseStatus] = useState([]);
  const uniqueModels = uniqBy(data, 'modelName');

  async function getData(page) {
    if (page === 'reset') {
      setData([]);
      setHasmore(true);
      return;
    }
    setHasmore(false);
    const result = await getAllTrainings(page, '', order);
    setHasmore(result.currentPage < result.pageCount);
    setData([...data, ...result.results]);

    return result;
  }

  function toggleCollapse(index) {
    let status = [...collapseStatus];
    status[index] = !collapseStatus[index];
    setCollapseStatus(status);
  }

  useEffect(() => {
    setData([]);
    scrollRef.current.resetScroll();
  }, [order]);

  return (
    <Container
      className="mh-100 mw-100 scrollbar"
      style={{
        height: `${height - 220}px`,
        overflow: 'visible !important',
        zIndex: 0
      }}
    >
      <Row>
        <InfiniteScroll
          ref={scrollRef}
          getData={getData}
          hasMore={hasMore}
          length={data.length}
          height={`${height - 220}px`}
        >
          {uniqueModels &&
            uniqueModels.map((item, index) => {
              return (
                <FolderContainer>
                  <FolderTitle>
                    <p style={{ width: `${width}px` }}>
                      <b>{`${item.modelName}`}</b> -
                      <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                        {item.startedAt}
                      </Moment>
                      {` - ${item.steps} ${t(strings.steps)}`}
                    </p>
                    <StatusContainer status={item.status}>{item.status}</StatusContainer>
                    <ButtonContainer>
                      <Link key="1" to={`/model/${item.modelId}?open`}>
                        <ButtonIcon>
                          <Icon name="rotate" width="20" height="20" />
                        </ButtonIcon>
                      </Link>
                    </ButtonContainer>
                    <FolderButton
                      style={{
                        display: `${item && item.length > 0 ? 'block' : 'block'}`
                      }}
                      onClick={() => toggleCollapse(index)}
                      open={collapseStatus[index]}
                    />
                  </FolderTitle>
                  <Collapse isOpen={collapseStatus[index]} className="mt-3">
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                      <Col className="ts_log_header">{t(strings.started_at)}</Col>
                      <Col className="ts_log_header">{t(strings.finished_at)}</Col>
                      <Col className="ts_log_header">{t(strings.training_eval_class)}</Col>
                      <Col className="ts_log_header">{t(strings.images)}</Col>
                      <Col className="ts_log_header">{t(strings.used_boxes)}</Col>
                      <Col className="ts_log_header">{t(strings.machine)}</Col>
                      <Col className="ts_log_header">{t(strings.steps)}</Col>
                      <Col className="ts_log_header">{t(strings.status)}</Col>
                    </Row>
                    {data
                      .filter(obj => obj.modelName === item.modelName)
                      .map(list => {
                        return <LogItens data={list} />;
                      })}
                    <br />
                  </Collapse>
                </FolderContainer>
              );
            })}
        </InfiniteScroll>
      </Row>
    </Container>
  );
}

export default TrainingSessionLogs;
