import Header from 'components/Header';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useGlobal from 'store/global';
import { GlobalFluid } from './styles';
// import { Switch, Route } from 'react-router-dom'
// import { SmartVisionRouter } from 'routes'

function FluidLayout(props) {
  const { noContainer, children } = props;
  const auth = useSelector(state => state.auth);
  const [modalActive] = useGlobal(state => state.modalActive);
  const height = window.innerHeight;

  useEffect(() => {
    if (!auth.loggedin) window.location.href = '/login';
  }, [auth]);
  return (
    <GlobalFluid>
      <div id="global_container" style={{ minHeight: height }}>
        <Header noContainer />
        <div className="d-flex flex-row align-items-start justify-content-start">
          <div className="w-100" style={{ filter: `${modalActive ? 'blur(5px)' : 'none'}` }}>
            {children}
          </div>
        </div>
      </div>
    </GlobalFluid>
  );
}

export default FluidLayout;
