import styled from 'styled-components';

export const Title = styled.small`
  color: #909090;
  cursor: default;
`;

export const TagDiv = styled.div`
  border: 1px solid ${props => props.theme.white};
  height: calc(100% - 200px);
`;

export const Label = styled.small`
  width: 85px;
  color: #909090;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BtnHidden = styled.small`
  color: ${props => props.theme.white};
  font-size: 20px;
  line-height: 75%;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${props => props.theme.white};
  margin-bottom: 5px;
  width: 25px;
`;

export const TopButton = styled.button`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 2px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
`;
