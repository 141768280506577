import React, { useState, useCallback, useEffect } from 'react';
import { slide as MainMenu } from 'react-burger-menu';
import { toast } from 'react-toastify';
import InsideLoading from 'components/Loading/insideLoading';

import { getNotifications, setNotificationRead } from 'services/Api/Notifications';

import { Container, CloseButton } from './styles';

import NotificationItem from './NotificationItem';
import { clearNotificationList } from 'utils/notificationHelpers';
import { t, strings } from 'language';

export default function NotificationPanel(props) {
  const [notification, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, closeMenu, setModalActive } = props;

  const loadData = async () => {
    setIsLoading(true);
    const notificationResult = await getNotifications();
    setIsLoading(false);

    setNotifications(notificationResult.results);
  };

  useEffect(() => {
    if (isOpen) {
      loadData();
      setNotificationRead();
      clearNotificationList();
    }
  }, [isOpen]);

  return (
    <MainMenu right isOpen={isOpen} customBurgerIcon={false} width={380} disableOverlayClick>
      <CloseButton visible={isOpen} onClick={closeMenu}>
        <img src={require('./assets/img/x.png')} alt="Close" />
      </CloseButton>
      <Container>
        <h2>{t(strings.notifications)}</h2>
        <div className="mt-2 notificationContainer">
          {isLoading && <InsideLoading />}
          {notification.map(item => {
            return (
              <NotificationItem setModalActive={setModalActive} item={item}>
                {item.message}
              </NotificationItem>
            );
          })}
        </div>
      </Container>
    </MainMenu>
  );
}
