export const colors = {
  background: '#111111',
  primary: '#111111',
  white: '#fff',
  black: '#000',
  header: '#000',
  secondary: '#2F2F2F',
  text: '#D1D1D1',
  default: '#E94D1F',
  green: '#fff',
  error: '#c44040',
  warning: '#C4A53F',
  iconColor: '#fff'
};
