/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import useGlobal from 'store/global';

function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);
  const [, setKeyPressing] = useGlobal(
    state => state.keyPressing,
    actions => actions.setKeyPressing
  );

  function downHandler({ key }) {
    // console.log('downHandler.key', key);
    if (key === targetKey) {
      setKeyPressed(true);
      setKeyPressing(key);
    }
  }

  const upHandler = ({ key }) => {
    // console.log('upHandler.key', key);
    if (key === targetKey) {
      setKeyPressed(false);
      setKeyPressing(null);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
}

export default useKeyPress;
