import styled from 'styled-components';

import { Container as ContainerRS } from 'reactstrap';

export const Container = styled(ContainerRS)`
  background: ${props => props.theme.secondary};
`;

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 8px;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  width: 30px;
  height: 30px;
`;

export const Title = styled.small`
  color: #909090;
  cursor: default;
`;
