import React, { useState, useEffect } from 'react';

import useGlobal from 'store/global';

import { Container } from './styles';

export default function BulkDeleteSelection({ data, children }) {
  const [deleteMode, setDeleteMode] = useGlobal(
    state => state.deleteMode,
    actions => actions.setDeleteMode
  );
  const [deleteModeList, addDeleteModeList] = useGlobal(
    state => state.deleteModeList,
    actions => actions.addDeleteModeList
  );
  const [, replaceDeleteModeList] = useGlobal(
    state => state.deleteModeList,
    actions => actions.replaceDeleteModeList
  );
  const [selected, setSelected] = useState(false);

  const handleSelectitem = e => {
    if (!deleteModeList.includes(data.id)) {
      addDeleteModeList(data.id);
    } else {
      const index = deleteModeList.indexOf(data.id);
      deleteModeList.splice(index, 1);
      replaceDeleteModeList(deleteModeList);
    }
    setSelected(!selected);
  };

  useEffect(() => {
    if (!deleteMode) {
      setSelected(false);
    }
  }, [deleteMode]);

  useEffect(() => {
    return () => {
      setDeleteMode(false);
    };
  }, []);

  return (
    <Container selected={deleteMode ? selected : false}>
      <button type="button" onClick={e => handleSelectitem(e)}>
        <div className={deleteMode ? 'insideContainer' : ''}>{children}</div>
      </button>
    </Container>
  );
}
