import querystring from 'querystring';
import { apiGet, apiPost } from '../index';

export async function makeInference(modelId, inferencePoints, datasets, confidence) {
  return apiPost(`Inference/model/${modelId}`, {
    inferencePoints,
    datasets,
    confidence
  });
}

export async function getInferences(modelId) {
  const query = querystring.stringify({
    page: 1,
    size: 100000
  });

  return apiGet(`Inference/model/${modelId}?${query}`);
}

export async function getAllInferences(page, size) {
  //OrderBy {string} Available values : Id, Name, Status
  if (!size) {
    size = 5000;
  }
  if (!page) {
    page = 1;
  }
  return apiGet(`Inference?size=${size}&page=${page}`);
}

export async function getInferenceDataset(inferenceId, datasetId, page) {
  if (!page) {
    page = 1;
  }
  const query = querystring.stringify({
    page: page,
    size: 125
  });

  return apiGet(`Inference/${inferenceId}/dataset/${datasetId}/images?${query}`);
}

export async function getInferenceImage(inferenceId, imageId) {
  return apiGet(`Inference/${inferenceId}/image/${imageId}`);
}

export async function getInferenceDetailsLog(sessionId, page = 1, size = 20) {
  return apiGet(`Inference/${sessionId}/logs?page=${page}&size=${size}`);
}

export async function getInferenceDetails(inferenceId) {
  return apiGet(`Inference/${inferenceId}`);
}
