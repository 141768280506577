import styled from 'styled-components';

export const Container = styled.div`
  height: 70px;
  width: 377px;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: ${props => props.theme.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: baseline;
  height: 70px;
  flex-direction: column;
`;

export const ImageTitle = styled.span`
  color: ${props => props.theme.white};
  width: 300px;
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProgressBarCompleted = styled.div`
  width: 219px;
  height: 15px;
  background: ${props => props.theme.default} 0% 0% no-repeat padding-box;
  border: 2px solid ${props => props.theme.default};
  border-radius: 5px;
  opacity: 1;
`;

export const ProgressBar = styled.div`
  width: 219px;
  height: 15px;
  background: #715f4c 0% 0% no-repeat padding-box;
  border: 2px solid ${props => props.theme.default};
  border-radius: 5px;
  opacity: 1;
`;

export const ProgressLoad = styled.div`
  width: ${props => props.progress}%;
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  transition: width 1.5s ease;
  border-radius: 5px;
  opacity: 1;
  height: 14px;
  margin-top: -1.5px;
  margin-left: -1.7px;
`;
