import React from 'react';

import { Container, Input, CopyButton } from './styles';
import { toast } from 'react-toastify';
import { Icon } from 'components/Icon';

function CopyPanel({ value, prefix }) {
  const copyToClipboard = text => {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toast.info('Copiado com sucesso.');
  };

  return (
    <Container>
      {prefix && <h3>{prefix}</h3>}
      <Input value={value} />
      <CopyButton onClick={() => copyToClipboard(value)}>
        <Icon name="copy_icon" />
      </CopyButton>
    </Container>
  );
}

export default CopyPanel;
