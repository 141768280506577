import styled from 'styled-components';

import { Container as ContainerRS } from 'reactstrap';
export const Container = styled(ContainerRS)``;
export const InternalContainer = styled.div`
  width: 195px;
`;
export const ContainerTop = styled.div`
  background: ${props => props.theme.secondary};
  align-items: center;
  justify-content: center;
  display: flex;
  width: 200px;
  height: 180px;
`;
export const Name = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 0px;
  padding: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.default};
  }
`;

export const Image = styled.img`
  height: 90px;
`;
export const ContainerRight = styled.div`
  border-left: 1px solid #707070;
  height: 100%;
  padding-left: 50px;
`;
