import React from 'react';
import { Container } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import ModelBrowser from 'components/ProjectBrowser/components/ModelBrowser';
import { t, strings } from 'language';

function Models() {
  return (
    <Container>
      <TitleBreadcrumb position={2} name={t(strings.models)} url="/models" />
      <ModelBrowser />
    </Container>
  );
}

export default Models;
