import React, { useEffect, useState } from 'react';
import { t, strings } from 'language';
import { Row, Col } from 'reactstrap';
import { DatasetAPI } from 'services/Api/Dataset';
import { LabelContainer, TagList } from './styles';

export default function(props) {
  const [datasetSumary, setDatasetSumary] = useState(null);

  async function loadData(datasetId) {
    const dataset = await DatasetAPI.getDataset(datasetId);
    setDatasetSumary(dataset);
  }
  useEffect(() => {
    if (!props.deleted) loadData(props.id ? props.id : props.datasetId);
  }, [props]);

  if (!datasetSumary) {
    if (props.deleted) return <p>{t(strings.dataset_deleted)}</p>;
    return <p>{t(strings.loading)}</p>;
  }
  console.log('datasetSumary', datasetSumary);
  return (
    <>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.name)}:`}</label>
            <p>{datasetSumary.title}</p>
          </LabelContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.total)}:`}</label>
            <p>{`${datasetSumary?.totalImages} ${t(strings.images)}`}</p>
          </LabelContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          {datasetSumary.tagSummary.map((i, index) => {
            return (
              <div key={index}>
                <LabelContainer>
                  <label>{i.type}</label>
                </LabelContainer>
                <TagList>
                  {i.tags?.map((tag, index) => {
                    return <li key={index}>{`${tag.tag}: ${tag.total}`}</li>;
                  })}
                </TagList>
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
}
