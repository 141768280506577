import { Icon } from 'components/Icon';
import React from 'react';
import { Container, Button, Count } from './styles';
import './styles.css';
export const ButtonsType = {
  Default: 'default-button',
  Training: 'training-button'
};

const CustomButton = props => (
  <Container className={`d-flex align-items-center justify-content-center mb-2`}>
    {props.count && <Count>{props.count}</Count>}
    <Button {...props} className={`${props.fluid ? 'w-100' : null} ${props.type} `}>
      {props.icon ? (
        <Icon name={props.icon} style={{ marginRight: 10 }} width="20" heigth="20" />
      ) : null}
      {props.children}
    </Button>
  </Container>
);

export default CustomButton;
