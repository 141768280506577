import styled from 'styled-components';

export const GraphContainer = styled.div`
  display: flex;
  font-size: 11px;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  width: 100%;
  height: 18px;
  padding: 0px;
`;

export const LeftBar = styled.div`
  float: left;
  text-align: left;
  font-size: 11px;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.default};
  padding: 3px;
  width: ${props => props.leftWidth};
  height: 18px;
`;

export const RightBar = styled.div`
  float: right;
  text-align: right;
  font-size: 11px;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.white};
  padding: 3px;
  padding-top: 2px;
  width: ${props => props.rightWidth};
  height: 18px;
`;

export const LeftText = styled.div`
  position: absolute;
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  background-color: tranparent;
  padding: 2px;
  width: 50%;
  height: 18px;
`;

export const RightText = styled.div`
  position: absolute;
  right: 18px;
  text-align: right;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  background-color: tranparent;
  padding: 2px;
  width: 50%;
  height: 18px;
`;

export const Data1 = styled.div`
  float: left;
  text-align: left;
  font-size: 11px;
  font-weight: bold;
`;

export const Data2 = styled.div`
  float: right;
  text-align: right;
  font-size: 11px;
  font-weight: bold;
`;
