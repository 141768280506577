import { takeLatest, put, call } from 'redux-saga/effects';
import { DatasetAPI } from 'services/Api/Dataset';
import { loading, loadingMini } from './general';

const INITIAL_STATE = {
  images: [],
  selectedImage: [],
  datasetId: null,
  annotation: [],
  editingAnnotation: [],
  imageId: null
};

export const Types = {
  GET_IMAGE: 'annotate/GET_IMAGE',
  SET_IMAGE: 'annotate/SET_IMAGE',
  SELECT_IMAGE: 'annotate/SELECT_IMAGE',
  SET_ANNOTATION: 'annotate/SET_ANNOTATION',
  SET_EDITING: 'annotate/SET_EDITING'
};

export function GetSelectImage(datasetId, imageId, show) {
  const showMini = show || false;
  return {
    type: Types.GET_IMAGE,
    payload: {
      datasetId,
      imageId,
      showMini
    }
  };
}

export function SetImages({ images }) {
  return {
    type: Types.SET_IMAGES,
    payload: {
      images
    }
  };
}

export function SelectImage(selectedImage) {
  return {
    type: Types.SELECT_IMAGE,
    payload: {
      selectedImage
    }
  };
}

export function SetAnnotation(annotation) {
  return {
    type: Types.SET_ANNOTATION,
    payload: {
      annotation
    }
  };
}

export function SetEditing(editingAnnotation) {
  return {
    type: Types.SET_EDITING,
    payload: {
      editingAnnotation
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_IMAGE:
      return {
        ...state,
        datasetId: payload.datasetId,
        imageId: payload.imageId,
        images: payload.images,
        selectedImage: payload.selectedImage,
        dataset: payload.dataset,
        totalImages: payload.totalImages
      };
    case Types.SELECT_IMAGE:
      return {
        ...state,
        image: payload.image,
        selectedImage: payload.selectedImage,
        dataset: payload.dataset,
        totalImages: payload.totalImages
      };
    case Types.SET_ANNOTATION:
      return {
        ...state,
        annotation: payload.annotation
      };
    case Types.SET_EDITING:
      return {
        ...state,
        editingAnnotation: payload.editingAnnotation
      };
    default:
      return state;
  }
}

/*---------------------*/

function* getImageFromAPI(action) {
  const { payload } = action;
  yield put(payload.showMini ? loadingMini(true) : loading(true));
  try {
    const dataset = yield call(DatasetAPI.getDataset, payload.datasetId);
    const result = yield call(DatasetAPI.getImage, payload.imageId);
    yield put({
      type: Types.SET_IMAGE,
      payload: {
        datasetId: payload.datasetId,
        imageId: payload.imageId,
        images: [result],
        selectedImage: result,
        dataset,
        totalImages: dataset.totalImages
      }
    });
  } catch (error) {
    throw error;
  }
  yield put(payload.showMini ? loadingMini(false) : loading(false));
}

export const sagas = [takeLatest(Types.GET_IMAGE, getImageFromAPI)];
