import React, { useState, useEffect } from 'react';

import { Row, Col } from 'reactstrap';
//import { toast } from 'react-toastify';
import Matrix from './matrix';
import StepSlider from './stepSlider';
import MulitSelect from 'components/MultSelect';
import InsideLoading from 'components/Loading/insideLoading';
import {
  Container,
  MatrixTab,
  ProgressLoad,
  ProgressBar,
  NextButton,
  MatrixTabContainer,
  LoadingContainer
} from './styles';
import { getMetricsDetail } from 'services/Api/Metrics';
import { t, strings } from 'language';

export default function(props) {
  const [trainMatrix, setTrainMatrix] = useState(null);
  const [evalMatrix, setEvalMatrix] = useState(null);
  const [iou, setIou] = useState(0.5);
  const [confidence, setConfidence] = useState(0.5);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState(null);
  const [selectedMatrix, setSelectedMatrix] = useState('train');
  const [matrix, setMatrix] = useState(null);
  const [theme, setTheme] = useState(1);

  useEffect(() => {
    if (props.modelId) {
      async function getData() {
        const metrics = await getMetricsDetail(props.modelId, iou, confidence, step);

        setTrainMatrix(metrics?.train);
        setEvalMatrix(metrics?.eval);
        setStep(metrics?.step);
        setSteps(metrics?.steps);

        if (options.length === 0 && metrics?.classes) {
          const options = metrics.classes.map((i, index) => ({
            label: i,
            value: index
          }));
          setSelected(options);
          setOptions(options);
        }
      }
      getData();
    }
  }, [props.modelId, iou, confidence, step, options.length]);

  useEffect(() => {
    setMatrix(selectedMatrix === 'train' ? trainMatrix : evalMatrix);
  }, [setMatrix, selectedMatrix, trainMatrix, evalMatrix]);

  function backStep() {
    const index = steps.indexOf(step);
    if (index > 0) setStep(steps[index - 1]);
    else setStep(steps[0]);
  }

  function forwardStep() {
    const index = steps.indexOf(step);
    if (index < steps.length - 1) setStep(steps[index + 1]);
    else setStep(steps[steps.length - 1]);
  }
  useEffect(() => {
    const matrizTheme = localStorage.getItem('matrizTheme');
    if (matrizTheme) {
      setTheme(matrizTheme);
    }
  }, []);
  const handleColorsChange = event => {
    // console.log('theme selected', event.target.value);
    localStorage.setItem('matrizTheme', event.target.value);
    setTheme(event.target.value);
  };

  return (
    <Container>
      {matrix === null ? (
        <LoadingContainer>
          <InsideLoading />
        </LoadingContainer>
      ) : (
        <>
          {
            <>
              {options && steps && (
                <>
                  <Row>
                    <Col>
                      <div className="p-4">
                        <p>{`${t(strings.filters)}:`}</p>
                        <MulitSelect
                          options={options}
                          onChange={e => setSelected(e)}
                          selected={selected}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center w-100 justify-content-center">
                        <span className="mr-2">{`${t(strings.steps)}:`}</span>
                        <div className="d-flex align-items-center">
                          <NextButton onClick={backStep} disabled={step === steps[0]}>
                            {'<'}
                          </NextButton>
                          <span className="p-3">{step}</span>
                          <NextButton onClick={forwardStep}> {'>'} </NextButton>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center w-100 justify-content-center">
                        <span>{`${t(strings.iou)}:`}</span>
                        <StepSlider
                          label=""
                          labels={{
                            0.5: '0.50',
                            0.75: '0.75',
                            0.95: '0.95'
                          }}
                          min={0.5}
                          max={0.95}
                          step={0.25}
                          value={iou}
                          onChange={e => setIou(e)}
                        ></StepSlider>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center w-100 justify-content-center">
                        <span>{`${t(strings.confidence)}:`}</span>
                        <StepSlider
                          label=""
                          labels={{
                            0.5: '0.5',
                            0.6: '0.6',
                            0.7: '0.7',
                            0.8: '0.8',
                            0.9: '0.9'
                          }}
                          min={0.5}
                          max={0.9}
                          step={0.1}
                          value={confidence}
                          onChange={e => setConfidence(e)}
                        ></StepSlider>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          }
          {matrix?.matrix ? (
            <>
              <Row>
                <Col>
                  <MatrixTabContainer>
                    <MatrixTab
                      active={selectedMatrix === 'train'}
                      onClick={() => setSelectedMatrix('train')}
                    >
                      {t(strings.train)}
                    </MatrixTab>
                    <MatrixTab
                      active={selectedMatrix === 'eval'}
                      onClick={() => setSelectedMatrix('eval')}
                    >
                      {t(strings.eval)}
                    </MatrixTab>
                    <div className="d-flex w-100 mb-3 ">
                      <div className="d-flex w-100 align-items-center"></div>
                      <span style={{ width: 240 }}>{`${t(strings.color_pattern)}:`}</span>
                      <select
                        style={{ marginRight: 20 }}
                        defaultValue={theme}
                        onChange={handleColorsChange}
                      >
                        <option value="1" selected>{t(strings.standard)}</option>
                        <option value="2">{t(strings.alternative)}</option>
                      </select>

                      <span>{`${t(strings.precision)}:`}</span>
                      <div className="ml-1 mr-3">
                        <ProgressBar>
                          <span>{(matrix.precision ? matrix.precision : 0).toFixed(0)}%</span>
                          <ProgressLoad
                            progress={(matrix.precision ? matrix.precision : 0).toFixed(0)}
                          />
                        </ProgressBar>
                      </div>
                      <span>{`${t(strings.recall)}:`}</span>
                      <div className="ml-1">
                        <ProgressBar>
                          <span>{(matrix.recall ? matrix.recall : 0).toFixed(2)}%</span>
                          <ProgressLoad progress={(matrix.recall ? matrix.recall : 0).toFixed(0)} />
                        </ProgressBar>
                      </div>
                    </div>
                  </MatrixTabContainer>
                </Col>
              </Row>
              <div className="d-flex flex-column w-100 ">
                <Matrix
                  matrix={matrix.matrix}
                  precisions={matrix.precisions}
                  recalls={matrix.recalls}
                  labels={options.map(i => i.label)}
                  showIndex={selected.map(i => i.value)}
                  theme={theme}
                ></Matrix>
              </div>
            </>
          ) : (
            <LoadingContainer>
              <p>{t(strings.no_data)}</p>
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
}
