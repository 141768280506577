import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { DatasetAPI } from 'services/Api/Dataset';
import { LabelContainer, TagList } from './styles';
import { t, strings } from 'language';

export default function(props) {
  const [folderSumary, setFolderSumary] = useState(null);

  async function loadData(folderId) {
    const dataset = await DatasetAPI.getFolder(folderId);
    setFolderSumary(dataset);
  }
  useEffect(() => {
    loadData(props.id);
  }, [props]);

  if (!folderSumary) {
    return <p>{t(strings.loading)}</p>;
  }
  return (
    <>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.name)}:`}</label>
            <p>{folderSumary.title}</p>
          </LabelContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.total)}:`}</label>
            <p>{`${folderSumary?.totalImages} ${t(strings.images)}`}</p>
          </LabelContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          {folderSumary.tagSummary.map((i, index) => {
            return (
              <div key={index}>
                <LabelContainer>
                  <label>{i.type}</label>
                </LabelContainer>
                <TagList>
                  {i.tags?.map((tag, index) => {
                    return <li key={index}>{`${tag.tag}: ${tag.total}`}</li>;
                  })}
                </TagList>
              </div>
            );
          })}
        </Col>
      </Row>
    </>
  );
}
