import React, { useEffect, useState } from 'react';
import history from 'services/history';
import { Container } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import { t, strings } from 'language';
import TrainingSessionLogs from 'components/TrainingSessionLogs';
import InferenceSessionLogs from 'components/InferenceSessionLogs';
import { getIdFromUrl } from 'utils/helpers';
import { OrderSelect } from './styles';

function Logs() {
  const [order, setOrder] = useState('Id');
  const [title, setTitle] = useState(null);
  const [urlLog, setUrlLog] = useState(null);

  useEffect(() => {
    async function getData() {
      const url = getIdFromUrl(history.location.pathname, 2);
      setUrlLog(url);
      if (url === 'training_session') {
        setTitle(t(strings.training_session_logs));
      } else {
        setTitle(t(strings.inference_session_logs));
      }
    }
    getData();
  }, []);

  return (
    <Container fluid className="global_annotate">
      <TitleBreadcrumb position={2} name={`${title}`} url={`/logs/${urlLog}`} />
      <div style={{ position: 'absolute', top: '170px', right: '20px' }}>
        {`${t(strings.order_by)} `}
        <OrderSelect
          onChange={e => {
            setOrder(e.target.value);
          }}
        >
          <option key="1order" value="Id" selected>
            {t(strings.started_at)}
          </option>
          <option key="2order" value="Name">
            {t(strings.model_name)}
          </option>
          <option key="3order" value="Status">
            {t(strings.status)}
          </option>
        </OrderSelect>
      </div>
      {urlLog && urlLog === 'training_session' ? (
        <TrainingSessionLogs order={order} />
      ) : (
        <InferenceSessionLogs order={order} />
      )}
    </Container>
  );
}

export default Logs;
