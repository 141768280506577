import React, { useEffect, useState } from 'react';
import NotificationBadge, { Effect } from 'react-notification-badge';
import { getNotificationCount, getNotifications } from 'services/Api/Notifications';
import { setNotificationList } from 'utils/notificationHelpers';
import { toast } from 'react-toastify';

//import { Container } from './styles';
import { Icon } from 'components/Icon';

export default function NotificationBadgeContainer({ setNotificationIsOpen, setModalActive }) {
  const [notificationCounter, setNotificationCounter] = useState(0);

  useEffect(() => {
    getNotificationCounterData();

    const interval = setInterval(() => {
      getNotificationCounterData();
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function getNotificationCounterData() {
    const count = await getNotificationCount();
    setNotificationCounter(count);
  }

  function unescapeHTML(html) {
    var escapeEl = document.createElement('div');
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  }

  async function getLastNotification() {
    const notification = await getNotifications(1);

    if (setNotificationList(notification.results[0].id)) {
      toast.info(unescapeHTML(notification.results[0].message));
    }
  }

  useEffect(() => {
    if (notificationCounter > 0) {
      getLastNotification();
    }
    // eslint-disable-next-line
  }, [notificationCounter]);

  return (
    <button
      type="button"
      onClick={() => {
        setNotificationIsOpen(true);
        setModalActive(true);
        setNotificationCounter(0);
      }}
    >
      <div className="notificationContainer">
        <NotificationBadge count={notificationCounter} effect={Effect.SCALE} />
      </div>
      <Icon name="notification" style={{ marginTop: 15 }} alt="icon" color="#fff" />
    </button>
  );
}
