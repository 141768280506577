import styled, { keyframes } from 'styled-components';

const moveIn = () => keyframes`
  0% {
    transform: translate(0, -100px);
    opacity:0.1
  }
  100% {
    transform: translate(0, -0px);
  }

`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  left: 50%;
  margin-left: -${props => props.width / 2}px;
  animation: ${moveIn} 2s ease-in;
  animation-direction: normal;
`;

export const ImageDefault = styled.div`
  background-image: url(${props => props.src});
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ImageHover = styled(ImageDefault)`
  background-position: center;
`;
