import styled from 'styled-components';
import RangeSlider from 'react-rangeslider';

export const Slider = styled(RangeSlider)`
  height: 5px !important;
  border-radius: 5px !important;

  & > .rangeslider__fill.rangeslider__fill {
    background-color: ${props => props.theme.white};
  }

  & > .rangeslider__handle {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;
    ::after {
      display: none;
    }

    & > .rangeslider__handle-tooltip {
      width: 116px !important;
      background-color: ${props => props.theme.secondary} !important;
      border: 1px solid ${props => props.theme.text};
      ::after {
        border-top: 8px solid ${props => props.theme.secondary} !important;
      }
    }
  }
`;

export const Container = styled.div``;
export const ContainerFolders = styled.div`
  border: 1px solid ${props => props.theme.secondary};
  height: ${props => (props.height ? props.height : '300px')};
  display: block;
  padding: 20px;
  margin-top: 20px;
  overflow-y: scroll;
`;

export const ContainerFoldersRow = styled(ContainerFolders)`
  display: flex;
  flex-direction: column;
`;

export const PlaceholderTextContainer = styled.p`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: ${props => props.theme.text};
  height: -webkit-fill-available;
`;
export const AddFolderButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: ${props => props.theme.text};
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.default};
  width: 320px;
  height: 50px;
  :focus {
    outline: none;
  }

  img {
    margin-right: 20px;
  }
`;

export const LinkSelectFolders = styled.span`
  color: ${props => props.theme.white};
  margin-right: 29px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
`;

export const BlueLabel = styled.label`
  text-align: left;
  font-size: 18px;
  color: ${props => props.theme.white};
`;
export const LightBlueLabel = styled.label`
  text-align: left;
  font-size: 18px;
  color: ${props => props.theme.text};
`;

export const GrayText = styled.p`
  color: ${props => props.theme.text};
  font-size: 18px;
  margin-top: 10px;
`;

export const SliderContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0px 25px;
  margin-bottom: 10px;
`;

export const FolderTitle = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    font-size: 16px;
    margin: 0;
  }
`;

export const FolderButton = styled.div`
  cursor: pointer;
  width: 70px;
  text-align: center;
  background: ${props =>
    props.open ? props => props.theme.secondary : props => props.theme.default};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: ${props => (props.open ? props => props.theme.default : props => props.theme.secondary)};
  font-size: 16px;
  border: none;
  padding: 3px 8px;
  ::before {
    content: ${props => (props.open ? '"Fechar"' : '"Abrir"')};
  }
`;

export const StepInput = styled.input`
  background: #fff;
  border: none;
  display: block;
  padding: 2px 3px;
  border-radius: 5px;
  width: 100px;
  margin-left: 30px;
  text-align: center;
`;
export const ContainerFields = styled.div`
  flex-direction: row;
  display: flex;
`;
export const InferencePoint = styled.select`
  margin-left: 10px;
  background-color: ${props => props.theme.primary} !important;
  height: 51px;
  line-height: 30px;
  outline: none;
  background: ${props => props.theme.secondary};
  border: 1px solid black;
  display: block;
  padding: 14px 26px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 16px;
  & > option {
    background-color: ${props => props.theme.primary} !important;
    height: 37px;
    line-height: 37px;
    outline: none;
    background: ${props => props.theme.secondary};
    font-size: 16px;
  }
`;
