import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { BrowserType, TypeItem } from 'utils/constants';
import { addMarker, appendMarker, removeAppendMarker, removeMarker } from 'services/Api/Markers';
import {
  Container,
  DeleteMarkerButton,
  MarkerButton,
  ContainerStrap,
  MarkerSearchField
} from './styles';
import { Label, InputField } from 'components/FolderBrowser/styles';

import Dialog from 'components/Dialog';

import { loading } from 'store/ducks/general';
import Searchbar from 'components/Searchbar';
import { t, strings } from 'language';

function Marker({ data, type, markers, updateMarkers, onResetData }) {
  const [appendMarkers, setAppendMarkers] = useState([]);
  const [searchableMarkers, setSearchableMarkers] = useState([]);
  const [showMarkerDialog, setShowMarkerDialog] = useState(false);
  const [newMarker, setNewMarker] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchableMarkers(markers);
  }, [markers]);
  function containMarker(id, list) {
    if (list?.length >= 1) {
      for (let index = 0; index < list.length; index += 1) {
        if (list[index].id === id) {
          return true;
        }
      }
    }
    return false;
  }

  function openMarkerModel(event) {
    event.preventDefault();
    setShowMarkerDialog(true);
  }
  function closeMarkerModal() {
    setShowMarkerDialog(false);
    onResetData();
  }
  async function handleAddMarkers() {
    dispatch(loading(true));

    if (newMarker) {
      const newMarkerResp = await addMarker(newMarker);
      await appendMarker([newMarkerResp.id], data.id, type);
    }

    closeMarkerModal();
    updateMarkers();
    dispatch(loading(false));
  }
  async function handleDeleteMarker(item) {
    dispatch(loading(true));

    await removeMarker(item);

    closeMarkerModal();
    updateMarkers();
    dispatch(loading(false));
  }

  const handleCheckMarker = async item => {
    if (item.checked) {
      await appendMarker([parseInt(item.value)], data.id, type);
    } else {
      await removeAppendMarker([parseInt(item.value)], data.id, type);
    }
  };

  const handleMarkerSearch = search => {
    // console.log(search);
    setSearchableMarkers(
      markers.filter(item => item?.name?.toUpperCase().indexOf(search?.toUpperCase()) > -1)
    );
  };

  return (
    <Container>
      <MarkerButton
        className="markerButton"
        //mouseOver={mouseOver || showDialog}
        onClick={event => openMarkerModel(event)}
      ></MarkerButton>
      <Dialog
        title={t(strings.bookmarks)}
        open={showMarkerDialog}
        close={closeMarkerModal}
        actions={[
          { text: t(strings.cancel), action: closeMarkerModal },
          { text: t(strings.save), action: () => handleAddMarkers() }
        ]}
      >
        <Label>{t(strings.select_bookmark)}</Label>
        <MarkerSearchField
          placeholder={t(strings.fetch_bookmarks)}
          onChange={e => handleMarkerSearch(e.target.value)}
        />
        <ContainerStrap className="pl-2 pt-3 pb-0">
          {searchableMarkers?.map(item => (
            <Row>
              <label>
                <InputField
                  type="checkbox"
                  defaultChecked={containMarker(item.id, data?.markers) ? 'checked' : ''}
                  value={item.id}
                  onChange={e => handleCheckMarker(e.target)}
                  style={{ width: 25 }}
                />
                {item.name} <DeleteMarkerButton onClick={e => handleDeleteMarker(item.id)} />
              </label>
            </Row>
          ))}
        </ContainerStrap>
        <Row className="pl-2 pt-3 pb-0">
          <label>{t(strings.create_bookmark)}</label>

          <InputField
            type="text"
            placeholder={t(strings.enter_new_bookmark)}
            onChange={e => setNewMarker(e.target.value)}
          />
        </Row>
      </Dialog>
    </Container>
  );
}

export default Marker;
