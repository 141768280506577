import querystring from 'querystring';
import { apiGet, apiPost } from '../index';

export async function getTranings(modelId) {
  const query = querystring.stringify({
    page: 1,
    size: 100000
  });

  return apiGet(`TraningSession/model/${modelId}?${query}`);
}

export async function getTraningDetails(modelId, sessionId) {
  const query = querystring.stringify({
    page: 1,
    size: 100000
  });

  return apiGet(`TraningSession/model/${modelId}/session/${sessionId}?${query}`);
}

export async function getTraningDetailsLog(modelId, sessionId, page = 1, size = 20) {
  return apiGet(
    `TraningSession/model/${modelId}/session/${sessionId}/logs?page=${page}&size=${size}`
  );
}

export async function evaluateTraning(modelId, traningIds, evalIds) {
  const query = querystring.stringify({
    datasetIds: traningIds,
    evalDatasetIds: evalIds
  });

  return apiGet(`TraningSession/evaluate/model/${modelId}?${query}`);
}

export async function getTrainedDatasets(modelId) {
  return apiGet(`TraningSession/model/${modelId}/trained_datasets`);
}

export async function getEvalDatasets(modelId) {
  return apiGet(`TraningSession/model/${modelId}/eval_datasets`);
}

export async function getLastTraining(modelId, hideDeletedDatasets) {
  return apiGet(
    `TraningSession/model/${modelId}/lastTraining?hideDeletedDatasets=${hideDeletedDatasets}`
  );
}

export async function createTraining(traning) {
  return apiPost('TraningSession', traning);
}

export async function getAllTrainings(page, size, order) {
  //OrderBy {string} Available values : Id, Name, Status
  if (!size) {
    size = 5000;
  }
  if (!page) {
    page = 1;
  }
  if (!order) {
    order = 'Id';
  }
  return apiGet(`TraningSession?size=${size}&page=${page}&OrderBy=${order}`);
}

export async function getCheckpoints(modelId) {
  return apiGet(`TraningSession/model/${modelId}/checkpoints`);
}
