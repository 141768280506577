import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { getMetrics, getAvaiableMetrics } from 'services/Api/Metrics';
import { ChartContainer, SelectMetric, ControlsContainer, LoadingContainer } from './styles';
import InsideLoading from 'components/Loading/insideLoading';
import { ThemeContext } from 'styled-components';
import { t, strings } from 'language';

export default function(props) {
  const { modelId, height } = props;
  const [data, setData] = useState(null);
  const [lines, setLines] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const theme = useContext(ThemeContext);
  const [selectedMetrics, setSelectedMetric] = useState(
    props.metric ? props.metric : 'Losses/TotalLoss'
  );

  const lineColors = {
    train: theme.white,
    eval: theme.default
  };

  const loadData = useCallback(async () => {
    if (!metrics) {
      const metricdResult = await getAvaiableMetrics(modelId);
      setMetrics(metricdResult);
    }

    const dataResult = await getMetrics(modelId, selectedMetrics);
    setData(dataResult.data);
    setLines(dataResult.lines);
  }, [metrics, modelId, selectedMetrics]);

  useEffect(() => {
    if (modelId) loadData();
  }, [modelId, loadData]);

  return (
    <ChartContainer height={height}>
      {data ? (
        data.length === 0 ? (
          <LoadingContainer>
            <p>{t(strings.no_data)}</p>
          </LoadingContainer>
        ) : (
          <>
            <ControlsContainer>
              <span>{t(strings.metrics)}:</span>
              <SelectMetric
                value={selectedMetrics}
                onChange={e => setSelectedMetric(e.target.value)}
              >
                {metrics?.map(i => (
                  <option>{i}</option>
                ))}
              </SelectMetric>
            </ControlsContainer>
            <ResponsiveContainer>
              <LineChart
                data={data}
                margin={{
                  bottom: 80,
                  right: 40,
                  left: 20
                }}
              >
                <CartesianGrid />
                <XAxis dataKey="step" />
                <YAxis scale={'linear'} />
                <Tooltip />
                <Legend layout="horizontal" verticalAlign="bottom" iconType="square" />
                {lines.map(i => {
                  return (
                    <Line
                      stroke={lineColors[i]}
                      type="monotone"
                      strokeWidth="2"
                      connectNulls
                      dataKey={i}
                      dot={data.length < 50}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </>
        )
      ) : (
        <LoadingContainer>
          <InsideLoading />
        </LoadingContainer>
      )}
    </ChartContainer>
  );
}
