import styled from 'styled-components';

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  border: ${props => (props.active ? '1px solid #fff' : 'none')};
  display: block;
  padding: 5px;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  width: 40px;
  height: 40px;
  :focus {
    outline: 0;
  }
`;
