import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;
  float: left;
  position: relative;
  background: ${props => props.theme.secondary};
  border-radius: 6px;
`;
export const InternalContainer = styled.div`
  width: ${props => (props.width ? `${props.width}px` : '195px')};
  height: ${props => (props.height ? `${props.height}px` : '40px')};
  flex-direction: row;
  display: flex;
  align-items: center;
  border: 1px solid ${props => (props.selected ? props.theme.white : 'none')};
  border-radius: 5px;
  padding: 18px;
`;
export const ContainerTop = styled.div`
  background: ${props => props.theme.secondary};
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
`;
export const Name = styled.div`
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 16px;
    margin: 0;
    color: ${props => props.theme.default};

    max-width: ${props => (props.width ? `${props.width - 65}px` : '140px')};
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    color: ${props => props.theme.text};
    width: 100%;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

export const Image = styled.img`
  margin-right: 10px;
  width: 22px;
`;

export const Tag = styled.img`
  position: absolute;
  top: -8px;
  right: -6px;
`;
