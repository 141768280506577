import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Container, ImageCount, Input, NewImageButton, ContainerInput, Label } from './styles';
import BulkDelete from './BulkDelete';
import Filter from './Filter';
import Ordenation from './Ordenation';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilter, setSearch } from 'store/ducks/filter';
import { Icon } from 'components/Icon';
import VideoToImage from '../VideoToImage';
import Dialog from 'components/Dialog';
import { t, strings } from 'language';

function Searchbar(props) {
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (props.onResetData) {
      props.onResetData();
    }
  }, [filterState.markers, filterState.tags, filterState.orderby]);

  useEffect(() => {
    dispatch(clearFilter());
  }, []);

  function closeModal() {
    setShowDialog(false);
  }
  return (
    <Container id="searchbar" className={props.className}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col md={6}>
          <ContainerInput>
            <Icon name="search" width="25" height="25" />
            <Input
              type="search"
              placeholder={props.placeholder}
              value={props.value}
              onChange={e => {
                props.onChange(e.currentTarget.value);
                dispatch(setSearch(e.currentTarget.value));
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  props.onResetData();
                }
              }}
            />
          </ContainerInput>
        </Col>
        {props.buttonClick && (
          <>
            <Col md={3} className="d-flex flex-row">
              {!viewer && (
                <NewImageButton background={props.buttonImage} onClick={props.buttonClick}>
                  <Icon name={props.buttonImage} width="30" height="30" />
                </NewImageButton>
              )}
              {!viewer && (
                <BulkDelete onResetData={props.onResetData} deleteFunction={props.deleteFunction} />
              )}
              {viewer && (
                <NewImageButton background={props.buttonImage} onClick={() => setShowDialog(true)}>
                  <Icon name={props.buttonImage} width="30" height="30" />
                </NewImageButton>
              )}
              {viewer && (
                <NewImageButton background={props.buttonImage} onClick={() => setShowDialog(true)}>
                  <Icon name="delete_icon" width="30" height="30" />
                </NewImageButton>
              )}
              <Filter
                onResetData={props.onResetData}
                markers={props.markers}
                filter={props.filter}
                onChangeFilter={props.onChangeFilter}
                deleteFunction={props.deleteFunction}
                hideMarkersFilter={props.hideMarkersFilter}
                section={props.section}
              />
            </Col>
            <Col md="auto" style={{ width: '70px' }}>
              {props.section === 'Dataset' && !viewer ? (
                <VideoToImage folderId={props.folderId} onResetData={props.onResetData} />
              ) : (
                ''
              )}
            </Col>
            <Col lg={2}>
              <Ordenation section={props.section} />
            </Col>
          </>
        )}
        {props.custom && (
          <Col lg={3} className="text-right">
            {props.custom}
          </Col>
        )}

        {/* <Col lg={4}>{props.children}</Col> */}
      </Row>
      <Row>
        <ImageCount>
          {filterState.searchTotal > 0 && `${filterState.searchTotal} `+t(strings.items_found)}
          {filterState.searchTotal === 0 && t(strings.no_items_found)}
        </ImageCount>
      </Row>
      <Dialog
        title={t(strings.alert)}
        open={showDialog}
        close={closeModal}
        actions={[{ text: t(strings.close), action: closeModal }]}
      >
        <Label>{t(strings.option_disabled)}</Label>
      </Dialog>
    </Container>
  );
}

export default Searchbar;
