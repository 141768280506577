import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/loading.json';

export default function InsideLoading(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <Lottie options={defaultOptions} height={100} width={100} isClickToPauseDisabled={true} />
    </div>
  );
}
