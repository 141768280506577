/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetSelectImage } from 'store/ducks/annotate';
import { contentEdit } from 'store/ducks/contentEdit';
import { deleteTag, putTagPolygon } from 'services/Api/Dataset/dataset';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { Container, Row, Col } from 'reactstrap';
import ButtonIcon from './components/ButtonIcon';
import { ReactComponent as FinishIcon } from 'assets/images/confirm.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete_icon.svg';
import { ReactComponent as UndoIcon } from 'assets/images/undo.svg';
import { ReactComponent as RedoIcon } from 'assets/images/redo.svg';
import { Title, Box, BoxDiv, TitleGlobal } from './styles';
import { Icon } from 'components/Icon';

function ContentEdit(props) {
  //const { annotation, className, style, onSubmit, annotateList } = props;
  const annotateEdit = useSelector(state => state.annotateEdit);
  const { show, annotation, onSubmit, annotateList } = annotateEdit;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  // const selectedImage = useSelector(state => state.annotate.selectedImage);
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const { geometry } = annotation;
  const dispatch = useDispatch();
  const [, setDisableEditor] = useGlobal(
    state => state.disableEditor,
    actions => actions.setDisableEditor
  );
  const [, setDisableContent] = useGlobal(
    state => state.disableContent,
    actions => actions.setDisableContent
  );
  const [position, editingAnnotation] = useGlobal(
    state => state.position,
    actions => actions.editingAnnotation
  );
  const [selectedAnnotate, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const [, addAnnotateList] = useGlobal(
    state => state.annotateList,
    actions => actions.addAnnotateList
  );
  const [doing, addDoing] = useGlobal(
    state => state.doing,
    actions => actions.addDoing
  );
  const disabledUndo = annotateList && annotateList.length > 0 && doing > -1 ? '' : 'disabled';
  const disabledRedo =
    annotateList && annotateList.length > 0 && annotateList.length > doing + 1 ? '' : 'disabled';
  const opacityUndo = annotateList && annotateList.length > 0 && doing > -1 ? '1' : '.5';
  const opacityRedo =
    annotateList && annotateList.length > 0 && annotateList.length > doing + 1 ? '1' : '.5';
  const [polygon, setPolygon] = useGlobal(
    state => state.polygon,
    actions => actions.setPolygon
  );
  //const [polygons] = useGlobal(state => state.polygons);
  const [, setEditablePolygon] = useGlobal(
    state => state.editablePolygon,
    actions => actions.setEditablePolygon
  );
  const [, setActionTagWidth] = useGlobal(
    state => state.actionTagWidth,
    actions => actions.setActionTagWidth
  );
  const [, setEditMode] = useGlobal(
    state => state.editMode,
    actions => actions.setEditMode
  );
  const [, setActivePolygon] = useGlobal(
    state => state.activePolygon,
    actions => actions.setActivePolygon
  );

  useEffect(() => {
    setActionTagWidth(show ? 848 : 540);
    setEditMode(show);
  }, [annotateEdit]);

  if (!geometry) return null;

  function setNewGeometry() {
    const selected = selectedAnnotate;
    selected.geometry.x = (100 * position.x) / imgWidth;
    selected.geometry.y = (100 * position.y) / imgHeight;
    selected.geometry.width = (parseInt(position.width, 10) * 100) / imgWidth;
    selected.geometry.height = (parseInt(position.height, 10) * 100) / imgHeight;
    selected.data.editMode = false;
    return selected;
  }

  async function updatePolygon() {
    const tag = polygon && {
      tagId: polygon.data.tagId,
      points: polygon.geometry.filter(x => {
        return x.x > -1;
      })
    };
    await putTagPolygon(polygon.data.imageTagId, tag);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  function finishAnnotate(annotate) {
    const selected = setNewGeometry();
    const { geometry } = annotate;
    switch (geometry.type) {
      case 'RECTANGLE':
        if (annotate.geometry.x < 0) {
          setSelectedAnnotate([]);
          deleteAnnotate(annotate);
        } else {
          setSelectedAnnotate(selected);
          onSubmit(selectedAnnotate);
          setSelectedAnnotate([]);
          deleteAnnotate(annotate);
        }
        break;
      default:
        if (polygon.polygonId !== -1) {
          updatePolygon(selected);
        }
        setSelectedAnnotate([]);
        setActivePolygon(-1);
        setEditablePolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
        setPolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
    }
    dispatch(
      contentEdit({
        show: false,
        annotation: [],
        annotateList: [],
        className: 'boxEdit',
        onChange: {},
        onSubmit: {}
      })
    );
    setDisableEditor(false);
    setDisableContent(false);
  }

  function deleteNotFinish() {
    const selected = selectedAnnotate;
    addDoing(1);
    if (selected.geometry.type === 'POINT') {
      setPolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
      setEditablePolygon([]);
    } else {
      editingAnnotation(-10000, -10000, 0, 0);
      addAnnotateList({
        x: -10000,
        y: -10000,
        width: 0,
        height: 0
      });
    }
  }

  function redoAnnotate() {
    if (doing < annotateList.length) {
      const geo = annotateList[doing + 1];
      addDoing(1);
      if (geo.x) {
        editingAnnotation(geo.x, geo.y, geo.width, geo.height);
      } else {
        setPolygon(geo);
        setEditablePolygon(geo);
        setSelectedAnnotate({
          data: geo.data,
          geometry: geo.onChangeAnnotation
        });
      }
    }
  }

  function undoAnnotate() {
    if (annotateList.length > 0 && doing > 0) {
      const geo = annotateList[doing - 1];
      addDoing(-1);
      if (geo.x) {
        editingAnnotation(geo.x, geo.y, geo.width, geo.height);
      } else {
        setPolygon(geo);
        setEditablePolygon(geo);
        setSelectedAnnotate({
          data: geo.data,
          geometry: geo.onChangeAnnotation
        });
      }
    } else if (doing === 0) {
      addDoing(-1);
      if (annotateList[0].x) {
        const NewGeometry = {};
        NewGeometry.x = (geometry.x * imgWidth) / 100;
        NewGeometry.y = (geometry.y * imgHeight) / 100;
        NewGeometry.width = (geometry.width * imgWidth) / 100;
        NewGeometry.height = (geometry.height * imgHeight) / 100;
        editingAnnotation(NewGeometry.x, NewGeometry.y, NewGeometry.width, NewGeometry.height);
      }
    }
  }

  async function deleteAnnotate(annotate) {
    await deleteTag(annotate.data.imageTagId);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  return (
    <Box
      className="mh-100 no-margin"
      style={{ marginTop: '24px', marginLeft: '20px', marginRight: '10px', display: show }}
    >
      <TitleGlobal>{t(strings.edit_tag)}</TitleGlobal>
      <BoxDiv>
        <Row className="mt-3 ml-2 mr-2">
          <Container>
            <Row
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '240px', maxWidth: '240px' }}
            >
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => finishAnnotate(annotation)}>
                  <Icon name="confirm" />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => deleteNotFinish()}>
                  <Icon name="delete_icon" />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabledUndo} onClick={() => undoAnnotate()}>
                  <Icon name="undo" style={{ opacity: `${opacityUndo}` }} />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabledRedo} onClick={() => redoAnnotate()}>
                  <Icon name="redo" style={{ opacity: `${opacityRedo}` }} />
                </ButtonIcon>
              </Col>
            </Row>
            <Row style={{ minWidth: '240px', maxWidth: '240px', marginTop: '5px' }}>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.finish)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.delete)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title style={{ opacity: `${opacityUndo}` }}>{t(strings.undo)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title style={{ opacity: `${opacityRedo}` }}>{t(strings.redo)}</Title>
              </Col>
            </Row>
          </Container>
        </Row>
      </BoxDiv>
    </Box>
  );
}

ContentEdit.defaultProps = {
  style: {},
  className: ''
};

export default ContentEdit;
