import styled from 'styled-components';
import RangeSlider from 'react-rangeslider';

export const SliderItem = styled(RangeSlider)`
  height: 5px !important;
  border-radius: 5px !important;
  background-color: ${props => props.theme.white} !important;

  & > .rangeslider__fill.rangeslider__fill {
    background-color: ${props => props.theme.white};
  }

  & > .rangeslider__handle {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;
    background-color: ${props => props.theme.default} !important;
    ::after {
      display: none;
    }

    & > .rangeslider__handle-tooltip {
      width: 116px !important;
      background-color: ${props => props.theme.secondary} !important;
      border: 1px solid ${props => props.theme.text};
      ::after {
        border-top: 8px solid ${props => props.theme.secondary} !important;
      }
    }
  }
`;

export const SliderContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  width: auto;
  flex-grow: 1;
`;
export const StepInput = styled.input`
  background: #fff;
  border: none;
  display: block;
  padding: 2px 3px;
  border-radius: 5px;
  width: 100px;
  margin-left: 30px;
  text-align: center;
`;
