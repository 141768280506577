import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Container } from './styles';

export default function NotificationItem({ children, item, setModalActive }) {
  return (
    <Container>
      <span>
        <Moment local utc format="DD/MM/YYYY kk:mm" style={{ whiteSpace: 'nowrap' }}>
          {item.date}
        </Moment>
      </span>
      <Link to={item.redirect} onClick={() => setModalActive(false)}>
        <div className="content_notification" dangerouslySetInnerHTML={{ __html: children }} />
      </Link>
    </Container>
  );
}
