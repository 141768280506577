import { takeLatest, put, call } from 'redux-saga/effects';
import { InferenceAPI } from 'services/Api/Inference';
import { DatasetAPI } from 'services/Api/Dataset';
import { loading } from './general';

const INITIAL_STATE = {
  inferenceId: 0,
  selectedImage: [],
  datasetId: 0,
  annotation: [],
  imageId: 0
};

export const Types = {
  GET_IMAGE: 'inference/GET_IMAGE',
  SET_IMAGE: 'inference/SET_IMAGE',
  SELECT_IMAGE: 'inference/SELECT_IMAGE',
  CLEAR_INFERENCE: 'inference/CLEAR_INFERENCE'
};

export function GetInferenceImage(inferenceId, datasetId, imageId) {
  return {
    type: Types.GET_IMAGE,
    payload: {
      inferenceId,
      datasetId,
      imageId
    }
  };
}

export function SetInferenceImages({ images }) {
  return {
    type: Types.SET_IMAGES,
    payload: {
      images
    }
  };
}

export function ClearInference() {
  return {
    type: Types.CLEAR_INFERENCE,
    payload: {}
  };
}

export function SelectInferenceImage(selectedImage) {
  return {
    type: Types.SELECT_IMAGE,
    payload: {
      selectedImage
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_IMAGE:
      return {
        ...state,
        inferenceId: parseInt(payload.inferenceId, 10),
        imageId: parseInt(payload.imageId, 10),
        datasetId: parseInt(payload.datasetId, 10),
        images: payload.images,
        selectedImage: payload.selectedImage,
        dataset: payload.dataset,
        totalImages: payload.totalImages
      };
    case Types.SELECT_IMAGE:
      return {
        ...state,
        image: payload.image,
        selectedImage: payload.selectedImage,
        totalImages: payload.totalImages
      };
    case Types.CLEAR_INFERENCE:
      return {
        inferenceId: 0,
        selectedImage: [],
        datasetId: 0,
        annotation: [],
        imageId: 0,
        dataset: [],
        totalImages: 0
      };
    default:
      return state;
  }
}

/*---------------------*/

function* getInferenceImageFromAPI(action) {
  const { payload } = action;
  yield put(loading(true));
  try {
    const result = yield call(InferenceAPI.getInferenceImage, payload.inferenceId, payload.imageId);
    const dataset = yield call(InferenceAPI.getInferenceDetails, payload.inferenceId); 
    // const dataset = yield call(DatasetAPI.getDataset, payload.datasetId);
    yield put({
      type: Types.SET_IMAGE,
      payload: {
        inferenceId: payload.inferenceId,
        datasetId: payload.datasetId,
        imageId: payload.imageId,
        images: [result],
        selectedImage: result,
        dataset: dataset.datasets[0],
        totalImages: dataset.totalImages
      }
    });
  } catch (error) {
    throw error;
  }
  //yield put(loading(false));
}

export const sagas = [takeLatest(Types.GET_IMAGE, getInferenceImageFromAPI)];
