import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import './styles.css';
import { getIdFromUrl } from 'utils/helpers';
import { t, strings } from 'language';
import uuid from 'uuid/v4';
import useGlobal from 'store/global';
import { toast } from 'react-toastify';
import { Rnd } from 'react-rnd';
import { useSelector, useDispatch } from 'react-redux';
import { GetSelectImage } from 'store/ducks/annotate';
import {
  deleteTag,
  deleteAllTags,
  deleteImages,
  addDatasetImages,
  rotateBoxs,
  rotatePolygons
} from 'services/Api/Dataset/dataset';
import TitleBreadcrumb from 'components/Breadcrumb';
import { addBreadCrumb } from 'store/ducks/breadcrumb';
import Dialog from 'components/Dialog';
import Azure from 'services/Azure/azure-storage.blob.min';
import { GetStorageInfo } from 'services/Api/Storage';
import { loading } from 'store/ducks/general';
import Tools from './components/Tools';
import Tags from './components/Tags';
import Editor from './components/Editor';
import Carousel from './components/Carousel';
import ToolTip from './components/ToolTip';
import ActionTag from './components/ActionTag';
import ActionTagMask from './components/ActionTagMask';
import ContentEdit from './components/ContentEdit';
import DeleteTags from './components/DeleteTags';
import ButtonIcon from './components/Tools/ButtonIcon';
import { ContainerAnnotate, Row, Col, Title, BtnShow, ImageToRotate } from './styles';
import useWindowSize from './components/useWindowSize';
import { ThemeContext } from 'styled-components';
import { Icon } from 'components/Icon';
import useKeyPress from './components/Editor/components/AnnotateDrawer/components/useKeyPress';
// import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

function AnnotateForm(props) {
  const theme = useContext(ThemeContext);
  const { history } = props;
  const location = history.location.pathname;
  const dataset = getIdFromUrl(location, 2);
  const dispatch = useDispatch();
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const isInference = !!(inferenceID > 0 && getIdFromUrl(location, 3) === 'inference');
  const annotate = useSelector(state => (isInference ? state.inference : state.annotate));
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [viewerDialog, setViewerDialog] = useGlobal(
    state => state.viewerDialog,
    actions => actions.setViewerDialog
  );
  const { selectedImage, datasetId } = annotate;
  const { url, tags } = selectedImage;
  // const url = selectedImage && selectedImage.url;
  // const tags = selectedImage && selectedImage.tags;
  const size = useWindowSize();
  const square = size.width > size.height ? size.height : size.width;
  const [heightImages] = useGlobal(state => state.heightImages);
  const [widthImages] = useGlobal(state => state.widthImages);
  const [showTags, setShowTags] = useGlobal(
    state => state.showTags,
    actions => actions.setShowTags
  );
  const [floatTags] = useGlobal(state => state.floatTags);
  const [floatPos, editingfloatPos] = useGlobal(
    state => state.floatPos,
    actions => actions.editingfloatPos
  );
  const [, setDisableEditor] = useGlobal(
    state => state.disableEditor,
    actions => actions.setDisableEditor
  );
  const [, setDisableContent] = useGlobal(
    state => state.disableContent,
    actions => actions.setDisableContent
  );
  const [modal, setModal] = useGlobal(
    state => state.modal,
    actions => actions.setModal
  );
  const [modalRotation, setModalRotation] = useGlobal(
    state => state.modalRotation,
    actions => actions.setModalRotation
  );
  const [titleModal] = useGlobal(state => state.titleModal);
  const [textModal] = useGlobal(state => state.textModal);
  const [delItem] = useGlobal(state => state.delItem);
  const [, setRelaodAnnotate] = useGlobal(
    state => state.relaodAnnotate,
    actions => actions.setRelaodAnnotate
  );
  const title = isInference ? 'Annotate (Inference)' : 'Annotate';
  const [direction, setDirection] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [switchState, setSwitchState] = useState(false);
  const [disabledUndo, setDisabledUndo] = useState('disabled');
  const [disabledRedo, setDisabledRedo] = useState('disabled');
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);
  const [txtPreview, setTxtPreview] = useState('#3e3e3e');
  const [txtOriginal, setTxtOriginal] = useState('#3e3e3e');
  const refImage = useRef(null);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const saveUploadedImage = useCallback(
    async file => {
      dispatch(loading(false));
      setRelaodAnnotate(true);
      return addDatasetImages(datasetId, file);
    },
    [datasetId]
  );
  const handleUpload = async file => {
    dispatch(loading(true));
    const filename = file.name;
    const fileExtension = file.name.split('.').pop();
    const fileHash = `${uuid()}.${fileExtension}`;
    const originalBmp = await createImageBitmap(file);
    const { width, height } = originalBmp;
    const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    let uploadsetviceInstance = null;
    const result = await GetStorageInfo(datasetId);
    if (result.provider === 'AZURE') {
      uploadsetviceInstance = Azure.createBlobServiceWithSas(result.url, result.token);
    }
    uploadsetviceInstance.createBlockBlobFromBrowserFile(
      result.bucket,
      `${result.path}/${fileHash}`,
      file,
      { blockSize: customBlockSize },
      // eslint-disable-next-line func-names
      async function(error) {
        if (error) {
          toast.warn(`${t(strings.error_image)} ${filename}`, {
            autoClose: false
          });
        } else {
          await saveUploadedImage({
            filename,
            fileHash,
            fileSize: file.size,
            imageType: file.type,
            width,
            height,
            overrideMode: 'Override'
          });
        }
      }
    );
  };
  const enterPress = useKeyPress('Enter');
  const escPress = useKeyPress('Escape');

  useEffect(() => {
    if (enterPress && modal) {
      deleteItem(delItem);
    }
    if (escPress && modal) {
      closeDialog();
    }
  }, [enterPress, escPress]);

  function closeModal() {
    setViewerDialog(false);
  }

  function changeDisable(bool) {
    setDisableEditor(bool);
    setDisableContent(bool);
  }

  function closeDialog() {
    setModal(false);
    changeDisable(false);
  }

  async function deleteImage(img) {
    setModal(false);
    await deleteImages([img.id]);
    dispatch(GetSelectImage(datasetId, selectedImage.id));
    setRelaodAnnotate(true);
  }

  // eslint-disable-next-line no-shadow
  async function deleteAnnotate(annotate) {
    const { type } = annotate.geometry;
    setModal(false);
    switch (type) {
      case 'POINT':
      default:
        await deleteTag(annotate.data.imageTagId);
        dispatch(GetSelectImage(datasetId, selectedImage.id, true));
    }
  }

  async function deleteAllAnnotates() {
    setModal(false);
    await deleteAllTags(selectedImage.id);
    dispatch(GetSelectImage(datasetId, selectedImage.id));
    setRelaodAnnotate(true);
  }

  function deleteItem(item) {
    switch (item.type) {
      case 'img':
        deleteImage(item.value);
        break;
      case 'all':
        deleteAllAnnotates();
        break;
      default:
        deleteAnnotate(item.value);
    }
    changeDisable(false);
  }

  function dataURItoBlob(dataURI) {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < max; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ia], { type: mime });
  }

  function rotateBox(oldPos) {
    const newPos = {
      boxId: oldPos.id,
      tagId: oldPos.tagId,
      xmax: oldPos.data.xmax,
      xmin: oldPos.data.xmin,
      ymax: oldPos.data.ymax,
      ymin: oldPos.data.ymin
    };
    const old = { ...oldPos.data };
    if (scaleX !== 1) {
      old.xmin = 100 - oldPos.data.xmax;
      old.xmax = 100 - oldPos.data.xmin;
    }
    if (scaleY !== 1) {
      old.ymin = 100 - oldPos.data.ymax;
      old.ymax = 100 - oldPos.data.ymin;
    }
    let degrees;
    if (Math.sign(direction) === 1) {
      degrees = direction;
    } else {
      degrees = 360 + direction;
    }
    switch (Math.floor(Math.sin(((degrees % 360) * Math.PI) / 180))) {
      case 1:
        newPos.xmin = 100 - old.ymax;
        newPos.xmax = 100 - old.ymin;
        newPos.ymin = old.xmin;
        newPos.ymax = old.xmax;
        break;
      case -1:
        newPos.xmin = old.ymin;
        newPos.xmax = old.ymax;
        newPos.ymin = 100 - old.xmax;
        newPos.ymax = 100 - old.xmin;
        break;
      default:
        if (degrees % 360 === 180) {
          newPos.xmin = 100 - old.xmax;
          newPos.xmax = 100 - old.xmin;
          newPos.ymin = 100 - old.ymax;
          newPos.ymax = 100 - old.ymin;
        } else {
          newPos.xmin = old.xmin;
          newPos.xmax = old.xmax;
          newPos.ymin = old.ymin;
          newPos.ymax = old.ymax;
        }
    }
    return newPos;
  }

  function rotatePoint(oldPos) {
    const newPos = { x: oldPos.x, y: oldPos.y };
    const old = { ...oldPos };
    if (scaleX !== 1) {
      old.x = 100 - oldPos.x;
    }
    if (scaleY !== 1) {
      old.y = 100 - oldPos.y;
    }
    let degrees;
    if (Math.sign(direction) === 1) {
      degrees = direction;
    } else {
      degrees = 360 + direction;
    }
    switch (Math.floor(Math.sin(((degrees % 360) * Math.PI) / 180))) {
      case 1:
        newPos.x = 100 - old.y;
        newPos.y = old.x;
        break;
      case -1:
        newPos.x = old.y;
        newPos.y = 100 - old.x;
        break;
      default:
        if (degrees % 360 === 180) {
          newPos.x = 100 - old.x;
          newPos.y = 100 - old.y;
        } else {
          newPos.x = old.x;
          newPos.y = old.y;
        }
    }
    return newPos;
  }

  async function rotateTags() {
    const boxs = [];
    const pols = [];
    const points = [];
    // eslint-disable-next-line array-callback-return
    tags.map(box => {
      switch (box.type) {
        case 'Polygon':
          // eslint-disable-next-line array-callback-return
          box.data.map(obj => {
            points.push(rotatePoint(obj));
          });
          pols.push({
            boxId: box.id,
            tagId: box.tagId,
            points
          });
          break;
        default:
          boxs.push(rotateBox(box));
      }
    });
    if (boxs.length > 0) {
      await rotateBoxs(boxs);
    }
    if (pols.length > 0) {
      await rotatePolygons(pols);
    }
  }

  async function getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  function calExif(orientation) {
    // eslint-disable-next-line default-case
    switch (`${orientation}.${scaleX}.${scaleY}`) {
      case '0.1.1':
      case '3.-1.-1':
        return 1;
      case '0.-1.1':
      case '3.1.-1':
        return 2;
      case '3.1.1':
      case '0.-1.-1':
        return 3;
      case '3.-1.1':
      case '0.1.-1':
        return 4;
      case '4.-1.1':
      case '1.1.-1':
        return 5;
      case '1.1.1':
      case '4.-1.-1':
        return 6;
      case '1.-1.1':
      case '4.1.-1':
        return 7;
      case '4.1.1':
      case '1.-1.-1':
        return 8;
    }
  }

  async function createCanvas() {
    if (imgRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.src = await getBase64FromUrl(imgRef.current.src);
      // eslint-disable-next-line func-names
      image.onload = function() {
        const { width } = image;
        const { height } = image;
        let degrees;
        if (Math.sign(direction) === 1) {
          degrees = direction;
        } else {
          degrees = 360 + direction;
        }
        const orientation = Math.floor(((degrees % 360) * Math.PI) / 180);
        const res = calExif(orientation);
        if (res > 4 && res < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        switch (res) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        ctx.drawImage(image, 0, 0);
        //const dataURL = canvas.toDataURL();
        const dataURL = canvas.toDataURL('image/jpeg');

        //console.log('dataURL', dataURL);
        closeDialogRotation();
        const blob = dataURItoBlob(dataURL);
        const metadata = { type: 'image/jpeg' };
        const file = new File([blob], selectedImage.name, metadata);

        handleUpload(file);
      };
    }
  }

  // function createCanvas() {
  //   if (canvasRef.current) {
  //     const widthImage = selectedImage.width;
  //     const heightImage = selectedImage.height;
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext('2d');
  //     ctx.setTransform(1, 0, 0, 1, 0, 0);
  //     // ROTATE IMAGE
  //     console.log('direction', direction);
  //     let degrees;
  //     if (Math.sign(direction) === 1) {
  //       degrees = direction;
  //     } else {
  //       degrees = 360 + direction;
  //     }
  //     console.log('degrees', degrees);
  //     switch (Math.floor(Math.sin(((degrees % 360) * Math.PI) / 180))) {
  //       case 1:
  //         canvas.width = heightImage;
  //         canvas.height = widthImage;
  //         ctx.fillRect(0, 0, canvas.width, canvas.height);
  //         ctx.translate(heightImage, 0);
  //         ctx.rotate(((degrees % 360) * Math.PI) / 180);
  //         break;
  //       case -1:
  //         canvas.width = heightImage;
  //         canvas.height = widthImage;
  //         ctx.fillRect(0, 0, canvas.width, canvas.height);
  //         ctx.translate(widthImage - heightImage, widthImage);
  //         ctx.rotate(((degrees % 360) * Math.PI) / 180);
  //         ctx.translate(0, -(widthImage - heightImage));
  //         break;
  //       default:
  //         canvas.width = widthImage;
  //         canvas.height = heightImage;
  //         ctx.fillRect(0, 0, canvas.width, canvas.height);
  //         if (degrees % 360 === 180) {
  //           ctx.translate(widthImage, heightImage);
  //           ctx.rotate(((degrees % 360) * Math.PI) / 180);
  //           ctx.translate(0, 0);
  //         }
  //         break;
  //     }
  //     // FLIP IMAGE
  //     const posX = scaleX === 1 ? 0 : widthImage * -1;
  //     const posY = scaleY === 1 ? 0 : heightImage * -1;
  //     ctx.save();
  //     ctx.scale(scaleX, scaleY);

  //     const image = new Image();
  //     image.crossOrigin = 'anonymous';
  //     image.src = url;
  //     console.log('widthImage', widthImage);
  //     console.log('heightImage', heightImage);
  //     console.log('(Math.floor(Math.sin(((direction % 360) * Math.PI) / 180)))', (Math.floor(Math.sin(((degrees % 360) * Math.PI) / 180))));
  //     console.log('canvas.width', canvas.width);
  //     console.log('canvas.height', canvas.height);
  //     console.log('posX', posX);
  //     console.log('posY', posY);

  //     // eslint-disable-next-line func-names
  //     image.onload = function() {
  //       ctx.drawImage(this, posX, posY, widthImage, heightImage);
  //       ctx.restore();
  //       ctx.save();

  //       const dataURL = canvas.toDataURL('image/jpeg');
  //       const blob = dataURItoBlob(dataURL);

  //       const metadata = { type: 'image/jpeg' };
  //       const file = new File([blob], selectedImage.name, metadata);

  //       closeDialogRotation();
  //       handleUpload(file);
  //     };
  //   }
  // }

  async function saveRotation() {
    rotateTags();
    createCanvas();
  }

  function closeDialogRotation() {
    setDirection(0);
    setScaleX(1);
    setScaleY(1);
    setIndex(-1);
    setData([]);
    setSwitchState(false);
    setDisabledUndo('disabled');
    setDisabledRedo('disabled');
    setTxtPreview('#3e3e3e');
    setTxtOriginal('#3e3e3e');
    setModalRotation(false);
  }

  function addAction(func, value) {
    if (index === data.length - 1) {
      setData([...data, { func, value }]);
      setIndex(index + 1);
    } else {
      const newData = data;
      newData.length = index + 1;
      if (index === -1) {
        setData([{ func, value }]);
      } else {
        setData([...newData, { func, value }]);
      }
      setIndex(index + 1);
    }
  }

  function rotateImage(degree) {
    const newRotation = direction + degree;
    setDirection(newRotation);
    addAction('rotateImage', degree);
    setTxtPreview(theme.white);
  }

  function flipVertical() {
    const newScale = scaleY === 1 ? -1 : 1;
    setScaleY(newScale);
    addAction('flipVertical', newScale);
    setTxtPreview(theme.white);
  }

  function flipHorizontal() {
    const newScale = scaleX === 1 ? -1 : 1;
    setScaleX(newScale);
    addAction('flipHorizontal', newScale);
    setTxtPreview(theme.white);
  }

  function startEnd() {
    if (switchState) {
      setIndex(data.length);
      setTxtPreview(theme.white);
      setTxtOriginal('#3e3e3e');
      data.forEach(redoImage);
    } else {
      setDirection(0);
      setScaleX(1);
      setScaleY(1);
      setIndex(-1);
      setTxtPreview('#3e3e3e');
      setTxtOriginal(theme.white);
    }
    setSwitchState(!switchState);
  }
  // function startEnd() {
  //   if (switchState) {
  //     setIndex(data.length);
  //     setTxtPreview('#50FFF2');
  //     setTxtOriginal('#3e3e3e');
  //     data.forEach(redoImage);
  //   } else {
  //     setDirection(0);
  //     setScaleX(1);
  //     setScaleY(1);
  //     setIndex(-1);
  //     setTxtPreview('#3e3e3e');
  //     setTxtOriginal('#50FFF2');
  //   }
  //   setSwitchState(!switchState);
  // }

  function undoImage() {
    const action = data[index];
    if (action !== undefined) {
      switch (action.func) {
        case 'flipHorizontal':
          setScaleX(action.value * -1);
          break;
        case 'flipVertical':
          setScaleY(action.value * -1);
          break;
        default:
          setDirection(direction + action.value * -1);
      }
      setIndex(index - 1);
    }
  }

  function redoImage() {
    const newIndex = index > data?.length ? data?.length - 1 : index + 1;
    const action = data[newIndex];
    if (action !== undefined) {
      switch (action.func) {
        case 'flipHorizontal':
          setScaleX(action.value1);
          break;
        case 'flipVertical':
          setScaleY(action.value);
          break;
        default:
          setDirection(direction + action.value);
      }
      setIndex(index + 1);
    }
  }

  useEffect(() => {
    dispatch(addBreadCrumb({ position: 6, url: '#', name: 'loading' }));
    if (viewer) {
      setDisableEditor(true);
      setDisableContent(true);
    }
  }, []);

  useEffect(() => {
    if (index < -1) {
      setIndex(-1);
    }
    if (index >= -1) {
      setDisabledUndo('');
    } else {
      setDisabledUndo('disabled');
    }
    if (index < data?.length) {
      setDisabledRedo('');
    } else {
      setDisabledRedo('disabled');
    }
  }, [index]);

  useEffect(() => {
    if (isInference) {
      setDisableEditor(true);
    }
  }, [inferenceID]);
  return (
    <ContainerAnnotate fluid className="global_annotate">
      <div style={{ position: 'absolute', width: '95%' }}>
        <div style={{ height: '5px' }} />
        <TitleBreadcrumb position={5} name={title} url="#" margin={0} />
      </div>

      <ToolTip size={size} />
      <Rnd
        style={{
          position: 'absolute',
          display: `${floatTags ? 'block' : 'none'}`,
          //width: `${floatPos.width}`,
          zIndex: 202,
          background: theme.primary
        }}
        bounds="body"
        size={{ width: `${floatPos.width}`, height: `${floatPos.height}` }}
        position={{ x: `${floatPos.x}`, y: `${floatPos.y}` }}
        onDragStop={(e, d) => {
          editingfloatPos(d.x, d.y, floatPos.width, floatPos.height);
        }}
        // eslint-disable-next-line no-shadow
        onResize={(e, direction, ref, delta, position) => {
          editingfloatPos(
            position.x,
            position.y,
            // eslint-disable-next-line radix
            parseInt(ref.style.width.replace(/[^0-9]/g, '')),
            // eslint-disable-next-line radix
            parseInt(ref.style.height.replace(/[^0-9]/g, ''))
          );
        }}
      >
        <Tags history={history} size={{ height: floatPos.height + 248 }} />
      </Rnd>
      <Row style={{ height: '157px', paddingTop: '15px' }}>
        <Col style={{ minWidth: '490px', maxWidth: '490px' }}>
          <Tools history={history} size={size} />
        </Col>
        <Col>
          <ActionTag />
          <ActionTagMask />
          <ContentEdit />
          <DeleteTags />
        </Col>
        <Col
          style={{
            zIndex: 100,
            background: theme.primary,
            marginLeft: '10px',
            height: `${heightImages}px`,
            width: `${widthImages}px`
          }}
        >
          <Carousel history={history} size={size} theme={theme} />
        </Col>
      </Row>
      <Row style={{ zIndex: 1 }}>
        <Col className="d-md-flex no-margin no-overflow w-100">
          <Editor dataset={dataset} size={size} />
        </Col>
        <Col
          className="col-2 no-margin"
          style={{
            // eslint-disable-next-line no-nested-ternary
            display: `${showTags ? (floatTags ? 'none' : 'block') : 'none'}`,
            marginLeft: '20px'
          }}
        >
          <Tags history={history} size={size} />
        </Col>
        <Col className="auto" style={{ width: '30px', display: `${showTags ? 'none' : 'block'}` }}>
          <BtnShow onClick={() => setShowTags(true)}>
            <Icon name="left" style={{ height: 20, width: 20 }} />
          </BtnShow>
          <Title>{t(strings.tags)}</Title>
        </Col>
      </Row>

      <Dialog
        title={titleModal}
        open={modal}
        close={() => closeDialog()}
        actions={[
          { text: t(strings.cancel), action: () => closeDialog() },
          { text: t(strings.delete), action: () => deleteItem(delItem) }
        ]}
      >
        <div style={{ wordBreak: 'break-all' }}>{textModal}</div>
      </Dialog>
      <Dialog
        title={t(strings.titleRotation)}
        open={modalRotation}
        close={() => closeDialogRotation()}
        actions={[
          { text: t(strings.cancel), action: () => closeDialogRotation() },
          { text: t(strings.save_changes), action: () => saveRotation(), gold: true }
        ]}
        width={square + 120}
      >
        <div
          style={{
            display: 'block',
            width: square,
            height: square - 350,
            marginTop: '10px'
          }}
        >
          <Row>
            <Col style={{ width: '330px', marginRight: '20px' }}>
              <Row className="border_line">
                <Col>
                  <Row className="row_button">
                    <Col>
                      <ButtonIcon onClick={() => rotateImage(90)}>
                        <Icon name="rotate-right-90" style={{ height: 30, width: 30 }} />
                      </ButtonIcon>
                    </Col>
                    <Col className="col_text">{t(strings.rotate_right)}</Col>
                  </Row>
                  <Row className="row_button">
                    <Col>
                      <ButtonIcon onClick={() => rotateImage(-90)}>
                        <Icon name="rotate-left-90" style={{ height: 30, width: 30 }} />
                      </ButtonIcon>
                    </Col>
                    <Col className="col_text">{t(strings.rotate_left)}</Col>
                  </Row>
                  <Row className="row_button">
                    <Col>
                      <ButtonIcon onClick={() => rotateImage(180)}>
                        <Icon name="rotate-180" style={{ height: 30, width: 30 }} />
                      </ButtonIcon>
                    </Col>
                    <Col className="col_text">{t(strings.rotate_180)}</Col>
                  </Row>
                  <Row className="row_button">
                    <Col>
                      <ButtonIcon onClick={() => flipVertical()}>
                        <Icon name="invert-vertical" style={{ height: 30, width: 30 }} />
                      </ButtonIcon>
                    </Col>
                    <Col className="col_text">{t(strings.flip_vertical)}</Col>
                  </Row>
                  <Row className="row_button">
                    <Col>
                      <ButtonIcon onClick={() => flipHorizontal()}>
                        <Icon name="invert-horizontal" style={{ height: 30, width: 30 }} />
                      </ButtonIcon>
                    </Col>
                    <Col className="col_text">{t(strings.flip_horizontal)}</Col>
                  </Row>
                  {/* <Row className="row_button">
                    <Col className="text_center" style={{ color: txtPreview }}>
                      {t(strings.preview)}
                    </Col>
                    <Col>
                      <ToggleSwitch
                        ison={switchState}
                        onColor="#00E0FF" // "${props => props.theme.white}"
                        handleToggle={() => startEnd()}
                      />
                    </Col>
                    <Col className="text_center" style={{ color: txtOriginal }}>
                      {t(strings.original)}
                    </Col>
                  </Row> */}
                </Col>
              </Row>
              <Row className="row_undo_redo border_line">
                <Col className="col_button inner">
                  <ButtonIcon disabled={disabledUndo} onClick={() => undoImage()}>
                    <Icon name="undo" style={{ height: 30, width: 30 }} />
                  </ButtonIcon>
                  <div>
                    <small>{t(strings.undo)}</small>
                  </div>
                </Col>
                <Col className="col_button inner">
                  <ButtonIcon disabled={disabledRedo} onClick={() => redoImage()}>
                    <Icon name="redo" style={{ height: 30, width: 30 }} />
                  </ButtonIcon>
                  <div>
                    <small>{t(strings.redo)}</small>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className="border_line square_image"
              style={{ width: size.height - 350, height: size.height - 350 }}
            >
              <div
                className="inner"
                style={{ width: size.height - 352, height: size.height - 352 }}
              >
                <ImageToRotate
                  ref={refImage}
                  src={url}
                  style={{
                    transform: `rotate(${direction}deg) scale(${scaleX},${scaleY})`
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Dialog>
      <Dialog
        title={t(strings.alert)}
        open={viewerDialog}
        close={closeModal}
        actions={[{ text: 'Fechar', action: closeModal }]}
      >
        <div>{t(strings.message_1)}</div>
      </Dialog>
      <img
        ref={imgRef}
        src={url}
        style={{
          display: 'none',
          transform: `rotate(${direction}deg) scale(${scaleX},${scaleY})`
        }}
      />
      <canvas style={{ display: 'none' }} ref={canvasRef} />
    </ContainerAnnotate>
  );
}

export default AnnotateForm;
