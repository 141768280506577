import { apiGet, apiPost, apiPut } from '../index';

export async function addTags(tag) {
  return apiPost(`Tag`, {
    color: tag.color,
    description: tag.description
  });
}

export async function putTags(tag) {
  return apiPut(`Tag/${tag.id}`, {
    color: tag.color,
    description: tag.description
  });
}

export async function getTags(name, page, size) {
  const result = apiGet(`Tag?page=${page}&size=${size}&name=${name}`);
  return result;
}

export async function getTagsOfSubscription(page, size) {
  const result = apiGet(`Tag?page=${page}&size=${size}`);
  return result;
}
