import React, { useState, useEffect } from 'react';
import 'react-rangeslider/lib/index.css';
import { SliderContainer, SliderItem, StepInput } from './styles';

export default function Slider(props) {
  const [value, setValue] = useState(props.value);
  const [editingValue, setEditingValue] = useState(props.value);
  const { labels, min, max, step, label = 'steps' } = props;

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function changeValue(editingValue) {
    if (editingValue < 2000) {
      props.onChange(2000);
      setEditingValue(2000);
    } else if (editingValue > 100000) {
      props.onChange(100000);
      setEditingValue(100000);
    } else props.onChange(editingValue);
  }

  const formatSteps = value => value + ' ' + label;

  return (
    <SliderContainer style={props.style}>
      <SliderItem
        className="w-100"
        onChange={e => {
          setValue(e);
          setEditingValue(e);
        }}
        onChangeComplete={() => props.onChange(value)}
        value={value}
        min={min}
        max={max}
        step={step}
        labels={labels}
        format={formatSteps}
      />
    </SliderContainer>
  );
}
