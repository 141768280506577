export const Types = {
  SET_LOADING: 'loading/SET_LOADING',
  SET_LOADINGMINI: 'loading/SET_LOADINGMINI',
  SET_INVITE_MODAL: 'loading/SET_INVITE_MODAL'
};

const INITIAL_STATE = {
  loading: false,
  loadingMini: false,
  showInvite: false
};

export function loading(show) {
  return {
    type: Types.SET_LOADING,
    payload: {
      show
    }
  };
}

export function loadingMini(show) {
  return {
    type: Types.SET_LOADINGMINI,
    payload: {
      show
    }
  };
}

export function setInviteModal(show) {
  return {
    type: Types.SET_INVITE_MODAL,
    payload: {
      show
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  let { type, payload } = action;
  switch (type) {
    case Types.SET_LOADING:
      return { ...state, loading: payload.show };
    case Types.SET_LOADINGMINI:
      return { ...state, loadingMini: payload.show };
    case Types.SET_INVITE_MODAL:
      return { ...state, showInvite: payload.show };
    default:
      return state;
  }
}
