import { createStore } from 'redux';

export const Types = {
  SET_LANGUAGE: 'language/SET_LANGUAGE'
};

const INITIAL_STATE = {
  language: 'en'
};

export function setLanguage(language) {
  return {
    type: Types.SET_LANGUAGE,
    payload: {
      language
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_LANGUAGE:
      return { ...state, language: payload.language };
    default:
      return state;
  }
}
// eslint-disable-next-line no-undef
export const store = createStore(reducer);
