import styled, { keyframes } from 'styled-components';

export const fadeInScale = keyframes`
from {
  opacity: 0;
  transform: scale(0);
}

to {
  opacity: 1;
  transform: scale(1);
}
`;

export const Container = styled.div`
  background: transparent;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  ${'' /* transform-origin: top left;
  animation: ${fadeInScale} 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275); */}
  overflow: hidden;
`;

export const ContentBox = styled.div`
  background: ${props => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 10px;
  color: #a2a2a7;
  white-space: nowrap;
  cursor: default;
`;

export const ContainerButtons = styled.div`
  display: flex;
  white-space: nowrap;
`;
