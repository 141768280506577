export const colors = {
  background: '#fff',
  primary: '#fff',
  white: '#000',
  black: '#000',
  header: '#000',
  secondary: '#f2f2f2',
  text: '#000',
  default: '#E94D1F',
  green: '#f2f2f2',
  error: '#c44040',
  warning: '#C4A53F',
  iconColor: '#000'
};
