export function getIdFromUrl(pathname, index) {
  const newPathname = pathname.split('/');
  const id = newPathname[index];
  return id;
}

export function imageProcessor(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export function randomHex() {
  const hex = Math.floor(Math.random() * 0xffffff).toString(16);
  return `#${`000000${hex}`.slice(-6)}`;
}
