import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomItemButtom, { iconType } from 'components/CustomItemButtom';

import { getCheckpoints } from 'services/Api/Training';
import { selectInferencePoints, selectInferenceConfidence } from 'store/ducks/training';
import StepSlider from 'components/Matrix/stepSlider';
import { t, strings } from 'language';
import {
  ContainerFolders,
  PlaceholderTextContainer,
  ContainerFields,
  InferencePoint
} from './styles';
import AddFolderButton from './addFolderButton';

export default function NewTraining() {
  const dispatch = useDispatch();
  const [confidence, setConfidence] = useState(0.5);

  const training = useSelector(state => state.training);
  const [selectInferencePointsList, setSelectInferencePointsList] = useState([]);
  const [inferencePointValue, setInferencePointValue] = useState(0);

  const handleChangeInferencePoint = point => {
    dispatch(selectInferencePoints([point]));
    setInferencePointValue(point);
  };
  const handleChangeInferenceConfidence = value => {
    dispatch(selectInferenceConfidence(value));
    setConfidence(value);
  };
  useEffect(() => {
    async function getData() {
      const points = await getCheckpoints(training.model.id);
      // console.log('points', points);
      setSelectInferencePointsList(points);
    }
    //if (open) {
    if (training.model.id) {
      getData();
    }

    //}
  }, [training.model.id]);

  return (
    <div className="container_folders">
      <ContainerFields>
        <div className="inferencePointSelect">
          <InferencePoint
            name="inferencePointValue"
            onChange={e => handleChangeInferencePoint(e.target.value)}
          >
            <option>{t(strings.select_inference_point)}</option>
            {selectInferencePointsList?.map(i => (
              <option>{i.inferencePoint}</option>
            ))}
          </InferencePoint>
        </div>

        <div className="d-flex">
          <AddFolderButton />
        </div>
        <div className="d-flex flex-column align-items-center w-100 justify-content-center">
          <span className="mb0">{t(strings.confidence)}</span>
          <StepSlider
            label=""
            labels={{
              0.1: '0.1',
              0.2: '0.2',
              0.3: '0.3',
              0.4: '0.4',
              0.5: '0.5',
              0.6: '0.6',
              0.7: '0.7',
              0.8: '0.8',
              0.9: '0.9'
            }}
            min={0.1}
            max={0.9}
            step={0.1}
            value={confidence}
            onChange={e => handleChangeInferenceConfidence(e)}
          />
        </div>
      </ContainerFields>
      <ContainerFolders>
        {training.selectedInferenceDatasets.length > 0 ? (
          training.selectedInferenceDatasets.map((item, index) => {
            return (
              <CustomItemButtom
                key={index}
                className="float-left"
                width={261}
                icon={iconType.folder}
              >
                {item.title}
              </CustomItemButtom>
            );
          })
        ) : (
          <PlaceholderTextContainer>{t(strings.no_datasets_selected)}</PlaceholderTextContainer>
        )}
      </ContainerFolders>
    </div>
  );
}
