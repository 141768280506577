import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.secondary};
  padding: 20px 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
