import React from 'react';
import TagName from '../TagName';
function Rectangle(props) {
  // eslint-disable-next-line
  const { geometry, className, style, onClick, onDoubleClick, name, data } = props;

  if (!geometry) return null;
  return (
    <>
      <div
        className={className}
        style={{
          position: 'absolute',
          left: `${geometry.x}%`,
          top: `${geometry.y}%`,
          height: `${geometry.height}%`,
          width: `${geometry.width}%`,
          ...style
        }}
      >
        <TagName data={data} geometry={geometry}/>
      </div>
    </>
  );
}

Rectangle.defaultProps = {
  className: '',
  style: {}
};

export default Rectangle;
