import styled from 'styled-components';

import RangeSlider from 'react-rangeslider';

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 8px;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  width: 30px;
  height: 30px;
`;

export const Title = styled.small`
  color: #909090;
  cursor: default;
`;

export const Slider = styled(RangeSlider)`
  height: 5px !important;
  border-radius: 5px !important;

  & > .rangeslider__labels .rangeslider__label-item {
    font-size: small;
  }

  & > .rangeslider__fill.rangeslider__fill {
    background-color: ${props => props.theme.white};
  }

  & > .rangeslider__handle {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;
    ::after {
      display: none;
    }

    & > .rangeslider__handle-tooltip {
      width: 116px !important;
      background-color: ${props => props.theme.secondary} !important;
      border: 1px solid ${props => props.theme.text};
      ::after {
        border-top: 8px solid ${props => props.theme.secondary} !important;
      }
    }
  }
`;
