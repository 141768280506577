import styled from 'styled-components';

export const Container = styled.div`
  width: 195px;

  :hover .editButton {
    display: block;
  }

  :hover .editButton,
  :hover .markerButton {
    display: block;
  }
`;
export const InternalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const EditButton = styled.a`
  /* opacity: ${props => (props.mouseOver ? 1 : 0)}; */


  transition: opacity 150ms linear;
  width: 53px;
    height: 25px;
  margin-left: 12px;
  border-radius: 3px;
  border: none;
  background-size:20px;
  /* background-image: url(${require('assets/images/class_icon.svg')})!important; */
  background-color: ${props => props.theme.iconColor};
  -webkit-mask: url(${require('assets/images/edit-icon.svg')}) no-repeat left;
  mask: url(${require('assets/images/edit-icon.svg')}) no-repeat left;
  background-position: 1px 1px!important;
  padding-right: 52px!important;
  padding-left: 30px!important;
  background-repeat: no-repeat!important;
  bottom: 10px;
    left:0px;
    display:none;
    position:absolute;
  :focus {
    outline: none!important;
  }
`;
export const ContainerTop = styled.div`
  background: ${props => props.theme.secondary};
  align-items: flex-start;

  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 135px;
`;
export const Name = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 0px;
  padding: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  height: 43px;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.white};
    word-break: break-all;
  }
`;

export const Image = styled.img`
  height: 20px;
`;

export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;

  ::placeholder {
    color: ${props => props.theme.secondary};
    opacity: 0.5;
  }
`;
export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;
export const Tags = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: space-between;
  padding-left: 10px;
  width: 100%;
  justify-content: space-between;

  span {
    border-radius: 7px;
    background: ${props => props.theme.secondary};
    padding: 0px 8px;
    color: ${props => props.theme.white};
    font-size: 12px;
    border: 1px solid ${props => props.theme.default};
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    height: 22px;
  }
`;

export const ImageIcon = styled.img`
  height: 10px;
`;

export const TextContainer = styled.div`
  padding-left: 10px;
  padding-top: 10px;
`;
export const TextItemDetail = styled.p`
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 5px;
  font-size: 11px;
  color: ${props => props.theme.white};

  strong {
    color: ${props => props.theme.white};
  }
  time {
    color: ${props => props.theme.white};
  }
`;

export const StarContainer = styled.div``;
export const Markers = styled.div`
  display: flex;
  align-items: space-between;
  padding-left: 10px;
  width: 100%;
  justify-content: space-between;
  padding-top: 5px;
  height: 35px;
  top: 4px;
  right: 0;
  p {
    border-radius: 5px;
    background: ${props => props.theme.secondary};
    padding: 1px 5px;
    color: ${props => props.theme.white};
    font-size: 10px;
    border: 1px solid ${props => props.theme.white};
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    width: 76px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
  }
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`;
