import styled from 'styled-components';

export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;

  ::placeholder {
    color: ${props => props.theme.secondary};
    opacity: 0.5;
  }
`;
export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;
export const LabelAlert = styled.div`
  font-size: 12px;
  padding-top: 5px;
`;
