import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import InsideLoading from 'components/Loading/insideLoading';
import { useDispatch } from 'react-redux';
import { setSearchTotal } from 'store/ducks/filter';
import { t, strings } from 'language';
import useWindowSize from '../useWindowSize';

const InfiniteScrollV2 = forwardRef(({ pagedResource, customParams, size, ItemComponent }, ref) => {
  const [state, setState] = useState({
    currentPage: 0,
    hasMore: true,
    items: [],
    pageCount: undefined
  });
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMoreData(state.currentPage, customParams);
  }, []);

  const fetchMoreData = async (page, parans, reset) => {
    // debugger;
    let result;
    if (parans) {
      const keys = Object.keys(parans);
      result = await pagedResource(
        page + 1,
        size,
        parans[keys[0]],
        parans[keys[1]],
        parans[keys[2]],
        parans[keys[3]],
        parans[keys[4]],
        parans[keys[5]],
        parans[keys[6]]
      );
      dispatch(setSearchTotal(result.rowCount));
    } else {
      result = await pagedResource(page + 1, size);
    }

    const { results, pageCount, currentPage } = result;

    const hasMore = currentPage < pageCount;
    setState({
      ...state,
      currentPage,
      pageCount,
      items: reset ? results : [...state.items, ...results],
      hasMore
    });
  };

  useImperativeHandle(ref, () => ({
    resetScroll() {
      fetchMoreData(0, customParams, true);
    }
  }));

  return (
    <>
      <InfiniteScroll
        dataLength={state.items.length}
        next={async () => await fetchMoreData(state.currentPage, customParams)}
        hasMore={state.hasMore}
        loader={<InsideLoading key="InsideLoading" />}
        height={windowSize.height - 230}
        style={{ display: 'flex', width: '100%', flexWrap: 'wrap', alignContent: 'flex-start' }}
        endMessage={(
          <div style={{ width: '100%' }}>
            <p style={{ textAlign: 'center' }}>
              <b>{t(strings.infinite_scroll_msg)}</b>
            </p>
          </div>
        )}
      >
        {state.items.map((i, index) => (
          <ItemComponent item={i} key={index} itemkey={index} />
        ))}
      </InfiniteScroll>
    </>
  );
});
export default React.memo(InfiniteScrollV2);
