import { apiGet, apiPost } from '../index';

export async function getNotifications(size = 50) {
  return apiGet(`Notifications/?Size=${size}`);
}

export const setNotificationRead = () => {
  return apiPost(`Notifications/read/`);
};
export const getNotificationCount = () => {
  return apiGet(`Notifications/notreads`);
};
