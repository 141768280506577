import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { AddFolderButton } from './styles';
import { selectTrainingDataset, selectEvalDataset, selectTags } from 'store/ducks/training';
import { getLastTraining } from 'services/Api/Training';

import { loading } from 'store/ducks/general';
import { t, strings } from 'language';

export default function(props) {
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);
  const loadLastTraining = useCallback(async () => {
    dispatch(loading(true));
    const lastTraining = await getLastTraining(training.model.id, true);
    if (lastTraining == null) {
      dispatch(loading(false));
      toast.warn(t(strings.train_there_are_no));
      return;
    }
    dispatch(selectTrainingDataset(lastTraining.trainingDatasets));
    dispatch(selectEvalDataset(lastTraining.evalDatasets));
    dispatch(
      selectTags(
        lastTraining.usedTrainingTags.map(i => {
          return {
            id: i.tag.id,
            tag: i.tag.description,
            count: i.count,
            error: false,
            warning: false,
            color: i.tag.color,
            trained: true
          };
        })
      )
    );
    dispatch(loading(false));
  }, [dispatch, getLastTraining, training.model.id]);
  return (
    <div className="d-block ml-4">
      <AddFolderButton onClick={loadLastTraining}>
        <img src={require('assets/images/newmodelbutton.png')} alt="icon New folder" />
        {t(strings.train_load_last)}
      </AddFolderButton>
    </div>
  );
}
