import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 18px;
  position: relative;
`;
export const Badge = styled.p`
  position: absolute;
  top: -10px;
  right: -7px;
  background: #d4130d;
  color: #fff;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 13px;
`;
export const ButtonItem = styled.button`
  height: 47px;
  width: 52px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${props => props.theme.secondary} 0% 0% no-repeat padding-box;

  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  padding-right: 0;
  :focus {
    outline: none;
  }
  padding-left: ${props => (props.background ? '40px' : '0px')};
  padding-top: ${props => (props.background ? '5px' : '0px')};
  margin-left: 10px;
`;

export const ContainerMarkers = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ClearFilter = styled.a`
  text-align: right;
  position: absolute;
  top: -42px;
  right: 0;
  color: ${props => props.theme.white};
`;
