import styled from 'styled-components';

export const Container = styled.div`
  border-left: 2px solid #707070;
  margin-bottom: 20px;
  padding-left: 17px;
  margin-left: 10px;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .iconText span time,
  .iconText + p {
    color: ${props => props.theme.white};
  }

  .started {
    @-moz-keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    /* IE */
    @-ms-keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    /* Opera and prob css3 final iteration */
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
    -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
    -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
    animation: blink normal 2s infinite ease-in-out;
  }
`;
export const Link = styled.a`
  color: ${props => props.theme.white};
  text-decoration: underline;
`;

export const Icon = styled.img`
  margin-right: 10px;
  height: 20px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tr = styled.tr`
  whitespace: nowrap;
`;

export const Td = styled.td`
  padding: 3px;
`;

export const GearIcon = styled.img`
  padding-left: 5px;
`;

export const ProgressBar = styled.div`
  width: 150px;
  height: 15px;
  background: #715f4c 0% 0% no-repeat padding-box;
  border: 2px solid ${props => props.theme.default};
  border-radius: 5px;
  opacity: 1;
  position: relative;
  & > span {
    position: absolute;
    left: 40%;
    top: -1px;
    font-size: 10px;
    color: black;
  }
`;

export const ProgressLoad = styled.div`
  width: ${props => props.progress}%;
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  transition: width 1.5s ease;
  border-radius: 5px;
  opacity: 1;
  height: 14px;
  margin-top: -1.5px;
`;
