import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteTag } from 'services/Api/Dataset/dataset';
import { GetSelectImage } from 'store/ducks/annotate';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { Container, Row, Col } from 'reactstrap';
import { Icon } from 'components/Icon';
import ButtonIcon from './components/ButtonIcon';
import { Title, Box, BoxDiv, TitleGlobal } from './styles';

function DeleteTags(props) {
  const dispatch = useDispatch();
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  const [tagToDelete, setTagToDelete] = useGlobal(
    state => state.tagToDelete,
    actions => actions.setTagToDelete
  );
  const [, setActionTagWidth] = useGlobal(
    state => state.actionTagWidth,
    actions => actions.setActionTagWidth
  );
  const show = tagToDelete.length > 0 ? 'block' : 'none';

  useEffect(() => {
    setActionTagWidth(show === 'block' ? 728 : 540);
  }, [show]);

  async function deleteAnnotates() {
    tagToDelete.map(item => {
      const { type } = item.geometry;
      switch (type) {
        case 'POINT':
        default:
          deleteTag(item.data.imageTagId);
      }
    });
    setTagToDelete([]);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  function cancelAction() {
    setTagToDelete([]);
  }

  return (
    <Box
      className="mh-100 no-margin"
      style={{ marginTop: '24px', marginLeft: '20px', marginRight: '10px', display: show }}
    >
      <TitleGlobal>{t(strings.delete_tags)}</TitleGlobal>
      <BoxDiv>
        <Row className="mt-3 ml-2 mr-2">
          <Container>
            <Row
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '120px', maxWidth: '120px' }}
            >
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => deleteAnnotates()}>
                  <Icon name="delete_icon" />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => cancelAction()}>
                  <Icon name="cancel" />
                </ButtonIcon>
              </Col>
            </Row>
            <Row style={{ minWidth: '120px', maxWidth: '120px', marginTop: '5px' }}>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.delete)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.cancel)}</Title>
              </Col>
            </Row>
          </Container>
        </Row>
      </BoxDiv>
    </Box>
  );
}


DeleteTags.defaultProps = {
  style: {},
  className: ''
};

export default DeleteTags;