const INITIAL_STATE = {
  show: false,
  annotation: [],
  annotateList: [],
  className: 'boxEdit',
  onChange: {},
  onSubmit: {}
};

export const Types = {
  SHOW_CONTENT: 'contentEdit/SHOW_CONTENT'
};

export function contentEdit({ show, annotation, annotateList, className, onChange, onSubmit }) {
  return {
    type: Types.SHOW_CONTENT,
    payload: {
      show,
      annotation,
      annotateList,
      className,
      onChange,
      onSubmit
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  let { type, payload } = action;
  switch (type) {
    case Types.SHOW_CONTENT:
      return {
        ...state,
        show: payload.show,
        annotation: payload.annotation,
        annotateList: payload.annotateList,
        className: payload.className,
        onChange: payload.onChange,
        onSubmit: payload.onSubmit
      };
    default:
      return state;
  }
}
