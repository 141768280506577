import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  width: 195px;
`;
export const InternalContainer = styled.div`
  width: 195px;
`;
export const ContainerTop = styled.div`
  background: ${props => props.theme.secondary};
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 180px;
`;
export const Name = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 0px;
  padding: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.white};
  }
`;

export const Image = styled.img`
  width: 100%;
`;
