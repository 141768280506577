import styled, { css } from 'styled-components';
import down from 'assets/images/down_icon.png';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 21px;
  width: 490px;
  height: ${props => (props.collapse ? '70px' : '370px')};
  background: ${props => props.theme.background} 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #0707072e;
  border-radius: 8px 8px 0px 0px;
  transition: height 0.7s ease;
`;

export const UploadHeader = styled.div`
  background: ${props => props.theme.secondary} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px 8px 0px 0px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 33px;
  padding-right: 80px;
`;

export const Title = styled.span`
  font-size: 20px;
  color: ${props => props.theme.white};
`;

export const UploadBody = styled.div`
  height: 300px;
  padding-left: 33px;
  padding-right: 80px;
  padding-bottom: 25px;
  margin-top: 25px;
`;

export const CollapseButton = styled.button`
  transition: transform 0.3s;
  ${props =>
    props.collapse
      ? ''
      : css`
          transform: rotateX(180deg);
        `}

  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  width: 51px;
  height: 48px;
  background: url(${down});
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${props => props.theme.secondary};

  box-shadow: 0px ${props => (props.collapse ? '3px' : '-3px')} 6px #00000029;
  border: none;
  border-radius: 5px;

  &:active {
    box-shadow: none;
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;
