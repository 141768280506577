import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getToken } from 'store/ducks/auth';

const BASE_URL = process.env.REACT_APP_DOMAIN;

export const connect = () =>
  new Promise(function(resolve) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}/inferencehub`, {
        accessTokenFactory: getToken // Return access token
      })
      .withAutomaticReconnect()
      .build();

    connection.start().then(function() {
      resolve(connection);
    });
  });

export function handleInference(connection, id, callback) {
  // console.log('inferecenId', id);
  connection.invoke('HandleInference', id);
  connection.on('InferenceLog', data => callback(data));
}

export function fakeLog(connection, id, message) {
  connection.invoke('SendInferenceInfo', id, message, 'log');
}
