import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
`;

export const Table = styled.table`
  width: 100%;
  & th {
    padding: 5px;
  }
  & td {
    color: ${props => props.theme.white};
    position: relative;
    min-width: 150px;
    padding: 5px;
  }
`;
export const GaugeBG = styled.div`
  height: 20px;
  background-color: ${props => props.bgColor};
  width: ${props => props.value}%;
  & span {
    color: ${props => props.theme.white};
    text-align: right;
    width: 100%;
  }
`;

export const GaugeValue = styled.div`
  position: absolute;
  top: 5px;
  left: 10px;
  color: ${props => props.theme.white};
`;
