import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGlobal from 'store/global';
import { useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import { t, strings } from 'language';
import Moment from 'react-moment';
import Dialog from 'components/Dialog';
import { getInferenceDetails, getInferenceDataset } from 'services/Api/Inference/inference';
import { GetInferenceDataset } from 'store/ducks/inferenceDetails';
import CustomItemButtom, { iconType } from 'components/CustomItemButtom';
import {
  Table,
  Tr,
  Td,
  TdTitle,
  TdText,
  Title,
  HeaderContainer,
  ButtonContainer,
  StatusContainer,
  FolderContainer,
  FolderTitle,
  FolderButton
} from './styles';
import ConsoleLog from '../ConsoleLog';
import ButtonIcon from './ButtonIcon';
import { ReactComponent as CancelIcon } from './assets/images/cancel.svg';

export default function InferenceSession(props) {
  const { data, hubConnection, open, closeDetatils } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [inferenceDetails, setInferenceDetails] = useGlobal(
    state => state.inferenceDetails,
    actions => actions.setInferenceDetails
  );
  const [collapseStatus, setCollapseStatus] = useState([]);
  const showStopInference = false;
  const [, setInferenceID] = useGlobal(
    state => state.inferenceID,
    actions => actions.setInferenceID
  );
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    async function getData() {
      const inference = await getInferenceDetails(data.inferenceId);
      // console.log('getInferenceDetails', inference);
      setInferenceDetails(inference);
    }
    if (open) {
      getData();
    }
    // eslint-disable-next-line
  }, [open, data.inferenceId]);

  function dateDiffTime(d1, d2) {
    const date = new Date();
    const tz = date.getTimezoneOffset() / 60;
    const nowUTC = date.setHours(date.getHours() + tz);
    const date1 = new Date(d1);
    const date2 = d2 === undefined ? nowUTC : new Date(d2);
    const diff = date2 - date1;
    const diffDays = Math.floor(diff / 8.64e7);
    const diffHrs = Math.floor((diff % 8.64e7) / 3.6e6);
    const diffMins = Math.floor(((diff % 8.64e7) % 3.6e6) / 6e4);
    const diffSecs = Math.floor((((diff % 8.64e7) % 3.6e6) % 6e4) / 1000);
    const days = diffDays > 0 ? `${`${diffDays} ${t(strings.days)}`}, ` : '';
    const hours = `${diffHrs}`.length > 1 ? `${diffHrs}:` : `0${diffHrs}:`;
    const minutes = `${diffMins}`.length > 1 ? `${diffMins}:` : `0${diffMins}:`;
    const seconds = `${diffSecs}`.length > 1 ? diffSecs : `0${diffSecs}`;
    const result = `${days}${hours}${minutes}${seconds}`;

    return result;
  }
  // eslint-disable-next-line
  function spetDiff(s1, s2) {
    return s2 !== undefined ? `${s2 - s1} ${t(strings.steps)}` : t(strings.running);
  }

  function toggleCollapse(index) {
    const status = [...collapseStatus];
    status[index] = !collapseStatus[index];
    setCollapseStatus(status);
  }

  function stopInference() {
    alert('Ask if really to do.');
  }

  async function getImageId(datasetid) {
    const image = await getInferenceDataset(inferenceDetails.inferenceId, datasetid);
    const imageId = image.results[0].id;
    setInferenceID(inferenceDetails.inferenceId);
    history.push(
      `/dataset/${datasetid}/inference/${inferenceDetails.inferenceId}/annotate/${imageId}`
    );
  }

  function openAnnotate(dataset) {
    if (inferenceDetails?.tags.length > 0) {
      dispatch(GetInferenceDataset(data.inferenceId));
      getImageId(dataset.datasetId);
    }
  }

  function showSelected(index, bool) {
    if (inferenceDetails?.tags.length > 0) {
      const sel = [...selected];
      sel[index] = !selected[index];
      // console.log('sel', sel);
      // console.log('index', index);
      // console.log('selected[index]', selected[index]);
      setSelected(sel);
    }
  }

  return (
    <Dialog
      title={t(strings.inference_session)}
      open={open}
      close={() => closeDetatils()}
      //actions={[{ text: `${t(strings.close)}`, action: () => closeDetatils() }]}
      width="1000px"
    >
      {inferenceDetails && (
        <>
          <HeaderContainer>
            {showStopInference ? (
              <ButtonContainer style={{ minWidth: '60px', maxWidth: '60px' }}>
                <ButtonIcon
                  style={{ position: 'relative', left: '11px' }}
                  onClick={() => stopInference()}
                >
                  <CancelIcon />
                </ButtonIcon>
                <Title>{t(strings.cancel_inference)}</Title>
              </ButtonContainer>
            ) : (
              ''
            )}
            <StatusContainer status={data.status}>{data.status}</StatusContainer>
          </HeaderContainer>
          <Table>
            <tbody>
              <Tr>
                <TdTitle>{`${t(strings.started_at)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.inference_time)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.finished_at)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.machine)}:`}</TdTitle>
              </Tr>
              <Tr>
                <TdText>
                  <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                    {inferenceDetails.startAt}
                  </Moment>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {dateDiffTime(inferenceDetails.startAt, inferenceDetails.finishedAt)}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  {inferenceDetails.finishedAt === undefined ? (
                    <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                      {new Date()}
                    </Moment>
                  ) : (
                    <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                      {inferenceDetails.finishedAt}
                    </Moment>
                  )}
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {inferenceDetails.checkpoint.machineGroup}
                  </span>
                </TdText>
              </Tr>
              <Tr>
                <Td style={{ width: '10px' }} />
              </Tr>
              <Tr>
                <TdTitle>{`${t(strings.confidence)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.inference_point)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.images)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.tags)}:`}</TdTitle>
              </Tr>
              <Tr>
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>{inferenceDetails.confidence}</span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {inferenceDetails.checkpoint.inferencePoint}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {`${inferenceDetails.totalImagesWithInference}/${inferenceDetails.totalImages}`}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {`${inferenceDetails.tags &&
                      inferenceDetails.tags.reduce(
                        (totalTags, myTag) => totalTags + myTag.count,
                        0
                      )}`}
                  </span>
                </TdText>
              </Tr>
            </tbody>
          </Table>
          <br />
          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.inferenceDatasets)} - 
                ${inferenceDetails?.datasets?.length} ${t(strings.datasets)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    inferenceDetails.datasets && inferenceDetails?.datasets?.length > 0
                      ? 'block'
                      : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(0)}
                open={collapseStatus[0]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[0]} className="mt-3">
              {inferenceDetails.datasets &&
                inferenceDetails.datasets.map((item, index) => {
                  return (
                    <div
                      onMouseOver={() => showSelected(index, true)}
                      onMouseOut={() => showSelected(index, false)}
                    >
                      <CustomItemButtom
                        key={index}
                        icon={iconType.folder}
                        width={350}
                        selected={selected[index]}
                        onClick={() => openAnnotate(item)}
                      >
                        {`${item.title} - 
                        ${item.totalImagesWithInference}/${item.totalImages} 
                        ${t(strings.images)}`}
                      </CustomItemButtom>
                    </div>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.tags)} - `}
                {`${inferenceDetails?.tags?.length} ${t(strings.class)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    inferenceDetails?.tags && inferenceDetails?.tags?.length > 0 ? 'block' : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(2)}
                open={collapseStatus[2]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[2]} className="mt-3">
              {inferenceDetails?.tags &&
                inferenceDetails?.tags.map((item, index) => {
                  return (
                    <CustomItemButtom key={index} icon={iconType.tag} width={350}>
                      {`${item.tag.description} - ${item.count} ${t(strings.tags)}`}
                    </CustomItemButtom>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>{t(strings.inference_process)}</p>
              <FolderButton
                style={{
                  display: `${inferenceDetails.showConsole ? 'block' : 'none'}`
                }}
                onClick={() => toggleCollapse(4)}
                open={collapseStatus[4]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[4]} className="mt-3">
              {
                <ConsoleLog
                  data={data}
                  hubConnection={hubConnection}
                  inferenceDetails={inferenceDetails}
                  isInference={true}
                />
              }
            </Collapse>
          </FolderContainer>
        </>
      )}
    </Dialog>
  );
}
