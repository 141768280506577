import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  position: relative;

  .training-button {
    background: ${props => props.theme.default};
    color: ${props => props.theme.white};
  }
`;

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 8px 25px;
  border-radius: 5px;
  color: ${props => props.theme.default};
  padding: 15px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 16px;
`;
export const Icon = styled.img`
  margin-right: 15px;
  height: 20px;
`;

export const Count = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  background: #ff0000;
  color: #fff;
  border-radius: 25px;
  padding: 6px 8px;
  font-size: 11px;
  z-index: 100;
  width: 25px;
  text-align: center;
`;
