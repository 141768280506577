import React, { useContext, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';
import { ThemeContext } from 'styled-components';
import { t, strings } from 'language';

export default function MultiSelectSearchab(props) {
  const all = { value: '_select_all', label: 'Select All' };
  const theme = useContext(ThemeContext);

  const { selected, onChange, promiseOptions, options, setSelectedFilters, defaultValue } = props;
  useEffect(() => {
    setSelectedFilters(defaultValue);
  }, []);

  // console.log('props', props);
  const style = {
    control: e => ({
      ...e,
      backgroundColor: theme.secondary,
      outline: 'none',
      border: '1px solid black',
      boxShadow: '0px 3px 6px #00000029'
    }),
    indicatorSeparator: e => ({
      ...e,
      backgroundColor: 'black'
    }),
    multiValue: e => ({
      ...e,
      backgroundColor: theme.primary
    }),
    multiValueLabel: e => ({
      ...e,
      color: theme.default
    }),
    menu: e => ({
      ...e,
      backgroundColor: theme.secondary
    })
  };

  return (
    <AsyncSelect
      placeholder={t(strings.select)}
      noOptionsMessage={() => t(strings.enter_term)}
      loadingMessage={() => t(strings.loading)}
      loadOptions={promiseOptions}
      isMulti
      onChange={e => {
        onChange(e || []);
      }}
      value={options}
      styles={style}
    />
  );
}
