import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { t, strings } from 'language';
import { Container, Select } from './styles';
import { setOrderBy } from '../../../store/ducks/filter';

const Ordenation = ({ section }) => {
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.filter);

  // useEffect(() => {
  //   console.log('filterState dentro do ordenation', filterState);
  // }, [filterState]);

  const handleOrderBy = item => {
    dispatch(setOrderBy(item.target.value));
  };
  const isImageList = () => {
    if (section === 'Dataset' || section === 'Folder') return true;
  };

  return (
    <Container className="pb-2">
      <Select value={filterState.orderby} onChange={handleOrderBy}>
        <option value="">{t(strings.sort_by)}</option>
        <option value="AlphabeticalAsc">{t(strings.name)}</option>
        <option value="CreateAsc">{t(strings.younger)}</option>
        <option value="CreateDesc">{t(strings.older)}</option>
        {isImageList() && (
          <>
            <option value="ImageCountDesc">{t(strings.more_images)}</option>
            <option value="ImageCountAsc">{t(strings.less_images)}</option>
          </>
        )}
        {section === 'Model' && (
          <>
            <option value="LastTrainingDesc">{t(strings.old_training)}</option>
            <option value="LastTrainingAsc">{t(strings.new_training)}</option>
          </>
        )}
        {/* <option value="AlphabeticalDesc">Z-A</option> */}
      </Select>
    </Container>
  );
};

export default Ordenation;
