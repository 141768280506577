import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import * as animationData from 'assets/animations/loading.json';

function Loading(props) {
  const general = useSelector(state => state.general);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  if (!general.loading) return null;
  return (
    <div>
      <LoadingOverlay
        active={general.loading}
        className="d-flex h-100 w-100 position-absolute justify-content-center align-items-center"
        spinner={
          <Lottie options={defaultOptions} height={250} width={250} isClickToPauseDisabled={true} />
        }
      >
        {props.children}
      </LoadingOverlay>
    </div>
  );
}

export default Loading;
