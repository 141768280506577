import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.secondary};
  border-radius: 5px;
  padding: 20px;
  display: flex;
`;

export const Table = styled.table`
  & th {
    padding: 5px;
    color: ${props => props.theme.white};
  }
  & td {
    text-align: center;
    border: 1px solid ${props => props.theme.white};
    color: ${props => props.theme.white};
  }
  & tfoot {
    & th {
      padding: 5px;
      & span {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        white-space: nowrap;
        color: ${props => props.theme.white};
      }
    }
  }
`;

export const TD = styled.td`
  background-color: ${props => `rgba(255, 204, 111, ${props.opacity ?? 0})`};
`;

export const FalseTD = styled.td`
  background-color: ${props => props.theme.secondary};
`;

export const RecallTD = styled.td`
  background-color: ${props => props.theme.primary};
  font-size: 12px;
  padding: 5px;
  &:after {
    content: '%';
  }
`;

export const ColorBarContainer = styled.div`
  padding: 0 15px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorBar = styled.div`
  background-color: ${props => props.theme.default};
  width: 10px;
  height: 100%;
  border: 1px solid black;
  background: linear-gradient(rgba(255, 204, 111, 1), rgba(0, 255, 0, 0));
`;

export const ColorValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 105%;
`;

export const ColorValue = styled.span`
  font-weight: bold;
  color: ${props => props.theme.white};
  margin-left: 2px;
  &:before {
    content: '';
  }
`;
