import React from 'react';
import { Container, Button } from './styles';

const SVButton = props => (
  <Container className="d-flex align-items-center justify-content-center">
    <Button {...props}>{props.children}</Button>
  </Container>
);

export default SVButton;
