import React from 'react';
import { useSelector } from 'react-redux';

import CustomItemButtom, { iconType } from 'components/CustomItemButtom';

import { t, strings } from 'language';
import { ContainerFolders, PlaceholderTextContainer, GrayText } from './styles';
import AddFolderButton from './addFolderButton';

export default function NewTraining() {
  const training = useSelector(state => state.training);

  return (
    <div className="container_folders">
      <GrayText>{t(strings.train_folders_for_eval)}</GrayText>
      <AddFolderButton isEval={true} />
      <ContainerFolders>
        {training.selectedEvalDatasets.length > 0 ? (
          training.selectedEvalDatasets.map((item, index) => {
            return (
              <CustomItemButtom
                key={index}
                className="float-left"
                width={261}
                icon={iconType.folder}
              >
                {item.title}
              </CustomItemButtom>
            );
          })
        ) : (
          <PlaceholderTextContainer>{t(strings.no_folders_selected)}</PlaceholderTextContainer>
        )}
      </ContainerFolders>
    </div>
  );
}
