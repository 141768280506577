import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { showTooltip } from 'store/ducks/tooltip';

import { Container, Name, Image, InternalContainer, Tag } from './styles';

import ReactTooltip from 'react-tooltip';
import lockedImg from 'assets/images/locked.png';
import warningImg from 'assets/images/warning.png';

export const iconType = {
  folder: require('assets/images/folder.png'),
  tag: require('assets/images/tag.png')
};

function CustomItemButtom(props) {
  const dispatch = useDispatch();

  const [tooltipInterval, setTooltipInterval] = useState();
  const {
    children,
    tooltip,
    onClick,
    icon,
    selected,
    error,
    warning,
    message,
    width,
    height
  } = props;

  const errorTooltip = {
    hasTooltip: error || warning,
    type: error ? 'error' : warning ? 'warning' : null,
    image: error ? lockedImg : warning ? warningImg : null,
    hash: 'tooltip' + Math.random()
  };

  function scheduleTooltip() {
    const show = showTooltip(tooltip());
    setTooltipInterval(setTimeout(() => dispatch(show), 1000));
  }
  return (
    <Container
      onMouseEnter={() => (tooltip ? scheduleTooltip() : null)}
      onMouseLeave={() => {
        if (tooltip) {
          clearInterval(tooltipInterval);
          dispatch(showTooltip(false));
        }
      }}
    >
      {errorTooltip.hasTooltip && (
        <>
          <ReactTooltip id={errorTooltip.hash} type={errorTooltip.type} effect="solid" />
          <Tag data-for={errorTooltip.hash} data-tip={message} src={errorTooltip.image}></Tag>
        </>
      )}
      <div
        style={{ cursor: error ? 'not-allowed' : 'pointer' }}
        onClick={!error ? onClick : undefined}
      >
        <InternalContainer selected={selected} width={width} height={height}>
          {icon && <Image src={icon} />}
          <Name width={width}>
            <h3>{children}</h3> 
          </Name>
        </InternalContainer>
      </div>
    </Container>
  );
}

export default CustomItemButtom;
