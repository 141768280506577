import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  width: 195px;
`;
export const InternalContainer = styled.div`
  width: 195px;
`;
export const ContainerTable = styled.div`
  width: 100%;
  height: 65vh;
  overflow-y: auto;

  table th,
  table td {
    vertical-align: middle;
  }
`;
export const Name = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 0px;
  padding: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.default};
  }
`;

export const Image = styled.img``;
export const Select = styled.select`
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.default};

  padding: 5px 20px;
`;

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.default};
  font-size: 16px;
  border: none;
  padding: 5px 20px;
`;
