import React, { useEffect, useState, useCallback } from 'react';

import querystring from 'query-string';
import { useLocation } from 'react-router';
import { Container } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import { BrowserType } from 'utils/constants';
import DatasetBrowser from 'components//DatasetBrowser';

function Datasets() {
  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState(null);
  const [count, setCount] = useState('');

  const location = useLocation();

  const getBreadCrumn = useCallback(async () => {
    const folderId = querystring.parse(location.search).folder;
    const count = querystring.parse(location.search).count;
    setCount(count);
    setFolderName(querystring.parse(location.search).folderName);
    setFolderId(folderId);
  }, [location.search]);

  useEffect(() => {
    getBreadCrumn();
  }, [getBreadCrumn]);

  return (
    <Container>
      <TitleBreadcrumb
        position={3}
        name={folderName}
        url={`/datasets?folder=${folderId}&folderName=${folderName}&count=${count ? count : '0'}`}
        count={count}
      ></TitleBreadcrumb>
      <DatasetBrowser folderId={folderId} type={BrowserType.DATASET} />
    </Container>
  );
}

export default Datasets;
