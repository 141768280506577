import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showTooltip } from 'store/ducks/tooltip';
import { Container } from './styles';

export default function() {
  const dispatch = useDispatch();
  const tooltip = useSelector(state => state.tooltip);

  const [mouseX, setMouseX] = useState(-1);
  const [mouseY, setMouseY] = useState(-1);
  const tooltipRef = useRef();
  const refX = useRef(0);
  const refY = useRef();

  const trackMouse = useCallback(() => {
    window.onclick = function() {
      dispatch(showTooltip(false));
    };
    window.onmousemove = function(event) {
      const { width, height } = tooltipRef.current.getBoundingClientRect();
      if (event.clientX > window.innerWidth / 2) refX.current = event.clientX - width - 20;
      else refX.current = event.clientX + 20;
      if (event.clientY > window.innerHeight / 2) refY.current = event.clientY - height - 5;
      else refY.current = event.clientY + 5;
    };
  }, [dispatch]);

  useEffect(() => {
    trackMouse();
    return () => {
      window.onmousemove = null;
    };
  }, [trackMouse]);

  useEffect(() => {
    if (tooltip.show) {
      window.onmousemove = null;
      setMouseX(refX.current);
      setMouseY(refY.current);
    } else {
      trackMouse();
    }
  }, [trackMouse, tooltip.show]);
  return (
    <Container
      visible={tooltip.show && tooltip.url === window.location.href}
      top={mouseY}
      left={mouseX}
      ref={tooltipRef}
      onClick={() => dispatch(showTooltip(false))}
    >
      {tooltip.content}
    </Container>
  );
}
