import { apiGet } from '../index';

export async function GetStorageInfo(datasetId) {
  return apiGet('Storage/connection/' + datasetId);
}

export async function GetProfileStorageInfo() {
  return apiGet('Storage/connection');
}

export async function GetStorageVideo(datasetId) {
  return apiGet('Storage/connection/' + datasetId + '/video');
}
