import { apiDelete, apiGet, apiPost } from '../index';

export const changeActiveSubscription = subscriptionId => {
  return apiPost(`subscription/select/${subscriptionId}`);
};

export const inviteMember = email => {
  return apiPost(`subscription/invite`, {
    email
  });
};
export const getRoles = () => {
  return apiGet(`subscription/roles?size=50&page=1`);
};
export const getOverview = () => {
  return apiGet(`subscription/overview`);
};
export const getAssociationsTotal = () => {
  return apiGet(`subscription/association/total`);
};
export const getInvoices = () => {
  return apiGet(`subscription/invoices`);
};
export const makeApiAssociation = body => {
  return apiPost(`subscription/association`, body);
};
export const deleteApiAssociation = body => {
  return apiDelete(`subscription/association`, body);
};
export const getInvoice = id => {
  return apiGet(`subscription/invoices/${id}`);
};

export const getFtpInfo = id => {
  return apiGet(`subscription/ftpinfo`);
};
