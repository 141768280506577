import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga';

import reducers from './ducks';

import sagas from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'breadcrumb', 'inferenceDetails', 'theme', 'language']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, compose(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(sagas);

let persistor = persistStore(store);

export { store, persistor };
