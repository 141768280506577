import styled from 'styled-components';

import { Container } from 'reactstrap';

export const GlobalContainer = styled.div`
  background: ${props => props.theme.header};
  height: 70px;
  align-items: center;
  box-shadow: 0px -10px 16px 1px #000;
  .changeThemeContainer img {
    height: auto;
  }
`;
export const InternalContainer = styled(Container)`
  flex-direction: row;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
`;

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  img {
    height: 20px;
  }
  :focus {
    outline: none;
  }
`;

export const Logo = styled.img`
  margin-left: 25px;
`;
export const SideMenuContainer = styled.div`
  margin-left: 25px;
  flex-direction: row;
  display: flex;
  align-items: center;

  .notificationContainer + img {
    width: 30px;
    height: 30px;
  }
  button {
    background: none;
    border: none;
  }
`;
