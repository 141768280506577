import React from 'react';

import { useDispatch } from 'react-redux';
import { AddFolderButton } from './styles';
import { showSelectFolder } from 'store/ducks/training';
import { t, strings } from 'language';

export default function(props) {
  const dispatch = useDispatch();
  const { isEval } = props;

  return (
    <div className="d-block ml-3">
      <AddFolderButton
        onClick={event => {
          event.preventDefault();
          dispatch(showSelectFolder(true, isEval));
        }}
      >
        <img src={require('assets/images/newFolder.png')} alt="icon New folder" />
        {t(strings.add_folder)}
      </AddFolderButton>
    </div>
  );
}
