import styled from 'styled-components';
import { Container } from 'reactstrap';
export { Container };

export const ContainerLogin = styled.div`
  background: ${props => props.theme.secondary};
  padding: 20px;
  border-radius: 10px;
  width: 350px;
`;
