import { uniq } from 'lodash';

const INITIAL_STATE = {
  queue: null,
  uploadedFiles: []
};

export const Types = {
  ADD_TO_QUEUE: 'uploadQueue/ADD_TO_QUEUE',
  SET_QUEUE: 'uploadQueue/SET_QUEUE',
  CLEAR_QUEUE: 'uploadQueue/CLEAR_QUEUE',

  SET_UPLOADED_FILE: 'uploadQueue/SET_UPLOADED_FILE'
};

export function addToQueue(filename, status) {
  return {
    type: Types.ADD_TO_QUEUE,
    payload: {
      filename,
      status
    }
  };
}

export function setQueue(queue) {
  return {
    type: Types.SET_QUEUE,
    payload: {
      queue
    }
  };
}

export function clearQueue() {
  return {
    type: Types.CLEAR_QUEUE
  };
}

export function setUploadedFiles(filename) {
  return {
    type: Types.SET_UPLOADED_FILE,
    payload: {
      filename
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  let { type, payload } = action;
  switch (type) {
    case Types.ADD_TO_QUEUE:
      const queue = { ...state.queue, [payload.filename]: payload.status };
      return { ...state, queue };
    case Types.SET_QUEUE:
      return { ...state, queue: { ...state.queue, ...payload.queue } };
    case Types.CLEAR_QUEUE:
      return { ...state, queue: null, uploadedFiles: [] };
    case Types.SET_UPLOADED_FILE:
      return { ...state, uploadedFiles: uniq([...state.uploadedFiles, payload.filename]) };
    default:
      return state;
  }
}
