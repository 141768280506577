import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid ${props => props.theme.default};
  position: relative;
  overflow: hidden;
`;
export const ContainerFilled = styled.div`
  width: ${props => props.value}%;
  height: 38px;
  background: ${props => props.theme.default};

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transition: width 1s ease-in-out;

  &.ilimited {
    background: none;
    width: 100%;

    span {
      width: 100%;
      text-align: center;
      left: 0;
      right: 0;
    }
  }
`;
export const Span = styled.span`
  color: ${props => (props.value <= 15 ? '#000' : '#fff')};
  position: absolute;
  right: ${props => (props.value <= 15 ? '-35px' : '5px')};
  top: 7px;
`;

export const GlobalContainer = styled.div`
  position: relative;
  span.total {
    color: ${props => props.theme.white};
    position: absolute;
    right: 0px;
    top: -30px;
  }
`;
