import React, { useEffect, useState, useCallback } from 'react';
import history from 'services/history';
import { Container } from 'reactstrap';
import ImageBrowser from 'components/ImageBrowser';
import TitleBreadcrumb from 'components/Breadcrumb';

import { getDatasetTitle } from 'services/Api/Dataset/dataset';
import { getIdFromUrl } from 'utils/helpers';
import querystring from 'query-string';
import { useLocation } from 'react-router';

function DatasetDetail(props) {
  const [datasetId, setDatasetId] = useState(null);
  const [title, setTitle] = useState(null);

  const location = useLocation();
  const [count, setCount] = useState('');

  const getBreadCrumn = useCallback(async () => {
    const count = querystring.parse(location.search).count;
    setCount(count);
  }, [location.search]);

  useEffect(() => {
    getBreadCrumn();
  }, [getBreadCrumn]);

  useEffect(() => {
    async function getData() {
      const id = getIdFromUrl(history.location.pathname, 2);
      setDatasetId(id);
      var title = await getDatasetTitle(id);
      setTitle(title);
    }
    getData();
  }, []);

  return (
    <Container>
      <TitleBreadcrumb
        count={count}
        position={4}
        name={title}
        url={`/dataset/${datasetId}?count=${count ? count : '0'}`}
      />
      {datasetId && <ImageBrowser datasetId={datasetId} setTitle={e => setTitle(e)} />}
    </Container>
  );
}

export default DatasetDetail;
