import { hot } from 'react-hot-loader/root';
//  import bugsnag from '@bugsnag/js';
//import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import 'assets/css/general.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'app.css';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import Loading from 'components/Loading';
import F2Triangle from 'components/F2Triangle';

import GlobalTooltip from 'components/GlobalTooltip';

import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import Theme from 'styles/Theme';
import GlobalStyles from './styles.js';

function App() {
  //const bugsnagClient = bugsnag('477e81e3de2a36bc76c91cef510736ef');
  //bugsnagClient.use(bugsnagReact, React);
  //const ErrorBoundary = bugsnagClient.getPlugin('react');
  return (
    // <ErrorBoundary>
    <Provider store={store}>
      <Theme>
        <>
          <GlobalStyles />
          <Loading></Loading>
          <GlobalTooltip />
          <F2Triangle />
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Routes />
              <ToastContainer />
            </Router>
          </PersistGate>
        </>
      </Theme>
    </Provider>
    // </ErrorBoundary>
  );
}

export default hot(App);
