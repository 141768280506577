import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  width: 120px;
`;
export const Image = styled.img`
  height: 40px;
  margin-bottom: 10px;
`;
export const Count = styled.h4`
  color: ${props => props.theme.white};
  text-align: center;
  padding-top: 10px;
`;
export const Name = styled.p`
  color: ${props => props.theme.white};
  text-align: center;
`;
