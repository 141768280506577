import styled from 'styled-components';

export const Button = styled.button`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 2px;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  width: 40px;
  height: 40px;
`;
