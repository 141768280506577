import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-top: 7px;
  color: blue;
  .react-switch-button {
    background-color: ${props => props.theme.primary} !important;
  }
  .react-switch-label {
    background-color: ${props => (props.checkOff ? '#ff0000' : props.theme.white)};
  }
`;
