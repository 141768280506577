export const Types = {
  SET_PARAMETERS: 'loading/SET_PARAMETERS',
  CLEAR_PARAMETERS: 'loading/CLEAR_PARAMETERS'
};

const INITIAL_STATE = {
  tags: [],
  markers: [],
  orderby: '',
  withoutTags: false,
  search: '',
  searchTotal: 0,
  page: 1
};

export function setOrderBy(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'orderby',
      value
    }
  };
}

export function setTags(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'tags',
      value
    }
  };
}

export function setSearchTotal(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'searchTotal',
      value
    }
  };
}

export function setSearch(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'search',
      value
    }
  };
}
export function setMarkers(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'markers',
      value
    }
  };
}

export function setWithoutTags(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'withoutTags',
      value
    }
  };
}

export function setPage(value) {
  return {
    type: Types.SET_PARAMETERS,
    payload: {
      label: 'page',
      value
    }
  };
}

export function clearParameters() {
  return {
    type: Types.CLEAR_PARAMETERS,
    payload: {}
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  let { type, payload } = action;
  switch (type) {
    case Types.SET_PARAMETERS:
      return { ...state, [payload.label]: payload.value };
    case Types.CLEAR_PARAMETERS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
  