import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClearInference } from 'store/ducks/inference';
import history from 'services/history';
import { Row, Col } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import Searchbar from 'components/Searchbar';
import { Link } from 'react-router-dom';
import { getOverview } from 'services/Api/Subscriptions/subscriptions';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';
import { Container, ContainerTop, Name, Image, ContainerRight } from './styles';
import ApiLimits from './ApiLimits';
import StatusPanel from './StatusPanel';

// import { Container } from './styles';

function SubscriptionControl() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    images: 0,
    datasets: 0,
    inference: 0,
    keys: [],
    model: 0
  });
  const getSubscriptionOverview = async () => {
    const items = await getOverview();
    setData(items);
  };

  useEffect(() => {
    getSubscriptionOverview();
  }, []);

  return (
    <Container>
      <TitleBreadcrumb
        position={1}
        url="/subscriptioncontrol"
        name={t(strings.subscription_control)}
      />
      <Container className="d-flex nopadding">
        <Col md={5}>
          <Row>
            <Link to="/subscriptioncontrol/userList" style={{ marginRight: 20, marginBottom: 20 }}>
              <ContainerTop>
                <Icon name="users" width="80" height="80" />
              </ContainerTop>
              <Name>
                <h3>{t(strings.users)}</h3>
              </Name>
            </Link>
            <Link to="/subscriptioncontrol/linkModel" style={{ marginRight: 20, marginBottom: 20 }}>
              <ContainerTop>
                <Icon name="config" width="80" height="80" />
              </ContainerTop>
              <Name>
                <h3>{t(strings.settings)}</h3>
              </Name>
            </Link>
          </Row>
          <Row>
            <Link to="/subscriptioncontrol/invoices" style={{ marginRight: 20 }}>
              <ContainerTop>
                <Icon name="payment" width="80" height="80" />
              </ContainerTop>
              <Name>
                <h3>{t(strings.invoices)}</h3>
              </Name>
            </Link>
          </Row>
        </Col>

        <Col md={8}>
          <ContainerRight>
            <StatusPanel data={data} />
            <ApiLimits data={data} />
          </ContainerRight>
        </Col>
      </Container>
    </Container>
  );
}

export default SubscriptionControl;
