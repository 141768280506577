import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import shortid from 'shortid';
import { AuthAPI } from 'services/Api/Auth';
import { loading } from 'store/ducks/general';
import Azure from 'services/Azure/azure-storage.blob.min';
import { GetProfileStorageInfo } from 'services/Api/Storage';
import { SetActiveSubscription, Me } from 'store/ducks/auth';
import { toast } from 'react-toastify';
import { t, strings } from 'language';
import { LANG, DEFAULT_LANGUAGE } from 'utils/constants';
import { setLanguage } from 'store/ducks/language';

import {
  Container,
  ProfileContainer,
  Icon,
  Name,
  Sign,
  Info,
  SubscriptionSelect,
  LanguageSelect,
  AvatarEditOverlay
} from './styles';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';

export default function ProfileDisplay() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);  
  const { language } = useSelector(state => state.language);
  
  console.log('language', language);
  // console.log('globalLanguage', globalLanguage);
  // useEffect(() => {
  //   if((language)) {
  //     setGlobalLanguage(language);
  //     console.log('globalLanguage', globalLanguage);
  //   } else {
  //     setLanguage(DEFAULT_LANGUAGE)
  //   }
  // }, []);

  async function updateAvatar(imageUrl) {
    try {
      await AuthAPI.updateUserAvatar(imageUrl);

      toast.success(t(strings.image_changed));
      dispatch(Me());
    } catch {
      toast.error(t(strings.error_unexpected));
    }

    dispatch(loading(false));
  }

  const handleAvatarUpload = async e => {
    dispatch(loading(true));
    const file = e.target.files[0];
    const fileExtension = file.name.split('.').pop();
    const fileHash = `profile.${fileExtension}`;
    const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    const result = await GetProfileStorageInfo();
    let uploadsetviceInstance = null;
    if (result.provider === 'AZURE') {
      uploadsetviceInstance = Azure.createBlobServiceWithSas(result.url, result.token);
    }
    uploadsetviceInstance.createBlockBlobFromBrowserFile(
      result.bucket,
      `${result.path}/${fileHash}`,
      file,
      { blockSize: customBlockSize },
      async function(error) {
        if (error) {
          console.e(error);
        } else {
          updateAvatar(`${result.url}/${result.bucket}/${result.path}/${fileHash}`);
        }
      }
    );
  };

  return (
    <Container>
      {auth.user.name && (
        <ProfileContainer>
          {/* <Icon src={require('./assets/img/profileIcon.png')} /> */}

          <div href="#" className="uploadPhoto">
            <input type="file" name="imageUpload" onChange={e => handleAvatarUpload(e)} />
            <AvatarEditOverlay id="avatarEditOverlay">
              <EditIcon />
            </AvatarEditOverlay>
            {auth.user.name ? (
              <Icon
                size="48"
                name={auth.user.name}
                src={auth.user.avatar ? auth.user.avatar : null}
              />
            ) : null}
          </div>            
          <Info>
            <Name>{auth.user.name}</Name>  

            {/* <LanguageSelect
                value={language}
                onChange={e => {
                  dispatch(setLanguage(e.target.value));
                }}
              >
                {LANG
                  .filter(i => i.key)
                  .sort((a, b) => a.value.localeCompare(b.value))
                  .map(i => (
                    <option key={shortid.generate()} value={i.key}>
                      {i.value}
                    </option>
                  ))}
              </LanguageSelect> */}

            <SubscriptionSelect
              value={auth.user.activeSubscription}
              onChange={e => {
                dispatch(SetActiveSubscription(e.target.value));
              }}
            >
              {auth.user.subscriptions
                .filter(i => i.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(i => (
                  <option key={shortid.generate()} value={i.id}>
                    {i.name}
                  </option>
                ))}
            </SubscriptionSelect>    
          </Info>
        </ProfileContainer>
      )}
    </Container>
  );
}
