import React, { useEffect, useState } from 'react';
import { Dropzone } from './styles';

export default function(props) {
  const [droping, setDroping] = useState(false);

  useEffect(() => {
    let counter = 0;
    function handleDragenter(e) {
      counter++;
      setDroping(true);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    function handleDragleave(e) {
      counter--;
      if (counter <= 0) {
        setDroping(false);
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    function handleDragdrop(e) {
      setDroping(false);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    document.addEventListener('dragenter', handleDragenter);
    document.addEventListener('dragleave', handleDragleave);
    document.addEventListener('drop', handleDragdrop);
    return () => {
      document.removeEventListener('dragenter', handleDragenter);
      document.removeEventListener('dragleave', handleDragleave);
      document.removeEventListener('drop', handleDragdrop);
    };
  }, []);
  return <Dropzone {...props.dropzoneProps()} droping={droping}></Dropzone>;
}
