import styled from 'styled-components';
import checkboxArrow from 'assets/images/checkbox_arrow.png';

export const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: ${props => props.theme.white} 2px solid;
  background: ${props => (props.checked ? `url(${checkboxArrow}) no-repeat center` : 'none')};
  transition: all 0.3s;
  cursor: pointer;
`;
