export const Types = {
  ADD_TO_BREADCRUMB: 'breadcrumb/ADD_TO_BREADCRUMB'
};

const INITIAL_STATE = [{ position: 1, url: '/main', name: 'Home' }];

export function addBreadCrumb({ position, url, name }) {
  return {
    type: Types.ADD_TO_BREADCRUMB,
    payload: {
      position,
      url,
      name
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.ADD_TO_BREADCRUMB:
      if (!payload.position) return state;

      let newBread = state.slice(0, payload.position - 1);

      newBread[payload.position - 1] = payload;

      return newBread;

    default:
      return state;
  }
}
