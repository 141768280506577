function creatBorder(square, position) {
  const { width, height } = position;
  const newSize = `${square * 6}px`;
  const newSize2 = `${square * 3}px`;
  const offset = `${-square * 3}px`;
  const offset2 = `${-square * 1.5}px`;
  return {
    top: {
      width,
      height: newSize2,
      top: offset2,
      border: `solid ${square}px transparent`
    },
    bottom: {
      width,
      height: newSize2,
      bottom: offset2,
      border: `solid ${square}px transparent`
    },
    right: {
      width: newSize2,
      height,
      right: offset2,
      border: `solid ${square}px transparent`
    },
    left: {
      width: newSize2,
      height,
      left: offset2,
      border: `solid ${square}px transparent`
    },
    topRight: {
      width: newSize,
      height: newSize,
      right: offset,
      top: offset,
      border: `solid ${square}px ${props => props.theme.text}`,
      background: '${props => props.theme.text}',
      borderRadius: `${square}px`
    },
    bottomRight: {
      width: newSize,
      height: newSize,
      right: offset,
      bottom: offset,
      border: `solid ${square}px ${props => props.theme.text}`,
      background: '${props => props.theme.text}',
      borderRadius: `${square}px`
    },
    bottomLeft: {
      width: newSize,
      height: newSize,
      left: offset,
      bottom: offset,
      border: `solid ${square}px ${props => props.theme.text}`,
      background: '${props => props.theme.text}',
      borderRadius: `${square}px`
    },
    topLeft: {
      width: newSize,
      height: newSize,
      left: offset,
      top: offset,
      border: `solid ${square}px ${props => props.theme.text}`,
      background: '${props => props.theme.text}',
      borderRadius: `${square}px`
    }
  };
}

export default creatBorder;
