import React from 'react';
import TagName from '../TagName';

function Point(props) {
  const { geometry, zoom, color, active, edit, data } = props;
  const size = 100 / (zoom * 30);
  const point = active ? `${5 * size}px` : `${size}px`;
  const showTag = data.index === 0 ? <TagName data={data} geometry={geometry}/> : '';
  
  if (!geometry) return null;
  return (
    <div
      style={{
        position: 'absolute',
        top: `${geometry.y}%`,
        left: `${geometry.x}%`,
        zIndex: 10,
        border: active && `solid ${size}px ${color}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        height: point,
        transform: 'translate3d(-50%, -50%, 0)',
        width: point,
        background: edit ? `${color}` : !active && `${color}`
      }}
    >{showTag}</div>
  );
}

export default Point;
