import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from 'services/history';
// import ReactPlayer from 'react-player';
// eslint-disable-next-line
import { Container, Row, Col, NavLink, Nav, NavItem, TabPane, TabContent } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import CustomButton from 'components/CustomButton';
import { getModel } from 'services/Api/Model/model';
import { getTranings } from 'services/Api/Training';

import { getIdFromUrl } from 'utils/helpers';

import NewTraining from 'components/NewTraining';
import { setModel as setModelRedux } from 'store/ducks/training';
import NewInference from 'components/NewInference';
import { getInferences } from 'services/Api/Inference/inference';
//import ModelAnimation from './ModelAnimation';
// eslint-disable-next-line
import { getAvaiableMetrics, getMetricsMatrix } from 'services/Api/Metrics';
import { loading } from 'store/ducks/general';
import Lottie from 'react-lottie';
import * as modelData from 'assets/animations/modelItem.json';
import Dialog from 'components/Dialog';
import { t, strings } from 'language';
import Historic from './components/Historic';
import ToolTip from './components/ToolTip';
import useWindowSize from './components/useWindowSize';
import { PlayerContainer, TrainingHistoryList, TabLink, TabsContent, Label } from './styles';

function Model(props) {
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [showDialog, setShowDialog] = useState(false);
  const model = useSelector(state => state.training.model);
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [trainingModal, setTrainingModal] = useState(false);
  const [newTrainingModal, setNewTrainingModal] = useState(false);
  const [trainingHistory, setTrainingHistory] = useState(null);
  const [inferenceHistory, setInferenceHistory] = useState(null);
  const [metrics, setMetrics] = useState({});
  //console.log('Model.props', props);
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function closeModal() {
    setShowDialog(false);
  }

  useEffect(() => {
    async function getIfOpen() {
      const open = history.location.search;
      // console.log('getIfOpen.open', open);
      setTrainingModal(open === '?open' ? true : trainingModal);
    }

    async function getData() {
      dispatch(loading(true));
      const id = getIdFromUrl(history.location.pathname, 2);
      const modelReturn = await getModel(id);
      const metricdResult = await getMetricsMatrix(id);
      setMetrics(metricdResult);
      // console.log('metricas', metricdResult);

      const tranings = await getTranings(id);
      setTrainingHistory(tranings.results);
      const inferences = await getInferences(id);
      setInferenceHistory(inferences.results);
      dispatch(setModelRedux(modelReturn));
      getIfOpen();
      dispatch(loading(false));
    }
    getData();

    const interval = setInterval(() => {
      updateModelInfo();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (trainingModal === false) {
      updateModelInfo();
    }
  }, [trainingModal]);

  async function updateModelInfo() {
    const id = getIdFromUrl(history.location.pathname, 2);
    const tranings = await getTranings(id);
    const inferences = await getInferences(id);
    setTrainingHistory(tranings.results);
    setInferenceHistory(inferences.results);
  }

  useEffect(() => {
    if (!auth.loggedin) {
      history.push('/login');
    }
  }, [auth.loggedin]);
  const defaultOptions = {
    loop: true,
    autoplay: true,

    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Container>
      <ToolTip size={size} />
      <TitleBreadcrumb
        position={3}
        name={model && model.name}
        url={`/model/${model && model.id}`}
      />
      <Container>
        <Row>
          <Col md={4}>
            <PlayerContainer>
              <Lottie
                options={(defaultOptions, { animationData: modelData.default })}
                width={150}
                height={150}
                isClickToPauseDisabled
              />
              <div style={{ marginLeft: '20px', marginTop: 20 }}>
                <p>V1.0</p>
                <p>{model && model.networkType}</p>
                <p>{model && model.tagType}</p>
              </div>
            </PlayerContainer>

            <CustomButton
              count={metrics?.steps?.length}
              disabled={metrics?.step > 0 ? false : true}
              style={{ opacity: metrics?.step > 0 ? 1 : 0.5 }}
              onClick={() => {
                history.push(`/model/${model && model.id}/trainingstatistics`);
              }}
              icon="stats"
              fluid
            >
              {t(strings.statistic)}
            </CustomButton>
          </Col>
          <Col md={4}>
            <h4 className="subtitle">{t(strings.model_msg_1)}</h4>
            {!viewer && (
              <CustomButton icon="modelIcon" fluid onClick={() => setTrainingModal(true)}>
                {`${t(strings.training)} / ${t(strings.eval)}`}
              </CustomButton>
            )}
            {!viewer && (
              <CustomButton
                icon="inference"
                onClick={() => setNewTrainingModal(!newTrainingModal)}
                fluid
              >
                {t(strings.infer)}
              </CustomButton>
            )}
            {viewer && (
              <CustomButton icon="modelIcon" fluid onClick={() => setShowDialog(true)}>
                {`${t(strings.training)} / ${t(strings.eval)}`}
              </CustomButton>
            )}
            {viewer && (
              <CustomButton icon="inference" onClick={() => setShowDialog(true)} fluid>
                {t(strings.infer)}
              </CustomButton>
            )}
            {/* <CustomButton icon={require('assets/images/export_icon.png')} fluid>
              Inferência
            </CustomButton> */}

            {/* <CustomButton icon={require('assets/images/dataset_icon.png')} fluid>
              Poste 11
            </CustomButton> */}
            {/*              
            <CustomButton type={ButtonsType.Training} fluid>
              Treinar
            </CustomButton> */}
          </Col>
          <TrainingHistoryList md={4} className="trainingHistoryList">
            <h4 className="subtitle">{t(strings.historic)}</h4>
            <Nav tabs style={{ margin: 10 }}>
              <NavItem style={{ width: '50%', textAlign: 'center' }}>
                <TabLink
                  active={activeTab === '1'}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  {t(strings.trainings)}
                </TabLink>
              </NavItem>

              <NavItem style={{ width: '50%', textAlign: 'center' }}>
                <TabLink
                  active={activeTab === '2'}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  {t(strings.inferences)}
                </TabLink>
              </NavItem>
            </Nav>
            <TabsContent className="tabsContent">
              <TabContent className="pt-3" activeTab={activeTab}>
                <TabPane tabId="1">
                  <Historic data={trainingHistory} type="training" />
                </TabPane>
                <TabPane tabId="2" style={{ textAlign: 'center' }}>
                  <Historic data={inferenceHistory} type="inference" />
                </TabPane>
              </TabContent>
            </TabsContent>
          </TrainingHistoryList>
        </Row>
      </Container>
      <NewTraining modal={trainingModal} setTrainingModal={setTrainingModal} />
      <NewInference modal={newTrainingModal} setNewTrainingModal={setNewTrainingModal} />
      <Dialog
        title={t(strings.alert)}
        open={showDialog}
        close={closeModal}
        actions={[{ text: t(strings.cancel), action: closeModal }]}
      >
        <Label>{t(strings.message_1)}</Label>
      </Dialog>
    </Container>
  );
}

export default Model;
