import { takeLatest, put, call } from 'redux-saga/effects';
import { InferenceAPI } from 'services/Api/Inference';
import { loading } from './general';

const INITIAL_STATE = {
  inferenceId: 0,
  tags: [],
  datasets: [],
  checkpoint: [],
  checkpointId: 0,
  status: '',
  confidence: 0,
  finishedAt: '',
  startAt: '',
  totalImages: 0,
  totalImagesWithInference: 0,
  totalImagesWithoutInference: 0
};

export const Types = {
  GET_INFERENCE: 'inferenceDetails/GET_INFERENCE',
  SET_INFERENCE: 'inferenceDetails/SET_INFERENCE'
};

export function GetInferenceDataset(inferenceId) {
  return {
    type: Types.GET_INFERENCE,
    payload: {
      inferenceId
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_INFERENCE:
      return {
        ...state,
        inferenceId: parseInt(payload.inferenceId, 10),
        tags: payload.tags,
        datasets: payload.datasets,
        checkpoint: payload.checkpoint,
        checkpointId: parseInt(payload.checkpointId, 10),
        status: payload.status,
        confidence: parseInt(payload.confidence, 10),
        finishedAt: payload.finishedAt,
        startAt: payload.startAt,
        totalImages: parseInt(payload.totalImages, 10),
        totalImagesWithInference: parseInt(payload.totalImagesWithInference, 10),
        totalImagesWithoutInference: parseInt(payload.totalImagesWithoutInference, 10)
      };
    default:
      return state;
  }
}

/*---------------------*/

function* getInferenceFromAPI(action) {
  const { payload } = action;
  yield put(loading(true));
  try {
    const result = yield call(InferenceAPI.getInferenceDetails, payload.inferenceId);
    yield put({
      type: Types.SET_INFERENCE,
      payload: {
        inferenceId: payload.inferenceId,
        tags: result.tags,
        datasets: result.datasets,
        checkpoint: result.checkpoint,
        checkpointId: result.checkpointId,
        status: result.status,
        confidence: result.confidence,
        finishedAt: result.finishedAt,
        startAt: result.startAt,
        totalImages: result.totalImages,
        totalImagesWithInference: result.totalImagesWithInference,
        totalImagesWithoutInference: result.totalImagesWithoutInference
      }
    });
  } catch (error) {
    throw error;
  }
  //yield put(loading(false));
}

export const sagas = [takeLatest(Types.GET_INFERENCE, getInferenceFromAPI)];
