import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
export default function Tooltip({ active, payload, label }) {
  const theme = useContext(ThemeContext);
  const containerStyle = {
    background: theme.primary,
    opacity: 0.9,
    borderRadius: '10px',
    padding: '15px 10px 5px'
  };
  const textStyle = { color: 'white' };

  if (active) {
    return (
      <div style={containerStyle}>
        <h4>{label}</h4>
        {payload.map(item => {
          if (item.name === 'Recall') {
            return <p style={textStyle}>{`Recall : ${(item.value * 100).toFixed(2)}%`}</p>;
          }
          if (item.name === 'Precision') {
            return <p style={textStyle}>{`Precision : ${(item.value * 100).toFixed(2)}%`}</p>;
          }
          return <p style={textStyle}>{`${item.name} : ${item.value}`}</p>;
        })}
      </div>
    );
  }

  return Tooltip;
}
