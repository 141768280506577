import React, { useState, useEffect, useRef } from 'react';
import useGlobal from 'store/global';

//import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
//import { loading } from 'store/ducks/general';
//import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dialog from 'components/Dialog';
import { Icon } from 'components/Icon';
// eslint-disable-next-line
import { t, strings } from 'language';
import { Container, ButtonItem, ContainerMarkers, Badge, ClearFilter } from './styles';
//import { ReactComponent as filterIcon } from '../../../assets/images/filter_icon.svg';
//import { InputField } from 'components/FolderBrowser/styles';
//import { Row, Col, Container as ContainerStrap, Label } from 'reactstrap';
import TagFilter from '../TagFilter';
// eslint-disable-next-line
import { setMarkers, setTags, clearFilter, setNetworkType } from '../../../store/ducks/filter';
import MarkersFilter from '../MarkersFilter';
import TagTypeFilter from '../TagTypeFilter';
import NetworkTypeFilter from '../NetworkTypeFilter';
import TrainingStatusFilter from '../TrainingStatusFilter';

export default function Filter({
  onResetData,
  markers,
  // eslint-disable-next-line
  onChangeFilter,
  // eslint-disable-next-line
  filter,
  hideMarkersFilter,
  section
}) {
  const [showModal, setShowModal] = useState(false);
  const [markerList, setMarkerList] = useState([]);
  const [filterSelection, setFilterSelection] = useState({});
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [deleteMode, setDeleteMode] = useGlobal(
    state => state.deleteMode,
    actions => actions.setDeleteMode
  );
  const filterState = useSelector(state => state.filter);

  const handleSetFilterSelection = obj => {
    setFilterSelection(obj);
  };

  // useEffect(() => {
  //   console.log('filterSelection', filterSelection);
  // }, [filterSelection]);

  const filterCount = useRef(0);

  const getBadgeCount = () => {
    let count = filterState?.markers?.length ? filterState?.markers?.length : 0;

    if (filterState?.tags) {
      count += filterState?.tags?.length;
    }
    if (filterState?.tagType) {
      count += filterState?.tagType?.length;
    }
    if (filterState?.withoutTags) {
      count += 1;
    }
    if (filterState?.networkType) {
      count += filterState?.networkType?.length;
    }
    if (filterState?.trainingStatus) {
      count += filterState?.trainingStatus?.length;
    }
    filterCount.current = count;
  };
  useEffect(() => {
    if (filterState) {
      setFilterSelection(filterState);
      setMarkerList(filterState.markers);
    }
    getBadgeCount();
    // eslint-disable-next-line
  }, [filterState]);

  // useEffect(() => {
  //   if (deleteMode) {
  //     toast.info('Selecione os ítens que deseja deletar.');
  //   }
  // }, [deleteMode]);
  // eslint-disable-next-line
  function containMarker(id, list) {
    // console.log(id, list);
    if (list?.length >= 1) {
      for (let index = 0; index < list.length; index += 1) {
        if (Number(list[index]) === Number(id)) {
          // console.log('deveria ir');
          return true;
        }
      }
    }
    return false;
  }

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handleFilter = () => {
    setShowModal(!showModal);

    if (filterSelection?.markers) {
      dispatch(setMarkers(filterSelection?.markers));
    }

    if (filterState.withoutTags) {
      dispatch(setTags([]));
    } else if (filterSelection?.tags) {
      dispatch(setTags(filterSelection.tags));
    }

    onResetData();
    return false;
  };
  const handleClearFilter = () => {
    setShowModal(!showModal);
    dispatch(clearFilter());
    onResetData();
    return false;
  };
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  // eslint-disable-next-line
  const prevFilterSelection = usePrevious(filterSelection);
  // eslint-disable-next-line
  const handleCheckMarker = item => {
    console.log('markerlist', markerList);
    if (item.checked) {
      setFilterSelection({
        ...filterSelection,
        markers: [...markerList, Number(item.value)]
      });
      setMarkerList([...markerList, Number(item.value)]);
    } else {
      const newList = markerList;
      const index = newList.indexOf(Number(item.value));
      if (index > -1) {
        newList.splice(index, 1);
        setFilterSelection({ markers: newList });
      }
    }
  };
  return (
    <Container>
      {filterCount.current > 0 ? <Badge>{filterCount.current}</Badge> : ''}
      <ButtonItem onClick={() => setShowModal(true)}>
        <Icon name="filter" />
      </ButtonItem>
      <Dialog
        title={t(strings.filter)}
        open={showModal}
        close={closeModal}
        actions={[
          { text: t(strings.cancel), action: closeModal },

          { text: t(strings.apply_filter), action: () => handleFilter(), gold: true }
        ]}
      >
        {filterCount.current > 0 ? (
          <ClearFilter href="#" onClick={handleClearFilter}>
            {t(strings.clear_filter)}
          </ClearFilter>
        ) : (
          ''
        )}

        <TagFilter
          filterSelection={filterSelection}
          setFilterSelection={handleSetFilterSelection}
        />
        {!hideMarkersFilter && (
          <MarkersFilter
            filterSelection={filterSelection}
            markers={markers}
            setFilterSelection={setFilterSelection}
          />
        )}

        {section === 'Model' && (
          <>
            <TagTypeFilter />
            <NetworkTypeFilter />
            <TrainingStatusFilter />
          </>
        )}

        {/* <br />
        <Label>Marcadores</Label>
        <ContainerMarkers className="pl-2 pt-3 pb-0">
          {markers?.map(item => {
            return (
              <Row>
                <label>
                  <InputField
                    type="checkbox"
                    defaultChecked={containMarker(item.id, markerList) ? 'checked' : ''}
                    value={item.id}
                    onChange={e => handleCheckMarker(e.target)}
                    style={{ width: 25 }}
                  />
                  {item.name}
                </label>
              </Row>
            );
          })}
        </ContainerMarkers> */}
      </Dialog>
    </Container>
  );
}
