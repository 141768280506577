import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useGlobal from 'store/global';
import { getTagsList } from 'store/ducks/annotateTag';
import { isUndefined } from 'lodash';

function SearchTags(searchString) {
  const auth = useSelector(state => state.auth);
  const { email, activeSubscription } = auth.user;
  const subscription = activeSubscription;
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [controlTag] = useGlobal(state => state.controlTag);
  // const [oldTag, setOldTag] = useGlobal(
  //   state => state.oldTag,
  //   actions => actions.setOldTag
  // );
  const [tagsDataset] = useGlobal(state => state.tagsDataset);
  const [dataset] = useGlobal(state => state.dataset);

  function comparer(otherArray) {
    return current => {
      return (
        otherArray.filter(other => {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  function exist(otherArray) {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.id === current.id;
        }).length > 0
      );
    };
  }

  function filterTags() {
    const lowercasedFilter = searchString.toLowerCase();
    const filteredData = tagsDataset.filter(obj => {
      return obj.description?.toLowerCase().includes(lowercasedFilter);
    });
    return filteredData;
  }

  useEffect(() => {
    // if (searchString && searchString !== controlTag && searchString && searchString !== oldTag) {
    if (searchString && searchString !== controlTag && searchString) {
      setLoading(true);
      const tagList = filterTags();
      setTags(tagList);
      setLoading(false);
    }
    if (!searchString) {
      setLoading(true);
      const empty = { id: 0, description: '', color: '' };
      const list = getTagsList(email, subscription, dataset);
      const listReversed = list.reverse();
      const onlyInA = listReversed.filter(exist(tagsDataset));
      const onlyInB = tagsDataset.filter(comparer(listReversed));
      // const result = listReversed.concat(onlyInB);
      // const result = onlyInA.concat(empty).concat(onlyInB);
      const result =
        tagsDataset.description !== isUndefined ? list : onlyInA.concat(empty).concat(onlyInB);
      setTags(result);
      setLoading(false);
      if (tagsDataset) setLoading(false);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [searchString, tagsDataset]);

  return { tags, isLoading };
}

export default SearchTags;
