import styled from 'styled-components';

export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 40px;

  ::placeholder {
    color: ${props => props.theme.iconColor};
    opacity: 0.5;
  }
`;
export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;
export const Description = styled.p`
  font-size: 16px;
  color: ${props => props.theme.text};
`;

export const TypeContainer = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

export const TypeItem = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
  border: ${props =>
    props.selected ? `4px solid ${props.theme.default}` : '2px solid ${props => props.theme.text}'};
  padding: ${props => (props.selected ? '6px' : '8px')};
  cursor: pointer;

  p {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

export const NetworkItem = styled.div`
  border: ${props => (props.selected ? `2px solid ${props.theme.default}` : '1px solid #707070')};
  padding: ${props => (props.selected ? '14px' : '15px')};
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;

  h4 {
    color: ${props => props.theme.white};
    font-size: 16px;
  }
  p {
    color: ${props => props.theme.white};
    font-size: 13px;
  }
`;
export const NetworkContainer = styled.div``;
