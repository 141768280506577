import React from 'react';

import { useDispatch } from 'react-redux';
import { showSelectDatasetInference } from 'store/ducks/training';
import { AddFolderButton } from './styles';
import { t, strings } from 'language';

export default function() {
  const dispatch = useDispatch();

  return (
    <div className="d-block ml-3">
      <AddFolderButton
        onClick={event => {
          event.preventDefault();
          dispatch(showSelectDatasetInference(true));
        }}
      >
        <img src={require('assets/images/newFolder.png')} alt="icon New folder" />
        {t(strings.add_dataset)}
      </AddFolderButton>
    </div>
  );
}
