import styled from 'styled-components';
import { Container as ContainerRS } from 'reactstrap';

export const Container = styled(ContainerRS)``;
export const Title = styled.small`
  color: #909090;
  cursor: default;
`;

export const ToolDiv = styled.div`
  border: 1px solid ${props => props.theme.white};
  height: 104px;
  cursor: default;
  padding: 10px;
  background: ${props => props.theme.secondary};
`;

export const ZoomText = styled.div`
  position: absolute;
  top: 45px;
`;

export const ButtonText = styled.div`
  color: ${props => props.theme.iconColor};
  font-weight: bold;
  letter-spacing: 3px;
`;

export const Text = styled.div`
  position: relative;
  top: -10px;
  color: #909090;
  font-size: 16px;
`;
