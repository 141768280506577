import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getToken } from 'store/ducks/auth';

const BASE_URL = process.env.REACT_APP_DOMAIN;

export const connect = () =>
  new Promise(function(resolve) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}/traininghub`, {
        accessTokenFactory: getToken // Return access token
      })
      .withAutomaticReconnect()
      .build();

    connection.start().then(function() {
      resolve(connection);
    });
  });

export function handleTraining(connection, trainingId, callback) {
  connection.invoke('HandleTraining', trainingId);
  connection.on('TrainingLog', data => callback(data));
}

export function fakeLog(connection, trainingId, message) {
  connection.invoke('SendTrainingInfo', trainingId, message, 'log');
}
