import React, { useRef, useState, useCallback, useEffect } from 'react';

import { Container, Row } from 'reactstrap';
import DatasetItem from 'components/DatasetItem';
import Searchbar from 'components/Searchbar';
import Dialog from 'components/Dialog';
// import InfiniteScroll from 'components/InfiniteScroll';
import { useDispatch, useSelector } from 'react-redux';
import { createDataset, deleteDataset } from 'services/Api/Dataset/dataset';
import { getDatasets, getAllFolders } from 'services/Api/Dataset/dataset';
import BulkDeleteSelection from 'components/BulkDeleteSelection';
import { getMarkers } from 'services/Api/Markers';
import { setSearchTotal } from 'store/ducks/filter';
import InfiniteScrollV2 from 'components/InfiniteScrollV2';
import { t, strings } from 'language';
import { Label, InputField, LabelAlert } from './styles';

function DatasetBrowser(props) {
  const [modal, setModal] = useState(false);
  const [newDataset, setNewDataset] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [search, setSearch] = useState('');
  const scrollRef = useRef();
  const [markers, setMarkers] = useState([]);
  const [filter, setFilter] = useState(null);
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();  
  const [folders, setFolders] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const alert = (
    <LabelAlert style={{ display: showAlert ? 'block' : 'none' }}>
      {t(strings.name_exists)}
    </LabelAlert>
  );

  async function getAllSets(folderId) {
    const resultFolders = await getAllFolders(folderId);
    setFolders(resultFolders.results);
  }

  async function getAllMarkers() {
    const resultMarkers = await getMarkers();
    setMarkers(resultMarkers.results);
    return resultMarkers;
  }

  useEffect(() => {
    getAllMarkers();
  }, []);

  useEffect(() => {
    if (props?.folderId) {
      getAllSets(props.folderId);
    }
  }, [props]);

  const getData = async page => {
    if (page == 'reset') {
      setData([]);
      setHasmore(true);
      return;
    }
    setHasmore(false);
    const result = await getDatasets(page, props.folderId, {
      query: search,
      markers: filterState?.markers,
      orderBy: filterState?.orderby,
      tags: filterState?.tags,
      withoutTags: filterState?.withoutTags
    });
    setHasmore(result.currentPage < result.pageCount);
    setData([...data, ...result.results]);
    dispatch(setSearchTotal(result.rowCount));
  };

  function sameNameTest(name) {
    setShowAlert(false);
    folders.map(item => {
      if (item.title === name) {
        setShowAlert(true);
      }
    })
  }

  function handleNewFolder(e) {
    setModal(true);
    e.preventDefault();
  }
  function handleNewFolderChange(text) {
    sameNameTest(text);
    setNewDataset(text);
  }
  async function createNewFolder() {
    await createDataset({
      description: newDataset,
      folderId: parseInt(props.folderId)
    });
    setModal(false);
    scrollRef.current.resetScroll();
  }

  let newItem = {
    title: t(strings.new_dataset),
    image: 'newFolder',
    id: 'new',
    folders: [],
    newItem: true
  };

  const ScrollItem = props => (
    <BulkDeleteSelection key={props.itemkey} data={props.item}>
      <DatasetItem
        itemkey={props.itemkey}
        data={props.item}
        markers={markers}
        updateMarkers={getAllMarkers}
        // onResetData={() => scrollRef.current.resetScroll()}
        onChangeFilter={setFilter}
      >
        {props.item.title}
      </DatasetItem>
    </BulkDeleteSelection>
  );
  return (
    <>
      <Searchbar
        value={search}
        onChange={setSearch}
        onResetData={() => scrollRef.current?.resetScroll()}
        placeholder={t(strings.search_dataset)}
        buttonClick={e => handleNewFolder(e)}
        buttonImage={newItem.image}
        deleteFunction={deleteDataset}
        markers={markers}
        onChangeFilter={setFilter}
        filter={filter}
        section="Dataset"
        folderId={props.folderId}
      />
      <Container>
        <Row>
          {props.folderId && (
            <>
              <InfiniteScrollV2
                ref={scrollRef}
                pagedResource={getDatasets}
                customParams={{
                  folderId: props.folderId,
                  query: {
                    query: search,
                    markers: filterState?.markers,
                    orderBy: filterState?.orderby,
                    tags: filterState?.tags,
                    withoutTags: filterState?.withoutTags
                  }
                }}
                size={20}
                ItemComponent={ScrollItem}
              />
            </>
          )}
        </Row>
      </Container>

      <Dialog
        title={t(strings.new_dataset)}
        open={modal}
        close={() => {
          setModal(false);
          setShowAlert(false);
        }}
        actions={[
          {
            text: t(strings.cancel),
            action: () => {
              setModal(false);
              setShowAlert(false);
            }
          },
          {
            text: t(strings.create),
            action: () => {
              if (!showAlert) createNewFolder();
            },
            gold: !showAlert
          }
        ]}
      >
        <Label>{t(strings.name_of_new_dataset)}</Label>
        <InputField
          type="text"
          placeholder={t(strings.enter_new_dataset_name)}
          onChange={e => handleNewFolderChange(e.target.value)}
        />
        {alert}
      </Dialog>
    </>
  );
}

export default DatasetBrowser;
