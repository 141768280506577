import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import { GetProfileStorageInfo } from 'services/Api/Storage';
import Azure from 'services/Azure/azure-storage.blob.min';
import { slide as MainMenu } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';
import { Logout, SetActiveSubscription, Me } from 'store/ducks/auth';
import { t, strings } from 'language';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { toast } from 'react-toastify';
import { Icon as LogoWhite } from 'components/Icon';
import NewMemberDialog from './newMemberDialog';
import {
  Container,
  CloseButton,
  Logo,
  ProfileContainer,
  Icon,
  Name,
  Sign,
  Info,
  SubscriptionSelect,
  MenuItensContainer,
  AvatarEditOverlay,
  VersionText
} from './styles';
import MenuItem from './components/MenuItem';
import { AuthAPI } from 'services/Api/Auth';
import { loading, setInviteModal } from 'store/ducks/general';
import { version } from '../../../../../package.json';

export default function Menu(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  // const viewer = subscription.role === 'VIEWER';
  const admin = subscription.role === 'ADMIN';
  const [showInviteModal, setShowinviteModal] = useState(false);
  const general = useSelector(state => state.general);
  const { theme } = useSelector(state => state.theme);
  const { isOpen, closeMenu } = props;
  const openUrl = url => {};
  
  useEffect(() => {
    //setShowinviteModal(general.showInvite);
  }, [general.showInvite]);
  
  return (
    <MainMenu isOpen={isOpen} customBurgerIcon={false} width={380} disableOverlayClick>
      <NewMemberDialog
        show={general.showInvite}
        onClose={() => {
          //setShowinviteModal(false);
          dispatch(setInviteModal(false));
          props.closeMenu();
        }}
      />
      <CloseButton visible={isOpen} onClick={closeMenu}>
        <img src={require('./assets/img/x.png')} alt="Close" />
      </CloseButton>
      <Container>
        <LogoWhite name="smartvision-white" height="50" width="300" />
        <div className="mt-5">
          <MenuItensContainer>
            <MenuItem
              action={() => {
                props.closeMenu();
              }}
              icon={'home_icon'}
              destination="/main"
            >
              {t(strings.home)}
            </MenuItem>
            <MenuItem
              action={() => {
                props.closeMenu();
              }}
              icon={'modelIcon'}
              destination="/models"
            >
              {t(strings.models)}
            </MenuItem>
            <MenuItem
              action={() => {
                props.closeMenu();
              }}
              icon={'folder_icon'}
              destination="/folders"
            >
              {t(strings.datasets)}
            </MenuItem>
            <MenuItem
              action={() => {
                props.closeMenu();
              }}
              icon={'training_log'}
              destination="/logs/training_session"
            >
              {t(strings.training_session_logs)}
            </MenuItem>
            <MenuItem
              action={() => {
                props.closeMenu();
              }}
              icon={'is-log'}
              destination="/logs/inference_session"
            >
              {t(strings.inference_session_logs)}
            </MenuItem>
            {admin && (
              <MenuItem
                action={() => {
                  props.closeMenu();
                }}
                icon={'sc_icon'}
                destination="/subscriptioncontrol"
              >
                {t(strings.subscription_control)}
              </MenuItem>
            )}
            {admin && (
              <MenuItem
                action={() => {
                  //setShowinviteModal(true);
                  dispatch(setInviteModal(true));
                }}
                icon={'add_member_icon'}
              >
                {t(strings.invite_new_member)}
              </MenuItem>
            )}            
            <MenuItem action={() => dispatch(Logout())} icon={'logout'} destination="/login">
              {t(strings.quit)}
            </MenuItem>
          </MenuItensContainer>
        </div>
        <VersionText>{t(strings.version)}: {version}</VersionText>
      </Container>
    </MainMenu>
  );
}
