import React, { useEffect, useCallback, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { t, strings } from 'language';
import uuid from 'uuid/v4';
import { chain } from 'lodash';
import BulkDeleteSelection from 'components/BulkDeleteSelection';
import { deleteImages } from 'services/Api/Dataset/dataset';
import Dialog from 'components/Dialog';
import Azure from 'services/Azure/azure-storage.blob.min';
import { useDropzone } from 'react-dropzone';
import { GetStorageInfo } from 'services/Api/Storage';
import { DatasetAPI } from 'services/Api/Dataset';
import { addToQueue, setQueue, setUploadedFiles, clearQueue } from 'store/ducks/uploadQueue';
import { ClearInference } from 'store/ducks/inference';
import { loading } from 'store/ducks/general';
import Searchbar from 'components/Searchbar';
import InfiniteScrollV2 from 'components/InfiniteScrollV2';
import ImageItem from 'components/ImageItem';
import { useDispatch, useSelector } from 'react-redux';
import { clearParameters } from 'store/ducks/parameters';
import UploadQueue from './UploadQueue';
import Dropzone from './Dropzone';

function ImageBrowser(props) {
  const dispatch = useDispatch();
  const uploadInputRef = React.createRef();
  const { datasetId } = props;
  const [uploadService, setUploadService] = useState(null);
  const [uploadInfo, setUploadInfo] = useState(null);
  const [data, setData] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [search, setSearch] = useState('');
  const [overrideImagesModalShow, setOverridImagesModalShow] = useState(false);

  const overrideImages = useRef(null);
  const overrideInterval = useRef(null);

  const scrollRef = useRef();
  const filterState = useSelector(state => state.filter);
  const saveUploadedImage = useCallback(
    async file => {
      return DatasetAPI.addDatasetImages(datasetId, file);
    },
    [datasetId]
  );
  const [pageSize, setPageSize] = useState('50');

  function resetData() {
    scrollRef.current.resetScroll();
  }

  function overrideImageResponse() {
    return new Promise(resolve => {
      overrideInterval.current = setInterval(() => {
        if (overrideImages.current !== null) {
          clearInterval(overrideInterval.current);
          resolve();
        }
      }, 300);
    });
  }

  const handleFiles = useCallback(
    async acceptedFiles => {
      dispatch(loading(true));
      dispatch(clearQueue());
      let newQueue = acceptedFiles.map(i => {
        return { id: uuid(), name: i.name, value: 0 };
      });
      if (overrideImages.current == null) {
        const imageExist = await DatasetAPI.checkImagesNames(
          datasetId,
          newQueue.map(i => i.name)
        );
        if (imageExist) {
          dispatch(loading(false));
          setOverridImagesModalShow(true);
          await overrideImageResponse();
          dispatch(loading(true));
        }
      }
      newQueue = chain(newQueue)
        .keyBy('name')
        .mapValues(['id', 'value'])
        .value();

      dispatch(setQueue(newQueue));

      dispatch(loading(false));

      acceptedFiles.forEach(async file => {
        if (file.size === 0) {
          toast.warn(`${t(strings.image)} ${file.name} ${t(strings.is_corrupted)}`, {
            autoClose: false
          });
          return;
        }
        const filename = file.name;
        const fileExtension = file.name.split('.').pop();
        const fileHash = `${uuid()}.${fileExtension}`;
        const originalBmp = await createImageBitmap(file);
        const { width, height } = originalBmp;
        const uploadsetviceInstance = uploadService;
        const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        uploadsetviceInstance.singleBlobPutThresholdInBytes = customBlockSize;
        const speedSummary = uploadsetviceInstance.createBlockBlobFromBrowserFile(
          uploadInfo.bucket,
          `${uploadInfo.path}/${fileHash}`,
          file,
          { blockSize: customBlockSize },
          async function(error, result, response) {
            if (error) {
              toast.warn(`${t(strings.error_image)} ${filename}`, {
                autoClose: false
              });
            } else {
              await saveUploadedImage({
                filename,
                fileHash,
                fileSize: file.size,
                imageType: file.type,
                width,
                height,
                overrideMode: overrideImages.current
              });
            }
          }
        );
        speedSummary.on('progress', function() {
          const status = speedSummary.getCompletePercent();
          dispatch(addToQueue(filename, status));
          if (status === '100.0') {
            dispatch(setUploadedFiles(filename));
          }
        });
      });
    },
    [saveUploadedImage, uploadInfo, uploadService, dispatch]
  );

  const loadStorageInfo = useCallback(async datasetId => {
    const result = await GetStorageInfo(datasetId);
    if (result.provider === 'AZURE') {
      setUploadService(Azure.createBlobServiceWithSas(result.url, result.token));
    }
    setUploadInfo({ bucket: result.bucket, path: result.path });
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleFiles,
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png'
  });

  async function setInference() {
    dispatch(ClearInference());
  }

  useEffect(() => {
    if (!datasetId) return;
    loadStorageInfo(datasetId);
    dispatch(clearQueue());
    dispatch(clearParameters());
    setInference();
  }, [loadStorageInfo, datasetId, dispatch]);

  const newItem = {
    title: `${t(strings.add_images)}`,
    image: 'newImage'
  };

  const ScrollItem = props => (
    <BulkDeleteSelection key={props.itemkey} data={props.item}>
      <ImageItem
        key={props.itemkey}
        index={props.itemkey}
        data={props.item}
        dataset={datasetId}
        pageSize={pageSize}
      >
        {props.item.name}
      </ImageItem>
    </BulkDeleteSelection>
  );

  return (
    <>
      <input type="hidden" {...getInputProps()} onClick={open} ref={uploadInputRef} />
      <Dialog
        title={t(strings.question_one_or_more)}
        open={overrideImagesModalShow}
        actions={[
          {
            text: `${t(strings.replace)}`,
            action: () => {
              overrideImages.current = 'Override';
              setOverridImagesModalShow(false);
            }
          },
          {
            text: `${t(strings.keep_all)}`,
            action: () => {
              overrideImages.current = 'Clone';
              setOverridImagesModalShow(false);
            }
          },
          {
            text: `${t(strings.do_nothing)}`,
            action: () => {
              overrideImages.current = 'Ignore';
              setOverridImagesModalShow(false);
            }
          }
        ]}
      />
      <Searchbar
        value={search}
        onChange={setSearch}
        onResetData={resetData}
        placeholder={t(strings.search_folder_image)}
        buttonClick={() => uploadInputRef.current.click()}
        buttonImage={newItem.image}
        deleteFunction={deleteImages}
        hideMarkersFilter
      />

      <UploadQueue
        onFinish={() => {
          scrollRef.current.resetScroll();
          overrideImages.current = null;
        }}
      />
      <Dropzone dropzoneProps={() => ({ ...getRootProps({ className: 'dropzone' }) })} />
      <InfiniteScrollV2
        ref={scrollRef}
        pagedResource={DatasetAPI.getDatasetImages}
        customParams={{
          datasetId,
          query: {
            q: search,
            markers: filterState?.markers,
            orderBy: filterState?.orderby,
            tags: filterState?.tags,
            withoutTags: filterState?.withoutTags
          }
        }}
        size={100}
        ItemComponent={ScrollItem}
      />
    </>
  );
}

export default ImageBrowser;
