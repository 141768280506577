import React, { useEffect, useState, useRef } from 'react';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import { getTraningDetailsLog } from 'services/Api/Training';

import { handleTraining } from 'services/SignalR/TrainingHub';
import { handleInference } from 'services/SignalR/InferenceHub';
import Typed from 'react-typed';
import ScrollView from 'react-inverted-scrollview';

import { t, strings } from 'language';
import { getInferenceDetailsLog } from 'services/Api/Inference/inference';
import { LogContainer, LogLogo, LogText, FilterContainer } from './styles';

function ConsoleLog(props) {
  const { data, hubConnection, isInference } = props;
  const [filterError, setFilterError] = useState(true);
  const [filterNormal, setFilterNormal] = useState(true);
  const [filterWarning, setFilterWarning] = useState(true);
  const [filterStep, setFilterStep] = useState(true);
  const [filterInference, setFilterInference] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [page, setPage] = useState(1);
  const scrollRef = useRef();

  useEffect(() => {
    if (dataItems.length <= 20) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
  }, [dataItems]);

  useEffect(() => {
    if (hubConnection) {
      // console.log('hasConnection');
      if (isInference) {
        handleInference(hubConnection, data.inferenceId, printLog);
      } else {
        handleTraining(hubConnection, data.trainigSessionId, printLog);
      }
    } else {
      // console.log('no hasConnection');
    }
  }, [hubConnection]);

  async function getData(pageItem) {
    if (pageItem === 'reset') {
      setDataItems([]);
      return;
    }

    var result = {};
    if (isInference) {
      result = await getInferenceDetailsLog(data?.inferenceId, pageItem);
    } else {
      result = await getTraningDetailsLog(data?.modelId, data?.trainigSessionId, pageItem);
    }

    if (result.results) {
      result.results.map(item => {
        try {
          const logObj = JSON.parse(item.log);
          logObj.date = item.createDate;

          if (typeof logObj.message === 'string') {
            setDataItems(dataItems => {
              return [logObj, ...dataItems];
            });
            //console.log('itens', dataItems.length);
          }
        } catch (error) {}
      });
    }

    //setDataItems([...dataItems, ...result.results]);
  }

  const getLog = () => {
    let newLog = [...dataItems];
    if (!filterError) {
      newLog = newLog.filter(i => i.type !== 'error');
    }
    if (!filterWarning) {
      newLog = newLog.filter(i => i.type !== 'warning');
    }
    if (!filterNormal) {
      newLog = newLog.filter(i => i.type !== 'normal');
    }
    if (!filterStep) {
      newLog = newLog.filter(i => i.type !== 'step');
    }
    if (!filterInference) {
      newLog = newLog.filter(i => i.type !== 'inference');
    }
    return newLog;
  };
  useEffect(() => {
    getData(page);
  }, []);

  const getTypeImage = type => {
    switch (type) {
      case 'error':
        return require('assets/images/icon_error.png');
      case 'warning':
        return require('assets/images/icon_warning.png');
      case 'step':
      case 'inference':
        return require('assets/images/icon_step.png');
      default:
        return require('assets/images/icon_done.png');
    }
  };

  const printLog = dataLog => {
    const dataReturn = JSON.parse(dataLog);
    // console.log('log', dataReturn);

    if (typeof dataReturn.message === 'string') {
      const date = new Date();
      dataReturn.date = date.toUTCString();

      return setDataItems(dataItems => dataItems.concat(dataReturn));
    }
  };

  const scrollToBottom = () => {
    if (!scrollRef) return;
    scrollRef.current.scrollToBottom();
  };

  const handleScroll = ({ scrollTop }) => {
    if (scrollTop === 0) {
      getData(page + 1);
      setPage(pageVal => pageVal + 1);
    }
  };
  return (
    <>
      <FilterContainer>
        <input
          type="checkbox"
          name="filter"
          value="error"
          checked={filterError}
          onChange={() => setFilterError(!filterError)}
        />
        <span>{t(strings.errors)}</span>
        <input
          type="checkbox"
          name="filter"
          value="warning"
          checked={filterWarning}
          onChange={() => setFilterWarning(!filterWarning)}
        />
        <span>{t(strings.warnings)}</span>
        <input
          type="checkbox"
          name="filter"
          value="normal"
          checked={filterNormal}
          onChange={() => setFilterNormal(!filterNormal)}
        />
        <span>{t(strings.messages)}</span>
        {isInference ? (
          <>
            <input
              type="checkbox"
              name="filter"
              value="step"
              checked={filterInference}
              onChange={() => setFilterInference(!filterInference)}
            />
            <span>{t(strings.inferences)}</span>
          </>
        ) : (
          <>
            <input
              type="checkbox"
              name="filter"
              value="step"
              checked={filterStep}
              onChange={() => setFilterStep(!filterStep)}
            />
            <span>{t(strings.steps)}</span>
          </>
        )}
      </FilterContainer>
      <LogContainer>
        <LogLogo src={require('assets/images/smartvision_terminal.png')} />
        {data && data.status && data.status === 'Processing' && (
          <Typed
            backSpeed={40}
            typeSpeed={40}
            showCursor={false}
            strings={[`<LogText>${t(strings.loading_training)}</LogText>`]}
          />
        )}

        <div className="subcontainer">
          <ScrollView ref={scrollRef} width={810} height={165} onScroll={handleScroll}>
            {dataItems &&
              getLog().map(item => {
                return (
                  <LogText key={item.date.toString()} typeImage={getTypeImage(item.type)}>
                    <Moment local utc format="DD/MM/YYYY HH:mm:ss" style={{ whiteSpace: 'nowrap' }}>
                      {item.date}
                    </Moment>
                    {' - '}
                    {item.message}
                  </LogText>
                );
              })}
          </ScrollView>
        </div>
      </LogContainer>
    </>
  );
}
export default React.memo(ConsoleLog);
