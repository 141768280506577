export const Types = {
  SET_SELECT_DATASET_INFERENCE: 'traning/SET_SELECT_DATASET_INFERENCE',
  SELECT_INFERENCE_POINTS: 'traning/SELECT_INFERENCE_POINTS',
  SELECT_INFERENCE_CONFIDENCE: 'traning/SELECT_INFERENCE_CONFIDENCE',
  SELECT_INFERENCE_DATASET: 'traning/SELECT_INFERENCE_DATASET',
  SET_SELECT_FOLDER: 'traning/SET_SELECT_FOLDER',

  SELECT_TRAINING_DATASET: 'traning/SELECT_TRAINING_DATASET',
  SELECT_EVAL_DATASET: 'traning/SELECT_EVAL_DATASET',
  CLEAR_TRAINING: 'training/CLEAR_TRAINING',
  CLEAR_INFERENCE: 'training/CLEAR_INFERENCE',
  SET_MODEL: 'training/SET_MODEL',
  SELECT_TAGS: 'training/SELECT_TAGS',
  SET_STEPS: 'training/SET_STEPS',
  SELECT_MACHINE_GROUP: 'training/SELECT_MACHINE_GROUP'
};

const INITIAL_STATE = {
  model: null,
  openTrainingFolderSelector: false,
  openInferenceDatasetSelector: false,
  openEvalFolderSelector: false,
  selectedTraningDatasets: [],
  selectedInferencePoints: [],
  selectedInferenceConfidence: 0.5,
  selectedInferenceDatasets: [],
  selectedEvalDatasets: [],
  selectedTags: [],
  selectedMachineGroup: [],
  steps: 2000,
  machineGroup: 'gpu_t3'
};

export function clearTraining() {
  return {
    type: Types.CLEAR_TRAINING
  };
}
export function clearInference() {
  return {
    type: Types.CLEAR_INFERENCE
  };
}

export function setModel(model) {
  return {
    type: Types.SET_MODEL,
    payload: {
      model
    }
  };
}

export function showSelectFolder(show, isEval) {
  return {
    type: Types.SET_SELECT_FOLDER,
    payload: {
      show,
      isEval
    }
  };
}

export function showSelectDatasetInference(show) {
  return {
    type: Types.SET_SELECT_DATASET_INFERENCE,
    payload: {
      show
    }
  };
}
export function selectInferenceConfidence(value) {
  return {
    type: Types.SELECT_INFERENCE_CONFIDENCE,
    payload: {
      value
    }
  };
}
export function selectInferenceDataset(datasets) {
  return {
    type: Types.SELECT_INFERENCE_DATASET,
    payload: {
      datasets
    }
  };
}

export function selectInferencePoints(points) {
  return {
    type: Types.SELECT_INFERENCE_POINTS,
    payload: {
      points
    }
  };
}
export function selectTrainingDataset(datasets) {
  return {
    type: Types.SELECT_TRAINING_DATASET,
    payload: {
      datasets
    }
  };
}
export function selectEvalDataset(datasets) {
  return {
    type: Types.SELECT_EVAL_DATASET,
    payload: {
      datasets
    }
  };
}
export function selectTags(tags) {
  return {
    type: Types.SELECT_TAGS,
    payload: {
      tags
    }
  };
}
export function selectMachineGroup(machineGroup) {
  return {
    type: Types.SELECT_MACHINE_GROUP,
    payload: {
      machineGroup
    }
  };
}
export function setSteps(steps) {
  return {
    type: Types.SET_STEPS,
    payload: {
      steps
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_SELECT_FOLDER:
      if (payload.isEval) return { ...state, openEvalFolderSelector: payload.show };
      return { ...state, openTrainingFolderSelector: payload.show };

    case Types.SET_SELECT_DATASET_INFERENCE:
      return { ...state, openInferenceDatasetSelector: payload.show };
    case Types.SELECT_TRAINING_DATASET: {
      return { ...state, selectedTraningDatasets: payload.datasets };
    }
    case Types.SELECT_INFERENCE_DATASET: {
      return { ...state, selectedInferenceDatasets: payload.datasets };
    }
    case Types.SELECT_INFERENCE_CONFIDENCE: {
      return { ...state, selectedInferenceConfidence: payload.value };
    }
    case Types.SELECT_INFERENCE_POINTS: {
      return { ...state, selectedInferencePoints: payload.points };
    }
    case Types.SELECT_EVAL_DATASET: {
      return { ...state, selectedEvalDatasets: payload.datasets };
    }
    case Types.SELECT_TAGS: {
      return { ...state, selectedTags: payload.tags };
    }
    case Types.SELECT_MACHINE_GROUP: {
      return { ...state, machineGroup: payload.machineGroup };
    }
    case Types.SET_STEPS: {
      return { ...state, steps: payload.steps };
    }
    case Types.CLEAR_TRAINING: {
      return {
        ...INITIAL_STATE,
        model: state.model
      };
    }
    case Types.CLEAR_INFERENCE: {
      return {
        ...INITIAL_STATE,
        model: state.model
      };
    }
    case Types.SET_MODEL: {
      return {
        ...state,
        model: payload.model
      };
    }
    default:
      return state;
  }
}
