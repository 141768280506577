import styled from 'styled-components';
import ScrollableFeed from 'react-scrollable-feed';

export const ScrollableFeedContainer = styled(ScrollableFeed)`
  padding: 0 20px;
`;
export const LogContainer = styled.div`
  width: 100%;
  min-height: 300px;
  background: #000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* padding: 20px; */
  /* overflow-y: scroll; */
  height: 300px;
  margin-bottom: 20px;
  padding: 15px;
  .subcontainer {
    margin-top: 20px;
  }
`;
export const LogText = styled.p`
  color: ${props => props.theme.white};
  ont-family: 'metropolisregular';
  padding: 5px;
  margin-bottom: 10px;
  background: url(${props => props.typeImage}) no-repeat top left;
  font-size: 15px;
  padding: 0px 30px;
  background-size: 20px;
  background-position-y: 2px;

  strong {
    color: #92ec00;
    display: block;
    font-size: 12px;
  }
  time {
    color: ${props => props.theme.white};
  }
`;
export const LogLogo = styled.img`
  width: 300px;
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  width: 100%;
rgba(255, 255, 255, 0.5);
  /* padding: 20px; */
  /* overflow-y: scroll; */

  input{
    
  }
span{
  padding-left:10px;
  padding-right:10px
}
`;
