import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Table, Collapse } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import Searchbar from 'components/Searchbar';
import {
  ContainerTable,
  Button,
  LinkedItems,
  Select,
  StepButton,
  StepMethod,
  StepTitle,
  StepContainer,
  StepButtonContainer,
  StepCollapseContainer,
  StepHeader,
  BodyContainer
} from './styles';
import Dialog from 'components/Dialog';
import { loading } from 'store/ducks/general';
import Switch from 'components/Switch/Switch';
import Moment from 'react-moment';
import { getModels } from 'services/Api/Model/model';
import InfiniteScroll from 'components/InfiniteScroll';
import { setSearchTotal } from 'store/ducks/filter';
import {
  deleteApiAssociation,
  getAssociationsTotal,
  getInvoices,
  makeApiAssociation
} from 'services/Api/Subscriptions/subscriptions';
import CopyPanel from './InvoiceDetail';
import { Link } from 'react-router-dom';
import { t, strings } from 'language';

function Invoices({ navigation }) {
  const scrollRef = useRef();
  const [hasMore, setHasmore] = useState(true);
  const auth = useSelector(state => state.auth);
  const filter = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rules, setRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [totalAssociation, setTotalAssociation] = useState({});
  const [modal, setModal] = useState(false);
  const [collapsedStep1, setCollapsedStep1] = useState(false);
  const [collapsedStep2, setCollapsedStep2] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedStep, setSelectedStep] = useState('');

  // useEffect(() => {
  //   getTotalInvoices();
  // }, []);

  const switchModal = step => {
    switch (step) {
      case 1:
        if (!collapsedStep1) {
          setCollapsedStep1(true);
          setCollapsedStep2(false);
        } else {
          setCollapsedStep1(false);
          setCollapsedStep2(false);
        }
        break;
      case 2:
        if (!collapsedStep2) {
          setCollapsedStep1(false);
          setCollapsedStep2(true);
        } else {
          setCollapsedStep2(false);
          setCollapsedStep1(false);
        }
        break;
    }
  };
  const closeDialog = () => {
    setModal(false);
  };
  const openDialog = () => {
    setModal(true);
  };

  async function getData(page) {
    if (page === 'reset') {
      setData([]);
      setHasmore(true);
      return;
    }
    setHasmore(false);

    const result = await getInvoices(page, 16, {
      query: search
    });
    setHasmore(result.rowCount > result.currentPage * result.pageSize);
    // console.log('data', data);
    // debugger;
    setData([...data, ...result.results]);
    dispatch(setSearchTotal(result.rowCount));
  }

  const handleStatusUpdate = async item => {
    dispatch(loading(true));
    // console.log('id', item);

    if (item.apiKeysAssociated.length > 0) {
      await deleteApiAssociation({
        model: item.id,
        inferencePoint: item.checkpoints[item.checkpoints.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
    } else {
      await makeApiAssociation({
        model: item.id,
        inferencePoint: item.checkpoints[item.checkpoints.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
    }

    getData('reset');
    scrollRef.current.resetScroll();
    dispatch(loading(false));

    // setRoles(rolesList.results);
  };

  const handleChangeStatusUpdate = async (item, newInferencePoint) => {
    dispatch(loading(true));
    // console.log('id', item);

    if (item.apiKeysAssociated.length > 0) {
      await deleteApiAssociation({
        model: item.id,
        inferencePoint: item.apiKeysAssociated[item.apiKeysAssociated.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
      await makeApiAssociation({
        model: item.id,
        inferencePoint: parseInt(newInferencePoint),
        apikey: totalAssociation.apiKeys.apiKey
      });

      //setData([]);
      //getTotalAssociations();

      // await getData('reset');
      // await getData(1);
      getData('reset');
      scrollRef.current.resetScroll();
    }

    dispatch(loading(false));
  };

  const getMaxModelAssociations = () => {
    if (totalAssociation?.apiKeys?.maxModelAssociations == -1) {
      return 'ilimitado';
    }
    if (totalAssociation?.apiKeys?.maxModelAssociations > 0) {
      return totalAssociation?.apiKeys?.maxModelAssociations;
    } else {
      return 0;
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'paid':
        return (
          <>
            <img src={require('../../../assets/images/facturePaid.png')} /> {t(strings.paid_out)}
          </>
        );
        break;
      case 'pending':
        return (
          <>
            <img src={require('../../../assets/images/facturePending.png')} /> {t(strings.pending)}
          </>
        );
        break;

      default:
        return (
          <>
            <img src={require('../../../assets/images/facturePaid.png')} /> {t(strings.paid_out)}
          </>
        );
    }
  };

  return (
    <Container>
      <TitleBreadcrumb position={2} url={'/subscriptioncontrol/linkmodel'} name={t(strings.invoices)} />
      <Searchbar
        value={search}
        onChange={setSearch}
        onMakeSearch={() => scrollRef.current.resetScroll()}
        onResetData={() => scrollRef.current.resetScroll()}
        placeholder={'Buscar modelos'}
      />

      <InfiniteScroll ref={scrollRef} getData={getData} hasMore={hasMore} length={data.length}>
        <ContainerTable>
          <Table>
            <thead>
              <tr>
                <th>{t(strings.invoice_code)}</th>
                <th>{t(strings.invoice_bill)}</th>
                <th>{t(strings.invoice_date)}</th>
                <th>{t(strings.status)}</th>
                <th>{t(strings.download)}</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(item => {
                return (
                  <tr key={`${item.id}`}>
                    <td scope="row">{item.invoiceNumber}</td>
                    <td>
                      {item.lastClosedDate && (
                        <Moment local utc format="DD/MM/YYYY" style={{ whiteSpace: 'nowrap' }}>
                          {item?.lastClosedDate}
                        </Moment>
                      )}
                    </td>

                    <td>
                      {item.closedDate && (
                        <Moment local utc format="DD/MM/YYYY" style={{ whiteSpace: 'nowrap' }}>
                          {item?.closedDate}
                        </Moment>
                      )}
                    </td>
                    <td>{getStatus(item?.status)}</td>

                    <td>
                      <Link to={`/subscriptioncontrol/invoices/download/${item.id}`}>
                        <img src={require('../../../assets/images/downloadIcon.png')} />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ContainerTable>
      </InfiniteScroll>
    </Container>
  );
}

export default Invoices;
