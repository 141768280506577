export const LANG = [
  { key: 'pt', value: 'Português' },
  { key: 'en', value: 'English' },
  { key: 'es', value: 'Español' },
  { key: 'zh', value: '中文' }
];

export const BrowserType = {
  MODEL: 1,
  DATASET: 2,
  DATASETDETAIL: 3
};

export const TypeItem = {
  MODEL: 1,
  DATASET: 2,
  FOLDER: 3
};

export const SECHASH = 'A93reSDIFRTUJHsVisionCuQSHR+fu2reL3GxqsmartOJyDmQpCgps1022018ciuabc=';

export const DEFAULT_LANGUAGE = 'en';
