import React from 'react';

import { Container, Image, Count, Name } from './styles';
import CountUp from 'react-countup';
import { Icon } from 'components/Icon';
function PanelItem({ image, name, count }) {
  return (
    <Container>
      <Icon name={image} />
      <Count>
        <CountUp end={count} />
      </Count>
      <Name>{name}</Name>
    </Container>
  );
}

export default PanelItem;
