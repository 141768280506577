import React, { useContext } from 'react';
import Select from 'react-select';
import { ThemeContext } from 'styled-components';
export default function MultiSelect(props) {
  const all = { value: '_select_all', label: 'Select All' };
  const theme = useContext(ThemeContext);
  const { selected, onChange, options, className } = props;
  return (
    <Select
      className={className}
      options={[all, ...options]}
      {...props}
      isMulti
      onChange={e => {
        if (e !== null && e.length > 0 && e[e.length - 1].value === all.value) {
          onChange(options);
        } else onChange(e ? e : []);
      }}
      value={selected}
      styles={{
        control: e => ({
          ...e,
          backgroundColor: theme.secondary,
          outline: 'none',
          border: '1px solid black',
          boxShadow: '0px 3px 6px #00000029'
        }),
        indicatorSeparator: e => ({
          ...e,
          backgroundColor: 'black'
        }),
        multiValue: e => ({
          ...e,
          backgroundColor: theme.primary
        }),
        multiValueLabel: e => ({
          ...e,
          color: theme.default
        }),
        menu: e => ({
          ...e,
          backgroundColor: theme.primary
        })
      }}
    />
  );
}
