"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useCookie(key, defaultValue) {
    var _a = react_1.useState(function () {
        var match = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        var value = match ? match[2] : defaultValue;
        try {
            value = JSON.parse(value);
        }
        catch (_) { }
        return value;
    }), value = _a[0], setValue = _a[1];
    var setCookie = function (value, options) {
        var cookieOptions = __assign({ expires: 0, domain: '', path: '', secure: false, httpOnly: false, maxAge: 0, sameSite: '' }, options);
        setValue(value);
        // if value is an array or a plain object, convert to JSON
        if (Array.isArray(value) || Object.prototype.toString.call(value) === '[object Object]') {
            value = JSON.stringify(value);
        }
        var cookie = key + "=" + value;
        if (cookieOptions.expires) {
            // var date = new Date();
            // date.setTime(date.getTime() + 1000 * cookieOptions.expires);
            // cookie += "; Expires=" + date.toUTCString();
            cookie += "; Expires=" + cookieOptions.expires;
        }
        if (cookieOptions.path) {
            cookie += "; Path=" + cookieOptions.path;
        }
        if (cookieOptions.domain) {
            cookie += "; Domain=" + cookieOptions.domain;
        }
        if (cookieOptions.maxAge) {
            cookie += "; Max-Age=" + cookieOptions.maxAge;
        }
        if (cookieOptions.sameSite) {
            cookie += "; SameSite=" + cookieOptions.sameSite;
        }
        if (cookieOptions.secure) {
            cookie += '; Secure';
        }
        if (cookieOptions.httpOnly) {
            cookie += '; HttpOnly';
        }
        document.cookie = cookie;
    };
    var clearCookie = function () {
        setCookie('', { expires: -3600 });
        setValue(defaultValue);
    };
    return [value, setCookie, clearCookie];
}
exports.default = useCookie;
