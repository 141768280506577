import styled from 'styled-components';
import { NavLink, Row } from 'reactstrap';

export const ChartContainer = styled.div`
  height: 350px;
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.secondary};
  flex-direction: column;
  border-radius: 10px;
`;
export const ChartsContainer = styled.div`
  background-color: ${props => props.theme.secondary};
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
`;

export const GaugeContainer = styled.div`
  height: 150px;
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 0px 30px;
  align-items: center;
  border-radius: 10px;
  background: ${props => props.theme.secondary};
`;

export const ChartTitle = styled.h3`
  margin: 20px 30px 0;
`;

export const SelectMetric = styled.select`
  margin-left: 10px;
  min-width: 350px;
  background-color: ${props => props.theme.primary} !important;
  height: 37px;
  line-height: 37px;
  outline: none;
  background: ${props => props.theme.secondary};
  border: 1px solid black;
  display: block;
  padding: 8px 25px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 16px;
  & > option {
    background-color: ${props => props.theme.primary} !important;
    height: 37px;
    line-height: 37px;
    outline: none;
    background: ${props => props.theme.secondary};
    font-size: 16px;
  }
`;

export const TabLink = styled(NavLink)`
  background-color: ${props => (props.active ? `${props => props.theme.default}` : 'transparent')};
  height: 100%;
`;
export const CopyButton = styled.a`
  background-color: ${props => props.theme.default};
  color: ${props => props.theme.white};
  padding: 5px 10px;
  margin: 0 20px 0 30px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 12px;
  align-items: center;
`;

export const SwitchOptions = styled.p`
  margin: 0 10px;
`;

export const TabsContent = styled.div`
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ConfigButton = styled.img`
  height: 32px;
  width: 32px;

  :hover {
    cursor: pointer;
  }
`;

export const ControllerContainer = styled(Row)`
  background-color: ${props => props.theme.secondary};
  border-radius: 5px;
  align-items: center;
`;
