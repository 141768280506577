import React from 'react';
import { t, strings } from 'language';
import PanelItem from './PanelItem';

import { Container } from './styles';

function StatusPanel({ data }) {
  // console.log('info', data);
  return (
    <Container>
      <PanelItem image="modelImage" name={t(strings.models)} count={data?.model} />

      <PanelItem image="folder_icon" name={t(strings.datasets)} count={data?.datasets} />

      <PanelItem image="images" name={t(strings.images)} count={data?.images} />

      {/* <PanelItem
        image={require('assets/images/inference.png')}
        name="Inferências"
        count={data?.inference}
      /> */}

      <PanelItem image="api" name={t(strings.api)} count={data?.keys.length} />
    </Container>
  );
}

export default StatusPanel;
