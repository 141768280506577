import React from 'react';

import { Container, Text } from './styles';
import { Link } from 'react-router-dom';
import { Icon } from 'components/Icon';

export default function MenuItem(props) {
  const { icon, children, destination, action } = props;
  return (
    <Link
      to={destination ? destination : '#'}
      onClick={e => {
        if (!destination) e.preventDefault();
        action();
      }}
    >
      <Container className="d-flex flex-row align-content-start align-items-start">
        <Icon name={icon}></Icon>
        <Text className="ml-3">{children}</Text>
      </Container>
    </Link>
  );
}
