import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useGlobal from 'store/global';
import { addBreadCrumb } from 'store/ducks/breadcrumb';
import { Breadcrumb } from 'reactstrap';
import LoadingMini from 'components/LoadingMini';
import shortid from 'shortid';

import { Container, ImageCount } from './styles';

function TitleBreadcrumb(props) {
  const dispatch = useDispatch();
  const bread = useSelector(i => i.breadcrumb);
  const { position, url, name, margin } = props;
  const [, setLoadingPosition] = useGlobal(
    state => state.loadingPosition,
    actions => actions.setLoadingPosition
  );
  const div = useCallback(node => {
    if (node !== null) {
      setLoadingPosition(node.getBoundingClientRect().width + 30);
    }
  }, []);

  useEffect(() => {
    dispatch(
      addBreadCrumb({
        position,
        url,
        name
      })
    );
  }, [position, url, name, margin, dispatch]);
  return (
    <Container margin={margin}>
      <Breadcrumb tag="nav" listTag="div">
        {bread.map(
          i =>
            i &&
            (i.name !== 'loading' ? (
              <Link key={shortid.generate()} className="breadcrumb-item" to={i.url}>
                {i.name}
              </Link>
            ) : (
              <LoadingMini></LoadingMini>
            ))
        )}
      </Breadcrumb>
    </Container>
  );
}

export default TitleBreadcrumb;
