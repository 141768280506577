import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const InputText = styled.input`
  background: #fff;
  border: none;
  display: block;
  padding: 8px 10px;
  border-radius: 5px;
  width: 100%;
`;

export const Label = styled.label`
  display: block;

  span {
    margin-bottom: 5px;
    display: block;
  }
`;
