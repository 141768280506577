import React from 'react';
import './styles.css';
import { Container } from './style.js';
const Switch = ({ ison, handleToggle, onColor, name, disabled, checkOff }) => {
  return (
    <Container checkOff={checkOff}>
      <input
        checked={ison}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new` + name}
        type="checkbox"
        disabled={disabled}
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new` + name}
        style={{ opacity: disabled ? '0.5' : '1', background: ison && onColor }}
      >
        <span className={`react-switch-button`} />
      </label>
    </Container>
  );
};

export default Switch;
