import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 18px;
  position: relative;
`;

export const ButtonItem = styled.button`
  height: 47px;
  width: 47px;
  background: ${props => props.theme.secondary} 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  :focus {
    outline: none;
  }
  padding-left: '5px'};
  margin-left: 10px;
`;

export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;

  ::placeholder {
    color: ${props => props.theme.iconColor};
    opacity: 0.5;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;

export const LabelAlert = styled.div`
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 7px;
`;
