import React from 'react';
import {
  Container,
  ProgressContainer,
  ImageTitle,
  ProgressBar,
  ProgressBarCompleted,
  ProgressLoad
} from './styles';
import check from 'assets/images/check.png';
import { Icon } from 'components/Icon';

function QueueItem(props) {
  return (
    <Container>
      <ProgressContainer>
        <ImageTitle>{props.title}</ImageTitle>
        {props.progress < 100 ? (
          <ProgressBar>
            <ProgressLoad progress={props.progress}></ProgressLoad>
          </ProgressBar>
        ) : (
          <ProgressBarCompleted></ProgressBarCompleted>
        )}
      </ProgressContainer>
      {props.uploaded && <Icon name="confirm" />}
    </Container>
  );
}

export default React.memo(QueueItem);
