import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid ${props => props.theme.text};
  width: 100%;
  padding-left: 20px;
  padding-top: 14px;
`;
export const Icon = styled.img`
  width: 20px;
`;
export const Text = styled.p`
  color: ${props => props.theme.white};
  font-size: 16px;
`;
