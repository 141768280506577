import styled from 'styled-components';
import { Col, NavLink } from 'reactstrap';

export const PlayerContainer = styled.div`
  display: flex;
  padding: 0 10px;

  a.nav-link.active {
    background-color: ${props => props.theme.default} !important;
  }
  p {
    color: ${props => props.theme.white};
  }
`;

export const TrainingHistoryList = styled(Col)``;

export const TabsContent = styled.div`
  max-height: 650px;
  overflow-y: auto;
  height: 65vh;
  overflow-x: hidden;
`;

export const TabLink = styled(NavLink)`
  color: '#fff';
  background-color: ${props => (props.active ? `${props => props.theme.default}` : 'transparent')};
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;