import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { SelectImage } from 'store/ducks/annotate';
import { getInferenceImage } from 'services/Api/Inference/inference';
import { getIdFromUrl } from 'utils/helpers';
import { t, strings } from 'language';
import useGlobal from 'store/global';
import { resolveParameters } from 'services/Api';
import ButtonIcon from './ButtonIcon';
import TagType from './TagType';
import Effects from './Effects';
import useOutsideClick from 'components/useOutsideClick';
import { ReactComponent as ZoomOutIcon } from 'assets/images/zoom-out.svg';
import { ReactComponent as ZoomInIcon } from 'assets/images/zoom-in.svg';
import { ReactComponent as FitIcon } from 'assets/images/fit.svg';
import { ReactComponent as Box } from 'assets/images/tag_icon.svg';
import { ReactComponent as Class } from 'assets/images/class_icon.svg';
import { ReactComponent as Polygon } from 'assets/images/polygon_icon.svg';
import { ReactComponent as Mask } from 'assets/images/mask.svg';
import { ReactComponent as DownloadIcon } from './assets/images/download.svg';
import { ReactComponent as Rotation } from 'assets/images/rotate.svg';
import { Title, ToolDiv, ZoomText, ButtonText, Text, Container } from './styles';
import './styles.css';
import { Icon } from 'components/Icon';

function Tools(props) {
  const { history } = props;
  const location = history.location.pathname;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const isInference = !!(inferenceID > 0 && getIdFromUrl(location, 3) === 'inference');
  const annotate = useSelector(state => (isInference ? state.inference : state.annotate));
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [, setViewerDialog] = useGlobal(
    state => state.viewerDialog,
    actions => actions.setViewerDialog
  );
  const { images } = annotate;
  const totalImges = annotate.totalImages;
  const dataset = getIdFromUrl(history.location.pathname, 2);
  const [zoom, addZoom] = useGlobal(
    state => state.zoom,
    actions => actions.addZoom
  );
  const [, setZoom] = useGlobal(
    state => state.zoom,
    actions => actions.setZoom
  );
  const [, setFitZoom] = useGlobal(
    state => state.fitZoom,
    actions => actions.setFitZoom
  );
  const [index] = useGlobal(state => state.index);
  const [, setNewIndex] = useState('');
  const [showTagType, setShowTagType] = useGlobal(
    state => state.showTagType,
    actions => actions.setShowTagType
  );
  const [showEffects, setShowEffects] = useGlobal(
    state => state.showEffects,
    actions => actions.setShowEffects
  );
  const [inferenceJson, setInferenceJson] = useState('none');
  const dispatch = useDispatch();
  const [tagType] = useGlobal(state => state.tagType);
  const selectTag = x => {
    switch (x) {
      case 'CLASS':
        return <Icon name="class_icon" style={{ width: 30, height: 30 }} />;
      case 'POINT':
        return <Icon name="polygon_icon" style={{ width: 30, height: 30 }} />;
      case 'MASK':
        return <Icon name="mask" style={{ width: 30, height: 30 }} />;
      default:
        return <Icon name="tag_icon" style={{ width: 30, height: 30 }} />;
    }
  };
  const zoomValue = zoom && zoom.toFixed(2);
  const paramState = useSelector(state => state.parameters);
  const refTagType = useRef();
  const [, setModalRotation] = useGlobal(
    state => state.modalRotation,
    actions => actions.setModalRotation
  );

  useOutsideClick(refTagType, () => {
    if (showTagType === 'block') {
      setShowTagType('none');
    }
    if (showEffects === 'block') {
      setShowEffects('none');
    }
  });

  useEffect(() => {
    setNewIndex(`${index + 1}/ ${totalImges}`);
  }, [index]);

  useEffect(() => {
    async function getInference() {
      const imageId = annotate.images && annotate.images[0].id;
      const result = await getInferenceImage(inferenceID, imageId);
      setInferenceJson(result);
      return result;
    }
    if (isInference && annotate.images) {
      getInference();
    }
  }, [images]);

  async function showImage(newIdx) {
    const params = `${resolveParameters({
      page: paramState?.page,
      query: paramState?.search,
      markers: paramState?.markers,
      orderBy: paramState?.orderby,
      tags: paramState?.tags,
      withoutTags: paramState?.withoutTags
    })}`;
    if (newIdx > -1 && newIdx <= totalImges) {
      const image = images.find((img, idx) => {
        return idx === parseInt(newIdx, 10);
      });
      if (image) {
        if (isInference) {
          history.push(
            `/dataset/${dataset}/inference/${inferenceID}/annotate/${image.id}${params}`
          );
        } else {
          history.push(`/dataset/${dataset}/annotate/${image.id}${params}`);
        }
        dispatch(SelectImage(image));
      }
    }
  }

  function handleNewIndex(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setNewIndex(event.target.value);
    }
  }

  function onBlur(event) {
    const newIdx = event.target.value;
    if (newIdx === '' || parseInt(newIdx, 10) === index + 1 || parseInt(newIdx, 10) > totalImges) {
      setNewIndex(`${index + 1}/ ${totalImges}`);
    } else {
      showImage(newIdx - 1);
    }
  }

  function onKeyPress(event) {
    if (event.key === 'Enter') {
      onBlur(event);
    }
  }

  function showTags() {
    setShowTagType(showTagType === 'none' ? 'block' : 'none');
    setShowEffects('none');
  }

  function showToolsEffects() {
    setShowEffects(showEffects === 'none' ? 'block' : 'none');
    setShowTagType('none');
  }

  function downloadInference() {
    const name = images[0].name.substr(0, images[0].name.lastIndexOf('.'));
    const element = document.createElement('a');
    const newString = JSON.stringify(inferenceJson);
    const file = new Blob([newString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.json`;
    document.body.appendChild(element);
    element.click();
  }

  return (
    <Container className="w-100 no-margin">
      <ToolDiv style={{ position: 'relative', top: 24, background: 'none' }}>
        <Row style={{ position: 'absolute' }}>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ marginLeft: '165px', minWidth: '240px', maxWidth: '240px' }}
          >
            <Text>{parseInt(zoomValue * 100)}%</Text>
          </Col>
        </Row>
        <Row className="mt-3 ml-2" style={{ flexWrap: 'nowrap' }}>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '60px', maxWidth: '60px' }}
          >
            {isInference ? (
              <>
                <ButtonIcon className="float-right" onClick={() => downloadInference()}>
                  <DownloadIcon style={{ width: 20, height: 20 }} />
                </ButtonIcon>
                <ZoomText>
                  <Title>{t(strings.download)}</Title>
                </ZoomText>
              </>
            ) : (
              <>
                {!viewer && (
                  <ButtonIcon className="float-right" onClick={() => showTags()}>
                    {selectTag(tagType)}
                  </ButtonIcon>
                )}
                {viewer && (
                  <ButtonIcon className="float-right" onClick={() => setViewerDialog(true)}>
                    {selectTag(tagType)}
                  </ButtonIcon>
                )}
                <ZoomText>
                  <Title>{t(strings.tag)}</Title>
                </ZoomText>
              </>
            )}
          </Col>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '60px', maxWidth: '60px' }}
          >
            <ButtonIcon className="float-right" onClick={() => showToolsEffects()}>
              <ButtonText>fx</ButtonText>
            </ButtonIcon>
            <ZoomText>
              <Title>{t(strings.effects)}</Title>
            </ZoomText>
          </Col>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '20px', maxWidth: '20px' }}
          />
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '120px', maxWidth: '120px' }}
          >
            <Col
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '60px', maxWidth: '60px' }}
            >
              <ButtonIcon className="float-right" onClick={() => addZoom(0.05)}>
                <Icon name="zoom-in" style={{ width: 30, height: 30 }} />
              </ButtonIcon>
            </Col>
            <Col
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '60px', maxWidth: '60px' }}
            >
              <ButtonIcon onClick={() => addZoom(-0.05)}>
                <Icon name="zoom-out" style={{ width: 30, height: 30 }} />
              </ButtonIcon>
            </Col>
            <ZoomText>
              <Title>{t(strings.zoom)}</Title>
            </ZoomText>
          </Col>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '60px', maxWidth: '60px' }}
          >
            <ButtonIcon className="float-right" onClick={() => setFitZoom(0)}>
              <Icon name="fit" style={{ width: 30, height: 30 }} />
            </ButtonIcon>
            <ZoomText>
              <Title>{t(strings.fit)}</Title>
            </ZoomText>
          </Col>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '60px', maxWidth: '60px' }}
          >
            <ButtonIcon className="float-right" onClick={() => setZoom(1)}>
              <ButtonText>1:1</ButtonText>
            </ButtonIcon>
            <ZoomText>
              <Title>{t(strings.scale)}</Title>
            </ZoomText>
          </Col>
          <Col
            className="d-flex justify-content-center no-margin"
            style={{ minWidth: '85px', maxWidth: '85px' }}
          >
            {!viewer && (
              <ButtonIcon
              className="float-right"
              onClick={() => {
                setModalRotation(true);
              }}
            >
              <Icon name="rotate" style={{ width: 30, height: 30 }} />
            </ButtonIcon>
            )}
            {viewer && (
              <ButtonIcon
              className="float-right"
              onClick={() => {
                setViewerDialog(true);
              }}
            >
              <Icon name="rotate" style={{ width: 30, height: 30 }} />
            </ButtonIcon>
            )}
            <ZoomText style={{ whiteSpace: 'nowrap' }}>
              <Title>{t(strings.rotation)}</Title>
            </ZoomText>
            {/* <ButtonIcon className="float-right" onClick={() => handleTagNames(0)}>
              {showTagsName ? <ShowIcon /> : <HideIcon />}
            </ButtonIcon>
            <ZoomText style={{ whiteSpace: 'nowrap' }}>
              <Title>{t(strings.all_names)}</Title>
            </ZoomText> */}
          </Col>
        </Row>
        <Row className="mt-2 ml-2" style={{ flexWrap: 'nowrap' }}>
          <ToolDiv
            ref={refTagType}
            style={{
              zIndex: 1000,
              display: showTagType
            }}
          >
            <Col
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '240px', maxWidth: '240px' }}
            >
              <TagType showTags={showTags} />
            </Col>
          </ToolDiv>
          <ToolDiv
            style={{
              zIndex: 1000,
              display: showEffects,
              marginLeft: '60px',
              height: '170px'
            }}
          >
            <Col
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '300px', maxWidth: '300px' }}
            >
              <Effects />
            </Col>
          </ToolDiv>
        </Row>
      </ToolDiv>
    </Container>
  );
}

export default Tools;
