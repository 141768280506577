import styled from 'styled-components';

import UserAvatar from 'react-user-avatar';

export const Container = styled.div``;

export const Name = styled.p`
  color: ${props => props.theme.green};
  margin: 0;
  font-size: 16px;
`;
export const Sign = styled.span`
  color: ${props => props.theme.text};
  font-size: 13px;
`;
export const Info = styled.div`
  margin-left: 73px;
  display: flex;
  flex-direction: column;
  width: 150px;
`;

export const SubscriptionSelect = styled.select`
  background: transparent;
  border: none;
  padding: 0px;
  margin-left: -4px;
  background: ${props => props.theme.secondary};
  padding: 0px;
`;

export const LanguageSelect = styled.select`
background: transparent;
border: none;
padding: 0px;
margin-left: -4px;
background: ${props => props.theme.secondary};
padding: 0px;
`;

export const AvatarEditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
`;
export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 12px;

  div.uploadPhoto {
    position: fixed;
  }

  div.uploadPhoto:hover #avatarEditOverlay {
    display: flex;
    cursor: pointer;
  }
  div.uploadPhoto input {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    bottom: 0;
    right: 0;
    z-index: 11;
    opacity: 0;
  }
  div.uploadPhoto input:hover {
    cursor: pointer;
  }
`;
export const Icon = styled(UserAvatar)`
  border: 2px solid ${props => props.theme.white};
  border-radius: 25px;
`;
