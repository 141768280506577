import styled from 'styled-components';
import { Container as ContainerReactStrap } from 'reactstrap';

export const ContainerStrap = styled(ContainerReactStrap)`
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const Container = styled.div``;
export const Star = styled.img`
  height: 20px;
  width: 20px;
  margin: 1px 5px;
`;
export const DeleteMarkerButton = styled.button`
  /* opacity: ${props => (props.mouseOver ? 1 : 0)}; */


  transition: opacity 150ms linear;
  width: 23px;
  height: 23px;
  margin-left: 12px;
  border: none;
  background: transparent url(${require('assets/images/delete_icon.png')}) 0px 7px;
  background-repeat: no-repeat!important;
  background-position-x:5px;
  background-position-y:5px;
  
`;
export const MarkerButton = styled.a`
  /* opacity: ${props => (props.mouseOver ? 1 : 0)}; */


  transition: opacity 150ms linear;
  width: 53px;
    height: 25px;
  margin-left: 12px;
  border-radius: 3px;
  border: none;
  background-size:20px;
  /* background-image: url(${require('assets/images/class_icon.svg')})!important; */
  background-color: ${props => props.theme.iconColor};
  -webkit-mask: url(${require('assets/images/class_icon.svg')}) no-repeat left;
  mask: url(${require('assets/images/class_icon.svg')}) no-repeat left;
  background-position: 1px 1px!important;
  padding-right: 52px!important;
  padding-left: 30px!important;
  background-repeat: no-repeat!important;
  bottom: 10px;
    right: -49px;
    display:none;
    position:absolute;
  :focus {
    outline: none!important;
  }
  path{
    fill:#fff;
  }
`;
export const MarkerSearchField = styled.input`
  /* opacity: ${props => (props.mouseOver ? 1 : 0)}; */

  border:none;
  background:none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;
  font-size:12px;
  margin-bottom:5px
  
`;
