import React, { useState, useEffect } from 'react';
import MultSelectSearchable from '../../MultSelectSearchable';
import { Collapse, Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { getTags } from 'services/Api/Tag/tag';
import { setTags, clearFilter, setWithoutTags } from 'store/ducks/filter';
import { t, strings } from 'language';

const TagFilter = ({ setFilterSelection, filterSelection }) => {
  const [selectedFilters, setSelectedFilters] = useState(null);
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.filter);

  const handleSelect = e => {
    setSelectedFilters(e);
  };
  const handleWithoutTags = e => {
    dispatch(setWithoutTags(!filterState.withoutTags));

    setFilterSelection({
      tags: null
    });

    setSelectedFilters([]);
  };
  useEffect(() => {
    // console.log('redux', filterState);
    if (filterState.tags) {
      setFilterSelection({
        tags: filterState.tags
      });
    }
  }, []);

  useEffect(() => {
    if (selectedFilters) {
      // console.log('selected', selectedFilters);
      setFilterSelection({
        ...filterSelection,
        tags: selectedFilters
          .sort((a, b) => a?.description?.localeCompare(b.description))
          .map(i => {
            return {
              label: i.label,
              value: i.value
            };
          })
      });
      // console.log('filterState.tags', filterState.tags);
      //dispatch(setTags(filterState.tags));
    }
  }, [selectedFilters]);

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      getTags(inputValue, 1, 10).then(data => {
        resolve(
          data.results
            .sort((a, b) => a.description.localeCompare(b.description))
            .map(i => {
              return {
                label: i.description,
                value: i.id
              };
            })
        );
      });
    });

  return (
    <Container>
      <Label>{t(strings.tags)}</Label>
      <label style={{ float: 'right' }}>
        {t(strings.no_tags)+' '}
        <input
          type="checkbox"
          value={filterState.withoutTags}
          checked={filterState.withoutTags ? 'checked' : ''}
          onChange={handleWithoutTags}
        />
      </label>

      <MultSelectSearchable
        defaultValue={filterState.tags}
        promiseOptions={promiseOptions}
        selected={filterState.tags}
        onChange={handleSelect}
        options={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Container>
  );
};

export default TagFilter;
