import styled from 'styled-components';

import { t, strings } from 'language';

export const Table = styled.table`
  position: relative;
  top: 30px;
  width: 100%;
  margin-bottom: 10px;
`;

export const Tr = styled.tr`
  white-space: nowrap;
`;

export const Td = styled.td`
  padding: 3px;
`;

export const TdTitle = styled.td`
  font-size: 14px;
  white-space: nowrap;
  color: #707070;
`;

export const TdText = styled.td`
  padding: 3px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #707070;
  width: 24%;
`;

export const Title = styled.small`
  color: #909090;
  cursor: default;
`;

export const HeaderContainer = styled.div`
  position: absolute;
  top: -50px;
  right: 16px;
  display: flex;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-right: 10px;
  line-height: 90%;
`;

export const StatusContainer = styled.div`
  text-align: center;
  font-size: 18px;
  white-space: nowrap;
  color: white;
  background: ${props =>
    props.status === 'Error' || props.status === 'Failed' ? '#dc3545' : '#28a745'};
  padding: 3px;
  width: 212px;
  height: 35px;
`;

export const FolderContainer = styled.div`
  position: relative;
  top: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0px 25px;
  margin-bottom: 10px;
`;

export const FolderTitle = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    font-size: 16px;
    margin: 0;
  }
`;

export const FolderButton = styled.div`
  cursor: pointer;
  width: 70px;
  text-align: center;
  background: ${props =>
    props.open ? props => props.theme.secondary : props => props.theme.default};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: ${props => (props.open ? props => props.theme.default : props => props.theme.white)};
  font-size: 16px;
  border: none;
  padding: 3px 8px;
  ::before {
    content: ${props => (props.open ? `"${t(strings.close)}"` : `"${t(strings.open)}"`)};
  }
`;

export const StepInput = styled.input`
  background: #fff;
  border: none;
  display: block;
  padding: 2px 3px;
  border-radius: 5px;
  width: 100px;
  margin-left: 30px;
  text-align: center;
`;

export const LogContainer = styled.div`
  width: 100%;
  min-height: 300px;
  background: #000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 20px;
  overflow-y: scroll;
  height: 300px;

  .subcontainer {
    margin-top: 20px;
  }
`;

export const LogText = styled.p`
  color: ${props => props.theme.white};
  font-family: serif;
  padding: 5px 0;
  margin-bottom: 0;
  strong {
    color: #92ec00;
    display: block;
    font-size: 12px;
  }
  time {
    color: ${props => props.theme.white};
  }
`;

export const LogLogo = styled.img`
  width: 300px;
  display: block;
  margin-bottom: 10px;
`;

export const Icon = styled.img`
  margin-right: 10px;
`;
