import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { t, strings } from 'language';
import Moment from 'react-moment';
import Dialog from 'components/Dialog';
import { getTraningDetails } from 'services/Api/Training';
import DatasetTooltip from 'components/GlobalTooltip/datasetTooltip';
import TagTooltip from 'components/GlobalTooltip/tagTooltip';
import CustomItemButtom, { iconType } from 'components/CustomItemButtom';
import {
  Table,
  Tr,
  Td,
  TdTitle,
  TdText,
  Title,
  HeaderContainer,
  ButtonContainer,
  StatusContainer,
  FolderContainer,
  FolderTitle,
  FolderButton
} from './styles';
import ConsoleLog from '../ConsoleLog';
import ButtonIcon from './ButtonIcon';
import { ReactComponent as CancelIcon } from './assets/images/cancel.svg';

export default function TrainingSession(props) {
  const { data, hubConnection, open, closeDetatils } = props;
  const [traningDetails, setTraningDetails] = useState(null);
  const [collapseStatus, setCollapseStatus] = useState([]);
  // const [logs, setLogs] = useState([]);
  const totalTrainingTags = traningDetails
    ? traningDetails.usedTrainingTags.reduce((totalTags, tag) => totalTags + tag.count, 0)
    : 0;
  const totalEvalTags = traningDetails
    ? traningDetails.usedEvalTags.reduce((totalTags, tag) => totalTags + tag.count, 0)
    : 0;
  const showStopTraining = false; //data.status !== 'Error' ? data.status !== 'Finished' : false;

  useEffect(() => {
    async function getData() {
      const traning = await getTraningDetails(data.modelId, data.trainigSessionId);
      setTraningDetails(traning);
    }
    if (open) {
      getData();
    }
  }, [open, data.trainigSessionId, data.modelId]);

  function dateDiffTime(d1, d2) {
    const date = new Date();
    const tz = date.getTimezoneOffset() / 60;
    const nowUTC = date.setHours(date.getHours() + tz);
    const date1 = new Date(d1);
    const date2 = d2 === undefined ? nowUTC : new Date(d2);
    const diff = date2 - date1;
    const diffDays = Math.floor(diff / 8.64e7);
    const diffHrs = Math.floor((diff % 8.64e7) / 3.6e6);
    const diffMins = Math.floor(((diff % 8.64e7) % 3.6e6) / 6e4);
    const diffSecs = Math.floor((((diff % 8.64e7) % 3.6e6) % 6e4) / 1000);
    const days = diffDays > 0 ? `${`${diffDays} ${t(strings.days)}`}, ` : '';
    const hours = `${diffHrs}`.length > 1 ? `${diffHrs}:` : `0${diffHrs}:`;
    const minutes = `${diffMins}`.length > 1 ? `${diffMins}:` : `0${diffMins}:`;
    const seconds = `${diffSecs}`.length > 1 ? diffSecs : `0${diffSecs}`;
    const result = `${days}${hours}${minutes}${seconds}`;

    return result;
  }

  function spetDiff(s1, s2) {
    return s2 !== undefined ? `${s2 - s1} ${t(strings.steps)}` : t(strings.running);
  }

  function toggleCollapse(index) {
    const status = [...collapseStatus];
    status[index] = !collapseStatus[index];
    setCollapseStatus(status);
  }

  function stopTraining() {
    alert('Ask if really to do.');
  }

  return (
    <Dialog
      title={t(strings.training_session)}
      open={open}
      close={() => closeDetatils()}
      //actions={[{ text: `${t(strings.close)}`, action: () => closeDetatils() }]}
      width="1000px"
    >
      {traningDetails && (
        <>
          <HeaderContainer>
            {showStopTraining ? (
              <ButtonContainer style={{ minWidth: '60px', maxWidth: '60px' }}>
                <ButtonIcon
                  style={{ position: 'relative', left: '11px' }}
                  onClick={() => stopTraining()}
                >
                  <CancelIcon />
                </ButtonIcon>
                <Title>{t(strings.cancel_training)}</Title>
              </ButtonContainer>
            ) : (
              ''
            )}
            <StatusContainer status={data.status}>{data.status}</StatusContainer>
          </HeaderContainer>
          <Table>
            <tbody>
              <Tr>
                <TdTitle>{`${t(strings.create_at)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.started_at)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.training_time)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.finished_at)}:`}</TdTitle>
              </Tr>
              <Tr>
                <TdText>
                  <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                    {traningDetails.createAt}
                  </Moment>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                    {traningDetails.startedAt}
                  </Moment>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {traningDetails.finishedAt
                      ? dateDiffTime(traningDetails.startedAt, traningDetails.finishedAt)
                      : '-'}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  {traningDetails.finishedAt ? (
                    <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                      {traningDetails.finishedAt}
                    </Moment>
                  ) : (
                    '-'
                  )}
                </TdText>
              </Tr>
              <Tr>
                <Td style={{ width: '10px' }} />
              </Tr>
              <Tr>
                <TdTitle>{`${t(strings.machine)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.earlyStep)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.training_steps)}:`}</TdTitle>
                <Td style={{ width: '10px' }} />
                <TdTitle>{`${t(strings.lastStep)}:`}</TdTitle>
              </Tr>
              <Tr>
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>{traningDetails.machineGroup}</span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {`${traningDetails.startStep} ${t(strings.steps)}`}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {spetDiff(traningDetails.startStep, traningDetails.endInStep)}
                  </span>
                </TdText>
                <Td style={{ width: '10px' }} />
                <TdText>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {`${traningDetails.endInStep} ${t(strings.steps)}`}
                  </span>
                </TdText>
              </Tr>
            </tbody>
          </Table>
          <br />
          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.trainingDatasets)} - 
                ${traningDetails.trainingDatasets.length} ${t(strings.datasets)} - 
                ${traningDetails.trainingImages} ${t(strings.images)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    traningDetails.trainingDatasets && traningDetails.trainingDatasets.length > 0
                      ? 'block'
                      : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(0)}
                open={collapseStatus[0]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[0]} className="mt-3">
              {traningDetails.trainingDatasets &&
                traningDetails.trainingDatasets.map((item, index) => {
                  return (
                    <CustomItemButtom
                      key={index}
                      icon={iconType.folder}
                      width={261}
                      tooltip={() => <DatasetTooltip {...item} />}
                    >
                      {item.title}
                    </CustomItemButtom>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.evalDatasets)} - 
                ${traningDetails.evalDatasets.length} ${t(strings.datasets)} - 
                ${traningDetails.evalImages} ${t(strings.images)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    traningDetails.evalDatasets && traningDetails.evalDatasets.length > 0
                      ? 'block'
                      : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(1)}
                open={collapseStatus[1]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[1]} className="mt-3">
              {traningDetails.evalDatasets &&
                traningDetails.evalDatasets.map((item, index) => {
                  return (
                    <CustomItemButtom
                      key={index}
                      icon={iconType.folder}
                      width={261}
                      tooltip={() => <DatasetTooltip {...item} />}
                    >
                      {item.title}
                    </CustomItemButtom>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.tags)} - `}
                {`${traningDetails.usedTrainingTags.length} ${t(strings.training_class)}`}
                {` / ${totalTrainingTags} ${t(strings.tags)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    traningDetails.usedTrainingTags && traningDetails.usedTrainingTags.length > 0
                      ? 'block'
                      : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(2)}
                open={collapseStatus[2]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[2]} className="mt-3">
              {traningDetails.usedTrainingTags &&
                traningDetails.usedTrainingTags.map((item, index) => {
                  return (
                    <CustomItemButtom
                      key={index}
                      icon={iconType.tag}
                      width={261}
                      tooltip={() => <TagTooltip {...item} />}
                    >
                      {`${item.tag.description} - ${item.count} ${t(strings.tags)}`}
                    </CustomItemButtom>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>
                {`${t(strings.tags)} - `}
                {`${traningDetails.usedEvalTags.length} ${t(strings.eval_class)}`}
                {` / ${totalEvalTags} ${t(strings.tags)}`}
              </p>
              <FolderButton
                style={{
                  display: `${
                    traningDetails.usedEvalTags && traningDetails.usedEvalTags.length > 0
                      ? 'block'
                      : 'none'
                  }`
                }}
                onClick={() => toggleCollapse(3)}
                open={collapseStatus[3]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[3]} className="mt-3">
              {traningDetails.usedEvalTags &&
                traningDetails.usedEvalTags.map((item, index) => {
                  return (
                    <CustomItemButtom
                      key={index}
                      icon={iconType.tag}
                      width={261}
                      tooltip={() => <TagTooltip {...item} />}
                    >
                      {`${item.tag.description} - ${item.count} ${t(strings.tags)}`}
                    </CustomItemButtom>
                  );
                })}
            </Collapse>
          </FolderContainer>

          <FolderContainer>
            <FolderTitle>
              <p>{t(strings.training_process)}</p>
              <FolderButton
                style={{
                  display: `${traningDetails.showConsole ? 'block' : 'none'}`
                }}
                onClick={() => toggleCollapse(4)}
                open={collapseStatus[4]}
              />
            </FolderTitle>
            <Collapse isOpen={collapseStatus[4]} className="mt-3">
              <ConsoleLog
                data={data}
                hubConnection={hubConnection}
                traningDetails={traningDetails}
              />
            </Collapse>
          </FolderContainer>
        </>
      )}
    </Dialog>
  );
}
