import styled from 'styled-components';
import { t, strings } from 'language';
import { Container as ContainerRS } from 'reactstrap';

export const Container = styled(ContainerRS)`
  .ts_log_header {
    background: ${props => props.theme.secondary};
    padding: 10px;
    border: 1px solid ${props => props.theme.primary};
    color: ${props => props.theme.default};
    font-weight: bold;
  }

  .ts_log_tr {
    background: ${props => props.theme.secondary};
    padding: 10px;
    border: 1px solid ${props => props.theme.default};
  }
`;
export const Title = styled.small`
  color: #909090;
  cursor: default;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const StatusContainer = styled.div`
  text-align: center;
  font-size: 18px;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  background: ${props => (props.status === 'Error' ? '#dc3545' : '#28a745')};
  padding: 3px;
  width: 212px;
  height: 35px;
  margin-right: 10px;
`;

export const FolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0px 25px;
  margin-bottom: 10px;
  width: 100%;
`;

export const FolderTitle = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    font-size: 16px;
    margin: 0;
  }
`;

export const FolderButton = styled.div`
  cursor: pointer;
  width: 70px;
  text-align: center;
  background: ${props => (props.open ? props => props.theme.white : props => props.theme.default)};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: ${props => (props.open ? props => props.theme.default : props => props.theme.white)};
  font-size: 16px;
  border: none;
  padding: 3px 8px;
  ::before {
    content: ${props => (props.open ? `"${t(strings.close)}"` : `"${t(strings.open)}"`)};
  }
`;
