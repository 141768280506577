import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: ${props => (props.selected ? '19px' : '20px')};
  margin-right: ${props => (props.selected ? '9px' : '10px')};
  border: ${props => (props.selected ? '1px solid #fff' : 'none')};
  border-radius: 5px;

  button {
    background: none;
    border: none;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  .insideContainer {
    pointer-events: none;
  }
`;
