import React, { useState, useEffect } from 'react';
import LineBar from './LineBar';

import { Container } from './styles';
import { t, strings } from 'language';

function ApiLimits({ data }) {
  const [info, setInfo] = useState({
    actualDay: 10,
    actualMonth: 250,

    maxDay: 100,
    maxMonth: 1000
  });
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const handleSourceChange = value => {
    const itemValues = JSON.parse(value);
    // console.log('valores', itemValues);

    setInfo({
      actualDay: itemValues.dayRequest,
      actualMonth: itemValues.monthRequest,

      maxDay: itemValues.maxDayRequest,
      maxMonth: itemValues.maxMonthRequest
    });
  };
  // console.log('limites', data);
  return (
    <Container>
      {data?.keys.length > 0 && (
        <>
          <h5>{t(strings.select_source)}</h5>
          <select onChange={e => handleSourceChange(e.target.value)}>
            <option>{t(strings.smartVision_platform)}</option>
            {data?.keys.map(item => (
              <option value={JSON.stringify(item)}>API - {item.apiType}</option>
            ))}
          </select>
        </>
      )}

      <h3>{t(strings.limits_inferences)}</h3>
      <h4>{t(strings.daily)}</h4>
      <LineBar max={info.maxDay} actual={info.actualDay} />

      <h4>{t(strings.monthly)}</h4>
      <LineBar max={info.maxMonth} actual={info.actualMonth} />
    </Container>
  );
}

export default ApiLimits;
