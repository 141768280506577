import Header from 'components/Header';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useGlobal from 'store/global';
import { GlobalContainer } from './styles.js';
// import { Switch, Route } from 'react-router-dom'
// import { SmartVisionRouter } from 'routes'

function DefaultLayout(props) {
  const { noContainer, children } = props;
  const auth = useSelector(state => state.auth);
  const [modalActive] = useGlobal(state => state.modalActive);
  const height = window.innerHeight;

  if (!auth.loggedin) window.location.href = '/login';

  return (
    <GlobalContainer id="global_container" style={{ minHeight: height }}>
      <Header noContainer={noContainer} />
      <div className="d-flex flex-row align-items-start justify-content-start">
        <div
          className="w-100"
          id="content"
          style={{ filter: `${modalActive ? 'blur(5px)' : 'none'}` }}
        >
          {children}
        </div>
      </div>
    </GlobalContainer>
  );
}

export default DefaultLayout;
