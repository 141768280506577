import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  margin: 10px;
  padding: 10px;
  background: ${props => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 10px;
  z-index: 110;
`;

export const InnerContainer = styled.div`
  min-width: 100px;
  font-size: 13px;
`;

export const Title = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: #a2a2a7;
  word-break: break-word;
`;

export const TooltipDetails = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: ${props => props.theme.default};
  word-break: break-word;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${props => props.theme.secondary};
`;

export const InnerArrow = styled.div`
  position: absolute;
  top: -10px;
  left: -12px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${props => props.theme.secondary};
`;
