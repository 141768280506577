/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button } from './styles';

function ButtonIcon(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}

export default ButtonIcon;
