/* eslint-disable no-shadow */
import React, { useState, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetSelectImage, SetAnnotation } from 'store/ducks/annotate';
import { deleteTag } from 'services/Api/Dataset/dataset';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { showTooltip } from 'store/ducks/tooltip';
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete_icon.svg';
import { ReactComponent as TagIcon } from 'assets/images/tag_icon.svg';
import { Icon } from 'components/Icon';
import { ThemeContext } from 'styled-components';
import { ReactComponent as AddIcon } from './assets/images/add.svg';
//import { ReactComponent as HideIcon } from './assets/images/eye-off.svg';
import { Container, ContentBox, ContainerButtons } from './styles';
import ButtonIcon from './components/ButtonIcon';

function Content(props) {
  const theme = useContext(ThemeContext);
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const { selectedImage } = annotate;
  // const selectedImage = useSelector(state => state.annotate.selectedImage);
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const { annotation, className, style, activePolygon } = props;
  const { geometry, data } = annotation;
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const dispatch = useDispatch();
  const [, setDisableEditor] = useGlobal(
    state => state.disableEditor,
    actions => actions.setDisableEditor
  );
  const [, setDisableContent] = useGlobal(
    state => state.disableContent,
    actions => actions.setDisableContent
  );
  const [, editingAnnotation] = useGlobal(
    state => state.position,
    actions => actions.editingAnnotation
  );
  const [, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const [, setColor] = useGlobal(
    state => state.color,
    actions => actions.setColor
  );
  const [zoom] = useGlobal(state => state.zoom);
  const [widthDraw] = useGlobal(state => state.widthDraw);
  const [heightDraw] = useGlobal(state => state.heightDraw);
  const [, setTagType] = useGlobal(
    state => state.tagType,
    actions => actions.setTagType
  );
  const testSize = geometry.width * zoom * 10 < 10 || geometry.height * zoom * 10 < 10 ? 1 : 10;
  const padding = geometry.type === 'RECTANGLE' ? testSize : 1;

  const posX = (geometry.x * imgWidth) / 100;
  const screenX = imgWidth * zoom < widthDraw ? widthDraw : imgWidth * zoom;
  const leftOut =
    geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom < 0
      ? (padding * 100) / imgWidth / zoom
      : geometry.x + geometry.width - ((width + padding) * 100) / imgWidth / zoom;
  const left =
    width && posX * zoom + width < screenX
      ? geometry.x + (padding * 100) / imgWidth / zoom
      : leftOut;
  const posY = (geometry.y * imgHeight) / 100;
  const screenY = imgHeight * zoom < heightDraw ? heightDraw : imgHeight * zoom;
  const topOut =
    geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom < 0
      ? geometry.y - ((height + padding) * 100) / imgHeight / zoom
      : geometry.y + geometry.height - ((height + padding) * 100) / imgHeight / zoom;
  const top =
    height && posY * zoom + height < screenY
      ? geometry.y + (padding * 100) / imgHeight / zoom
      : topOut;
  const [polygons] = useGlobal(state => state.polygons);
  const [, setEditablePolygon] = useGlobal(
    state => state.editablePolygon,
    actions => actions.setEditablePolygon
  );
  // const [hideAnnotations, setHideAnnotations] = useGlobal(
  //   state => state.hideAnnotations,
  //   actions => actions.setHideAnnotations
  // );
  const stringEdit = underlineString(t(strings.edit), 'E');
  const stringTag = underlineString(t(strings.tag), 'T');
  const stringInsert = underlineString(t(strings.insert), 'Insert');
  const stringDelete = underlineString(t(strings.delete), 'Del');
  const stringAddDelete = underlineString(t(strings.add_delete), 'A');
  const stringRemoveDelete = underlineString(t(strings.remove_delete), 'R');
  const stringAdd = underlineString(t(strings.add), 'A');
  const stringRemove = underlineString(t(strings.remove), 'R');
  const [, setModal] = useGlobal(
    state => state.modal,
    actions => actions.setModal
  );
  const [, setTitleModal] = useGlobal(
    state => state.titleModal,
    actions => actions.setTitleModal
  );
  const [, setTextModal] = useGlobal(
    state => state.textModal,
    actions => actions.setTextModal
  );
  const [, setDelItem] = useGlobal(
    state => state.delItem,
    actions => actions.setDelItem
  );
  const [tagToDelete, setTagToDelete] = useGlobal(
    state => state.tagToDelete,
    actions => actions.setTagToDelete
  );
  const existAnnotation = tagToDelete.some(
    item => annotation.data.imageTagId === item.data.imageTagId
  );
  const stringAddLessDelete = existAnnotation ? stringRemoveDelete : stringAddDelete;
  const stringAddLess = existAnnotation ? stringRemove : stringAdd;

  if (!geometry) return null;

  function underlineString(string, under) {
    const exist = string.search(under);
    const result = string.replace(under, `[${under}]`);

    const newString = exist > -1 ? result : string;
    return newString;
  }

  function editAnnotate(annotate) {
    const { type } = annotate.geometry;
    const NewAnnotation = annotate;
    const NewGeometry = {};

    setTagType(type);
    setSelectedAnnotate(annotate);
    dispatch(SetAnnotation(annotate));
    setDisableEditor(true);
    setDisableContent(true);
    setColor(data.color);
    NewAnnotation.data.editMode = true;
    switch (type) {
      case 'POINT':
        NewGeometry.x = (geometry.x * imgWidth) / 100;
        NewGeometry.y = (geometry.y * imgHeight) / 100;
        NewGeometry.width = 0;
        NewGeometry.height = 0;
        break;
      default:
        NewGeometry.x = (geometry.x * imgWidth) / 100;
        NewGeometry.y = (geometry.y * imgHeight) / 100;
        NewGeometry.width = (geometry.width * imgWidth) / 100;
        NewGeometry.height = (geometry.height * imgHeight) / 100;
    }
    editingAnnotation(NewGeometry.x, NewGeometry.y, NewGeometry.width, NewGeometry.height);
  }

  function addLessAnnotate(annotate) {
    console.log('addLessAnnotate', annotate);
  }

  async function updatePolygon(selected) {
    const polygon = polygons.find(x => {
      return x.polygonId === selected.data.imageTagId;
    });
    const idx = polygon.geometry.indexOf(selected.geometry);
    const nextPoint = idx + 1 >= polygon.geometry.length ? 0 : idx + 1;
    const newPoint = {
      x: (selected.geometry.x + polygon.geometry[nextPoint].x) / 2,
      y: (selected.geometry.y + polygon.geometry[nextPoint].y) / 2,
      height: 0,
      width: 0,
      type: 'POINT'
    };

    polygon.geometry.splice(idx + 1, 0, newPoint);
  }

  function addNewPoint(annotate) {
    updatePolygon(annotate);
    editAnnotate(annotate);
    const edit = polygons.find(x => {
      return x.polygonId === activePolygon;
    });
    setEditablePolygon(edit);
  }

  function handleDelete(e) {
    setTitleModal(t(strings.confirm_delete_tag));
    setTextModal(`${t(strings.confirm_delete)} "${data.description}" ?`);
    setDelItem({ value: props.annotation, type: 'tag' });
    setModal(true);
    e.preventDefault();
  }

  function editTag(annotate) {
    const annotation = { ...annotate, editTag: true };
    setDisableEditor(true);
    setDisableContent(true);
    setSelectedAnnotate(annotation);
  }

  // function hideAnnotate(annotate) {
  //   const annotates = hideAnnotations;
  //   annotates.push(annotate.data.imageTagId);
  //   setHideAnnotations(annotates);
  //   setSelectedAnnotate([]);
  // }
  function handleAddDelete(e) {
    if (existAnnotation) {
      setTagToDelete(tagToDelete.filter(item => item.data.id !== annotation.data.id));
    } else {
      setTagToDelete([...tagToDelete, annotation]);
    }
    e.preventDefault();
  }

  return (
    <Container
      ref={div}
      style={{
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        transformOrigin: 'top left',
        transform: `scale(${1 / zoom})`,
        zIndex: 10,
        ...style
      }}
      className={className}
      geometry={geometry}
    >
      <ContentBox>
        {data && `${t(strings.content_question)} "${data.description}" ?`}
        <ContainerButtons>
          <ButtonIcon
            style={{ marginRight: '10px', background: theme.primary }}
            onClick={() => editAnnotate(props.annotation)}
          >
            <>
              <Icon name="edit-icon" EditIcon style={{ height: '20px' }} />
              <p
                style={{
                  fontSize: '8px',
                  lineHeight: '10px',
                  color: theme.white
                }}
              >
                {stringEdit}
              </p>
            </>
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: '10px', background: theme.primary }}
            onClick={() => editTag(props.annotation)}
          >
            <>
              <Icon name="tag_icon" style={{ height: '20px' }} />
              <p
                style={{
                  fontSize: '8px',
                  lineHeight: '10px',
                  color: theme.white
                }}
              >
                {stringTag}
              </p>
            </>
          </ButtonIcon>
          {geometry.type === 'POINT' ? (
            <ButtonIcon
              style={{ marginRight: '10px', background: theme.primary }}
              onClick={() => addNewPoint(props.annotation)}
            >
              <>
                <Icon name="add" style={{ height: '20px' }} />
                <p
                  style={{
                    fontSize: '8px',
                    lineHeight: '10px',
                    color: theme.white
                  }}
                >
                  {stringInsert}
                </p>
              </>
            </ButtonIcon>
          ) : (
            ''
          )}
          {/* <ButtonIcon
            style={{ marginRight: '10px' }}
            onClick={() => hideAnnotate(props.annotation)}
          >
            <HideIcon style={{ height: '20px' }} />
            <p style={{ fontSize: '8px', lineHeight: '10px', color: theme.white }}>
              {t(strings.hide)}
            </p>
          </ButtonIcon> */}
          <ButtonIcon
            style={{ marginRight: '10px', background: theme.primary }}
            onClick={e => handleDelete(e)}
          >
            <>
              <Icon name="delete_icon" style={{ height: '20px' }} />
              <p
                style={{
                  fontSize: '8px',
                  lineHeight: '10px',
                  color: theme.white
                }}
              >
                {stringDelete}
              </p>
            </>
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: '10px', background: theme.primary }}
            onClick={e => handleAddDelete(e)}
            onMouseEnter={() => dispatch(showTooltip(stringAddLessDelete))}
            onMouseLeave={() => dispatch(showTooltip(false))}
          >
            <>
              <Icon name="delete_icon" style={{ height: '20px' }} />
              <p
                style={{
                  fontSize: '8px',
                  lineHeight: '10px',
                  color: theme.white
                }}
              >
                {stringAddLess}
              </p>
            </>
          </ButtonIcon>

        </ContainerButtons>
      </ContentBox>
    </Container>
  );
}

Content.defaultProps = {
  style: {},
  className: ''
};

export default Content;
