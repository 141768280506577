import { apiGet, apiPost, apiDelete } from '../index';
import { TypeItem } from 'utils/constants';

export async function getMarkers() {
  return apiGet(`markers?page=1&size=120`);
}
export async function getMarkersByText(name, page, size) {
  return apiGet(`markers?page=${page}&size=${size}&q=${name}`);
}

export async function addMarker(name) {
  return apiPost(`markers/`, {
    name
  });
}

export async function removeMarker(id) {
  return apiDelete(`markers/`, {
    id
  });
}

export async function appendMarker(markerIds, id, type) {
  switch (type) {
    case TypeItem.MODEL:
      return apiPost(`markers/append`, {
        markerIds,
        modelId: id
      });

    case TypeItem.DATASET:
      return apiPost(`markers/append`, {
        markerIds,
        datasetId: id
      });

    case TypeItem.FOLDER:
      return apiPost(`markers/append`, {
        markerIds,
        folderID: id
      });
  }
}

export async function removeAppendMarker(markerIds, id, type) {
  switch (type) {
    case TypeItem.MODEL:
      return apiDelete(`markers/append`, {
        markerIds,
        modelId: id
      });

    case TypeItem.DATASET:
      return apiDelete(`markers/append`, {
        markerIds,
        datasetId: id
      });

    case TypeItem.FOLDER:
      return apiDelete(`markers/append`, {
        markerIds,
        folderID: id
      });
  }
}
