import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { t, strings } from 'language';
import './styles.css';
import { ThemeContext } from 'styled-components';

function LoadingMini() {
  const general = useSelector(state => state.general);
  const theme = useContext(ThemeContext);
  if (!general.loadingMini) return null;

  return (
    <div style={{ paddingLeft: '10px' }}>
      <div className="lds-ripple">
        <div />
        <div />
      </div>
      <span style={{ position: 'relative', top: '-13px', color: theme.white }}>
        {t(strings.saving)}
      </span>
    </div>
  );
}

export default LoadingMini;
