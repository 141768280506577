import React, { useState, useEffect } from 'react';
//import MultSelectSearchable from '../../MultSelectSearchable';
import { Collapse, Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
//import { getTags } from 'services/Api/Tag/tag';
import { setTags, clearFilter, setTrainingStatus } from 'store/ducks/filter';
//import { getMarkersByText } from 'services/Api/Markers';
//import MultiSelectSearchab from '../../MultSelectSearchable';
import MultiSelect from 'components/MultSelect';
import { t, strings } from 'language';


const TrainingStatusFilter = () => {
  const filterState = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const options = [
    { value: 'Created', label: t(strings.created) },
    { value: 'Preparing', label: t(strings.preparing) },
    { value: 'Scheduled', label: t(strings.scheduled) },
    { value: 'Processing', label: t(strings.processing) },
    { value: 'TrainFinish', label: t(strings.training_finish) },
    { value: 'Finished', label: t(strings.finished) },
    { value: 'Failed', label: t(strings.failed) },
    { value: 'Error', label: t(strings.error) }
  ];

  const handleSelect = e => {
    //setSelectedFilters(e);
    // console.log(e);
    dispatch(setTrainingStatus(e));
  };

  return (
    <Container>
      <br />
      <Label>{t(strings.status)}</Label>

      <MultiSelect
        placeholder={t(strings.select)}
        defaultValue={filterState.trainingStatus}
        selected={filterState.trainingStatus}
        onChange={handleSelect}
        options={options}
      />
    </Container>
  );
};

export default TrainingStatusFilter;
