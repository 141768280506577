import styled from 'styled-components';

export const MatrixFooterContainer = styled.div`
  height: 45px;
  width: 100%;
  padding: 5px;
  /* border: 2px gray solid; */
  border-radius: 0px 0px 10px 10px;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.default};
  display: flex;
  flex-direction: row;
`;

export const Text = styled.p`
  color: ${props => props.theme.white};
`;

export const Value = styled.span`
  color: ${props => props.theme.white};
  font-weight: bolder;
`;
