import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import { loading } from 'store/ducks/general';
import { AuthAPI } from 'services/Api/Auth';
import Checkbox from 'components/Checkbox';
import checkboxArrow from 'assets/images/checkbox_arrow.png';
import {
  ImageTitle,
  FormContainer,
  RegisterForm,
  InputContainer,
  CheckboxContainer,
  RegisterButton,
  PasswordValidLabel
} from './styles';

export default function RegisterMember() {
  const [termAccepted, setTermAccepted] = useState(false);
  const [name, setName] = useState('');
  const [invalidName, setInvalidName] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidpasswordMin, setInvalidpasswordMin] = useState(true);
  const [invalidpasswordNumber, setInvalidpasswordNumber] = useState(true);
  const [invalidpasswordLetter, setInvalidpasswordLetter] = useState(true);
  const [enableRegisterButton, setEnableRegisterButton] = useState(false);

  const token = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    dispatch(loading(true));
    token.current = queryString.parse(location.search).token;
    const user = await AuthAPI.tokenInfo(token.current);
    if (user) {
      setEmail(user.email);
    } else {
      toast.error('Token expirado', {
        onClose: () => {
          window.location.href = '/login';
        }
      });
    }
    dispatch(loading(false));
  }, [dispatch, location.search]);

  async function registerUser() {
    dispatch(loading(true));
    try {
      await AuthAPI.updateUserWithToken(token.current, name, password);
      toast.success('Usuario registrado com sucesso!', {
        onClose: () => {
          window.location.href = '/login';
        }
      });
    } catch {
      toast.error('Erro inesperado.');
    }
    dispatch(loading(false));
  }

  useEffect(() => {
    loadData();
  }, [token, location, loadData]);

  function validateName(nameText) {
    if (nameText === '') {
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }
    setName(nameText);
  }

  function validatePassword(passwordText) {
    if (passwordText.length < 8) {
      setInvalidpasswordMin(true);
    } else {
      setInvalidpasswordMin(false);
    }
    if (!/[A-Z]/.test(passwordText)) {
      setInvalidpasswordLetter(true);
    } else {
      setInvalidpasswordLetter(false);
    }
    if (!/\d/.test(passwordText)) {
      setInvalidpasswordNumber(true);
    } else {
      setInvalidpasswordNumber(false);
    }

    setPassword(passwordText);
  }

  useEffect(() => {
    if (
      invalidName ||
      invalidpasswordLetter ||
      invalidpasswordMin ||
      invalidpasswordNumber ||
      termAccepted === false
    ) {
      setEnableRegisterButton(true);
    } else {
      setEnableRegisterButton(false);
    }
  }, [
    enableRegisterButton,
    invalidName,
    invalidpasswordLetter,
    invalidpasswordMin,
    invalidpasswordNumber,
    termAccepted
  ]);
  return (
    <Container className="">
      <Row className="">
        <Col className="d-flex align-items-center">
          <FormContainer>
            <RegisterForm>
              <ImageTitle />
              <p>
                Você foi convidado para ingressar na plataforma, preencha seus dados cadastrais para
                poder acessá-la.
              </p>
              <InputContainer>
                <input
                  type="text"
                  value={name}
                  onChange={e => validateName(e.target.value)}
                  placeholder="Digite o nome e sobrenome"
                />
                {invalidName && <span>campo obrigatório</span>}
              </InputContainer>
              <InputContainer>
                <input type="email" value={email} placeholder="Digite o e-mail" />
              </InputContainer>
              <InputContainer>
                <input
                  type="text"
                  value={password}
                  onChange={e => validatePassword(e.target.value)}
                  placeholder="Digite a senha"
                />
                <PasswordValidLabel invalid={invalidpasswordMin}>
                  senha deve conter pelo menos 8 caracteres
                  <img src={checkboxArrow} alt="check" />
                </PasswordValidLabel>
                <PasswordValidLabel invalid={invalidpasswordNumber}>
                  conter pelo menos 1 número
                  <img src={checkboxArrow} alt="check" />
                </PasswordValidLabel>
                <PasswordValidLabel invalid={invalidpasswordLetter}>
                  conter pelo menos uma 1 letra maiúscula
                  <img src={checkboxArrow} alt="check" />
                </PasswordValidLabel>
              </InputContainer>
              <CheckboxContainer onClick={() => setTermAccepted(!termAccepted)}>
                <Checkbox id="term" checked={termAccepted} />
                <span>Concordo com os termos</span>
              </CheckboxContainer>

              <RegisterButton disabled={enableRegisterButton} onClick={() => registerUser()}>
                Cadastrar
              </RegisterButton>
            </RegisterForm>
          </FormContainer>
        </Col>
      </Row>
    </Container>
  );
}
