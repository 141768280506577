import React from 'react';
import background from 'assets/images/register_bg.png';

function HeadlessLayout(props) {
  return (
    <div
      id="global_container"
      className="d-flex justify-content-center align-items-center"
      style={{
        background: `url(${background}) no-repeat center`,
        height: '100%',
        overflowY: 'scroll',
        backgroundSize: 'cover'
      }}
    >
      {props.children}
    </div>
  );
}

export default HeadlessLayout;
