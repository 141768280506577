import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTraining } from 'services/Api/Training';
import { clearTraining } from 'store/ducks/training';
import { loading } from 'store/ducks/general';

import Dialog from 'components/Dialog';
import { t, strings } from 'language';
import { Container } from './styles';
import SelectFolder from './selectFolder';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

export default function NewTraining(props) {
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);

  const [step, setStep] = useState(0);
  const [title, setTitle] = useState('');
  const { modal, setTrainingModal } = props;

  const changeStep = useCallback(
    async step => {
      switch (step) {
        case 1:
          setTitle(t(strings.train_step_1));
          setStep(1);
          break;
        case 2:
          if (training.selectedTraningDatasets.length > 0) {
            setTitle(t(strings.train_step_2));
            setStep(2);
          } else {
            toast.error(t(strings.select_folder));
          }
          break;
        case 3:
          setTitle(t(strings.train_step_3));
          setStep(3);
          break;
        case 4:
          if (training.selectedTags.length === 0) {
            toast.error(t(strings.select_one_tag));
            break;
          } else {
            const traningData = {
              modelId: training.model.id,
              traningDatasetIds: training.selectedTraningDatasets.map(i => i.id),
              evalDatasetIds: training.selectedEvalDatasets.map(i => i.id),
              steps: training.steps,
              tagIds: training.selectedTags.map(i => i.id),
              machineGroup: training.machineGroup
            };
            dispatch(loading(true));
            await createTraining(traningData);
            changeStep(1);
            setTrainingModal(false);
            dispatch(clearTraining());
            dispatch(loading(false));
            break;
          }
        default:
          break;
      }
    },
    [dispatch, setTrainingModal, training]
  );

  const getDialogButtons = useCallback(() => {
    let buttons = [
      {
        text: t(strings.cancel),
        action: () => {
          changeStep(1);
          setTrainingModal(false);
          dispatch(clearTraining());
        }
      },
      {
        text: step === 3 ? t(strings.train) : t(strings.next),
        action: () => {
          changeStep(step + 1);
        },
        gold: true
      }
    ];

    if (step !== 1) {
      buttons.splice(1, 0, {
        text: t(strings.previous),
        action: () => {
          changeStep(step - 1);
        }
      });
    }
    return buttons;
  }, [step, changeStep, dispatch, setTrainingModal]);

  useEffect(() => {
    if (step === 0) changeStep(1);
  }, [step, changeStep]);

  return (
    <Container>
      {training.openTrainingFolderSelector && (
        <SelectFolder nextStep={() => changeStep(step + 1)} />
      )}
      {training.openEvalFolderSelector && (
        <SelectFolder eval={true} nextStep={() => changeStep(step + 1)} />
      )}
      <Dialog title={title} open={modal} width={'100%'} smallButtons actions={getDialogButtons()}>
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
      </Dialog>
    </Container>
  );
}
