import React, { useState, useEffect } from 'react';
import { Container, ContainerLogin } from './styles';
import SVInputText from 'components/Form/SVInputText';
import SVButton from 'components/Form/SVButton';
// import { login } from 'services/API/Auth/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Login } from 'store/ducks/auth';
import { t, strings } from 'language';

function LoginPage(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let expired = params.get('expired');
    if (expired) {
      toast.error(t(strings.logn_error));
    }
  }, []);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      submit();
    }
  }

  async function submit() {
    dispatch(Login({ username, password }));
  }
  return (
    <Container
      className="d-flex align-items-center justify-content-center  flex-column"
      style={{ height: '100vh' }}
    >
      <img
        src={require('assets/images/logo_full.svg')}
        style={{ height: 50 }}
        alt="SmartVisionAi"
      />
      <span>&nbsp;</span>
      <ContainerLogin className="d-flex align-items-center justify-content-center">
        <form action="" className="w-100">
          <SVInputText
            type="text"
            id="login-email"
            name="email"
            placeholder={t(strings.user)}
            label={t(strings.user)}
            onChange={e => setUsername(e.target.value)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <SVInputText
            type="password"
            name="password"
            placeholder="********"
            label={t(strings.password)}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => handleKeyDown(e)}
          />

          <SVButton type="button" className="btn-login text-center" onClick={submit}>
          {t(strings.enter)}
          </SVButton>
        </form>
      </ContainerLogin>
    </Container>
  );
}
export default LoginPage;
