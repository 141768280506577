import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.primary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 55px;
  padding-top: 25px;
  a {
    display: flex;
  }
  h2 {
    color: ${props => props.theme.white};
  }

  .notificationContainer {
    overflow-y: scroll;
    height: 100%;
    margin: 17px;

    padding-bottom: 50px;
  }
`;

export const CloseButton = styled.a`
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  position: absolute;
  left: -60px;
  top: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: ${props => (props.visible ? 'flex !important' : 'none!important')};
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;
