import styled from 'styled-components';

import ImageFallback from 'components/ImageFallback';

export const Container = styled.div`
  /* margin-bottom: 20px;
  margin-right: 10px; */
`;
export const ImageContainer = styled.div`
  position: relative;
`;
export const InternalContainer = styled.div`
  width: 195px;
`;
export const ContainerTop = styled.div`
  background: ${props => props.theme.secondary};
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
`;
export const Name = styled.div`
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.default};
  }

  span {
    color: ${props => props.theme.text};
    width: 100%;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

export const Image = styled(ImageFallback)`
  border: 1px solid #707070;
  width: 100%;
  height: 77px;
`;

export const ImageHasTag = styled(ImageFallback)`
  height: 15px;
  position: absolute;
  top: 5px;
  left: 5px;
`;
