import styled from 'styled-components';
import target from './assets/images/imageTag.png';

export const Container = styled.div`
  background: ${props => props.theme.primary};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 10px;
  white-space: nowrap;
  display: flex;
`;

export const InputText = styled.input`
  background-color: ${props => props.theme.secondary};
  background-image: url(${target});
  background-position: 3px 3px;
  background-repeat: no-repeat;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 5px;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  padding-left: 40px;
  color: ${props => props.theme.white};

  &:focus {
    outline: none;
  }
`;

export const Options = styled.div`
  background-color: ${props => props.theme.secondary};
  max-height: 200px;
  overflow: auto;
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 80%;
  font-weight: 400;
  ${'' /* margin-top: -10px; */}
  margin-left: 10px;
  z-index: 2;
`;

export const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${props => props.theme.white};
  &:hover {
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.white};
  }
`;
