/* eslint-disable no-template-curly-in-string */
import React from 'react';

import { t, strings } from 'language';
import { Container, Table, GaugeBG, GaugeValue } from './styles';

function ByClassChart({ data, labels, precisions, recalls }) {
  const chartData = labels.map(label => {
    const total1 = data
      .filter(i => i.x === label && i.y === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);
    const total2 = data
      .filter(i => i.x === label && i.y !== label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);
    const total3 = data
      .filter(i => i.x !== label && i.y === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);

    // varia coluna
    const precision = precisions.filter(i => i.name === label)[0].value;
    const recall = recalls.filter(i => i.name === label)[0].value;

    const column = data.filter(i => i.y === label && i.x !== 'FN' && i.x !== 'FP');

    const total = column.map(i => i.value).reduce((a, b) => a + b, 0);

    //const multiplier = total / 100;

    const biggestConfusions = column
      .filter(i => i.x !== label)
      .map(value => ({
        label: value.x,
        value: value.value,
        percent: (value.value / total) * 100 //* multiplier
      }))
      .sort((a, b) => (a.value < b.value ? 1 : -1));

    return {
      class: label,
      data,
      elements: total1 + total2 + total3,
      precision,
      recall,
      biggestConfusions
    };
  });
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>{t(strings.class)}</th>
            <th>{t(strings.elements)}</th>
            <th>{t(strings.precision)}</th>
            <th>{t(strings.recall)}</th>
            <th>{t(strings.recall)}</th>
            <th>{`${t(strings.biggest_confusions)} (&gt;5%)`}</th>
          </tr>
        </thead>
        <tbody>
          {chartData.map(i => (
            <tr key={i.class}>
              <td>{i.class}</td>
              <td>{i.elements}</td>
              <td>
                <GaugeBG
                  bgColor="${props => props.theme.default}"
                  color="white"
                  value={i.precision}
                />
                <GaugeValue>{`${i.precision} %`}</GaugeValue>
              </td>
              <td>
                <GaugeBG bgColor="${props => props.theme.default}" color="white" value={i.recall} />
                <GaugeValue>{`${i.recall} %`}</GaugeValue>
              </td>
              <td>
                {i.biggestConfusions
                  .filter(biggestConfusion => biggestConfusion.percent > 5)
                  .slice(0, 3)
                  .map(biggestConfusion => (
                    <span className="mr-2">{`${biggestConfusion.label} `} </span>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ByClassChart;
