import React from 'react';
import Matrix from './Matrix';
import MatrixByClass from './MatrixByClass';

// import { Container } from './styles';

function components({ matrix, byClass }) {
  const data = matrix;
  const labels = [...new Set(data.filter(i => i.x !== 'FP' && i.y !== 'FN').map(i => i.x))].sort(
    function(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    }
  );

  const precisions = labels.map(label => {
    return {
      name: label,
      value: CalcPrecision(label)
    };
  });
  
  const recalls = labels.map(label => {
    return {
      name: label,
      value: CalcRecall(label)
    };
  });

  function CalcPrecision(label) {
    const value1 = data.filter(i => i.y === label && i.x === label)[0].value;
    const sum = data
      .filter(i => i.y === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);
    if (sum === 0) return 0;

    return ((value1 / sum) * 100).toFixed(1);
  }

  function CalcRecall(label) {
    const value1 = data.filter(i => i.y === label && i.x === label)[0].value;
    const sum = data
      .filter(i => i.x === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);
    if (sum === 0) return 0;

    return ((value1 / sum) * 100).toFixed(1);
  }

  function CalcF1(label, label2) {
    const tp = data.filter(i => i.x === label && i.y === label2)[0].value;

    const fp = data
      .filter(i => (i.y === label || i.y === label2) && i.x === 'FP')
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);

    const fn = data
      .filter(i => i.y === 'FN' && i.x === (i.x === label || i.x === label2))
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);

    return tp / (tp + 0.5 * (fp + fn));
  }

  const datap = matrix.map(i => ({ ...i, f1: CalcF1(i.x, i.y) }));
  // console.log(datap);

  if (byClass)
    return <MatrixByClass data={datap} labels={labels} precisions={precisions} recalls={recalls} />;
  return <Matrix data={datap} labels={labels} precisions={precisions} recalls={recalls} />;
}

export default components;
