import React from 'react';
import shortid from 'shortid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './styles.css';
import { Modal } from './styles';

export default function Dialog(props) {
  const { title, children, actions, smallButtons, open, width, close } = props;
  if (!open) return <></>;

  return (
    <Modal isOpen={open} className="sv_modal" style={{ width, maxWidth: width }} centered>
      <div className="container" style={{ width, maxWidth: width }}>
        {close && (
          <button
            type="button"
            href=""
            className="close_btn"
            onClick={() => {
              close();
            }}
          >
            x
          </button>
        )}

        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter className={smallButtons && 'justify-content-end'}>
          {actions &&
            actions.map(item => {
              return (
                <Button
                  key={shortid.generate()}
                  size="sm"
                  onClick={item.action}
                  className={`${item.gold && 'goldButton'} ${smallButtons && 'w-auto'}`}
                >
                  {item.text}
                </Button>
              );
            })}
        </ModalFooter>
      </div>
    </Modal>
  );
}
