import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import { connect, handleTraining } from 'services/SignalR/TrainingHub';
import { getTraningDetails } from 'services/Api/Training';

import { t, strings } from 'language';
import useGlobal from 'store/global';
import useDimensions from 'react-use-dimensions';
import TrainingSession from './HistoricDetails';

import { Container, Link, ProgressLoad, ProgressBar } from './styles';
import { Icon } from 'components/Icon';

function HistoricTrainingItem(props) {
  const { data } = props;
  const [modal, setModal] = useState(false);
  const [connection, setConnection] = useState(null);
  const [percent, setPercent] = useState(0);
  const [lastStep, setLastStep] = useState(0);
  const [actualStep, setActualStep] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [ref, { x }] = useDimensions();
  const [traningDetails, setTraningDetails] = useState(null);
  const [, setShowToolTip] = useGlobal(
    state => state.showToolTip,
    actions => actions.setShowToolTip
  );

  useEffect(() => {
    if (connection) {
      handleTraining(connection, data.trainigSessionId, printLog);
      // console.log('tem conexao', data);
      getTrainingDetails();
    }
  }, [connection]);

  useEffect(() => {
    // console.log('lastStepEffect', lastStep);
    setActualStep(lastStep);
    calcStepsPercent(lastStep);
  }, [lastStep]);

  async function getTrainingDetails() {
    const traning = await getTraningDetails(data.modelId, data.trainigSessionId);
    // console.log('detalhes', traning, traning.lastStepInfo.log);

    if (traning.lastStepInfo.log) {
      const logObj = JSON.parse(traning.lastStepInfo.log);
      setLastStep(logObj.message.step_num);
      if (logObj.message && logObj.message.step_num) {
        setStatusMessage(`Passo atual :${logObj.message.step_num}`);
        calcStepsPercent(logObj.message.step_num);
      }
    } else {
      setLastStep(traning.startStep);
      calcStepsPercent(traning.startStep);
    }

    setTraningDetails(traning);
  }

  const printLog = dataLog => {
    const dataReturn = JSON.parse(dataLog);
    //console.log(dataReturn);

    if (dataReturn.type === 'step_info') {
      //console.log(dataReturn.message.step_num);
      setActualStep(dataReturn.message.step_num);
      setStatusMessage(`Passo atual :${dataReturn.message.step_num}`);
      calcStepsPercent(dataReturn.message.step_num);
    }

    if (dataReturn.type === 'status_info') {
      //console.log(dataReturn.message);
      setStatusMessage(dataReturn.message);
    }
  };

  const calcStepsPercent = actual => {
    //console.log(lastStep, data.steps, actual);
    let calc = ((actual - lastStep) / (data.steps - lastStep)) * 100;
    //console.log(calc);

    //pois é... nunca vai chegar a 100.
    if (calc > 99) {
      calc = 99;
    }

    setPercent(calc);
    return calc;
  };

  useEffect(() => {
    async function connectHub() {
      const hubConnection = await connect();
      setConnection(hubConnection);
    }
    //para conectar apenas se tiver um treinamento em andamento.
    if (data.status === 'Processing') {
      connectHub();
    }
  }, []);

  function openDetatils(event) {
    setModal(true);
    event.preventDefault();
  }

  function closeDetatils() {
    setModal(false);
  }

  function showTooltip(title, text, datails) {
    const left = x - 12;
    const tooltip = { left, title, text, tags: datails };
    setShowToolTip(tooltip);
  }

  function hideTooltip() {
    const tooltip = { left: -1000, title: '', text: '', tags: '' };
    setShowToolTip(tooltip);
  }

  function showStatus() {
    const title = `${t(strings.progress)}`;
    const text = `Processando: ${percent.toFixed(0)}%`;
    const datails = <div>{statusMessage}</div>;

    switch (data.status) {
      case 'Processing':
        return (
          <>
            <p>
              <Icon name={'modelIcon'} className={data.status === 'Processing' ? 'started' : ''} />
              <span>
                <Moment local utc format="DD/MM/YYYY HH:mm">
                  {data.startedAt}
                </Moment>
              </span>
            </p>
            <p>
              <ProgressBar
                ref={ref}
                onMouseOver={() => showTooltip(title, text, datails)}
                onFocus={() => showTooltip(title, text, datails)}
                onMouseLeave={() => hideTooltip()}
              >
                <span>{`${percent.toFixed(0)}%`}</span>
                <ProgressLoad progress={percent.toFixed(0)} />
              </ProgressBar>
            </p>
          </>
        );
      case 'Finished':
      case 'Failed':
      case 'Error':
        return (
          <>
            <p className="iconText">
              <Icon
                width="20"
                name={'modelIcon'}
                style={{ marginRight: 5 }}
                className={data.status === 'Processing' ? 'started' : ''}
              />
              <span>
                <Moment utc format="DD/MM/YYYY HH:mm">
                  {data.finishedAt}
                </Moment>
              </span>
            </p>
            <p>{`${t(strings.status)}: ${data.status}`}</p>
          </>
        );
      default:
        return (
          <>
            <p>
              <Icon name={'modelIcon'} className={data.status === 'Processing' ? 'started' : ''} />
              <span>
                <Moment format="DD/MM/YYYY HH:mm">{data.startedAt}</Moment>
              </span>
            </p>
            <p>{`${t(strings.status)}: ${data.status}`}</p>
          </>
        );
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={7}>{showStatus()}</Col>
          <Col xs={5}>
            <p>{`${data.steps} ${t(strings.steps)}`}</p>
            <Link href="#" onClick={e => openDetatils(e)}>{`${t(strings.show_details)}`}</Link>
          </Col>
        </Row>
      </Container>
      <TrainingSession
        open={modal}
        data={data}
        closeDetatils={closeDetatils}
        hubConnection={connection}
      />
    </>
  );
}
export default React.memo(HistoricTrainingItem);
