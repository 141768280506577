import styled from 'styled-components';

import lupa from './assets/images/search.svg';
export const Container = styled.div`
  margin-bottom: 10px;
`;
export const ContainerInput = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 8px;
    top: 8px;
  }
`;
export const Input = styled.input`
  background: ${props => props.theme.secondary};

  border: 1px solid #707070;
  color: ${props => props.theme.white};
  padding: 10px 30px;
  border-radius: 5px;
  width: 100%;
  padding-left: 45px;
  background-size: 20px;

  ::placeholder {
    color: ${props => props.theme.secondary};
  }
`;

export const Image = styled.image`
  width: 30px;
  height: 30px;
`;

export const NewImageButton = styled.button`
  width: 48px;
  height: 47px;
  background: ${props => props.theme.secondary} 0% 0% no-repeat padding-box;
  /* background-image: url(${props => props.background}); */
  background-position: center center;
  background-size: 30px;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  :focus {
    outline: none;
  }
`;
export const ImageCount = styled.p`
  font-size: 12px;
  color: ${props => props.theme.text};
  width: 100%;
  color: ${props => props.theme.white};
  margin-left: 17px;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;
