import styled from 'styled-components';

export const ChartContainer = styled.div`
  height: ${props => props.height};
  background-color: ${props => props.theme.secondary};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
`;

export const SelectMetric = styled.select`
  margin-left: 10px;
  background-color: ${props => props.theme.primary} !important;
  height: 37px;
  line-height: 37px;
  outline: none;
  background: ${props => props.theme.secondary};
  border: 1px solid black;
  display: block;
  padding: 8px 25px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 16px;
  & > option {
    background-color: ${props => props.theme.primary} !important;
    height: 37px;
    line-height: 37px;
    outline: none;
    background: ${props => props.theme.secondary};
    font-size: 16px;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 60px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
