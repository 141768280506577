import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Col = styled.div`
  display: flex;
`;

export const Label = styled.small`
  width: 85px;
  color: ${props => props.theme.white};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.small`
  color: ${props => props.theme.white};
  cursor: default;
`;

export const CarouselDiv = styled.div`
  border: 1px solid ${props => props.theme.white};
  padding: 10px;
`;

export const ShowLink = styled.a`
  color: ${props => props.theme.white};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.white};
  }
`;

export const Input = styled.input`
  background: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.white};
  height: 25px;
  width: 60px;
  color: ${props => props.theme.default};
  font-size: 14px;
  text-align: center;
  ::placeholder {
    color: ${props => props.theme.default};
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 8px;

  & > input:focus {
    outline: none;
  }
`;
