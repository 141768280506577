import React from 'react';

function TagType(props) {
  const { type, color } = props;

  switch (type) {
    case 'Class':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27.438"
          height="16"
          viewBox="0 0 27.438 16"
          style={{ float: 'left', width: '16px', height: '16px' }}
        >
          <g transform="translate(-49.621 -456.297)">
            <g fill="none" transform="translate(49.621 456.297)">
              <path stroke="none" d="M0,0H21.353l6.084,8.4L21.353,16H0Z" />
              <path
                fill="${props => props.theme.white}"
                d="M 1 1 L 1 15 L 20.87292098999023 15 L 26.18077659606934 8.373663902282715 L 20.84257125854492 1 L 1 1 M 0 0 L 21.3531608581543 0 L 27.4375 8.404300689697266 L 21.3531608581543 16 L 0 16 L 0 0 Z"
              />
            </g>
            <g fill="none" transform="translate(69 462)">
              <circle stroke="${props => props.theme.white}" cx="2" cy="2" r="2" />
            </g>
          </g>
        </svg>
      );
    case 'Polygon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30.318"
          height="28.576"
          viewBox="0 0 30.318 28.576"
          style={{ float: 'left', width: '16px', height: '16px' }}
        >
          <path
            fill={color}
            stroke={color}
            d="M4511.008,24140.545l4.319-11.785-11.292-3.666,24.06-8.719-2.934,10.766,6.479,15.5-8.481-6.4-3.994,4.3Z"
            transform="translate(-4502.496 -24115.576)"
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          style={{ float: 'left', width: '16px', height: '16px' }}
        >
          <g fill={color} stroke={color}>
            <rect stroke={color} width="22" height="22" />
            <rect fill={color} x="0.5" y="0.5" width="21" height="21" />
          </g>
        </svg>
      );
  }
}

export default TagType;
