/* eslint-disable no-param-reassign */
import React from 'react';
import { t, strings } from 'language';
import { chain } from 'lodash';
import shortid from 'shortid';

function returnTags(item) {
  const { tags } = item;

  const selectedImageTags = chain(tags)
    .groupBy('type')
    .map(value =>
      chain(value)
        .groupBy('tagId')
        .map((value, key) => ({
          tagId: key,
          name: value[0].tag,
          color: value[0].color,
          count: value.length,
          type: value[0].type,
          tags: value
        }))
        .value()
    )
    .value();

  let results;
  if (selectedImageTags) {
    results = selectedImageTags.map(type => {
      return type.map((tag, index) => {
        const total = tag.count;
        let tagLine = total;
        tagLine = total > 1 ? `${tagLine} ${t(strings.tags)}` : `${tagLine} ${t(strings.tag)}`;
        tagLine = `${tagLine} ${tag.name}`;
        if (index === 0) {
          return (
            <div key={shortid.generate()}>
              <br />
              <b>{`${tag.type}`}</b>
              <li>{tagLine}</li>
            </div>
          );
        }
        return (
          <div key={shortid.generate()}>
            <li>{tagLine}</li>
          </div>
        );
      });
    });
  }

  return results;
}

export default returnTags;
