import React from 'react';
import { Container } from 'reactstrap';
import AnnotateDrawer from './components/AnnotateDrawer';

function Editor(props) {
  const { dataset, size } = props;
  const { height } = size;

  return (
    <Container
      className="mh-100 mw-100 scrollbar no-margin"
      style={{
        height: `${height - 246}px`,
        overflow: 'auto'
      }}
    >
      <AnnotateDrawer dataset={dataset} size={size} />
    </Container>
  );
}

export default Editor;
