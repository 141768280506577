import React, { useState, useEffect } from 'react';
import 'isomorphic-fetch';
import useGlobal from 'store/global';
import hexToRgba from 'hex-to-rgba';
// import Point from '../Point';
// import { TagName } from './styles';

function Polygon(props) {
  const { polygon, polygons, img, zoom, selectedAnnotate } = props;
  const [svgPolygon, setSvgPolygon] = useState([]);
  const [svgPolygons, setSvgPolygons] = useState([]);
  const [svgPoints, setSvgPoints] = useState([]);
  const newArray = [];
  const newArrayPoint = [];
  const [activePolygon] = useGlobal(state => state.activePolygon);
  const [, setEditablePolygon] = useGlobal(
    state => state.editablePolygon,
    actions => actions.setEditablePolygon
  );
  // eslint-disable-next-line
  const [tagToDelete, setTagToDelete] = useGlobal(
    state => state.tagToDelete,
    actions => actions.setTagToDelete
  );

  function makePolygon(tag, origin) {
    const polygonId = tag.polygonId === -1 ? 0 : tag.polygonId;
    const imageTagId = selectedAnnotate.data && selectedAnnotate.data.imageTagId;
    const selected = selectedAnnotate.geometry && origin && imageTagId === polygonId;
    const edit = selectedAnnotate.geometry && !selected && imageTagId === polygonId;
    const points =
      tag.geometry &&
      tag.geometry.map(obj => {
        const x = (obj.x * img.width) / 100;
        const y = (obj.y * img.height) / 100;
        if (edit) {
          const active =
            selectedAnnotate.geometry &&
            selectedAnnotate.geometry.x === obj.x &&
            selectedAnnotate.geometry.y === obj.y;
          makePoints(x, y, tag.data.color, active);
        }
        if (selected) {
          const active =
            selectedAnnotate.geometry &&
            selectedAnnotate.geometry.x === obj.x &&
            selectedAnnotate.geometry.y === obj.y;
          makePoints(x, y, tag.data.color, active);
        }
        return `${x},${y}`;
      });
    const percent = polygonId === 0 || activePolygon === polygonId ? 0.8 : 0.5;
    const style = selected
      ? {
          fill: hexToRgba(`${tag.data.color}`, 0),
          strokeDasharray: `${100 / (zoom * 30)},${100 / (zoom * 30)}`,
          stroke: `${tag.data.color}`,
          strokeWidth: `${100 / (zoom * 30)}`
        }
      : {
          // fill: hexToRgba(`${tag.data.color}`, `${percent}`),
          fill: tagToDelete.some(item => tag.polygonId === item.data.imageTagId)
            ? 'rgba(255, 255, 255, 0.8)'
            : hexToRgba(`${tag.data.color}`, `${percent}`),
          stroke: `${tag.data.color}`,
          strokeWidth: `${100 / (zoom * 30)}`
        };
    const newPolygon = (
      <polygon key={edit ? `edit${polygonId}` : polygonId} points={points} style={style} />
    );

    if (polygonId === 0) {
      setSvgPolygon(newPolygon);
    } else {
      newArray.push(newPolygon);
      setSvgPolygons(newArray);
    }
  }

  function makePoints(x, y, color, active) {
    const size = 100 / (zoom * 50);
    const radius = 4 * size;
    const newPoint = (
      <circle
        cx={x}
        cy={y}
        r={radius}
        stroke={color}
        strokeWidth={size}
        fill={hexToRgba(`${color}`, `${active ? 1 : 0}`)}
      />
    );

    newArrayPoint.push(newPoint);
    setSvgPoints(newArrayPoint);
  }

  useEffect(() => {
    setSvgPolygon([]);
    setSvgPolygons([]);
    setSvgPoints([]);
    makePolygon(polygon);
    // eslint-disable-next-line
    polygons.map(obj => {
      // console.log('obj', obj);
      // console.log('tagToDelete', tagToDelete);
      makePolygon(obj, 1);
    });
    if (selectedAnnotate.geometry && activePolygon > 0) {
      const edit = polygons.find(x => {
        return x.polygonId === activePolygon;
      });
      setEditablePolygon(edit);
    }
    // eslint-disable-next-line
  }, [polygon, polygons, zoom, img, activePolygon, selectedAnnotate, tagToDelete]);

  return (
    <svg
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 2,
        width: img && img.width,
        height: img && img.height
      }}
    >
      {svgPolygon}
      {svgPolygons}
      {svgPoints}
    </svg>
 );
}

export default Polygon;
