export const Types = {
  SET_FILTER: 'loading/SET_FILTER',
  CLEAR_FILTER: 'loading/CLEAR_FILTER'
};

const INITIAL_STATE = {
  tags: [],
  markers: [],
  orderby: '',
  withoutTags: false,
  search: '',
  searchTotal: '',
  tagType: [],
  networkType: [],
  trainingStatus: []
};

export function setOrderBy(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'orderby',
      value: value
    }
  };
}
export function setTags(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'tags',
      value: value
    }
  };
}
export function setTagType(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'tagType',
      value: value
    }
  };
}
export function setNetworkType(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'networkType',
      value: value
    }
  };
}
export function setTrainingStatus(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'trainingStatus',
      value: value
    }
  };
}
export function setSearchTotal(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'searchTotal',
      value: value
    }
  };
}
export function setSearch(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'search',
      value: value
    }
  };
}
export function setMarkers(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'markers',
      value: value
    }
  };
}
export function setWithoutTags(value) {
  return {
    type: Types.SET_FILTER,
    payload: {
      label: 'withoutTags',
      value: value
    }
  };
}
export function clearFilter() {
  return {
    type: Types.CLEAR_FILTER,
    payload: {}
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  let { type, payload } = action;
  switch (type) {
    case Types.SET_FILTER:
      return { ...state, [payload.label]: payload.value };
    case Types.CLEAR_FILTER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
