import React from 'react';
import { Row, Col } from 'reactstrap';
import { t, strings } from 'language';
import { LabelContainer } from './styles';

export default function(props) {
  const { tag, count } = props;
  return (
    <>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.name)}:`}</label>
            <p>{tag.description}</p>
          </LabelContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelContainer>
            <label>{`${t(strings.total)}:`}</label>
            <p>{`${count} ${t(strings.tags)}`}</p>
          </LabelContainer>
        </Col>
      </Row>
    </>
  );
}
