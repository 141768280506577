import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import shortid from 'shortid';
import { t, strings } from 'language';
import { connect } from 'services/SignalR/TrainingHub';
import InferenceSession from 'pages/Models/components/Historic/InferenceSession';
import GraphBar from './GraphBar';
import './styles.css';

function LogItens(props) {
  const { data } = props;
  const [modal, setModal] = useState(false);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    async function connectHub() {
      const hubConnection = await connect();
      setConnection(hubConnection);
    }
    if (data.status === t(strings.processing)) {
      connectHub();
    }
  }, []);

  function openDetatils(event) {
    setModal(true);
    event.preventDefault();
  }

  function closeDetatils() {
    setModal(false);
  }
  return (
    <>
      <Row
        style={{ width: '100%', cursor: 'pointer', marginLeft: '0px' }}
        onClick={e => openDetatils(e)}
      >
        <Col className="ts_log_tr">
          <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
            {data.startAt}
          </Moment>
        </Col>
        <Col className="ts_log_tr">
          <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
            {data.finishedAt}
          </Moment>
        </Col>
        <Col
          className="ts_log_tr"
          style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}
        >
          <GraphBar
            data1={data.totalImagesWithInference}
            data2={data.totalImagesWithoutInference}
            unit1={t(strings.with_inference).toLowerCase()}
            unit2={t(strings.without_inference).toLowerCase()}
          />
        </Col>
        <Col className="ts_log_tr">
          {data.tags && data.tags.reduce((totalTags, myTag) => totalTags + myTag.count, 0) + ' '}
          {t(strings.tags).toLowerCase()}
        </Col>
        <Col className="ts_log_tr">{data.checkpoint.machineGroup}</Col>
        <Col className="ts_log_tr">{data.confidence}</Col>
        <Col className="ts_log_tr">{data.status}</Col>
      </Row>
      <InferenceSession
        key={shortid.generate()}
        open={modal}
        data={data}
        closeDetatils={closeDetatils}
        hubConnection={connection}
      />
    </>
  );
}

export default LogItens;
