import { apiPost, apiGet, apiPut } from '../index';

export async function login(body) {
  return apiPost('Users/login', body, false);
}
export async function me() {
  return apiGet('Users/me');
}

export async function tokenInfo(token) {
  return apiGet(`Users/token/${token}`);
}
export const updateUserWithToken = async (token, name, password) => {
  return apiPut(`Users/token/${token}`, { name, password });
};
export const updateUserAvatar = async avatar => {
  return apiPut(`Users/avatar/`, { avatar });
};

export const updateUserPushToken = async token => {
  return apiPut(`Users/pushtoken/${token}`);
};

export async function getUsers() {
  return apiGet(`Users/?size=100&page=1`);
}
export async function changeRole(id, role) {
  return apiPut(`Users/${id}/role`, {
    role: role
  });
}
export async function changeStatus(id) {
  return apiPut(`Users/${id}/active`);
}
