import styled from 'styled-components';
import logoText from 'assets/images/logo_text.png';

export const MainContainer = styled.div`
  background-color: ${props => props.theme.white};
  height: 100%;
`;

export const FormContainer = styled.div`
  max-height: 723px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const RegisterForm = styled.div`
  width: 441px;
  height: 100%;
  background: ${props => props.theme.secondary} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 52px 50px;

  & * {
    margin-bottom: 36px;
  }
  & p {
    font: 16px;
    color: #a2a2a7;
  }
`;

export const ImageTitle = styled.div`
  background-image: url(${logoText});
  width: 210px;
  height: 25px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & * {
    margin-bottom: 0;
  }
  & input {
    background-color: transparent;
    border: none;
    border-bottom-color: ${props => props.theme.white};
    border-bottom-width: 1px;
    border-bottom-style: solid;
    outline: none;
    color: ${props => props.theme.white};
    max-width: 317px;
  }
  & span {
    font-size: 12px;
    color: #c45e3f;
  }
`;

export const PasswordValidLabel = styled.span`
  transition: all 0.3s;
  margin-top: 15px;
  color: ${props => (props.invalid ? '#c45e3f' : '#358f9a')} !important;
  & img {
    display: ${props => (props.invalid ? 'none' : 'initial')};
    height: 8px;
    margin-left: 2px;
    padding: 0 8px;
  }
`;

export const CheckboxContainer = styled(InputContainer)`
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-bottom: 56px;

  & span {
    margin-left: 14px;
    color: ${props => props.theme.white};
    font-size: 16px;
    cursor: pointer;
  }
`;

export const RegisterComponent = styled.div`
  height: 100%;
`;

export const RegisterButton = styled.button`
  transition: all 0.3s;
  width: 100%;
  height: 47px;
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 16px;
  color: ${props => props.theme.secondary};
  :disabled {
    opacity: 0.5;
  }
`;
