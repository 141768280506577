import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import img1 from './assets/app_f2.png';
import img2 from './assets/logo_fu2re_fundo_escuro.png';

import { Container, ImageDefault, ImageHover } from './styles';

export default function F2Triangle(props) {
  const { height = 80, width = height * 2 } = props;
  const [flipped, setFlipped] = useState(false);
  return (
    <Container
      width={width}
      height={height}
      onMouseEnter={() => setFlipped(true)}
      onMouseLeave={() => setFlipped(false)}
    >
      <ReactCardFlip
        containerStyle={{
          width: '100%',
          height: '100%'
        }}
        isFlipped={flipped}
        flipDirection="horizontal"
      >
        <div className="d-flex w-100 h-100 justify-content-center">
          <ImageDefault src={img1} />
        </div>
        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
          <ImageHover src={img2} />
        </div>
      </ReactCardFlip>
    </Container>
  );
}
