import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  width: 195px;
`;
export const InternalContainer = styled.div`
  width: 195px;
`;
export const ContainerTable = styled.div`
  width: 100%;
  margin-bottom: 20px;
  overflow-y: auto;

  table th,
  table td {
    vertical-align: middle;
  }
`;
export const Name = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 0px;
  padding: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

  h3 {
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.default};
  }
`;

export const Image = styled.img``;
export const Select = styled.select`
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.default};

  padding: 5px 20px;
`;

export const Button = styled.button`
  background: none;
  color: ${props => props.theme.default};
  font-size: 16px;
  border: none;
  padding: 5px 20px;
`;
export const LinkedItems = styled.p`
  color: ${props => props.theme.default};
  font-size: 20px;

  padding: 5px 20px;
`;

export const StepButton = styled.button`
  color: ${props => props.theme.default};
  font-size: 14px;
  margin: 0;
  padding: 5px 10px;
  border: none;
  width: 130px;
  background: ${props =>
    props.open ? props => props.theme.secondary : props => props.theme.default};
  color: ${props => (props.open ? props => props.theme.default : props => props.theme.secondary)};
`;
export const StepMethod = styled.p`
  color: ${props => props.theme.default};
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  margin-right: 20px;
`;
export const StepTitle = styled.p`
  color: ${props => props.theme.text};
  font-size: 20px;
  margin: 0;
  margin-left: 10px;
`;
export const StepContainer = styled.div`
  padding: 5px 20px;
  border: 1px solid ${props => props.theme.default};
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
`;
export const StepHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StepButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
`;

export const StepCollapseContainer = styled.div`
  padding: 10px 20px;
  background: ${props => props.theme.secondary};
  margin: 10px;
  font-size: 14px;

  h5 {
    color: #c4c4c4;
    font-size: 14px;
  }
  h4 {
    color: #c4c4c4;
    font-size: 20px;
    text-align: center;
  }
  table {
    width: 100%;

    th {
      color: ${props => props.theme.default};
    }
    td {
      color: ${props => props.theme.text};
    }
  }
`;
export const BodyContainer = styled.div`
  background: ${props => props.theme.primary};
  padding: 10px;
  border: ${props => (props.border ? '1px solid #00FFFF ' : '')};
`;
