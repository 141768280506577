import styled from 'styled-components';
import image from 'assets/images/dropzone.png';

export const Dropzone = styled.div`
  display: ${props => (props.droping ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 20, 58, 0.79);
  z-index: 9999;
  background-image: url(${image});
  background-position: center;
  background-repeat: no-repeat;
`;
