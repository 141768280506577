import React, { useState, useLayoutEffect, useRef } from 'react';
import useGlobal from 'store/global';
import useMousePosition from './useMousePosition';
import { Container, InnerContainer, Title, TooltipDetails, Arrow, InnerArrow } from './styles';

function ToolTip(props) {
  const { size } = props;
  const [showToolTip] = useGlobal(state => state.showToolTip);
  const { left, title, text, tags } = showToolTip;
  const position = useMousePosition();
  const top = position.y - 10;
  const ref = useRef();
  const [divHeight, setDivHeight] = useState();
  const [divWidth, setDivWidth] = useState();

  useLayoutEffect(() => {
    setDivHeight(ref.current.getBoundingClientRect().height);
    setDivWidth(ref.current.getBoundingClientRect().width);
  }, [top]);

  const newTop =
    divHeight && top + 20 + divHeight < size.height ? top - 10 : size.height - divHeight - 20;
  const topArrow = top - newTop - 20 > 1 ? top - newTop - 20 : 10;
  const newTLeft = divWidth && left - divWidth - 12;

  return (
    <Container ref={ref} style={{ top: isNaN(newTop) ? -10 : newTop, left: newTLeft }}>
      <InnerContainer>
        <Title>{title}</Title>
        <TooltipDetails>
          {text}
          {tags}
        </TooltipDetails>
      </InnerContainer>
      <Arrow
        style={{
          top: newTop !== top ? topArrow : 10,
          left: divWidth && divWidth - 2
        }}
      >
        <InnerArrow />
      </Arrow>
    </Container>
  );
}

export default ToolTip;
