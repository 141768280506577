import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from 'pages/Main';
import HeadlessLayout from 'layouts/headless';
import DefaultLayout from 'layouts/default';
import Login from 'pages/Login';
import RegisterMemberLayout from 'layouts/registerMember';
import RegisterMember from 'pages/RegisterMember';
import Annotate from 'pages/Annotate';
import AnnotateInference from 'pages/AnnotateInference';
import TrainingStatistics from 'pages/TrainingStatistics';

import Models from 'pages/Models';
import Model from 'pages/Models/detail';
import Statistics from 'pages/Models/statistics';

import Datasets from 'pages/Datasets';
import Folders from 'pages/Folders';
import Dataset from 'pages/Datasets/detail';
import FluidLayout from 'layouts/fluid';

import Logs from 'pages/Logs';
import SubscriptionControl from 'pages/SubscriptionControl';
import UserList from 'pages/UserList';
import LinkModels from 'pages/SubscriptionControl/LinkModels';
import Invoices from 'pages/SubscriptionControl/Invoices';
import InvoicePDF from 'pages/SubscriptionControl/Invoices/InvoiceDetail';

export const SmartVisionRouter = () => (
  <Switch>
    <CustomRoute path="/" exact Layout={DefaultLayout} Component={Main} />
    <CustomRoute path="/main" Layout={DefaultLayout} Component={Main} />
    <CustomRoute
      path="/subscriptioncontrol"
      exact
      Layout={DefaultLayout}
      Component={SubscriptionControl}
    />
    <CustomRoute path="/subscriptioncontrol/userList" Layout={DefaultLayout} Component={UserList} />
    <CustomRoute
      path="/subscriptioncontrol/linkModel"
      Layout={DefaultLayout}
      Component={LinkModels}
    />
    <CustomRoute
      path="/subscriptioncontrol/invoices"
      exact
      Layout={DefaultLayout}
      Component={Invoices}
    />
    <CustomRoute
      path="/subscriptioncontrol/invoices/download/:id"
      Layout={DefaultLayout}
      Component={InvoicePDF}
    />
    <CustomRoute path="/models" Layout={DefaultLayout} Component={Models} />
    <CustomRoute path="/models/:slug" Layout={DefaultLayout} Component={Models} />
    <CustomRoute path="/model/:slug" exact Layout={DefaultLayout} Component={Model} />
    <CustomRoute path="/model/:slug/statistics" exact Layout={FluidLayout} Component={Statistics} />
    <CustomRoute
      path="/model/:slug/trainingstatistics"
      exact
      Layout={FluidLayout}
      Component={TrainingStatistics}
    />
    <CustomRoute path="/datasets" Layout={DefaultLayout} Component={Datasets} />
    <CustomRoute path="/folders" Layout={DefaultLayout} Component={Folders} />
    <CustomRoute path="/login" exact Layout={HeadlessLayout} Component={Login} />
    <CustomRoute path="/logs/:slug" Layout={DefaultLayout} Component={Logs} />
    <CustomRoute
      path="/registermember"
      exact
      Layout={RegisterMemberLayout}
      Component={RegisterMember}
    />
    <CustomRoute path="/dataset/:slug" exact Layout={DefaultLayout} Component={Dataset} />
    <CustomRoute
      path="/dataset/:slug/annotate/:slug"
      exact
      Layout={DefaultLayout}
      Component={Annotate}
    />
    <CustomRoute
      path="/dataset/:slug/inference/:slug/annotate/:slug"
      exact
      Layout={DefaultLayout}
      Component={AnnotateInference}
    />
    <Route component={() => <p>404</p>} />
  </Switch>
);

const CustomRoute = ({ Layout, Component, path, exact }) => (
  <Route
    path={path}
    exact={exact}
    component={props => {
      const { history } = props;
      return (
        <Layout noContainer={Component === Annotate ? true : Component === Logs ? true : false}>
          <Component history={history} />
        </Layout>
      );
    }}
  />
);

export default SmartVisionRouter;
