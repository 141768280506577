import { takeLatest, put, call } from 'redux-saga/effects';
import { TagAPI } from 'services/Api/Tag';
import { loading, loadingMini } from './general';

const INITIAL_STATE = {
  tags: [],
  page: 1,
  size: 100000
};

export const Types = {
  GET_TAGS: 'annotatetag/GET_TAGS',
  SET_TAGS: 'annotatetag/SET_TAGS'
};

export function GetTags(name, page, size) {
  return {
    type: Types.GET_TAGS,
    payload: {
      name,
      page,
      size
    }
  };
}

export function SetTags(tags) {
  return {
    type: Types.SET_TAGS,
    payload: {
      tags
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_TAGS:
      return {
        ...state,
        tags: payload.tags
      };
    default:
      return state;
  }
}

/*---------------------*/

function* getTagsFromAPI(action) {
  const { payload } = action;
  yield put(loading(true));

  try {
    const result = yield call(TagAPI.getTags, payload.name, payload.page, payload.size);
    //yield put(SetTags(result));
    yield put({
      type: Types.SET_TAGS,
      payload: {
        tags: result.results
      }
    });
    //payload.navigation.navigate('RegistrationSummary');
  } catch (error) {
    throw error;
    //alert('Erro ao efetuar login. Tente novamente mais tarde.');
  }
  yield put(loading(false));
}

/*---------------------*/

export function setTagsList(user, subscription, dataset, tag) {
  let list = JSON.parse(localStorage.getItem(`tagList_${user}_${subscription}_${dataset}`)) || '[]';
  if (list instanceof Array) {
    list = list.filter(el => el.id !== tag.id);
    list = list.concat(tag);
  } else {
    list = [tag];
  }
  //if (list.length > 10) list = list.splice(Math.max(list.length - 10, 1));
  localStorage.setItem(`tagList_${user}_${subscription}_${dataset}`, JSON.stringify(list));
}

/*---------------------*/

export function getTagsList(user, subscription, dataset) {
  const list = JSON.parse(
    localStorage.getItem(`tagList_${user}_${subscription}_${dataset}`) || '[]'
  );
  return list;
}

/*---------------------*/

export const sagas = takeLatest(Types.GET_TAGS, getTagsFromAPI);
