import styled from 'styled-components';
import { Modal as ModalRS } from 'reactstrap';

export const Modal = styled(ModalRS)`
  .modal-content {
    background-color: transparent;
  }
  .container {
    background-color: ${props => props.theme.primary};
    padding: 40px;
    position: relative;
  }
  .container .modal-header {
    border: none;
  }
  .container .modal-title {
    color: ${props => props.theme.white};
    font-size: 20px;
  }
  .container .modal-body {
    color: ${props => props.theme.white};
    font-size: 16px;
    padding: 0px 18px 18px 18px;
  }
  .container .modal-footer {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .container .modal-footer button {
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.default};
    font-size: 16px;
    border: none;
    padding: 10px 30px;
    width: 47%;
  }
  .container .modal-footer button:focus {
    box-shadow: none;
  }
  .container .modal-footer button.goldButton {
    background: ${props => props.theme.default};
    color: ${props => props.theme.white};
  }
  .close_btn {
    position: absolute;
    color: ${props => props.theme.white};
    right: 20px;
    top: 15px;
    font-size: 16px;
    background: none;
    border: none;
  }
`;
