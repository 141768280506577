import styled from 'styled-components';
import button from 'assets/images/copyIcon.png';
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    color: ${props => props.theme.text};
    font-size: 16px;
    width: 55px;
    margin: 0;
    margin-right: 20px;
  }
  label {
    margin: 0;
  }
`;
export const Input = styled.input`
  background: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  font-size: 14px;
  border: none;
  width: 100%;
  padding: 5px;
  margin: 0 20px 0 0;
`;
export const CopyButton = styled.button`
  background: none;
  width: 30px;
  height: 30px;
  border: none;
`;
