import React, { useContext } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import { ThemeContext } from 'styled-components';
import CustomTooltip from './tooltip';

export default function PrecisionRecallChart({ chartData, xKey }) {
  const theme = useContext(ThemeContext);
  const lines = chartData?.lines;
  const data = chartData?.data;
  const lineColors = [theme.white, theme.default, theme.warning];

  return (
    <ResponsiveContainer minHeight="280 " minWidth="480">
      <LineChart
        data={data}
        margin={{
          top: 10,
          bottom: 20,
          right: 20
        }}
      >
        <CartesianGrid />
        <XAxis dataKey={xKey} />
        <YAxis scale="linear" />
        <Tooltip content={<CustomTooltip labelText={xKey} />} />
        <Legend layout="horizontal" verticalAlign="bottom" iconType="square" />
        {lines?.map((i, index) => {
          return (
            <Line
              key={index}
              stroke={lineColors[index]}
              type="monotone"
              strokeWidth="2"
              connectNulls
              dataKey={i}
              dot={data.length < 50}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}
