import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { useSelector } from 'react-redux';
import { t, strings } from 'language';

//import { setQueue } from 'store/ducks/uploadQueue';
import { Container, UploadHeader, Title, UploadBody, CollapseButton } from './styles';
import QueueItem from '../QueueItem';

export default function(props) {
  const [collapse, setCollapse] = useState(false);

  const finishedFlag = useRef(false);

  const [items, setItems] = useState([]);
  const queue = useSelector(state => state.uploadQueue.queue);
  const uploadedFiles = useSelector(state => state.uploadQueue.uploadedFiles);
  const { onFinish } = props;

  useEffect(() => {
    if (queue) {
      const newItems = Object.keys(queue).map(item => {
        const value = queue[item];
        const uploaded = uploadedFiles.includes(item);
        return { progress: value, title: item, uploaded };
      });
      setItems(newItems);
    }
  }, [uploadedFiles, queue, setItems]);

  useEffect(() => {
    async function checkFinished() {
      if (items.length > 0 && items.length === uploadedFiles.length) {
        if (!finishedFlag.current) {
          setCollapse(true);
          finishedFlag.current = true;
          setTimeout(async () => {
            await onFinish();
          }, 1000);
        }
      }
      //reabrir
      if (items.length > 0 && items.length !== uploadedFiles.length) {
        if (finishedFlag.current && collapse) {
          setCollapse(false);
        }
        finishedFlag.current = false;
      }
    }
    checkFinished();
  }, [items, uploadedFiles, onFinish, finishedFlag, collapse]);

  const Row = ({ index, style }) => {
    return (
      <div style={style}>
        <QueueItem
          style={style}
          progress={items[index].progress}
          title={items[index].title}
          uploaded={items[index].uploaded}
        />
      </div>
    );
  };

  if (!queue) return <></>;

  return (
    <Container collapse={collapse}>
      <UploadHeader>
        <Title>
          {t(strings.uploading_images)} 
{' '}
{uploadedFiles.length}/
{items.length}
        </Title>
        <CollapseButton collapse={collapse} onClick={() => setCollapse(collapse => !collapse)} />
      </UploadHeader>
      <UploadBody>
        {items && (
          <FixedSizeList height={300} itemSize={75} itemCount={items.length} width={457}>
            {Row}
          </FixedSizeList>
        )}
        )}
      </UploadBody>
    </Container>
  );
}
