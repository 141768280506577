import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.secondary};
  padding: 20px;

  align-items: center;
  justify-content: center;
  margin-top: 20px;

  select {
    width: 100%;
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.default};
    padding: 10px;
  }
  h5 {
    font-size: 12px;
  }
  h3 {
    margin-top: 30px;
    font-size: 20px;
  }
  h4 {
    margin-top: 30px;
    font-size: 16px;
  }
`;
