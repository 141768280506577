import React, { useState, useEffect, useLayoutEffect } from 'react';
import Annotation from 'utils/react-image-annotation/lib/components/Annotation';
import { RectangleSelector, PointSelector } from 'utils/react-image-annotation/lib/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { postTag, postTagPolygon } from 'services/Api/Dataset/dataset';
import { addTags } from 'services/Api/Tag/tag';
import { GetSelectImage, SetAnnotation } from 'store/ducks/annotate';
import { setTagsList } from 'store/ducks/annotateTag';
import { contentEdit } from 'store/ducks/contentEdit';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import useDimensions from 'react-use-dimensions';
import { Rnd } from 'react-rnd';
import useCookie from 'components/useCookie';
//import { useCookies } from "react-cookie";
import Editor from './components/Editor';
import Rectangle from './components/Rectangle';
import Point from './components/Point';
import creatBorder from './components/creatBorder';
import Content from './components/Content';
import ContentTag from './components/ContentTag';
import ContentInference from './components/ContentInference';
import MagicWandTool from '../../../MagicWandTool';
// import { loading, loadingMini } from 'store/ducks/general';
import Polygon from './components/Polygon/polygon';
import useKeyPress from './components/useKeyPress';
import { useMousePosition } from './components/useMousePosition';
import './styles.css';

function AnnotateDrawer(props) {
  const { dataset, size } = props;
  const { height } = size;
  const startWidth = 20;
  const startHeight = 246;
  const diffWidth = size.width - startWidth;
  const diffHeight = height - startHeight;
  const dispatch = useDispatch();
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const auth = useSelector(state => state.auth);
  const { email, activeSubscription } = auth.user;
  const subscription = activeSubscription;
  const subscriptionViwer = auth.user.subscriptions.find(
    element => element.id === activeSubscription
  );
  const viewer = subscriptionViwer.role === 'VIEWER';
  const { selectedImage } = annotate;
  const img = selectedImage && selectedImage.url;
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const [ref, { width }] = useDimensions({ liveMeasure: true });
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});
  const showContent = annotation.selection;
  const [tagType] = useGlobal(state => state.tagType);
  const [disableEditor, setDisableEditor] = useGlobal(
    state => state.disableEditor,
    actions => actions.setDisableEditor
  );
  const [disableSelector] = useGlobal(state => state.disableSelector);
  const [disableOverlay] = useGlobal(state => state.disableOverlay);
  const [disableContent, setDisableContent] = useGlobal(
    state => state.disableContent,
    actions => actions.setDisableContent
  );
  const [position, editingAnnotation] = useGlobal(
    state => state.position,
    actions => actions.editingAnnotation
  );
  const [selectedAnnotate, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const [annotateList, addAnnotateList] = useGlobal(
    state => state.annotateList,
    actions => actions.addAnnotateList
  );
  const [doing, addDoing] = useGlobal(
    state => state.doing,
    actions => actions.addDoing
  );
  const [color] = useGlobal(state => state.color);
  const [zoom, setZoom] = useGlobal(
    state => state.zoom,
    actions => actions.setZoom
  );
  const [fitZoom, setFitZoom] = useGlobal(
    state => state.fitZoom,
    actions => actions.setFitZoom
  );
  const [, setWidthDraw] = useGlobal(
    state => state.widthDraw,
    actions => actions.setWidthDraw
  );
  const [, setHeightDraw] = useGlobal(
    state => state.heightDraw,
    actions => actions.setHeightDraw
  );
  const [, setControlTag] = useGlobal(
    state => state.controlTag,
    actions => actions.setControlTag
  );
  const [polygon, setPolygon] = useGlobal(
    state => state.polygon,
    actions => actions.setPolygon
  );
  const [polygons, setPolygons] = useGlobal(
    state => state.polygons,
    actions => actions.setPolygons
  );
  const [activePolygon, setActivePolygon] = useGlobal(
    state => state.activePolygon,
    actions => actions.setActivePolygon
  );
  const [editablePolygon, setEditablePolygon] = useGlobal(
    state => state.editablePolygon,
    actions => actions.setEditablePolygon
  );
  const [editablePoint, setEditablePoint] = useGlobal(
    state => state.editablePoint,
    actions => actions.setEditablePoint
  );
  const escPress = useKeyPress('Escape');
  const crtlPress = useKeyPress('Control');
  const shiftPress = useKeyPress('Shift');
  const altPress = useKeyPress('Alt');
  const plusPress = useKeyPress('+');
  const minusPress = useKeyPress('-');
  const editPress = useKeyPress('e');
  const deletePress = useKeyPress('Delete');
  const addPress = useKeyPress('Insert');
  const tagPress = useKeyPress('t');
  const deleteTagPress = useKeyPress('a');
  const removeTagPress = useKeyPress('r');
  const num0 = useKeyPress('0');
  const num1 = useKeyPress('1');
  const num2 = useKeyPress('2');
  const num3 = useKeyPress('3');
  const num4 = useKeyPress('4');
  const num5 = useKeyPress('5');
  const num6 = useKeyPress('6');
  const num7 = useKeyPress('7');
  const num8 = useKeyPress('8');
  const num9 = useKeyPress('9');
  const [keyPressing] = useGlobal(state => state.keyPressing);

  const [, addZoom] = useGlobal(
    state => state.zoom,
    actions => actions.addZoom
  );
  const [brightness] = useGlobal(state => state.brightness);
  const [contrast] = useGlobal(state => state.contrast);
  const [hue] = useGlobal(state => state.hue);
  const [hideTags] = useGlobal(state => state.hideTags);
  const [, setDataset] = useGlobal(
    state => state.dataset,
    actions => actions.setDataset
  );
  const [, setColor] = useGlobal(
    state => state.color,
    actions => actions.setColor
  );
  const [, setTagType] = useGlobal(
    state => state.tagType,
    actions => actions.setTagType
  );
  const [, setModal] = useGlobal(
    state => state.modal,
    actions => actions.setModal
  );
  const [, setTitleModal] = useGlobal(
    state => state.titleModal,
    actions => actions.setTitleModal
  );
  const [, setTextModal] = useGlobal(
    state => state.textModal,
    actions => actions.setTextModal
  );
  const [, setDelItem] = useGlobal(
    state => state.delItem,
    actions => actions.setDelItem
  );
  const [, setHandlePosition] = useGlobal(
    state => state.handlePosition,
    actions => actions.setHandlePosition
  );
  const [newMask, setNewMask] = useGlobal(
    state => state.newMask,
    actions => actions.setNewMask
  );
  const [, setShowTagType] = useGlobal(
    state => state.showTagType,
    actions => actions.setShowTagType
  );
  const [, setShowEffects] = useGlobal(
    state => state.showEffects,
    actions => actions.setShowEffects
  );
  const [, setShowToolTip] = useGlobal(
    state => state.showToolTip,
    actions => actions.setShowToolTip
  );
  const [tagToDelete, setTagToDelete] = useGlobal(
    state => state.tagToDelete,
    actions => actions.setTagToDelete
  );
  const [editorIsOpen, setEditorIsOpen] = useGlobal(
    state => state.editorIsOpen,
    actions => actions.setEditorIsOpen
  );
  const userSubscription = email + activeSubscription;
  const [cookie] = useCookie(userSubscription);
  const [hotkeys] = useGlobal(state => state.hotkeys);
  const [cookieCursor] = useCookie(`cursor-${email}`);
  const [cursor, setCursor] = useGlobal(
    state => state.cursor,
    actions => actions.setCursor
  );
  const annotateCursor = cursor && !disableEditor ? 'crosshair' : 'default';
  const annotateDisplay = cursor && !disableEditor ? 'block' : 'none';
  const pos = useMousePosition();
  const [left, setLeft] = useState(-1);
  const [top, setTop] = useState(-1);

  useEffect(() => {
    if (cookieCursor !== undefined) {
      setCursor(cookieCursor);
    }
  }, []);

  useEffect(() => {
    if (editorIsOpen && (cookie || hotkeys.length === undefined)) {
      const myCookie = hotkeys.length !== undefined ? cookie : hotkeys;
      let num = null;
      const newAnnotation = annotation;
      if (num0 && myCookie.tags[0].id !== 0) {
        num = 0;
      }
      if (num1 && myCookie.tags[1].id !== 0) {
        num = 1;
      }
      if (num2 && myCookie.tags[2].id !== 0) {
        num = 2;
      }
      if (num3 && myCookie.tags[3].id !== 0) {
        num = 3;
      }
      if (num4 && myCookie.tags[4].id !== 0) {
        num = 4;
      }
      if (num5 && myCookie.tags[5].id !== 0) {
        num = 5;
      }
      if (num6 && myCookie.tags[6].id !== 0) {
        num = 6;
      }
      if (num7 && myCookie.tags[7].id !== 0) {
        num = 7;
      }
      if (num8 && myCookie.tags[8].id !== 0) {
        num = 8;
      }
      if (num9 && myCookie.tags[9].id !== 0) {
        num = 9;
      }
      if (num !== null) {
        newAnnotation.data = myCookie.tags[num];
        onSubmit(annotation);
      }
    }
  }, [hotkeys, num0, num1, num2, num3, num4, num5, num6, num7, num8, num9]);

  function hideTooltip() {
    const tooltip = { left: -1000, title: '', text: '', tags: '' };
    setShowToolTip(tooltip);
  }

  useEffect(() => {
    setDataset(dataset);
  }, [dataset]);

  useEffect(() => {
    const annotates = [];
    const newPolygons = [];
    let annotatePoint = [];
    setPolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
    if (selectedImage.tags) {
      selectedImage.tags.map(box => {
        switch (box.type) {
          case 'Polygon':
            const hide = hideTags.polygon.find(element => {
              return element && element === box.tagId;
            });
            if (!hide) {
              annotatePoint = [];
              // console.log('AnnotateDrawer.useEffect.box', box);
              box.data.map((point, index) => {
                const onePoint = {
                  geometry: {
                    x: point.x,
                    y: point.y,
                    height: 0,
                    width: 0,
                    type: 'POINT'
                  },
                  data: {
                    id: Math.random(),
                    imageTagId: box.id,
                    tagId: box.tagId,
                    description: box.tag,
                    color: box.color,
                    index
                  }
                };
                annotatePoint.push(onePoint.geometry);
                annotates.push(onePoint);
              });
              newPolygons.push({
                polygonId: box.id,
                corner: box.data.length,
                data: {
                  imageTagId: box.id,
                  tagId: box.tagId,
                  description: box.tag,
                  color: box.color
                },
                geometry: annotatePoint
              });
            }
            break;
          default:
            const hideBox = hideTags.box.find(element => {
              return element && element === box.tagId;
            });
            if (!hideBox) {
              annotates.push({
                geometry: {
                  height: box.data.ymax - box.data.ymin,
                  width: box.data.xmax - box.data.xmin,
                  type: 'RECTANGLE',
                  x: box.data.xmin,
                  y: box.data.ymin
                },
                data: {
                  id: Math.random(),
                  imageTagId: box.id,
                  tagId: box.tagId,
                  description: box.tag,
                  color: box.color
                }
              });
            }
            break;
        }
      });
    }
    setAnnotations(annotates);
    setPolygons(newPolygons);
    //console.log('AnnotateDrawer.useEffect.annotates', annotates);
    //console.log('AnnotateDrawer.useEffect.annotations', annotations);
  }, [selectedImage.id, selectedImage.tags, hideTags.box.length, hideTags.polygon.length]);
  //console.log('AnnotateDrawer.annotations', annotations);

  useEffect(() => {
    addZoom(0.05);
  }, [plusPress]);

  useEffect(() => {
    addZoom(-0.05);
  }, [minusPress]);

  useEffect(() => {
    const realHeight = diffHeight;
    let fit = fitZoom;
    if (!fit || fit === 0) {
      if (width / imgWidth < realHeight / imgHeight) {
        fit = width / imgWidth;
      } else {
        fit = realHeight / imgHeight;
      }
      if (fit && fit > 0) {
        setZoom(fit);
      } else {
        setZoom(1);
      }
    }

    setFitZoom(fit);
    setWidthDraw(width);
    setHeightDraw(realHeight);
  }, [selectedImage.id, size, fitZoom, plusPress, minusPress]);

  useEffect(() => {
    const bool =
      showContent && (showContent.mode === 'SELECTING' || showContent.mode === 'EDITING');
    setDisableContent(bool);
  }, [showContent]);

  useEffect(() => {
    if (tagType === 'MASK' && !keyPressing) {
      setNewMask(newMask);
    }
  }, [keyPressing]);

  useEffect(() => {
    if (tagType === 'MASK') {
      if (shiftPress) {
        setNewMask(1);
      }
      if (altPress) {
        setNewMask(-1);
      }
    }
  }, [shiftPress, altPress]);

  useEffect(() => {
    if (escPress) {
      if (polygon && polygon.polygonId === 0) {
        clearTagPolygon();
      }
      setAnnotation({});
      if (selectedAnnotate.editTag) {
        setSelectedAnnotate([]);
        changeDisable(false);
      }
    }
    if (crtlPress) {
      setDisableContent(!disableContent);
    }
  }, [escPress, crtlPress]);

  useEffect(() => {
    const imgLeft = pos.x;
    const imgTop = pos.y;
    const maxWidth =
      imgWidth * zoom + startWidth < imgWidth * (width / imgWidth)
        ? imgWidth * zoom + startWidth
        : imgWidth * (width / imgWidth) + startWidth;
    const maxHeight =
      imgHeight * zoom + startHeight - 18 < diffHeight * (height / diffHeight)
        ? imgHeight * zoom + startHeight - 18
        : diffHeight * (height / diffHeight) - 18;
    if (startWidth > imgLeft || imgLeft > maxWidth) {
      setLeft(-1);
    } else {
      setLeft(imgLeft);
    }
    if (startHeight - 18 > imgTop || imgTop > maxHeight) {
      setTop(-1);
    } else {
      setTop(imgTop);
    }
  }, [pos]);

  function changeDisable(bool) {
    setDisableEditor(bool);
    setDisableContent(bool);
  }

  function delAnnotate(annotate) {
    const { data } = annotate;
    changeDisable(true);
    setTitleModal(t(strings.confirm_delete_tag));
    setTextModal(`${t(strings.confirm_delete)} "${data.description}" ?`);
    setDelItem({ value: annotate, type: 'tag' });
    setModal(true);
  }

  function editAnnotate(annotate) {
    const { type } = annotate.geometry;
    const NewAnnotation = annotate;
    const NewGeometry = {};

    setTagType(type);
    setSelectedAnnotate(annotate);
    dispatch(SetAnnotation(annotate));
    changeDisable(true);
    setColor(annotate.data.color);
    NewAnnotation.data.editMode = true;
    switch (type) {
      case 'POINT':
        NewGeometry.x = (annotate.geometry.x * imgWidth) / 100;
        NewGeometry.y = (annotate.geometry.y * imgHeight) / 100;
        NewGeometry.width = 0;
        NewGeometry.height = 0;
        break;
      default:
        NewGeometry.x = (annotate.geometry.x * imgWidth) / 100;
        NewGeometry.y = (annotate.geometry.y * imgHeight) / 100;
        NewGeometry.width = (annotate.geometry.width * imgWidth) / 100;
        NewGeometry.height = (annotate.geometry.height * imgHeight) / 100;
    }
    editingAnnotation(NewGeometry.x, NewGeometry.y, NewGeometry.width, NewGeometry.height);
  }

  function addAnnotate(annotate) {
    const existAnnotation = tagToDelete.some(
      item => annotate.data.imageTagId === item.data.imageTagId
    );
    if (!existAnnotation) {
      setTagToDelete([...tagToDelete, annotate]);
    }
  }

  function lessAnnotate(annotate) {
    const existAnnotation = tagToDelete.some(
      item => annotate.data.imageTagId === item.data.imageTagId
    );
    if (existAnnotation) {
      setTagToDelete(tagToDelete.filter(item => item.data.id !== annotate.data.id));
    }
  }

  async function updatePolygon(selected) {
    const polygon = polygons.find(x => {
      return x.polygonId === selected.data.imageTagId;
    });
    const idx = polygon.geometry.indexOf(selected.geometry);
    const nextPoint = idx + 1 >= polygon.geometry.length ? 0 : idx + 1;
    const newPoint = {
      x: (selected.geometry.x + polygon.geometry[nextPoint].x) / 2,
      y: (selected.geometry.y + polygon.geometry[nextPoint].y) / 2,
      height: 0,
      width: 0,
      type: 'POINT'
    };

    polygon.geometry.splice(idx + 1, 0, newPoint);
  }

  function addNewPoint(annotate) {
    updatePolygon(annotate);
    editAnnotate(annotate);
    const edit = polygons.find(x => {
      return x.polygonId === activePolygon;
    });
    setEditablePolygon(edit);
  }

  function editTag(annotate) {
    const note = { ...annotate, editTag: true };
    changeDisable(true);
    setSelectedAnnotate(note);
  }

  function randomHex() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  function clearTagPolygon() {
    setPolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  async function finishTagPolygon() {
    const tag = {
      tagId: polygon.data.tagId,
      points: polygon.geometry
    };
    await postTagPolygon(selectedImage.id, tag);
    clearTagPolygon();
  }

  function onMouseDown(e) {
    switch (tagType) {
      case 'MASK':
        const newPosition = {
          clientX: e.clientX,
          pageX: e.pageX,
          clientY: e.clientY,
          pageY: e.pageY
        };
        // console.log('onMouseDown.newPosition', newPosition);
        setHandlePosition(newPosition);
        break;
      default:
        if (selectedAnnotate.editTag) {
          setSelectedAnnotate([]);
          changeDisable(false);
        }
    }
  }

  function intersects(onChangeAnnotation) {
    const { geometry } = editablePolygon;
    const { x, y } = onChangeAnnotation.geometry;
    return geometry.find(point => {
      return !(x < point.x - 1 || y < point.y - 1 || x > point.x + 1 || y > point.y + 1);
    });
  }

  function onChange(onChangeAnnotation) {
    const { geometry, data, selection } = onChangeAnnotation;
    hideTooltip();
    if (onChangeAnnotation === annotation) return;

    if (editablePolygon.polygonId > 0) {
      if (intersects(onChangeAnnotation)) {
        const point = {
          data: editablePolygon.data,
          geometry: intersects(onChangeAnnotation)
        };
        setEditablePoint(point);
        setPolygon({
          polygonId: editablePolygon.data.imageTagId,
          corner: editablePolygon.corner,
          data: editablePolygon.data,
          geometry: editablePolygon.geometry.map(a => {
            return { x: a.x, y: a.y };
          })
        });
        if (doing === -1) {
          addAnnotateList({
            polygonId: editablePolygon.data.imageTagId,
            corner: editablePolygon.corner,
            data: editablePolygon.data,
            geometry: editablePolygon.geometry.map(a => {
              return { x: a.x, y: a.y };
            }),
            onChangeAnnotation: intersects(onChangeAnnotation)
          });
          addDoing(1);
        }
        setSelectedAnnotate({
          data: editablePolygon.data,
          geometry: intersects(onChangeAnnotation)
        });
      } else {
        if (editablePoint) {
          const editing = editablePolygon.geometry.map(a => {
            if (a.x === selectedAnnotate.geometry.x && a.y === selectedAnnotate.geometry.y) {
              a = onChangeAnnotation.geometry;
            }
            return a;
          });
          setEditablePolygon({
            polygonId: editablePolygon.polygonId,
            corner: editablePolygon.corner,
            data: editablePolygon.data,
            geometry: editing.map(a => {
              return { x: a.x, y: a.y };
            })
          });
          setPolygon({
            polygonId: editablePolygon.data.imageTagId,
            corner: editablePolygon.corner,
            data: editablePolygon.data,
            geometry: editing.map(a => {
              return { x: a.x, y: a.y };
            })
          });
          addAnnotateList({
            polygonId: editablePolygon.data.imageTagId,
            corner: editablePolygon.corner,
            data: editablePolygon.data,
            geometry: editing.map(a => {
              return { x: a.x, y: a.y };
            }),
            onChangeAnnotation
          });
          addDoing(1);
        }
        setEditablePoint(null);
      }
    }
    switch (tagType) {
      case 'POINT':
        if (selection) {
          if (disableEditor) {
            setDisableContent(true);
            setAnnotation({});
          } else {
            setAnnotation(onChangeAnnotation);
          }
        } else {
          setAnnotation(onChangeAnnotation);
        }
        break;
      default:
        if (selection) {
          if (disableEditor) {
            setDisableContent(true);
            setAnnotation({});
          } else {
            setAnnotation(onChangeAnnotation);
          }
        } else {
          setAnnotation(onChangeAnnotation);
        }
    }
    setShowTagType('none');
    setShowEffects('none');
  }

  async function onSubmit(onSubmitAnnotation) {
    const { geometry, data } = onSubmitAnnotation;
    if (!data) return;
    const color = data.color && data.color ? data.color : randomHex();
    //criar tag
    const newTag =
      !data.tagId &&
      (await addTags({
        color,
        description: data.description
      }));
    const tag = {
      tagId: newTag.id ? newTag.id : data.tagId,
      xmax: geometry.x + geometry.width,
      xmin: geometry.x,
      ymax: geometry.y + geometry.height,
      ymin: geometry.y
    };

    setEditorIsOpen(false);
    setControlTag(data.description);
    setTagsList(email, subscription, dataset, {
      id: newTag.id ? newTag.id : data.tagId,
      description: data.description,
      color: data.color
    });
    switch (tagType) {
      case 'POINT':
        setAnnotation({});
        // if (data.tagId === '') {
        // }
        setAnnotations(
          annotations.concat({
            geometry,
            data: {
              //...data,
              description: data.description,
              imageTagId: data.imageTagId,
              tagId: newTag.id ? newTag.id : data.tagId,
              editMode: false,
              color,
              id: Math.random(),
              polygonId: 0,
              corner: polygon.corner
            }
          })
        );
        setPolygon({
          polygonId: 0,
          corner: polygon.corner + 1,
          //...data,
          data: {
            color,
            description: data.description,
            imageTagId: data.imageTagId,
            tagId: newTag.id ? newTag.id : data.tagId
          },
          geometry: polygon.geometry
            ? polygon.geometry.concat({ x: geometry.x, y: geometry.y })
            : { x: geometry.x, y: geometry.y }
        });
        break;
      default:
        setAnnotation({});
        setAnnotations(
          annotations.concat({
            geometry,
            data: {
              ...data,
              editMode: false,
              color,
              id: Math.random()
            }
          })
        );
        await postTag(selectedImage.id, tag);
    }
    // console.log('onSubmit.annotations', annotations);
    switch (tagType) {
      case 'POINT':
        break;
      default:
        dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id, true));
    }
  }

  function renderSelector({ annotation }) {
    const { geometry, data } = annotation;
    const color = data && data.color;
    const size = 100 / (zoom * 30);
    if (!geometry) return null;
    if (data && !data.editMode) return null;
    return (
      <Rectangle
        key={`Rectangle_${Math.random()}`}
        geometry={geometry}
        style={{
          boxShadow: '0 0 0 99999px rgba(0, 0, 0, 0.5)',
          border: `dashed ${size}px white`
        }}
        color={color}
      />
    );
  }

  function renderEditor(props) {
    const { annotation, onChange, onSubmit } = props;
    const { geometry, data } = annotation;
    if (!geometry) return null;
    if (data && data.editMode) return null;
    if (geometry.type === 'POINT' && polygon.corner > 0) {
      const newAnnotation = {
        ...annotation,
        data: {
          imageTagId: polygon.data.tagId,
          tagId: polygon.data.tagId,
          description: polygon.data.description,
          color: polygon.data.color,
          polygonId: polygon.polygonId,
          corner: polygon.corner
        }
      };
      setAnnotation(newAnnotation);
      onSubmit();
      return null;
    }
    if (
      geometry.type !== 'POINT' &&
      selectedImage.width * (geometry.width / 100) < 5 &&
      selectedImage.height * (geometry.height / 100) < 5
    ) {
      return null;
    }
    return (
      <Editor
        key={`Editor_${Math.random()}`}
        annotation={annotation}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  }

  function renderHighlight({ key, annotation, active }) {
    const { geometry, data } = annotation;
    let boxShadow = '0 0 0 99999px rgba(255, 255, 255, .1) inset';
    let borderStyle = 'solid';
    const color = data && data.color;
    const size = 100 / (zoom * 30);
    const getPoint = !!(
      editablePoint &&
      editablePoint.geometry.x === geometry.x &&
      editablePoint.geometry.y === geometry.y
    );

    if (!geometry) return null;
    if (data.editMode && selectedAnnotate.data) {
      active = selectedAnnotate.data.id === annotation.data.id ? true : false;
      boxShadow = '0 0 0 99999px rgba(0, 0, 0, 0.15)';
      if (selectedAnnotate.data.id === annotation.data.id) {
        borderStyle = 'dashed';
      }
    }
    if (editPress && active && disableContent === undefined) {
      editAnnotate(annotation);
    }
    if (deletePress && active && disableContent === undefined) {
      delAnnotate(annotation);
    }
    if (tagPress && active && disableContent === undefined) {
      editTag(annotation);
    }
    if (deleteTagPress && active && disableContent === undefined) {
      addAnnotate(annotation);
    }
    if (removeTagPress && active && disableContent === undefined) {
      lessAnnotate(annotation);
    }

    switch (geometry.type) {
      case 'POINT':
        setActivePolygon(-1);
        if (editablePolygon.polygonId > 0) {
          if (editablePolygon.polygonId !== data.imageTagId) {
            return null;
          }
          active = true;
        }
        if (addPress && active && disableContent === undefined) {
          addNewPoint(annotation);
        }
        return (
          <Point
            key={key}
            geometry={geometry}
            data={data}
            zoom={zoom}
            color={color}
            active={active}
            edit={getPoint}
          />
        );
      default:
        return (
          <Rectangle
            data={data}
            key={key}
            geometry={geometry}
            style={{
              border: `solid ${size}px ${color}`,
              boxShadow: tagToDelete.some(item => data.imageTagId === item.data.imageTagId)
                ? '0 0 0 99999px rgba(255, 255, 255, .8) inset'
                : active && boxShadow,
              borderStyle
            }}
          />
        );
    }
  }

  function renderContent(props) {
    const { annotation } = props;
    const { geometry, data } = annotation;

    if (geometry.type === 'POINT') {
      if (data.polygonId === 0) return null;
      if (polygon && polygon.polygonId === 0) return null;
      setActivePolygon(data.imageTagId);
    }
    if (inferenceID > 0) {
      return (
        <ContentInference
          key={`Content_${Math.random()}`}
          annotation={annotation}
          activePolygon={activePolygon}
        />
      );
    } 

    return (
      <Content
        key={`Content_${Math.random()}`}
        annotation={annotation}
        activePolygon={activePolygon}
      />
    );
  }

  function renderOverlay(props) {
    const size = 100 / (zoom * 30);
    const square = 100 / (zoom * 40);
    const HandleClasses = creatBorder(square, position);

    if (!selectedAnnotate.geometry) return null;
    if (selectedAnnotate.editTag) {
      return <ContentTag key={`Tag_${Math.random()}`} annotation={selectedAnnotate} />;
    }

    dispatch(
      contentEdit({
        show: true,
        annotation: selectedAnnotate,
        annotateList,
        className: 'boxEdit',
        onChange,
        onSubmit
      })
    );
    if (tagType === 'POINT') {
      return null;
    }
    return (
      <>
        <Rnd
          key={`Rnd_${Math.random()}`}
          scale={`${zoom}`}
          size={{ width: position.width, height: position.height }}
          position={{ x: position.x, y: position.y }}
          bounds="parent"
          onDragStop={(e, d) => {
            editingAnnotation(d.x, d.y, position.width, position.height);
            addAnnotateList({ x: d.x, y: d.y, width: position.width, height: position.height });
            addDoing(1);
          }}
          onResizeStop={(e, direction, ref, delta, pos) => {
            editingAnnotation(pos.x, pos.y, ref.style.width, ref.style.height);
            addAnnotateList({
              x: pos.x,
              y: pos.y,
              width: ref.style.width,
              height: ref.style.height
            });
            addDoing(1);
          }}
          enableResizing={{
            bottom: tagType !== 'POINT',
            bottomLeft: tagType !== 'POINT',
            bottomRight: tagType !== 'POINT',
            left: tagType !== 'POINT',
            right: tagType !== 'POINT',
            top: tagType !== 'POINT',
            topLeft: tagType !== 'POINT',
            topRight: tagType !== 'POINT'
          }}
          resizeHandleStyles={HandleClasses}
          style={{
            zIndex: 0,
            border: `solid ${size}px ${color}`,
            boxShadow: '0 0 0 99999px rgba(0, 0, 0, 0.15)'
          }}
        />
      </>
    );
  }

  return (
    <>
      <div
        ref={ref}
        className="mh-100 mw-100 scrollbar"
        style={{
          height: `${diffHeight}px`,
          overflow: 'visible !important',
          zIndex: 0
        }}
      >
        <div
          className="vt"
          style={{
            transformOrigin: 'top left',
            display: annotateDisplay,
            height: imgHeight * (diffHeight / imgHeight),
            left
          }}
        />
        <div
          className="hl"
          style={{
            transformOrigin: 'top left',
            display: annotateDisplay,
            width: imgWidth * (width / imgWidth),
            top
          }}
        />
        <div
          style={{
            transformOrigin: 'top left',
            transform: `scale(${zoom})`,
            height: imgHeight,
            width: imgWidth,
            overflow: 'visible !important',
            cursor: annotateCursor
          }}
        >
          <Polygon
            polygon={polygon}
            polygons={polygons}
            selectedAnnotate={selectedAnnotate}
            img={selectedImage}
            zoom={zoom}
          />
          {selectedImage && (
            <MagicWandTool selectedImage={selectedImage} style={{ position: 'absolute' }} />
          )}
          <Annotation
            src={img}
            alt="smartvision"
            annotations={annotations}
            disableEditor={disableEditor}
            disableSelector={disableSelector}
            disableOverlay={disableOverlay}
            type={tagType === 'POINT' ? PointSelector.TYPE : RectangleSelector.TYPE}
            value={annotation}
            onChange={onChange}
            onSubmit={onSubmit}
            renderSelector={renderSelector}
            renderEditor={renderEditor}
            renderHighlight={renderHighlight}
            renderContent={disableContent ? () => null : renderContent}
            renderOverlay={renderOverlay}
            onMouseDown={tagType === 'RECTANGLE' ? null : e => onMouseDown(e)}
            style={{
              filter: `contrast(${contrast}%) brightness(${brightness}%) hue-rotate(${hue}deg)`,
              zIndex: 1
            }}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(AnnotateDrawer);
