import React from 'react';
import { Container, InputText, Label } from './styles';

const SVInputText = props => (
  <Container className="input-field">
    <Label>
      <span>{props.label}</span>
      <InputText {...props} />
    </Label>
  </Container>
);

export default SVInputText;
