import styled from 'styled-components';

import UserAvatar from 'react-user-avatar';

export const Container = styled.div`
  background: ${props => props.theme.secondary};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 55px;
  padding-top: 25px;

  a {
    display: flex;
  }
`;

export const CloseButton = styled.a`
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: ${props => props.theme.primary};
  position: absolute;
  right: -60px;
  top: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: ${props => (props.visible ? 'flex !important' : 'none!important')};
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;

export const Logo = styled.img``;
export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 12px;
  padding-bottom: 15px;

  div.uploadPhoto {
    position: relative;
  }

  div.uploadPhoto:hover #avatarEditOverlay {
    display: flex;
    cursor: pointer;
  }
  div.uploadPhoto input {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    bottom: 0;
    right: 0;
    z-index: 11;
    opacity: 0;
  }
  div.uploadPhoto input:hover {
    cursor: pointer;
  }
`;
export const Icon = styled(UserAvatar)`
  border: 2px solid ${props => props.theme.white};
  border-radius: 25px;
`;
export const Name = styled.p`
  color: ${props => props.theme.green};
  margin: 0;
  font-size: 16px;
`;
export const Sign = styled.span`
  color: ${props => props.theme.text};
  font-size: 13px;
`;
export const Info = styled.div`
  margin-left: 13px;
  display: flex;
  flex-direction: column;
`;

export const SubscriptionSelect = styled.select`
  background: transparent;
  border: none;
  padding: 0px;
  margin-left: -4px;
`;
export const MenuItensContainer = styled.div`
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.text};
  }
`;
export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;

  ::placeholder {
    color: ${props => props.theme.white};
    opacity: 0.5;
  }
`;
export const AvatarEditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
`;

export const VersionText = styled.div`
  position: fixed;
  bottom: 10px;
  font-size: 10px;
  margin-left: 10px;
`;
