import React, { useEffect, useState } from 'react';

import {
  ContainerTable,
  Button,
  LinkedItems,
  Select,
  StepButton,
  StepMethod,
  StepTitle,
  StepContainer,
  StepButtonContainer,
  StepCollapseContainer,
  StepHeader,
  BodyContainer,
  FolderInput
} from '../styles';
import { Container, Row, Table, Collapse } from 'reactstrap';
import CopyPanel from '../CopyPanel';
import Col from 'reactstrap/lib/Col';
// import { Container } from './styles';

function FTPInfo({ data }) {
  const [collapsedStep1, setCollapsedStep1] = useState(false);
  const [collapsedStep2, setCollapsedStep2] = useState(false);
  const [modal, setModal] = useState(false);

  const switchModal = step => {
    switch (step) {
      case 1:
        if (!collapsedStep1) {
          setCollapsedStep1(true);
          setCollapsedStep2(false);
        } else {
          setCollapsedStep1(false);
          setCollapsedStep2(false);
        }
        break;
      case 2:
        if (!collapsedStep2) {
          setCollapsedStep1(false);
          setCollapsedStep2(true);
        } else {
          setCollapsedStep2(false);
          setCollapsedStep1(false);
        }
        break;
    }
  };

  return (
    <div>
      <h5 className="modal-title mt-5">Informações de FTP</h5>
      <div style={{ wordBreak: 'break-all' }}>
        Instruções para implementação do FTP para inferência no smartvision .
        <StepContainer>
          <StepHeader>
            <StepTitle>Passo 1</StepTitle>
            <StepButtonContainer>
              <StepMethod>ENTRADA</StepMethod>
              <StepButton onClick={() => switchModal(1)} open={collapsedStep1}>
                {collapsedStep1 ? 'Fechar detalhes' : 'Abrir detalhes'}
              </StepButton>
            </StepButtonContainer>
          </StepHeader>

          <Collapse isOpen={collapsedStep1}>
            <StepCollapseContainer>
              {/* <CopyPanel
                navigation={navigation}
                value={`https://smartvisioninfer.azurewebsites.net/api/infer?api_key=${totalAssociation
                  ?.apiKeys?.apiKey ||
                  'API_KEY'}&model_key=model_${selectedModelId}_${selectedStep}&min_threshold=0.6`}
              /> */}

              <p style={{ marginTop: 10, marginBottom: 30, wordBreak: 'break-word' }}>
                Para realizar a ação solicitada, você precisa utilizar uma ferramenta de FTP para
                enviar imagens para nosso servidor.
              </p>

              <Row>
                <Col>
                  <label>Host</label>
                  <CopyPanel value={data?.host} />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <label>Porta</label>
                      <CopyPanel value={data?.port} />
                    </Col>
                    <Col>
                      <label>Protocolo</label>
                      <CopyPanel value={data?.protocol} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Usuário</label>
                  <CopyPanel value={data?.user} />
                </Col>
                <Col>
                  <label>Senha</label>
                  <CopyPanel value={data?.password} />
                </Col>
              </Row>
              <div className="d-flex flex-row align-items-baseline mt-4">
                <p style={{ fontSize: 16 }}>Local onde deve ser colocado os arquivos: </p>
                <FolderInput value={`input/${data?.modelIdStep}`} />
              </div>

              <h5 style={{ marginTop: 30 }}>
                Atenção: Durante o processo de inferência os arquivos estarão inacessíveis
              </h5>

              <hr style={{ borderColor: '#453E66', marginTop: 30, marginBottom: 30 }} />

              <p>
                Dentro da pasta acima você poderá alterar as configurações para inferência no <br />
                arquivo <strong>inference.config</strong>
              </p>

              <div className="d-flex flex-row align-items-baseline mt-4">
                <p style={{ fontSize: 16 }}>Exemplo de configuração: </p>
              </div>

              <BodyContainer border style={{ height: 80, overflow: 'hidden' }}>
                <br />
                confidence = 0.5
                <br />
              </BodyContainer>
            </StepCollapseContainer>
          </Collapse>
        </StepContainer>
        <StepContainer>
          <StepHeader>
            <StepTitle>Passo 2</StepTitle>
            <StepButtonContainer>
              <StepMethod>SAÍDA</StepMethod>
              <StepButton onClick={() => switchModal(2)} open={collapsedStep2}>
                {collapsedStep2 ? 'Fechar detalhes' : 'Abrir detalhes'}
              </StepButton>
            </StepButtonContainer>
          </StepHeader>

          <Collapse isOpen={collapsedStep2}>
            <StepCollapseContainer>
              {/* <CopyPanel
                navigation={navigation}
                value={`https://smartvisioninfer.azurewebsites.net/api/getresult?ticket=TICKET`}
              /> */}
              <div className="d-flex flex-row align-items-baseline mt-2">
                <p style={{ fontSize: 16 }}>Local onde deve ser retirados o resultado: </p>
                <FolderInput value={`output/${data?.modelIdStep}`} />
              </div>

              <h4 style={{ marginTop: 15 }}>Modelo de resultado:</h4>

              <BodyContainer border>
                {'{'}
                <br />
                "index": 0,
                <br />
                "idx": 0,
                <br />
                "img": string,
                <br />
                "level": 0,
                <br />
                "b1": 0,
                <br />
                "b2": 0,
                <br /> "b3": 0,
                <br />
                "b4": 1,
                <br />
                "absolute_b1": 0,
                <br />
                "absolute_b2": 0,
                <br />
                "absolute_b3": 0,
                <br />
                "absolute_b4": 0,
                <br />
                "score": 0,
                <br /> "class": 0,
                <br />
                "class_id": string,
                <br />
                "class_name": string,
                <br /> "class_color": string,
                <br />
                "master_id": 0,
                <br />
                "parent_id": 0,
                <br />
                "box_img": string,
                <br />
                "dist_prev_image": 0,
                <br />
                "model_ckpt": string,
                <br />
                "label_map_file": string,
                <br /> "th_lim": 0,
                <br />
                "dt_inf": 0,
                <br />
                "eps_time": 0,
                <br />
                "proc_folder": string
                <br />
                {'}'}
              </BodyContainer>
            </StepCollapseContainer>
          </Collapse>
        </StepContainer>
      </div>
    </div>
  );
}

export default FTPInfo;
