import React, { useEffect } from 'react';
import { t, strings } from 'language';
import { useSelector, useDispatch } from 'react-redux';
import { postTagPolygon } from 'services/Api/Dataset/dataset';
import useGlobal from 'store/global';
import { Container, Row, Col } from 'reactstrap';
import { GetSelectImage } from 'store/ducks/annotate';
import ButtonIcon from './ButtonIcon';
import { Title, Box, BoxDiv, TitleGlobal } from './styles';
import { ReactComponent as FinishIcon } from './assets/images/finish.svg'; 
import { ReactComponent as DeleteIcon } from './assets/images/delete.svg';
import { Icon } from 'components/Icon';

function ActionTag() {
  const dispatch = useDispatch();
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  const selectedImage = annotate.selectedImage;
  //const selectedImage = useSelector(state => state.annotate.selectedImage);
  const [polygon, setPolygon] = useGlobal(
    state => state.polygon,
    actions => actions.setPolygon
  );
  const [, setActionTagWidth] = useGlobal(
    state => state.actionTagWidth,
    actions => actions.setActionTagWidth
  );
  const showBox = polygon && polygon.polygonId === 0 ? 'block' : 'none';
  const opacity = polygon && polygon.corner > 2 ? '1' : '.5';
  const disabled = polygon && polygon.corner > 2 ? '' : 'disabled';
  const [, setEditMode] = useGlobal(
    state => state.editMode,
    actions => actions.setEditMode
  );

  useEffect(() => {
    setActionTagWidth(showBox === 'block' ? 728 : 540);
    setEditMode(!!showBox);
  }, [showBox]);

  async function finishPolygon() {
    const tag = {
      tagId: polygon.data.tagId,
      points: polygon.geometry
    };
    //console.log('tag', tag);
    await postTagPolygon(selectedImage.id, tag);
    clearTagPolygon();
  }

  function clearTagPolygon() {
    setPolygon({ polygonId: -1, corner: 0, data: [], geometry: [] });
    setEditMode(false);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
  }

  return (
    <Box
      className="mh-100 no-margin"
      style={{ marginTop: '24px', marginLeft: '20px', marginRight: '10px', display: showBox }}
    >
      <TitleGlobal>{t(strings.polygon)}</TitleGlobal>
      <BoxDiv>
        <Row className="mt-3 ml-2 mr-2">
          <Container>
            <Row
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '120px', maxWidth: '120px' }}
            >
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabled} onClick={() => finishPolygon()}>
                  <Icon name="confirm" style={{ opacity: `${opacity}` }} />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => clearTagPolygon()}>
                  <Icon name="delete_icon" />
                </ButtonIcon>
              </Col>
            </Row>
            <Row style={{ minWidth: '120px', maxWidth: '120px', marginTop: '5px' }}>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.finish)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.delete)}</Title>
              </Col>
            </Row>
          </Container>
        </Row>
      </BoxDiv>
    </Box>
  );
}

export default ActionTag;
