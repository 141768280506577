import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid ${props => props.theme.text};
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  span {
    font-size: 12px;
    color: ${props => props.theme.white};

    time {
      color: ${props => props.theme.white};
    }
  }
  a {
    display: initial !important;
    color: ${props => props.theme.white};
  }
`;
