import React, { useState, useEffect } from 'react';
import MultSelectSearchable from '../../MultSelectSearchable';
import { Collapse, Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
//import { getTags } from 'services/Api/Tag/tag';
//import { setTags, clearFilter } from 'store/ducks/filter';
import { getMarkersByText } from 'services/Api/Markers';
import { t, strings } from 'language';
import { Container } from './styles';

const MarkersFilter = ({ setFilterSelection, filterSelection }) => {
  const [selectedFilters, setSelectedFilters] = useState(null);

  const filterState = useSelector(state => state.filter);

  const handleSelect = e => {
    setSelectedFilters(e);
  };
  useEffect(() => {
    // console.log('redux', filterState.markers);
    if (filterState.markers) {
      setFilterSelection({
        markers: filterState.markers
      });
    }
  }, []);

  useEffect(() => {
    if (selectedFilters) {
      setFilterSelection({
        ...filterSelection,
        markers: selectedFilters.map(i => {
          return {
            label: i.label,
            value: i.value
          };
        })
      });
    }
  }, [selectedFilters]);

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      getMarkersByText(inputValue, 1, 10).then(data => {
        resolve(
          data.results.map(i => {
            return {
              label: i.name,
              value: i.id
            };
          })
        );
      });
    });

  return (
    <Container>
      <br />
      <Label>{t(strings.bookmarks)}</Label>

      <MultSelectSearchable
        defaultValue={filterState.markers}
        promiseOptions={promiseOptions}
        selected={filterState.markers}
        onChange={handleSelect}
        options={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Container>
  );
};

export default MarkersFilter;
