import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { Title, Slider } from './styles';

function Effects(props) {
  // const [value, setValue] = useState(props.value);
  const [brightness, setBrightness] = useGlobal(
    state => state.brightness,
    actions => actions.setBrightness
  );
  const [contrast, setContrast] = useGlobal(
    state => state.contrast,
    actions => actions.setContrast
  );
  const [hue, setHue] = useGlobal(
    state => state.hue,
    actions => actions.setHue
  );

  const labels = {
    0: '0%',
    //50: '50%',
    100: '100%',
    //150: '150%',
    200: '200%'
  };
  const labelHue = {
    0: '0°',
    90: '90°',
    180: '180°',
    270: '270°',
    360: '360°'
  };

  return (
    <Container>
      <Row style={{ minWidth: '300px', maxWidth: '300px' }}>
        <Col
          className="no-margin "
          style={{ minWidth: '80px', maxWidth: '80px', textAlign: 'center', paddingTop: '10px' }}
        >
          <Title>{t(strings.brightness)}</Title>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '200px', maxWidth: '200px' }}
        >
          <Slider
            className="w-100"
            onChange={e => {
              setBrightness(e);
            }}
            value={brightness}
            labels={labels}
            min={0}
            max={200}
          />
        </Col>
      </Row>
      <Row style={{ minWidth: '300px', maxWidth: '300px' }}>
        <Col
          className="no-margin "
          style={{ minWidth: '80px', maxWidth: '80px', textAlign: 'center', paddingTop: '10px' }}
        >
          <Title>{t(strings.contrast)}</Title>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '200px', maxWidth: '200px' }}
        >
          <Slider
            className="w-100"
            onChange={e => {
              setContrast(e);
            }}
            value={contrast}
            labels={labels}
            min={0}
            max={200}
          />
        </Col>
      </Row>
      <Row style={{ minWidth: '300px', maxWidth: '300px' }}>
        <Col
          className="no-margin "
          style={{ minWidth: '80px', maxWidth: '80px', textAlign: 'center', paddingTop: '10px' }}
        >
          <Title>{t(strings.hue)}</Title>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '200px', maxWidth: '200px' }}
        >
          <Slider
            className="w-100"
            onChange={e => {
              setHue(e);
            }}
            value={hue}
            labels={labelHue}
            min={0}
            max={360}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Effects;
