import React, { useEffect } from 'react';
import { t, strings } from 'language';
// import { useSelector, useDispatch } from 'react-redux';
// import { postTagPolygon } from 'services/Api/Dataset/dataset';
import useGlobal from 'store/global';
import { Container, Row, Col } from 'reactstrap';
// import { GetSelectImage } from 'store/ducks/annotate';
import ButtonIcon from './ButtonIcon';
import { Title, Box, BoxDiv, TitleGlobal } from './styles';
import { ReactComponent as FinishIcon } from './assets/images/finish.svg';
import { ReactComponent as DeleteIcon } from './assets/images/delete.svg';
import { ReactComponent as AddIcon } from './assets/images/add.svg';
import { ReactComponent as LessIcon } from './assets/images/less.svg';
import { Icon } from 'components/Icon';

function ActionTagMask() {
  // const dispatch = useDispatch();
  // const [inferenceID] = useGlobal(state => state.inferenceID);
  // const annotate = useSelector(state => (inferenceID > 0 ? state.inference : state.annotate));
  // const {selectedImage} = annotate;
  // const [polygon, setPolygon] = useGlobal(
  //   state => state.polygon,
  //   actions => actions.setPolygon
  // );
  const [, setActionTagWidth] = useGlobal(
    state => state.actionTagWidth,
    actions => actions.setActionTagWidth
  );
  const [showTrace, setShowTrace] = useGlobal(
    state => state.showTrace,
    actions => actions.setShowTrace
  );
  const showBox = showTrace ? 'block' : 'none';
  const opacity = showTrace ? '1' : '.5';
  const disabled = showTrace ? '' : 'disabled';
  const [, setEditMode] = useGlobal(
    state => state.editMode,
    actions => actions.setEditMode
  );
  const [, setTrace] = useGlobal(
    state => state.trace,
    actions => actions.setTrace
  );
  const [, setCancelTrace] = useGlobal(
    state => state.cancelTrace,
    actions => actions.setCancelTrace
  );
  const [newMask, setNewMask] = useGlobal(
    state => state.newMask,
    actions => actions.setNewMask
  );
  const opacityAdd = newMask > 0 ? '1' : '.5';
  const opacityLess = newMask < 0 ? '1' : '.5';

  useEffect(() => {
    // setActionTagWidth(showBox === 'block' ? 728 : 540);
    setActionTagWidth(showBox === 'block' ? 848 : 540);
    setEditMode(!!showBox);
  }, [showBox]);

  async function finishPolygon() {
    setTrace(true);
    setShowTrace(false);
  }

  function cancelTagPolygon() {
    setCancelTrace(true);
    setShowTrace(false);
  }

  return (
    <Box
      className="mh-100 no-margin"
      style={{ marginTop: '24px', marginLeft: '20px', marginRight: '10px', display: showBox }}
    >
      <TitleGlobal>{t(strings.edit_mask)}</TitleGlobal>
      <BoxDiv>
        <Row className="mt-3 ml-2 mr-2">
          <Container>
            <Row
              className="d-flex justify-content-center no-margin"
              style={{ minWidth: '240px', maxWidth: '240px' }}
            >
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabled} onClick={() => setNewMask(1)}>
                  <Icon name="add" style={{ opacity: `${opacityAdd}` }} />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabled} onClick={() => setNewMask(-1)}>
                  <Icon name="less" style={{ opacity: `${opacityLess}` }} />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon disabled={disabled} onClick={() => finishPolygon()}>
                  <Icon name="confirm" style={{ opacity: `${opacity}` }} />
                </ButtonIcon>
              </Col>
              <Col
                className="d-flex justify-content-center no-margin"
                style={{ minWidth: '60px', maxWidth: '60px' }}
              >
                <ButtonIcon onClick={() => cancelTagPolygon()}>
                  <Icon name="delete_icon" />
                </ButtonIcon>
              </Col>
            </Row>
            <Row style={{ minWidth: '240px', maxWidth: '240px', marginTop: '5px' }}>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.add_mask)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.less_mask)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.finish)}</Title>
              </Col>
              <Col
                className="no-margin"
                style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
              >
                <Title>{t(strings.cancel)}</Title>
              </Col>
            </Row>
          </Container>
        </Row>
      </BoxDiv>
    </Box>
  );
}

export default ActionTagMask;
