export const getNotificationList = () => {
  const list = JSON.parse(localStorage.getItem('notificationList', ''));
  return list;
};

export const setNotificationList = notification => {
  let list = [];
  if (getNotificationList()) {
    list = getNotificationList();
  }
  if (!list.includes(notification)) {
    list.push(notification);
    localStorage.setItem('notificationList', JSON.stringify(list));
    return true;
  }
  return false;
};

export const clearNotificationList = () => {
  localStorage.removeItem('notificationList');
};
