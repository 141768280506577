import React, { useContext } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ThemeContext } from 'styled-components';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';

// import { Container } from './styles';

function Gauge({ value, valueText, title, maxSelect, buttonClick }) {
  const theme = useContext(ThemeContext);
  const display = maxSelect ? 'inline-block' : 'none';
  const iconName = maxSelect ? 'arrow-maxpriority' : 'arrow-orange';
  const divText = maxSelect ? t(strings.max_priority) : '';
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div style={{ position: 'absolute', cursor: 'pointer' }} onClick={buttonClick}>
        <div style={{ position: 'absolute', top: '35px', textAlign: 'center', width: '100%' }}>
          <Icon name={iconName} width="20" height="20" color={theme.secondary} />
          <div style={{ fontSize: '9px', paddingTop: '25px' }}>{divText}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Icon
            name="priority-signal"
            width="10"
            height="10"
            color={theme.secondary}
            style={{ display: `${display}`, marginRight: '5px' }}
          />
          <span style={{ color: theme.white }}>{title}</span>
        </div>
        <div style={{ width: 100, height: 100 }}>
          <CircularProgressbar
            counterClockwise
            text={`${(value * 100).toFixed(2)}%`}
            value={value * 100}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0,

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',

              // Text size
              textSize: '16px',

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              pathColor: `rgba(233, 77, 31, ${(value * 100) / 100})`,
              textColor: theme.white,
              trailColor: theme.primary,
              backgroundColor: theme.secondary
            })}
          />
        </div>
      </div>
    </div>
  );
}

export default Gauge;
