import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import ButtonIcon from './ButtonIcon';
// import { ReactComponent as Box } from 'assets/images/tag_icon.svg';
// import { ReactComponent as Class } from 'assets/images/class_icon.svg';
// import { ReactComponent as Polygon } from 'assets/images/polygon_icon.svg';
// import { ReactComponent as Mask } from 'assets/images/mask.svg';
import { ThemeContext } from 'styled-components';
import { Title } from './styles';
import { Icon } from 'components/Icon';

function TagType(props) {
  const { showTags } = props;
  const theme = useContext(ThemeContext);
  const [tagType, setTagType] = useGlobal(
    state => state.tagType,
    actions => actions.setTagType
  );

  return (
    <Container>
      <Row style={{ minWidth: '240px', maxWidth: '240px' }}>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '60px', maxWidth: '60px' }}
        >
          <ButtonIcon
            style={{ background: theme.primary }}
            active={tagType === 'RECTANGLE'}
            onClick={() => {
              setTagType('RECTANGLE');
              showTags();
            }}
          >
            <Icon name="tag_icon" style={{ width: 30, height: 30 }} />
          </ButtonIcon>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '60px', maxWidth: '60px' }}
        >
          <ButtonIcon
            style={{ background: theme.primary }}
            active={tagType === 'CLASS'}
            onClick={() => {
              setTagType('CLASS');
              showTags();
            }}
          >
            <Icon name="class_icon" style={{ width: 30, height: 30 }} />
          </ButtonIcon>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '60px', maxWidth: '60px' }}
        >
          <ButtonIcon
            style={{ background: theme.primary }}
            active={tagType === 'POINT'}
            onClick={() => {
              setTagType('POINT');
              showTags();
            }}
          > 
            <Icon name="polygon_icon" style={{ width: 30, height: 30 }} />
          </ButtonIcon>
        </Col>
        <Col
          className="d-flex justify-content-center no-margin"
          style={{ minWidth: '60px', maxWidth: '60px' }}
        >
          <ButtonIcon
            style={{ background: theme.primary }}
            active={tagType === 'MASK'}
            onClick={() => {
              setTagType('MASK');
              showTags();
            }}
          >
            <Icon name="mask" style={{ width: 30, height: 30 }} />
          </ButtonIcon>
        </Col>
      </Row>
      <Row style={{ minWidth: '240px', maxWidth: '240px', marginTop: '5px' }}>
        <Col
          className="no-margin "
          style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
        >
          <Title>{t(strings.box)}</Title>
        </Col>
        <Col
          className="no-margin"
          style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
        >
          <Title>{t(strings.class)}</Title>
        </Col>
        <Col
          className="no-margin"
          style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
        >
          <Title>{t(strings.polygon)}</Title>
        </Col>
        <Col
          className="no-margin"
          style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}
        >
          <Title>{t(strings.mask)}</Title>
        </Col>
      </Row>
    </Container>
  );
}

export default TagType;
