export const Types = {
  SET_THEME: 'theme/SET_THEME'
};

const INITIAL_STATE = {
  theme: 'dark'
};

export function setTheme(theme) {
  return {
    type: Types.SET_THEME,
    payload: {
      theme
    }
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_THEME:
      return { ...state, theme: payload.theme };
    default:
      return state;
  }
}
