import { TypeItem } from 'utils/constants';
import { apiPut } from '../index';

export async function changeFavorite(id, type) {
  // eslint-disable-next-line
  switch (type) {
    case TypeItem.MODEL:
      return apiPut(`Model/${id}/favorite`);
      // eslint-disable-next-line
      break;
    case TypeItem.FOLDER:
      return apiPut(`Folder/${id}/favorite`);
      // eslint-disable-next-line
      break;
    case TypeItem.DATASET:
      return apiPut(`Dataset/${id}/favorite`);
      // eslint-disable-next-line
      break;
  }
}
