import styled from 'styled-components';
import arrow from './assets/images/breadcrumb.svg';

export const Container = styled.div`
  .breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
    position: ${props => (props.margin === 0 ? 'absolute' : 'static')};
    z-index: ${props => (props.margin === 0 ? 201 : 0)};
    margin-top: ${props => (props.margin === 0 ? '0px' : '20px')};
    margin-bottom: ${props => (props.margin === 0 ? '0px' : '10px')};
  }
  .breadcrumb-item {
    font-size: 20px;

    color: ${props => props.theme.white};
  }
  .breadcrumb-item.active {
    color: ${props => props.theme.white};
  }

  .breadcrumb-item + .breadcrumb-item::before {
    background: url(${arrow}) no-repeat left top;
    content: '';
    width: 14px;
    margin-right: 8px;
    margin-left: 5px;
    height: 14px;
    margin-top: 8px;
    background-size: contain;
  }
`;

export const ImageCount = styled.span`
  color: ${props => props.theme.white};
  font-size: 19px;
  margin-left: 10px;
  text-align: right;

  width: 100%;
`;
