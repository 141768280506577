import React, { useState, useEffect } from 'react';

import { Container, ContainerFilled, Span, GlobalContainer } from './styles';

function LineBar({ max, actual }) {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    setTimeout(() => setCompleted(actual), 500);
  }, [actual]);

  return (
    <GlobalContainer>
      <Container value={max}>
        {max === -1 ? (
          <ContainerFilled value={0} className="ilimited">
            <Span value={0}>{completed}</Span>
          </ContainerFilled>
        ) : (
          <ContainerFilled value={(completed / max) * 100}>
            <Span value={(completed / max) * 100}>{completed}</Span>
          </ContainerFilled>
        )}
      </Container>
      <span className="total">{max === -1 ? 'Ilimitado' : max}</span>
    </GlobalContainer>
  );
}

export default LineBar;
