import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClearInference } from 'store/ducks/inference';
import history from 'services/history';
import { Container, Row, Table } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import Searchbar from 'components/Searchbar';
import { ContainerTable, Name, Image, Button, Select } from './styles';
import { Link } from 'react-router-dom';
import { changeRole, changeStatus, getUsers } from 'services/Api/Auth/auth';
import { getRoles } from 'services/Api/Subscriptions/subscriptions';
import { loading, setInviteModal } from 'store/ducks/general';
import Switch from 'components/Switch/Switch';
// import { Container } from './styles';
import Moment from 'react-moment';
import { t, strings } from 'language';

function UserList() {
  const auth = useSelector(state => state.auth);
  const filter = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rules, setRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  useEffect(() => {
    dispatch(loading(true));
    getUserList();
    getRolesList();
    // console.log(auth);
  }, []);

  const general = useSelector(state => state.general);
  useEffect(() => {
    // console.log(search);
    setSearchItems(
      data.filter(item => item?.name?.toUpperCase().indexOf(search?.toUpperCase()) > -1)
    );
  }, [search]);
  const getUserList = async () => {
    const users = await getUsers();

    setData(users.results);
    setSearchItems(users.results);
    dispatch(loading(false));
  };

  const getRolesList = async () => {
    const rolesList = await getRoles();
    setRoles(rolesList.results);
  };

  const handleRoleUpdate = async (id, role) => {
    dispatch(loading(true));

    await changeRole(id, role);
    await getUserList();
    dispatch(loading(false));

    // setRoles(rolesList.results);
  };

  const handleStatusUpdate = async id => {
    dispatch(loading(true));
    // console.log('id', id);
    await changeStatus(id);
    await getUserList();
    dispatch(loading(false));

    // setRoles(rolesList.results);
  };

  const handleAddClick = () => {
    dispatch(setInviteModal(!general.showInvite));
  };
  const AddButton = () => {
    return <Button onClick={() => handleAddClick()}>{t(strings.add)}</Button>;
  };
  return (
    <Container>
      <TitleBreadcrumb
        position={2}
        url={'/subscriptioncontrol/userList'}
        name={t(strings.user_list)}
      />
      <Searchbar onChange={setSearch} placeholder={t(strings.search_user)} custom={<AddButton />} />
      <ContainerTable>
        <Table>
          <thead>
            <tr>
              <th>{t(strings.name)}</th>
              <th>{t(strings.email)}</th>
              <th>{t(strings.last_acess)}</th>
              <th>{t(strings.paper)}</th>
              <th>{t(strings.status)}</th>
            </tr>
          </thead>
          <tbody>
            {searchItems?.map(item => {
              return (
                <tr key={`${item.id}`}>
                  <th scope="row">{item.name}</th>
                  <td>{item.email}</td>
                  <td>
                    {item.lastLogin && (
                      <Moment local utc format="DD/MM/YYYY HH:mm" style={{ whiteSpace: 'nowrap' }}>
                        {item.lastLogin}
                      </Moment>
                    )}
                  </td>
                  <td>
                    <Select
                      disabled={item.id === auth.user.id ? true : false}
                      defaultValue={item.role}
                      value={item.role}
                      onChange={event => handleRoleUpdate(item.id, event.target.value)}
                    >
                      {rules.map(item => {
                        return <option>{item}</option>;
                      })}
                    </Select>
                  </td>
                  <td>
                    <Switch
                      onColor="${props => props.theme.primary}"
                      disabled={item.id === auth.user.id ? true : false}
                      ison={item.active}
                      handleToggle={() => handleStatusUpdate(item.id)}
                      name={item.id}
                      checkOff
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ContainerTable>
    </Container>
  );
}

export default UserList;
