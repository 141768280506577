/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useContext } from 'react';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { ThemeContext } from 'styled-components';
import { Icon } from 'components/Icon';
import ButtonIcon from './components/ButtonIcon';
import SearchTags from './components/SearchTags';
// import { ReactComponent as EditIcon } from './assets/images/edit.svg';
import { Container, InputText, Options, Option } from './styles';
import useKeyPress from '../useKeyPress';

function TextEditorTag(props) {
  const { value, controller, onChange, onSubmit, onSelect } = props;
  const [showOptions, setShowOptions] = useState(false);
  const { tags, isLoading } = SearchTags(value, controller);
  const inputRef = useRef(null);
  const theme = useContext(ThemeContext);
  const [, setControlTag] = useGlobal(
    state => state.controlTag,
    actions => actions.setControlTag
  );
  const [, setEditorIsOpen] = useGlobal(
    state => state.editorIsOpen,
    actions => actions.setEditorIsOpen
  );
  const escPress = useKeyPress('Escape');
  const enterPress = useKeyPress('Enter');

  useEffect(() => {
    inputRef.current.focus();
    setEditorIsOpen(true);
  }, []);

  useEffect(() => {
    if (value && enterPress) {
      onSubmit();
    }
    if (escPress) {
      setEditorIsOpen(false);
    }
  }, [escPress, enterPress]);

  return (
    <>
      <Container>
        <InputText
          ref={inputRef}
          value={value}
          onChange={onChange}
          onFocus={() => setShowOptions(true)}
          //onBlur={() => setTimeout(() => setShowOptions(false), 1000)}
          placeholder={t(strings.tag_description)}
        />
        <ButtonIcon onClick={onSubmit} style={{ backgroundColor: theme.secondary }}>
          <Icon name="edit-icon" />
        </ButtonIcon>
      </Container>
      {showOptions && (
        <Options className="scrollbar">
          {isLoading && <span style={{ paddingLeft: '10px' }}>{`${t(strings.loading)}...`}</span>}
          {tags.map((item, id) => (
            <Option
              key={id}
              onClick={() => {
                onSelect(item);
                setControlTag(item.description);
                setTimeout(() => {
                  onSubmit(item);
                }, 100);
              }}
            >
              {item.description}
            </Option>
          ))}
        </Options>
      )}
    </>
  );
}

export default TextEditorTag;
