import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Table, Collapse } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
import Searchbar from 'components/Searchbar';
import {
  ContainerTable,
  Button,
  LinkedItems,
  Select,
  StepButton,
  StepMethod,
  StepTitle,
  StepContainer,
  StepButtonContainer,
  StepCollapseContainer,
  StepHeader,
  BodyContainer
} from './styles';
import Dialog from 'components/Dialog';
import { loading } from 'store/ducks/general';
import Switch from 'components/Switch/Switch';
import Moment from 'react-moment';
import { getModels } from 'services/Api/Model/model';
import InfiniteScroll from 'components/InfiniteScroll';
import { setSearchTotal } from 'store/ducks/filter';
import {
  deleteApiAssociation,
  getAssociationsTotal,
  getFtpInfo,
  makeApiAssociation
} from 'services/Api/Subscriptions/subscriptions';
import CopyPanel from './CopyPanel';
import FTPInfo from './FTPInfo';
import { t, strings } from 'language';

function LinkModels({ navigation }) {
  const scrollRef = useRef();
  const [hasMore, setHasmore] = useState(true);
  const auth = useSelector(state => state.auth);
  const filter = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [rules, setRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [totalAssociation, setTotalAssociation] = useState({});
  const [modal, setModal] = useState(false);
  const [collapsedStep1, setCollapsedStep1] = useState(false);
  const [collapsedStep2, setCollapsedStep2] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedStep, setSelectedStep] = useState('');
  const [ftpInfo, setFtpInfo] = useState({});

  useEffect(() => {
    getTotalAssociations();
  }, []);

  async function getTotalAssociations() {
    dispatch(loading(true));
    const result = await getAssociationsTotal();
    const resultFtpInfo = await getFtpInfo();
    setFtpInfo(resultFtpInfo);
    setTotalAssociation(result[0]);
    dispatch(loading(false));
  }
  const switchModal = step => {
    switch (step) {
      case 1:
        if (!collapsedStep1) {
          setCollapsedStep1(true);
          setCollapsedStep2(false);
        } else {
          setCollapsedStep1(false);
          setCollapsedStep2(false);
        }
        break;
      case 2:
        if (!collapsedStep2) {
          setCollapsedStep1(false);
          setCollapsedStep2(true);
        } else {
          setCollapsedStep2(false);
          setCollapsedStep1(false);
        }
        break;
    }
  };
  const closeDialog = () => {
    setModal(false);
  };
  const openDialog = () => {
    setModal(true);
  };

  async function getData(page) {
    if (page === 'reset') {
      setData([]);
      setHasmore(true);
      return;
    }
    setHasmore(false);

    const result = await getModels(page, 16, {
      query: search
    });
    setHasmore(result.rowCount > result.currentPage * result.pageSize);
    // console.log('data', data);
    // debugger;
    setData([...data, ...result.results]);
    dispatch(setSearchTotal(result.rowCount));
  }

  const handleStatusUpdate = async item => {
    dispatch(loading(true));
    // console.log('id', item);

    if (item.apiKeysAssociated.length > 0) {
      await deleteApiAssociation({
        model: item.id,
        inferencePoint: item.checkpoints[item.checkpoints.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
    } else {
      await makeApiAssociation({
        model: item.id,
        inferencePoint: item.checkpoints[item.checkpoints.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
    }

    getData('reset');
    scrollRef.current.resetScroll();
    dispatch(loading(false));

    // setRoles(rolesList.results);
  };

  const handleChangeStatusUpdate = async (item, newInferencePoint) => {
    dispatch(loading(true));
    // console.log('id', item);

    if (item.apiKeysAssociated.length > 0) {
      await deleteApiAssociation({
        model: item.id,
        inferencePoint: item.apiKeysAssociated[item.apiKeysAssociated.length - 1].inferencePoint,
        apikey: totalAssociation.apiKeys.apiKey
      });
      await makeApiAssociation({
        model: item.id,
        inferencePoint: parseInt(newInferencePoint),
        apikey: totalAssociation.apiKeys.apiKey
      });

      //setData([]);
      getTotalAssociations();

      // await getData('reset');
      // await getData(1);
      getData('reset');
      scrollRef.current.resetScroll();
    }

    dispatch(loading(false));
  };

  const getMaxModelAssociations = () => {
    if (totalAssociation?.apiKeys?.maxModelAssociations == -1) {
      return 'ilimitado';
    }
    if (totalAssociation?.apiKeys?.maxModelAssociations > 0) {
      return totalAssociation?.apiKeys?.maxModelAssociations;
    } else {
      return 0;
    }
  };

  return (
    <Container>
      <TitleBreadcrumb position={2} url={'/subscriptioncontrol/linkmodel'} name={t(strings.setup)} />
      <Searchbar
        value={search}
        onChange={setSearch}
        onMakeSearch={() => scrollRef.current.resetScroll()}
        onResetData={() => scrollRef.current.resetScroll()}
        placeholder={'Buscar modelos'}
        custom={
          <LinkedItems>
            Associados: {totalAssociation?.count || 0}/{getMaxModelAssociations()}
          </LinkedItems>
        }
      />

      <InfiniteScroll ref={scrollRef} getData={getData} hasMore={hasMore} length={data.length}>
        <ContainerTable>
          <Table>
            <thead>
              <tr>
                <th>{t(strings.name)}</th>
                <th>{t(strings.type)}</th>
                <th>{t(strings.network)}</th>
                <th>{t(strings.status)}</th>
                <th>{t(strings.steps)}</th>
                <th>{t(strings.instructions)}</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(item => {
                return (
                  <tr key={`${item.id}`}>
                    <th scope="row">{item.name}</th>
                    <td>{item.networkType}</td>
                    {/* <td>
                      {item.lastTrainingSession && (
                        <Moment
                          local
                          utc
                          format="DD/MM/YYYY HH:mm"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {item?.lastTrainingSession?.finishedAt}
                        </Moment>
                      )}
                    </td> */}
                    <td>{item.networkType}</td>
                    <td>
                      <Switch
                        onColor="${props => props.theme.primary}"
                        disabled={item?.checkpoints?.length > 0 ? false : true}
                        ison={item?.apiKeysAssociated?.length > 0 ? true : false}
                        handleToggle={() => handleStatusUpdate(item)}
                        name={item.id}
                        checkOff
                      />
                    </td>
                    <td>
                      <Select
                        disabled={item?.apiKeysAssociated?.length > 0 ? false : true}
                        defaultValue={
                          item?.apiKeysAssociated && item?.apiKeysAssociated[0]?.inferencePoint
                        }
                        // value={
                        //   item?.apiKeysAssociated && item?.apiKeysAssociated[0]?.inferencePoint
                        // }
                        onChange={event => handleChangeStatusUpdate(item, event.target.value)}
                      >
                        {item?.checkpoints.map(it => {
                          return (
                            <option key={`${item.id}${it.id}`} value={it.inferencePoint}>
                              {it.inferencePoint}
                            </option>
                          );
                        })}
                      </Select>
                    </td>
                    <td>
                      <Button
                        disabled={item?.apiKeysAssociated?.length > 0 ? false : true}
                        onClick={() => {
                          openDialog();
                          setSelectedModelId(item?.id);
                          setSelectedStep(
                            item?.checkpoints[item?.checkpoints?.length - 1]?.inferencePoint
                          );
                        }}
                      >
                        {t(strings.see_instructions)}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ContainerTable>
      </InfiniteScroll>

      <Dialog title={'Informações da API'} open={modal} width={800} close={() => closeDialog()}>
        <div style={{ wordBreak: 'break-all' }}>
          {t(strings.link_models_msg_1)}
          <StepContainer>
            <StepHeader>
              <StepTitle>{t(strings.step_1)}</StepTitle>
              <StepButtonContainer>
                <StepMethod>POST</StepMethod>
                <StepButton onClick={() => switchModal(1)} open={collapsedStep1}>
                  {collapsedStep1 ? 'Fechar detalhes' : 'Abrir detalhes'}
                </StepButton>
              </StepButtonContainer>
            </StepHeader>

            <Collapse isOpen={collapsedStep1}>
              <StepCollapseContainer>
                <CopyPanel
                  prefix="HTTP"
                  navigation={navigation}
                  value={`https://smartvisioninfer.azurewebsites.net/api/infer?api_key=${totalAssociation
                    ?.apiKeys?.apiKey ||
                    'API_KEY'}&model_key=model_${selectedModelId}_${selectedStep}&min_threshold=0.6`}
                />
                <h5>Parâmetros</h5>
                <table>
                  <thead>
                    <th>{t(strings.name)}</th>
                    <th>{t(strings.description)}</th>
                    <th>{t(strings.type)}</th>
                    <th>{t(strings.status)}</th>
                    <th>{t(strings.details)}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>model_key</td>
                      <td>id do modelo</td>
                      <td>String</td>
                      <td>obrigatório</td>
                    </tr>
                    <tr>
                      <td>api_key</td>
                      <td>código da api</td>
                      <td>String</td>
                      <td>obrigatório</td>
                    </tr>
                    <tr>
                      <td>min_threshold</td>
                      <td>Nível do confidence</td>
                      <td>Double</td>
                      <td>opcional (default 0.6)</td>
                    </tr>
                  </tbody>
                </table>

                <h5 style={{ marginTop: 10 }}>Corpo</h5>
                <h6>formData</h6>
                <BodyContainer>imagem.jpg : binary</BodyContainer>
                <small>{t(strings.link_models_msg_2)}</small>

                <h4 style={{ marginTop: 15 }}>{t(strings.result)}:</h4>

                <BodyContainer border>
                  {'{'}
                  <br />
                  &nbsp;&nbsp;&nbsp;"ticket":String
                  <br />
                  {'}'}
                </BodyContainer>
                <small>{t(strings.link_models_msg_3)}</small>
              </StepCollapseContainer>
            </Collapse>
          </StepContainer>
          <StepContainer>
            <StepHeader>
              <StepTitle>{t(strings.step_2)}</StepTitle>
              <StepButtonContainer>
                <StepMethod>GET</StepMethod>
                <StepButton onClick={() => switchModal(2)} open={collapsedStep2}>
                  {collapsedStep2 ? 'Fechar detalhes' : 'Abrir detalhes'}
                </StepButton>
              </StepButtonContainer>
            </StepHeader>

            <Collapse isOpen={collapsedStep2}>
              <StepCollapseContainer>
                <CopyPanel
                  prefix="HTTP"
                  navigation={navigation}
                  value={`https://smartvisioninfer.azurewebsites.net/api/getresult?ticket=TICKET`}
                />
                <h5>{t(strings.parameters)}</h5>
                <table>
                  <thead>
                    <th>{t(strings.name)}</th>
                    <th>{t(strings.description)}</th>
                    <th>{t(strings.type)}</th>
                    <th>{t(strings.details)}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ticket</td>
                      <td>resultado do passo 1</td>
                      <td>String</td>
                      <td>obrigatório</td>
                    </tr>
                  </tbody>
                </table>
                <h4 style={{ marginTop: 15 }}>{t(strings.result)}:</h4>

                <BodyContainer border>
                  {'{'}
                  <br />
                  "ticket": "string",
                  <br /> "return_code": 0,
                  <br /> "RequestTime": "2020-01-01T00:00:00.690Z",
                  <br />
                  "StartTime": "2021-01-01T00:00:00.690Z",
                  <br /> "EndTime": "2021-01-01T00:00:00.690Z",
                  <br />
                  "Eps": 0, <br />
                  "ReturnJson": "string",
                  <br /> "Message": "string"
                  <br />
                  {'}'}
                </BodyContainer>
              </StepCollapseContainer>
            </Collapse>
          </StepContainer>
        </div>

        <FTPInfo
          data={{
            ...ftpInfo,
            protocol: 'FTP',
            port: '21',
            modelIdStep: `model_${selectedModelId}_${selectedStep}`
          }}
        />
      </Dialog>
    </Container>
  );
}

export default LinkModels;
