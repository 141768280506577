import { takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AuthAPI } from 'services/Api/Auth';

import { SubscriptionApi } from 'services/Api/Subscriptions';

import history from 'services/history';
import { t, strings } from 'language';
import { loading } from './general';

export const Types = {
  LOGOUT: 'login/LOGOUT',
  LOGIN: 'login/LOGIN',
  ME: 'login/ME',
  SET_USER_DATA: 'login/SET_USER_DATA',
  SET_ACTIVE_SUBSCRIPTION: 'login/SET_ACTIVE_SUBSCRIPTION'
};

const INITIAL_STATE = {
  loggedin: false,
  token: '',
  user: {
    name: '',
    email: '',
    avatar: '',
    id: 0,
    subscriptions: [
      {
        id: 0,
        name: '',
        type: '',
        role: ''
      }
    ],
    status: 0
  }
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.LOGOUT:
      //AsyncStorage.removeItem('token');
      return {
        token: '',
        user: INITIAL_STATE.user,
        loggedin: false
      };
    case Types.SET_USER_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function Login({ username, password }) {
  return {
    type: Types.LOGIN,
    payload: {
      username,
      password
    }
  };
}

export function SetActiveSubscription(subscriptionId) {
  return {
    type: Types.SET_ACTIVE_SUBSCRIPTION,
    payload: subscriptionId
  };
}
export function Me() {
  return {
    type: Types.ME
  };
}

export function Logout() {
  return {
    type: Types.LOGOUT
  };
}

/*---------------------*/

function* login(action) {
  const { payload } = action;
  yield put(loading(true));
  try {
    const payloadRequest = { login: payload.username, password: payload.password };
    let result = yield call(AuthAPI.login, payloadRequest);

    const { token } = result;

    yield put({
      type: Types.SET_USER_DATA,
      payload: {
        token: token,
        loggedin: true
      }
    });
    localStorage.setItem('token', token);
    yield put(Me());
    //payload.navigation.navigate('RegistrationSummary');
  } catch (error) {
    //alert('Erro ao efetuar login. Tente novamente mais tarde.');
    toast.error(t(strings.auth_error_1));
  }
  yield put(loading(false)); 
}

function* setActiveSubscription(action) {
  const { payload } = action;
  yield put(loading(true));
  try {
    let result = yield call(SubscriptionApi.changeActiveSubscription, payload);
    const { token } = result;

    yield put({
      type: Types.SET_USER_DATA,
      payload: {
        token: token,
        loggedin: true
      }
    });
    localStorage.setItem('token', token);
    yield put(Me());
    //payload.navigation.navigate('RegistrationSummary');
  } catch (error) {
    //alert('Erro ao efetuar login. Tente novamente mais tarde.');
    toast.error(t(strings.auth_error_1));
  }
  yield put(loading(false));
}

function* me(action) {
  yield put(loading(true));
  try {
    let result = yield call(AuthAPI.me);

    const { id, name, email, avatar, subscriptions, activeSubscription } = result;

    yield put({
      type: Types.SET_USER_DATA,
      payload: {
        user: {
          name: name,
          email: email,
          avatar: avatar,
          id: id,
          subscriptions: subscriptions,
          activeSubscription
        }
      }
    });
    const OneSignal = window.OneSignal || [];

    OneSignal.push(function() {
      OneSignal.getUserId(function(userId) {
        // console.log('OneSignal User ID inside me:', userId);
        AuthAPI.updateUserPushToken(userId);
        // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
      });
    });
    history.push('/main');
    //payload.navigation.navigate('RegistrationSummary');
  } catch (error) {
    //alert('Erro ao efetuar login. Tente novamente mais tarde.');
    toast.error(t(strings.auth_error_2));
    throw error;
  }
  yield put(loading(false));
}

//--------------------------------------------//
export function getToken() {
  return localStorage.getItem('token');
}
//--------------------------------------------//

export const sagas = [
  takeLatest(Types.LOGIN, login),
  takeLatest(Types.ME, me),
  takeLatest(Types.SET_ACTIVE_SUBSCRIPTION, setActiveSubscription)
];
