/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { t, strings } from 'language';
import { Container, Row, Col } from 'reactstrap';
import { chain } from 'lodash';
import useGlobal from 'store/global';
import { useSelector, useDispatch } from 'react-redux';
// import { getDataset } from 'services/Api/Dataset/dataset';
import { showTooltip } from 'store/ducks/tooltip';
import { getIdFromUrl } from 'utils/helpers';
import { Icon } from 'components/Icon';
import useCookie from 'components/useCookie';
import { Title, TagDiv, TopButton } from './styles';
import TagItem from './components/TagItem';
import './styles.css';

function Tags(props) {
  const { history, size, cookie } = props;
  const { height } = size;
  const location = history.location.pathname;
  const [inferenceID] = useGlobal(state => state.inferenceID);
  const isInference = !!(inferenceID > 0 && getIdFromUrl(location, 3) === 'inference');
  const annotate = useSelector(state => (isInference ? state.inference : state.annotate));
  const { selectedImage, images, dataset } = annotate;
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [, setViewerDialog] = useGlobal(
    state => state.viewerDialog,
    actions => actions.setViewerDialog
  );
  const { email } = auth.user;
  const [, setCookieCursor] = useCookie(`cursor-${email}`);
  const [cursor, setCursor] = useGlobal(
    state => state.cursor,
    actions => actions.setCursor
  );
  const [, setShowTags] = useGlobal(
    state => state.showTags,
    actions => actions.setShowTags
  );
  const [floatTags, setFloatTags] = useGlobal(
    state => state.floatTags,
    actions => actions.setFloatTags
  );
  const [, editingfloatPos] = useGlobal(
    state => state.floatPos,
    actions => actions.editingfloatPos
  );
  const [, setModal] = useGlobal(
    state => state.modal,
    actions => actions.setModal
  );
  const [, setTitleModal] = useGlobal(
    state => state.titleModal,
    actions => actions.setTitleModal
  );
  const [, setTextModal] = useGlobal(
    state => state.textModal,
    actions => actions.setTextModal
  );
  const [, setDelItem] = useGlobal(
    state => state.delItem,
    actions => actions.setDelItem
  );
  const dispatch = useDispatch();
  const [showTagsName, setShowTagsName] = useGlobal(
    state => state.showTagsName,
    actions => actions.setShowTagsName
  );
  const [showAllTags, setShowAllTags] = useGlobal(
    state => state.showAllTags,
    actions => actions.setShowAllTags
  );
  const [hideTags, setHideTags] = useGlobal(
    state => state.hideTags,
    actions => actions.setHideTags
  );
  const [, setSelectedAnnotate] = useGlobal(
    state => state.selectedAnnotate,
    actions => actions.setSelectedAnnotate
  );
  const control = selectedImage.tags?.length === hideTags.box?.length + hideTags.polygon?.length;

  let tags = [];
  images &&
    images
      .filter(images => images.tags.length > 0 && images.tags, {})
      .map(imageTags => {
        // eslint-disable-next-line no-shadow
        tags = tags.concat(imageTags.tags.map(tags => tags, {}));
        return null;
      }, {});

  function filterTest(value) {
    return `${value.type}-${value.tagId}`;
  }

  const groupByTag = chain(selectedImage.tags)
    .groupBy(filterTest)
    .map((value, key) => ({
      tagId: key,
      name: value[0].tag,
      color: value[0].color,
      count: value.length,
      type: value[0].type,
      tags: value
    }))
    .sort((a, b) => {
      return b.count - a.count;
    })
    .value();

  const totalAnnotateTags = annotate.dataset && annotate.dataset.tags;
  const totalTags = chain(totalAnnotateTags)
    .groupBy('type')
    .map(value =>
      chain(value)
        .groupBy('tag.id')
        .map((value, key) => {
          const exist = groupByTag.find(obj => {
            return obj.tagId === `${value[0].type}-${value[0].tag.id}`;
            //return obj.tagId === value[0].tag.id && obj.type === value[0].type;
          });
          return {
            tagId: key,
            name: value[0].tag.description,
            color: value[0].tag.color,
            count: value[0].count,
            type: value[0].type,
            exist: exist ? exist.count : 0
          };
        })
        .sort((a, b) => {
          return b.exist - a.exist;
        })
        .value()
    )
    .value();

  function deleteAllAnnotates() {
    setTitleModal(t(strings.confirm_delete_all_tags));
    setTextModal(t(strings.all_tags_of_this));
    setDelItem({ value: selectedImage, type: 'all' });
    setModal(true);
  }

  function handleSetCursor() {
    setCookieCursor(!cursor);
    setCursor(!cursor);
  }

  function handleTagNames() {
    setShowTagsName(!showTagsName);
  }

  function handleTags() {
    const tags = { box: [], polygon: [] };
    if (showAllTags) {
      selectedImage.tags.map(tag => {
        switch (tag.type) {
          case 'Polygon':
            tags.polygon.push(tag.tagId);
            break;
          default:
            tags.box.push(tag.tagId);
        }
      });
    }

    setHideTags(tags);
    setSelectedAnnotate([]);
    setShowAllTags(!showAllTags);
  }

  function toolTipCursor() {
    if (cursor) {
      dispatch(showTooltip(t(strings.cursor_default)));
    } else {
      dispatch(showTooltip(t(strings.cursor_crosshair)));
    }
  }

  function toolTipForAllTagsName() {
    if (showTagsName) {
      dispatch(showTooltip(t(strings.hide_all_names)));
    } else {
      dispatch(showTooltip(t(strings.show_all_names)));
    }
  }

  function toolTipForAllTags() {
    if (showAllTags) {
      dispatch(showTooltip(t(strings.hide_all_tags)));
    } else {
      dispatch(showTooltip(t(strings.show_all_tags)));
    }
  }

  useEffect(() => {
    setShowAllTags(!control);
  }, [control]);

  return (
    <Container className="mh-100 no-margin">
      <Row className="d-flex">
        <Col className="col">
          <Title>{t(strings.tags)}</Title>
          <TopButton
            style={{ float: 'right' }}
            onClick={() => {
              setFloatTags(false);
              setShowTags(false);
              editingfloatPos(10, -20, 400, 600);
            }}
          >
            <Icon name="right" style={{ width: '20px', height: '20px' }} />
          </TopButton>
          <TopButton
            style={{ display: `${floatTags ? 'none' : 'block'}`, float: 'right' }}
            onClick={() => setFloatTags(true)}
          >
            <Icon name="move" style={{ width: 20, height: 20 }} />
          </TopButton>
          <TopButton
            style={{ display: `${floatTags ? 'block' : 'none'}`, float: 'right' }}
            onClick={() => {
              setFloatTags(false);
              setShowTags(true);
              editingfloatPos(10, -20, 400, 600);
            }}
          >
            <Icon name="move" />
          </TopButton>
          {!viewer && (
            <TopButton
              style={{ float: 'right' }}
              onClick={() => {
                deleteAllAnnotates();
              }}
              onMouseEnter={() => dispatch(showTooltip(t(strings.delete_all_tags)))}
              onMouseLeave={() => dispatch(showTooltip(false))}
            >
              <Icon name="delete-tags" style={{ width: '20px', height: '20px' }} />
            </TopButton>
          )}
          {viewer && (
            <TopButton
              style={{ float: 'right' }}
              onClick={() => {
                setViewerDialog(true);
              }}
              onMouseEnter={() => dispatch(showTooltip(t(strings.delete_all_tags)))}
              onMouseLeave={() => dispatch(showTooltip(false))}
            >
              <Icon name="delete-tags" style={{ width: '20px', height: '20px' }} />
            </TopButton>
          )}
          <TopButton
            style={{ float: 'right' }}
            onClick={() => {
              handleTags();
            }}
            onMouseEnter={() => toolTipForAllTags()}
            onMouseLeave={() => dispatch(showTooltip(false))}
          >
            {showAllTags ? (
              <Icon name="hide-tags" style={{ width: '20px', height: '20px' }} />
            ) : (
              <Icon name="show-tags" style={{ width: '20px', height: '20px' }} />
            )}
          </TopButton>
          <TopButton
            style={{ float: 'right' }}
            onClick={() => {
              handleTagNames(0);
            }}
            onMouseEnter={() => toolTipForAllTagsName()}
            onMouseLeave={() => dispatch(showTooltip(false))}
          >
            {showTagsName ? (
              <Icon name="hide-title" style={{ width: '20px', height: '20px' }} />
            ) : (
              <Icon name="show-title" style={{ width: '20px', height: '20px' }} />
            )}
          </TopButton>
          <TopButton
            style={{ float: 'right' }}
            onClick={() => {
              handleSetCursor();
            }}
            onMouseEnter={() => toolTipCursor()}
            onMouseLeave={() => dispatch(showTooltip(false))}
          >
            {cursor ? (
              <Icon name="plus" style={{ width: '20px', height: '20px' }} />
            ) : (
              <Icon name="cursor-default" style={{ width: '20px', height: '20px' }} />
            )}
          </TopButton>
        </Col>
      </Row>
      <TagDiv style={{ height: height - 282 }}>
        <div className="scrollbar overflow" style={{ height: height - 284 }}>
          {totalTags.map(type => {
            return type.map((tag, index) => {
              return (
                <TagItem
                  key={index}
                  item={tag}
                  dataset={dataset}
                  selectedImage={selectedImage}
                  history={history}
                  cookie={cookie}
                  tagsPasta={groupByTag.find(obj => {
                    return obj.tagId === `${tag.type}-${tag.tagId}`;
                  })}
                />
              );
            });
          })}
        </div>
      </TagDiv>
    </Container>
  );
}
export default Tags;
