import React, { useState, useEffect } from 'react';
import useGlobal from 'store/global';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { loading } from 'store/ducks/general';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Container, ButtonItem } from './styles';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';

export default function BulkDelete({ onResetData, deleteFunction }) {
  const dispatch = useDispatch();
  const [deleteMode, setDeleteMode] = useGlobal(
    state => state.deleteMode,
    actions => actions.setDeleteMode
  );

  const [deleteModeList, clearDeleteModeList] = useGlobal(
    state => state.deleteModeList,
    actions => actions.clearDeleteModeList
  );

  useEffect(() => {
    if (deleteMode) {
      toast.info(t(strings.bulkdelete_info_1));
    }
  }, [deleteMode]);

  const handleDeleteConfirmation = async () => {
    let deleteItems;
    dispatch(loading(true));

    try {
      deleteItems = await deleteFunction(deleteModeList);
      setDeleteMode(false);
      clearDeleteModeList();
      onResetData();
      toast.success(t(strings.bulkdelete_success));
    } catch (error) {
      toast.error(t(strings.bulkdelete_error));
    }

    dispatch(loading(false));
  };
  const handleConfirm = () => {
    if (deleteModeList.length <= 0) {
      toast.info(t(strings.bulkdelete_info_2));
      return;
    }

    confirmAlert({
      title: t(strings.bulkdelete_alert_title),
      message: t(strings.bulkdelete_msg_1)+' '+deleteModeList.length+' '+(strings.bulkdelete_msg_2),
      buttons: [
        {
          label: t(strings.confirm),
          onClick: () => handleDeleteConfirmation()
        },
        {
          label: t(strings.cancel)
        }
      ]
    });
  };

  const handleCancel = () => {
    setDeleteMode(!deleteMode);
    clearDeleteModeList();
  };
  return (
    <Container>
      <ButtonItem
        onClick={
          deleteMode
            ? () => handleConfirm()
            : () => {
                setDeleteMode(!deleteMode);
                clearDeleteModeList();
              }
        }
      >
        {deleteMode ? <Icon name="confirm" /> : <Icon name="delete_icon" />}
      </ButtonItem>
      {deleteMode && (
        <ButtonItem onClick={() => handleCancel()}>
          <Icon name="close" />
        </ButtonItem>
      )}
    </Container>
  );
}
