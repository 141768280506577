import styled from 'styled-components';

export const Header = styled.div`
  margin-top: 50px;
  cursor: default;
`;

export const ContainerAnnotate = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  color: ${props => props.theme.default};
  height: 100%;
  overflow: hidden;
  padding-bottom: 19px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  margin: 0;
`;

export const Dragg = styled.div`
  position: absolute;
  width: 400px;
  z-index: 200;
  background: ${props => props.theme.secondary};
`;

export const Title = styled.small`
  color: ${props => props.theme.secondary};
  cursor: pointer;
  writing-mode: vertical-rl;
  margin-left: 10px;
`;

export const BtnShow = styled.div`
  background: ${props => props.theme.secondary};
  border: none;
  display: block;
  padding: 2px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const ImageToRotate = styled.img`
  display: block;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
`;
