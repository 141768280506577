import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loading } from 'store/ducks/general';
import { SubscriptionApi } from 'services/Api/Subscriptions';
import Dialog from 'components/Dialog';
import { InputField } from './styles';
import { t, strings } from 'language';

export default function(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  async function InviteMember() {
    props.onClose();
    dispatch(loading(true));
    try {
      await SubscriptionApi.inviteMember(email);
      setEmail('');
      toast.success('Member Invited');
    } catch (error) {
      toast.error('Error on invite member');
    }
    dispatch(loading(false));
  }

  return (
    <Dialog
      title={t(strings.invite_new_member)}
      open={props.show}
      actions={[
        {
          text: t(strings.cancel),
          action: () => {
            props.onClose();
            setEmail('');
          }
        },
        {
          text: t(strings.send),
          gold: true,
          action: () => {
            InviteMember();
          }
        }
      ]}
    >
      <InputField
        type="email"
        value={email}
        placeholder={t(strings.enter_new_members_email)}
        onChange={e => setEmail(e.target.value)}
       />
    </Dialog>
  );
}
