import React, { useState, useEffect } from 'react';
import history from 'services/history';
import { Container } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';

import { getModel } from 'services/Api/Model/model';
import { getIdFromUrl } from 'utils/helpers';
import { LineChart } from 'components/Charts';
import Matrix from 'components/Matrix';

// import { Container } from './styles';

export default function Models(props) {
  const [model, setModel, fluid] = useState(null);

  useEffect(() => {
    async function getData() {
      const id = getIdFromUrl(history.location.pathname, 2);
      setModel(await getModel(id));
    }
    getData();
  }, []);

  return (
    <Container fluid>
      <TitleBreadcrumb
        position={4}
        name="Estatisticas"
        url={`/model/${model && model.id}/statistics`}
      ></TitleBreadcrumb>
      <LineChart modelId={model && model.id} height="400px" />
      <Matrix modelId={model && model.id}></Matrix>
    </Container>
  );
}
