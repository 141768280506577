import React, { useEffect, useState, useRef } from 'react';
import { Collapse } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import Searchbar from 'components/Searchbar';
import CustomItemButtom, { iconType } from 'components/CustomItemButtom';
import { loading } from 'store/ducks/general';

import {
  showSelectDatasetInference,
  selectTrainingDataset,
  selectEvalDataset,
  selectInferenceDataset
} from 'store/ducks/training';
import DatasetTooltip from 'components/GlobalTooltip/datasetTooltip';
import { getFolders } from 'services/Api/Dataset/dataset';
import InfiniteScroll from 'components/InfiniteScroll';
import { t, strings } from 'language';
import {
  BlueLabel,
  ContainerFolders,
  LinkSelectFolders,
  FolderContainer,
  FolderTitle,
  FolderButton
} from './styles';

export default function(props) {
  // const scrollRef = useRef();
  // const [hasMore, setHasmore] = useState(true);
  // const [data, setData] = useState([]);
  const [collapseStatus, setCollapseStatus] = useState([]);
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);
  const [folders, setFolders] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedDatasets, setSelectedDatasets] = useState(training.selectedInferenceDatasets);

  const searchDebounce = useRef();

  async function getData(search) {
    dispatch(loading(true));
    const result = await getFolders(1, 1000, search, false);
    setCollapseStatus(result.results.map(i => false));
    // console.log();
    setFolders(result.results.filter(i => i.datasets?.length > 0));
    dispatch(loading(false));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (searchDebounce.current) {
      clearTimeout(searchDebounce.current);
    }
    searchDebounce.current = setTimeout(() => {
      getData(search);
    }, 1000);
  }, [search]);

  function toggleCollapse(index) {
    const status = [...collapseStatus];
    status[index] = !collapseStatus[index];
    setCollapseStatus(status);
  }

  function addDataset(dataset) {
    let newSelectedDatasets = selectedDatasets;
    if (isSelected(dataset)) {
      newSelectedDatasets = selectedDatasets.filter(item => item.id !== dataset.id);
    } else {
      newSelectedDatasets = [...newSelectedDatasets, dataset];
    }
    setSelectedDatasets(newSelectedDatasets);
  }

  function selectAllInFolder(folderId) {
    const datasets = selectedDatasets;

    const folderOpened = folders.filter(i => i.id === folderId)[0];

    folderOpened.datasets.forEach(dataset => {
      const datasetItem = selectedDatasets.filter(i => i.id === dataset.id)[0];
      if (!datasetItem) datasets.push(dataset);
    });

    setSelectedDatasets([...datasets]);
  }

  function selectAll() {
    const datasets = [].concat.apply(
      [],
      folders.map(i => i.datasets)
    );

    const collapse = [];
    for (let index = 0; index < collapseStatus.length; index++) {
      collapse[index] = true;
    }
    setCollapseStatus(collapse);
    setSelectedDatasets(datasets);
  }

  function deselectAllInFolder(folderId) {
    const selecteds = [...selectedDatasets];
    const { datasets } = folders.filter(i => i.id === folderId)[0];

    for (let index = 0; index < selectedDatasets.length; index++) {
      const element = selectedDatasets[index];

      if (datasets.filter(i => i.id === element.id)[0]) {
        selecteds[index] = null;
      }
    }

    setSelectedDatasets(selecteds.filter(i => i !== null));
  }

  function deselectAll() {
    const collapse = [];
    for (let index = 0; index < collapseStatus.length; index++) {
      collapse[index] = false;
    }
    setCollapseStatus(collapse);
    setSelectedDatasets([]);
  }

  function isSelected(dataset) {
    return !!selectedDatasets.find(item => item.id === dataset.id);
  } 

  return (
    <Dialog
      title={t(strings.select_datasets)}
      smallButtons
      open
      width="80%"
      small
      actions={[
        {
          text: t(strings.cancel),
          action: () => {
            dispatch(showSelectDatasetInference(false));
          }
        },
        {
          text: t(strings.select),
          action: () => {
            dispatch(selectInferenceDataset(selectedDatasets));
            dispatch(showSelectDatasetInference(false));
          },
          gold: true
        }
      ]}
    >
      <BlueLabel>{t(strings.images)}</BlueLabel>
      <Searchbar
        onChange={text => {
          setSearch(text);
        }}
        // onMakeSearch={() => scrollRef.current.resetScroll()}
        // onResetData={() => scrollRef.current.resetScroll()}
      >
        <div className="d-flex h-100 align-items-end">
          <LinkSelectFolders onClick={selectAll}>{t(strings.select_all_folders)}</LinkSelectFolders>
          <LinkSelectFolders onClick={deselectAll}>{t(strings.uncheck_folders)}</LinkSelectFolders>
        </div>
      </Searchbar>
      {/* <InfiniteScroll ref={scrollRef} getData={getData} hasMore={hasMore} length={data.length}>
        {folders.map((folder, index) => {
          return (
            <>
              <FolderContainer>
                <FolderTitle>
                  <p>{folder.name}</p>
                  <div className="d-flex    align-items-center">
                    {collapseStatus[index] && (
                      <>
                        <LinkSelectFolders onClick={() => selectAllInFolder(folder.id)}>
                          Selecionar todas
                        </LinkSelectFolders>
                        <LinkSelectFolders
                          onClick={() => {
                            deselectAllInFolder(folder.id);
                          }}
                        >
                          Desmarcar todas
                        </LinkSelectFolders>
                      </>
                    )}
                    <FolderButton
                      onClick={() => toggleCollapse(index)}
                      open={collapseStatus[index]}
                    />
                  </div>
                </FolderTitle>
                <Collapse isOpen={collapseStatus[index]} className="mt-3">
                  {folder.datasets.map((item, index) => {
                    let error = false;
                    let message;
                    if (props.eval) {
                      for (
                        let index = 0;
                        index < training.selectedInferenceDatasets.length;
                        index++
                      ) {
                        const selectedDataset = training.selectedInferenceDatasets[index];

                        if (selectedDataset.id === item.id) {
                          error = true;
                          message = 'MENSAGEM DE ERRO DE DATASET JÁ SELECIONADO';
                          break;
                        }
                      }
                    }
                    return (
                      <CustomItemButtom
                        key={index}
                        error={error}
                        message={message}
                        icon={iconType.folder}
                        selected={isSelected(item)}
                        tooltip={() => <DatasetTooltip {...item} />}
                        onClick={() => {
                          addDataset(item);
                        }}
                      >
                        {item.title}
                      </CustomItemButtom>
                    );
                  })}
                </Collapse>
              </FolderContainer>
            </>
          );
        })}
      </InfiniteScroll> */}
      <ContainerFolders height="24vw">
        {folders.map((folder, index) => {
          return (
            <>
              <FolderContainer>
                <FolderTitle>
                  <p>{folder.name}</p>
                  <div className="d-flex    align-items-center">
                    {collapseStatus[index] && (
                      <>
                        <LinkSelectFolders onClick={() => selectAllInFolder(folder.id)}>
                          {t(strings.select_all)}
                        </LinkSelectFolders>
                        <LinkSelectFolders
                          onClick={() => {
                            deselectAllInFolder(folder.id);
                          }}
                        >
                          {t(strings.uncheck_all)}
                        </LinkSelectFolders>
                      </>
                    )}
                    <FolderButton
                      onClick={() => toggleCollapse(index)}
                      open={collapseStatus[index]}
                    />
                  </div>
                </FolderTitle>
                <Collapse isOpen={collapseStatus[index]} className="mt-3">
                  {folder.datasets.map((item, index) => {
                    let error = false;
                    let message;
                    if (props.eval) {
                      for (
                        let index = 0;
                        index < training.selectedInferenceDatasets.length;
                        index++
                      ) {
                        const selectedDataset = training.selectedInferenceDatasets[index];

                        if (selectedDataset.id === item.id) {
                          error = true;
                          message = t(strings.dataset_already_selected);
                          break;
                        }
                      }
                    }
                    return (
                      <CustomItemButtom
                        key={index}
                        error={error}
                        message={message}
                        icon={iconType.folder}
                        selected={isSelected(item)}
                        tooltip={() => <DatasetTooltip {...item} />}
                        onClick={() => {
                          addDataset(item);
                        }}
                      >
                        {item.title}
                      </CustomItemButtom>
                    );
                  })}
                </Collapse>
              </FolderContainer>
            </>
          );
        })}
      </ContainerFolders>
    </Dialog>
  );
}
