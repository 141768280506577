/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useContext } from 'react';
import useGlobal from 'store/global';
import { t, strings } from 'language';
import { getTagsOfSubscription } from 'services/Api/Tag/tag';
import { getTagsOfDataset } from 'services/Api/Annotate/annotate';
import { Icon } from 'components/Icon';
import { ThemeContext } from 'styled-components';
import ButtonIcon from './components/ButtonIcon';
import SearchTags from './components/SearchTags';
// import { ReactComponent as EditIcon } from './assets/images/edit.svg';
import { Container, InputText, Options, Option } from './styles';
import useKeyPress from '../useKeyPress';

function TextEditor(props) {
  const { value, controller, onChange, onSubmit, onSelect } = props;
  const { tags, isLoading } = SearchTags(value, controller);
  const [showOptions, setShowOptions] = useState(false);
  const inputRef = useRef(null);
  const [, setControlTag] = useGlobal(
    state => state.controlTag,
    actions => actions.setControlTag
  );
  const [oldTag, setOldTag] = useGlobal(
    state => state.oldTag,
    actions => actions.setOldTag
  );
  const [, setTagsDataset] = useGlobal(
    state => state.tagsDataset,
    actions => actions.setTagsDataset
  );
  const [, setEditorIsOpen] = useGlobal(
    state => state.editorIsOpen,
    actions => actions.setEditorIsOpen
  );
  const [dataset] = useGlobal(state => state.dataset);
  const escPress = useKeyPress('Escape');
  const enterPress = useKeyPress('Enter');

  useEffect(() => {
    inputRef.current.focus();
    setEditorIsOpen(true);

    async function getDataDataset() {
      const result = await getTagsOfDataset(dataset);
      setTagsDataset(result);
      return result;
    }

    async function getDataSubscription() {
      const result = await getTagsOfSubscription(1, 1000);
      setTagsDataset(result.results);
      return result.results;
    }
    if (value !== oldTag) {
      if (value) {
        getDataSubscription();
      } else {
        getDataDataset();
      }
      setOldTag(value);
    }
    setShowOptions(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (value && enterPress) {
      onSubmit();
    }
    if (escPress) {
      setEditorIsOpen(false);
    }
    // eslint-disable-next-line
  }, [escPress, enterPress]);

  const theme = useContext(ThemeContext);

  return (
    <>
      <Container>
        <InputText
          ref={inputRef}
          value={value}
          onChange={onChange}
          onFocus={() => setShowOptions(true)}
          //onBlur={() => setTimeout(() => setShowOptions(false), 1000)}
          placeholder={t(strings.tag_description)}
        />
        <ButtonIcon onClick={onSubmit}>
          <Icon name="edit-icon" width="20px" color={theme.secondary} />
        </ButtonIcon>
      </Container>
      {showOptions && (
        <Options className="scrollbar">
          {isLoading && <span style={{ paddingLeft: '10px' }}>{`${t(strings.loading)}...`}</span>}
          {tags.map((item, id) =>
            item.id === 0 ? (
              <hr
                key={`hr_${Math.random()}`}
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  background: theme.default
                }}
              />
            ) : (
              <Option
                key={id}
                onClick={() => {
                  onSelect(item);
                  setControlTag(item.description);
                  setTimeout(() => {
                    onSubmit();
                  }, 100);
                }}
              >
                {item.description}
              </Option>
            )
          )}
        </Options>
      )}
    </>
  );
}

export default React.memo(TextEditor);
