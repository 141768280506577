import React from 'react';
import { GraphContainer, LeftBar, RightBar, LeftText, RightText, Data1, Data2 } from './styles';

function GraphBar(props) {
  const { data1, data2, unit1, unit2 } = props;
  const leftWidth = `${Math.round((data1 * 100) / (data1 + data2))}%`;
  const rightWidth = `${Math.round((data2 * 100) / (data1 + data2))}%`;

  return (
    <>
      <GraphContainer>
        {leftWidth === '0%' ? '' : <LeftBar leftWidth={leftWidth} />}
        {rightWidth === '0%' ? '' : <RightBar rightWidth={rightWidth} />}
        <LeftText>{leftWidth}</LeftText>
        <RightText>{rightWidth}</RightText>
      </GraphContainer>
      <Data1>
        {data1} {unit1}
      </Data1>
      <Data2>
        {data2} {unit2}
      </Data2>
    </>
  );
}

export default GraphBar;
