import React, { useEffect, useState, useRef } from 'react';
import { Collapse } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import Searchbar from 'components/Searchbar';
import CustomItemButtom, { iconType } from 'components/CustomItemButtom';
import { loading } from 'store/ducks/general';
import { t, strings } from 'language';

import {
  BlueLabel,
  ContainerFolders,
  LinkSelectFolders,
  FolderContainer,
  FolderTitle,
  FolderButton
} from './styles';
import { showSelectFolder, selectTrainingDataset, selectEvalDataset } from 'store/ducks/training';
import DatasetTooltip from 'components/GlobalTooltip/datasetTooltip';
import { getFolders } from 'services/Api/Dataset/dataset';

export default function(props) {
  const [collapseStatus, setCollapseStatus] = useState([]);
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);
  const [folders, setFolders] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedDatasets, setSelectedDatasets] = useState(
    props.eval ? training.selectedEvalDatasets : training.selectedTraningDatasets
  );

  const searchDebounce = useRef();

  async function getData(search) {
    dispatch(loading(true));
    const result = await getFolders(1, 1000, search, true);
    setCollapseStatus(result.results.map(i => false));
    setFolders(result.results.filter(i => i.datasets.length > 0));
    dispatch(loading(false));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (searchDebounce.current) {
      clearTimeout(searchDebounce.current);
    }
    searchDebounce.current = setTimeout(() => {
      getData(search);
    }, 1000);
  }, [search]);

  function toggleCollapse(index) {
    let status = [...collapseStatus];
    status[index] = !collapseStatus[index];
    setCollapseStatus(status);
  }

  function addDataset(dataset) {
    let newSelectedDatasets = selectedDatasets;
    if (isSelected(dataset)) {
      newSelectedDatasets = selectedDatasets.filter(item => item.id !== dataset.id);
    } else {
      newSelectedDatasets = [...newSelectedDatasets, dataset];
    }
    setSelectedDatasets(newSelectedDatasets);
  }

  function selectAllInFolder(folderId) {
    let datasets = selectedDatasets;

    if (props.eval) {
      let selectedTraningDatasetsIds = training.selectedTraningDatasets.map(i => i.id);
      datasets = datasets.filter(i => !selectedTraningDatasetsIds.includes(i.id));
    }

    const folderOpened = folders.filter(i => i.id === folderId)[0];

    folderOpened.datasets.forEach(dataset => {
      const datasetItem = selectedDatasets.filter(i => i.id === dataset.id)[0];
      if (!datasetItem) datasets.push(dataset);
    });

    if (props.eval) {
      let selectedTraningDatasetsIds = training.selectedTraningDatasets.map(i => i.id);
      datasets = datasets.filter(i => !selectedTraningDatasetsIds.includes(i.id));
    }

    setSelectedDatasets([...datasets]);
  }

  function selectAll() {
    let datasets = [].concat.apply(
      [],
      folders.map(i => i.datasets)
    );
    if (props.eval) {
      let selectedTraningDatasetsIds = training.selectedTraningDatasets.map(i => i.id);
      datasets = datasets.filter(i => !selectedTraningDatasetsIds.includes(i.id));
    }
    let collapse = [];
    for (let index = 0; index < collapseStatus.length; index++) {
      collapse[index] = true;
    }
    setCollapseStatus(collapse);
    setSelectedDatasets(datasets);
  }

  function deselectAllInFolder(folderId) {
    let selecteds = [...selectedDatasets];
    let datasets = folders.filter(i => i.id === folderId)[0].datasets;

    for (let index = 0; index < selectedDatasets.length; index++) {
      const element = selectedDatasets[index];

      if (datasets.filter(i => i.id === element.id)[0]) {
        selecteds[index] = null;
      }
    }

    setSelectedDatasets(selecteds.filter(i => i !== null));
  }

  function deselectAll() {
    let collapse = [];
    for (let index = 0; index < collapseStatus.length; index++) {
      collapse[index] = false;
    }
    setCollapseStatus(collapse);
    setSelectedDatasets([]);
  }

  function isSelected(dataset) {
    return selectedDatasets.find(item => item.id === dataset.id) ? true : false;
  }

  return (
    <Dialog
      title={'Escolha as pastas'}
      smallButtons
      open={true}
      width={'80%'}
      small
      actions={[
        {
          text: t(strings.cancel),
          action: () => {
            dispatch(showSelectFolder(false));
            dispatch(showSelectFolder(false, true));
          }
        },
        {
          text: t(strings.select),
          action: () => {
            if (props.eval) {
              dispatch(selectEvalDataset(selectedDatasets));
              dispatch(showSelectFolder(false, true));
            } else {
              dispatch(selectTrainingDataset(selectedDatasets));
              dispatch(showSelectFolder(false));
            }
          },
          gold: true
        },
        {
          text: t(strings.select_and_next),
          action: () => {
            if (props.eval) {
              dispatch(selectEvalDataset(selectedDatasets));
              dispatch(showSelectFolder(false, true));
              props.nextStep();
            } else {
              dispatch(selectTrainingDataset(selectedDatasets));
              dispatch(showSelectFolder(false));
              props.nextStep();
            }
          },
          gold: true
        }
      ]}
    >
      <BlueLabel>{t(strings.images)}</BlueLabel>
      <Searchbar
        onChange={text => {
          setSearch(text);
        }}
      >
        <div className="d-flex h-100 align-items-end">
          <LinkSelectFolders onClick={selectAll}>{t(strings.select_all)}</LinkSelectFolders>
          <LinkSelectFolders onClick={deselectAll}>{t(strings.uncheck_all)}</LinkSelectFolders>
        </div>
      </Searchbar>
      <ContainerFolders height="24vw">
        {folders.map((folder, index) => {
          return (
            <>
              <FolderContainer>
                <FolderTitle>
                  <p>{folder.name}</p>
                  <div className="d-flex    align-items-center">
                    {collapseStatus[index] && (
                      <>
                        <LinkSelectFolders onClick={() => selectAllInFolder(folder.id)}>
                          {t(strings.select_all)}
                        </LinkSelectFolders>
                        <LinkSelectFolders
                          onClick={() => {
                            deselectAllInFolder(folder.id);
                          }}
                        >
                          {t(strings.uncheck_all)}
                        </LinkSelectFolders>
                      </>
                    )}
                    <FolderButton
                      onClick={() => toggleCollapse(index)}
                      open={collapseStatus[index]}
                    ></FolderButton>
                  </div>
                </FolderTitle>
                <Collapse isOpen={collapseStatus[index]} className="mt-3">
                  {folder.datasets.map((item, index) => {
                    let error = false;
                    let message = undefined;
                    if (props.eval) {
                      for (
                        let index = 0;
                        index < training.selectedTraningDatasets.length;
                        index++
                      ) {
                        const selectedDataset = training.selectedTraningDatasets[index];

                        if (selectedDataset.id === item.id) {
                          error = true;
                          message = t(strings.dataset_already_selected);
                          break;
                        }
                      }
                    }
                    return (
                      <CustomItemButtom
                        key={index}
                        error={error}
                        message={message}
                        icon={iconType.folder}
                        selected={isSelected(item)}
                        tooltip={() => <DatasetTooltip {...item}></DatasetTooltip>}
                        onClick={() => {
                          addDataset(item);
                        }}
                        width={261}
                      >
                        {item.title}
                      </CustomItemButtom>
                    );
                  })}
                </Collapse>
              </FolderContainer>
            </>
          );
        })}
      </ContainerFolders>
    </Dialog>
  );
}
