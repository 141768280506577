import React, { useContext, useState } from 'react';
import Switch from 'components/Switch/Switch';
import {
  ResponsiveContainer,
  ComposedChart,
  // eslint-disable-next-line
  CartesianGrid,
  LabelList,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import { ThemeContext } from 'styled-components';
import { SwitchOptions, SwitchContainer } from '../../styles';
import CustomTooltip from './tooltip';

function MatrixBySize({ data }) {
  const [switchPrecisionRecallValue, setSwitchPrecisionRecallValue] = useState(false);
  const theme = useContext(ThemeContext);
  const lineSmall = switchPrecisionRecallValue ? data.recallBySize[0] : data.precisionBySize[0];
  const lineMedium = switchPrecisionRecallValue ? data.recallBySize[1] : data.precisionBySize[1];
  const lineLarge = switchPrecisionRecallValue ? data.recallBySize[2] : data.precisionBySize[2];

  function getBarData(label, line) {
    if (!data) {
      return null;
    }
    const { value } = data.matrixBySize.find(i => i.x === label && i.y === label);
    const xAxys = data.matrixBySize
      .filter(i => i.x === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);
    const yAxys = data.matrixBySize
      .filter(i => i.y === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);

    const total = data.matrixBySize
      .filter(i => i.y === label || i.X === label)
      .map(i => i.value)
      .reduce((a, b) => a + b, 0);

    return {
      name: label.charAt(0).toUpperCase() + label.slice(1),
      value,
      somaX: xAxys,
      somaY: yAxys,
      precision: value / yAxys,
      recall: value / xAxys,
      precisionError: yAxys - value,
      recallError: xAxys - value,
      total,
      line,
      lineText: (line * 100).toFixed(2) + '%'
    };
  }
  const dataTest = [
    getBarData('small', lineSmall),
    getBarData('medium', lineMedium),
    getBarData('large', lineLarge)
  ];
  // eslint-disable-next-line
  const lines = ['linha1', 'linha2'];
  //const data = [{ linha1: 10, linha2: 5 }, { linha1: 20 }, { linha1: 15, linha2: 30 }];
  // eslint-disable-next-line
  const lineColors = {
    linha1: theme.white,
    linha2: theme.default
  };

  return (
    <>
      <div className="d-flex  align-items-center p-3">
        <SwitchContainer>
          <SwitchOptions>Precision</SwitchOptions>
          <Switch
            isOn={switchPrecisionRecallValue}
            onColor={theme.default}
            name="preciosn"
            handleToggle={() => {
              setSwitchPrecisionRecallValue(!switchPrecisionRecallValue);
            }}
          />
          <SwitchOptions>Recall</SwitchOptions>
        </SwitchContainer>
      </div>
      <ResponsiveContainer>
        <ComposedChart width={2000} height={40} data={dataTest ?? []}>
          <Legend />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" scale="log" domain={[0.8, 'auto']} allowDataOverflow />
          <YAxis yAxisId="right" orientation="right" tickFormatter={value => `${value * 100}%`} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            stackId="a"
            name="Correct"
            fill={theme.default}
            yAxisId="left"
          />
          <Bar
            dataKey={switchPrecisionRecallValue ? 'recallError' : 'precisionError'}
            stackId="a"
            yAxisId="left"
            name="Error"
            fill={theme.primary}
          >
            {/* <LabelList dataKey="line" position="top" /> */}
          </Bar>
          <Line
            isAnimationActive={false}
            type="basil"
            dataKey="line"
            stroke={theme.white}
            strokeWidth="2"
            yAxisId="right"
            name={switchPrecisionRecallValue ? 'Recall' : 'Precision'}
          >
            <LabelList dataKey="lineText" position="insideTopLeft" stroke={theme.white} />
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
export default MatrixBySize;
