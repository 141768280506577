import React, { useEffect } from 'react';
import useGlobal from 'store/global';
import { useDispatch } from 'react-redux';
import AnnotateForm from 'components/AnnotateForm';
import { showTooltip } from 'store/ducks/tooltip';
import { GetInferenceImage } from 'store/ducks/inference';
import { getIdFromUrl } from 'utils/helpers';
import {
  setPage,
  setSearch,
  setOrderBy,
  setMarkers,
  setTags,
  setWithoutTags,
  clearParameters
} from 'store/ducks/parameters';

function AnnotateInference(props) {
  const { history } = props;
  const location = history.location.pathname;
  const dispatch = useDispatch();
  const datasetId = getIdFromUrl(location, 2);
  const inferenceId = getIdFromUrl(location, 4);
  const imageId = getIdFromUrl(location, 6).match(/[^&]*/i)[0];
  const searchParams = new URLSearchParams(location);
  const [relaodAnnotate, setRelaodAnnotate] = useGlobal(
    state => state.relaodAnnotate,
    actions => actions.setRelaodAnnotate
  );
  const [, setInferenceID] = useGlobal(
    state => state.inferenceID,
    actions => actions.setInferenceID
  );

  useEffect(() => {
    let markers = [];
    let tags = [];
    dispatch(clearParameters());

    searchParams.forEach((val, key) => {
      if (key === 'q') {
        dispatch(setSearch(val));
      }
      if (key === 'page') {
        dispatch(setPage(val));
      }
      if (key === 'orderBy') {
        dispatch(setOrderBy(val));
      }
      if (key === 'markers') {
        markers = markers.concat(val);
        dispatch(setMarkers(markers));
      }
      if (key === 'withoutTags') {
        dispatch(setTags([]));
        dispatch(setWithoutTags(val));
      } else if (key === 'tags') {
        tags = tags.concat(val);
        dispatch(setTags(tags));
        dispatch(setWithoutTags(false));
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getInferenceImage(inferenceId, imageId) {
      dispatch(GetInferenceImage(inferenceId, datasetId, imageId));
    }

    async function getInferenceData() {
      const data = await getInferenceImage(inferenceId, imageId);
      setRelaodAnnotate(false);
      return data;
    }

    setInferenceID(inferenceId);
    dispatch(showTooltip(false));
    getInferenceData();
    // eslint-disable-next-line
  }, [location, dispatch, relaodAnnotate]);

  return <AnnotateForm history={history} />;
}

export default AnnotateInference;
