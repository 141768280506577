import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showTooltip } from 'store/ducks/tooltip';
import ImageTooltip from 'components/GlobalTooltip/imageTooltip';
import useGlobal from 'store/global';
import { resolveFilter } from 'services/Api';
import { Container, Name, Image, ImageContainer, ImageHasTag } from './styles';
import { Icon } from 'components/Icon';

function ImageItem(props) {
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.filter);
  const { data, children, dataset, pageSize, index } = props;
  const [tooltipInterval, setTooltipInterval] = useState();
  const [deleteMode, setDeleteMode] = useGlobal(
    state => state.deleteMode,
    actions => actions.setDeleteMode
  );
  function scheduleTooltip() {
    const show = showTooltip(<ImageTooltip {...data}></ImageTooltip>);
    setTooltipInterval(setTimeout(() => dispatch(show), 1000));
  }
  // console.log('filterState', filterState);
  // console.log('Math.ceil((index + 1) / pageSize)', Math.ceil((index + 1) / pageSize));
  return (
    <Container
      className=""
      onMouseEnter={() => scheduleTooltip()}
      onMouseLeave={() => {
        clearInterval(tooltipInterval);
        dispatch(showTooltip(false));
      }}
    >
      <Link
        to={`${dataset}/annotate/${data.id}${resolveFilter({
          page: Math.ceil((index + 1) / pageSize),
          query: filterState?.search,
          markers: filterState?.markers,
          orderBy: filterState?.orderby,
          tags: filterState?.tags,
          withoutTags: filterState?.withoutTags
        })}`}
      >
        <ImageContainer>
          {data.tags.length > 0 && (
            <Icon name={'mark'} style={{ height: 15, position: 'absolute', top: 5, left: 5 }} />
          )}
          <Image alt={data.name} src={data.thumbnail} />
        </ImageContainer>
        <Name>
          <span>{children}</span>
        </Name>
      </Link>
    </Container>
  );
}

export default ImageItem;
