import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClearInference } from 'store/ducks/inference';
import history from 'services/history';
import { Container, Row } from 'reactstrap';
import TitleBreadcrumb from 'components/Breadcrumb';
//import Searchbar from 'components/Searchbar';
// eslint-disable-next-line
import { ContainerTop, Name, Image } from './styles';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import * as modelData from 'assets/animations/model.json';
import * as dataset from 'assets/animations/dataset.json';
import { t, strings } from 'language';

// import { Container } from './styles';
const defaultOptions = {
  loop: false,
  autoplay: true,

  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function Main() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.loggedin) {
      history.push('/login');
    }
    dispatch(ClearInference());
  }, [auth.loggedin]);

  return (
    <Container>
      <TitleBreadcrumb position={1} url={'/main'} name={'Home'} />

      <Container>
        <Row>
          <Link to={'models'}>
            <ContainerTop>
              <Lottie
                options={(defaultOptions, { animationData: modelData.default })}
                width={190}
                height={190}
                isClickToPauseDisabled={true}
              />
              {/* <Image
                src={require('assets/images/modelImage.png')}
                style={{ width: 160, margin: '15px 26px' }}
              /> */}
            </ContainerTop>
            <Name>
              <h3>{t(strings.models)}</h3>
            </Name>
          </Link>
          <Link to={'folders'} style={{ marginLeft: 20 }}>
            <ContainerTop>
              <Lottie
                options={(defaultOptions, { animationData: dataset.default })}
                width={190}
                height={190}
                isClickToPauseDisabled={true}
              />
            </ContainerTop>
            <Name>
              <h3>{t(strings.folders)}</h3>
            </Name>
          </Link>
        </Row>
      </Container>
    </Container>
  );
}

export default Main;
