import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loading } from 'store/ducks/general';
import useGlobal from 'store/global';
import MagicWand from 'magic-wand-tool';
import { postTagPolygon } from 'services/Api/Dataset/dataset';
import { GetSelectImage } from 'store/ducks/annotate';
import { addTags } from 'services/Api/Tag/tag';
import Editor from './Editor';
import './styles.css';

function MagicWandTool(props) {
  const { selectedImage } = props;
  const dispatch = useDispatch();
  const colorThreshold = 15;
  const [blurRadius, setBlurRadius] = useState(5);
  const simplifyTolerant = 0;
  const simplifyCount = 30;
  const hatchLength = 4;
  const [hatchOffset, setHatchOffset] = useState(0);

  const [imageInfo, setImageInfo] = useState(null);
  let cacheInd = null;
  let mask = null;
  const [maskPolygon, setMaskPolygon] = useGlobal(
    state => state.maskPolygon,
    actions => actions.setMaskPolygon
  );
  const [downPoint, setDownPoint] = useState(null);
  const [allowDraw, setAllowDraw] = useState(false);
  const [currentThreshold, setCurrentThreshold] = useState(colorThreshold);
  const [divThreshold, setDivThreshold] = useState(`Threshold: ${currentThreshold}`);
  const canvasRef = React.useRef(null);
  const tmpRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const [bounding, setBounding] = useState(null);
  const [showEdit, setShowEdit] = useState('none');
  const [zoom] = useGlobal(state => state.zoom);
  const [trace, setTrace] = useGlobal(
    state => state.trace,
    actions => actions.setTrace
  );
  const [cancelTrace, setCancelTrace] = useGlobal(
    state => state.cancelTrace,
    actions => actions.setCancelTrace
  );
  const [tagType] = useGlobal(state => state.tagType);
  const [handlePosition, setHandlePosition] = useGlobal(
    state => state.handlePosition,
    actions => actions.setHandlePosition
  );
  const showMask = handlePosition ? 1 : 0;
  const [, setShowTrace] = useGlobal(
    state => state.showTrace,
    actions => actions.setShowTrace
  );
  const [tag] = useGlobal(state => state.tag);
  const [newMask] = useGlobal(state => state.newMask);

  useEffect(() => {
    showThreshold();
    setShowTrace(false);
    setInterval(() => {
      hatchTick();
    }, 300);
  }, []);

  useEffect(() => {
    if (trace) {
      setTrace(false);
      setShowEdit('block');
    }
    if (cancelTrace) {
      const ctx = imageInfo.context;
      ctx.clearRect(0, 0, imageInfo.width, imageInfo.height);
      setHandlePosition(null);
      setCancelTrace(false);
    }
  }, [trace, cancelTrace]);

  useEffect(() => {
    if (handlePosition?.clientX) {
      setShowTrace(true);
      const position = getMousePosition(handlePosition);
      setAllowDraw(true);
      setDownPoint(position);
      drawMask(position.x, position.y);
    }
  }, [handlePosition]);

  useEffect(() => {
    const ref = canvasRef.current;
    setBounding(ref ? ref.getBoundingClientRect() : { top: 0, left: 0 });
  }, [canvasRef.current, tagType]);
  useEffect(() => {
    const canvas = canvasRef.current; //document.getElementById('resultCanvas');
    canvas.width = selectedImage?.width;
    canvas.height = selectedImage?.height;
    mask = null;
    setMaskPolygon(null);

    const tempCtx = tmpRef.current.getContext('2d'); // = document.createElement('canvas').getContext('2d');
    tempCtx.canvas.width = selectedImage?.width;
    tempCtx.canvas.height = selectedImage?.height;
    const image = imgRef.current;
    image.setAttribute('crossOrigin', '');
    let dataImage;
    image.onload = () => {
      tempCtx.drawImage(image, 0, 0);
      image.crossOrigin = 'Anonymous';
      dataImage = tempCtx.getImageData(0, 0, selectedImage.width, selectedImage.height);
      setImageInfo({
        width: selectedImage?.width,
        height: selectedImage?.height,
        context: canvas.getContext('2d'),
        data: dataImage
      });
    };
  }, [selectedImage.id]);

  function onRadiusChange(e) {
    setBlurRadius(e);
  }

  function getMousePosition(e) {
    const x = Math.round(((e.clientX || e.pageX) - bounding.left) / zoom);
    const y = Math.round(((e.clientY || e.pageY) - bounding.top) / zoom);
    return { x, y };
  }

  function onMouseDown(e) {
    if (e.button === 0) {
      setShowTrace(true);
      const position = getMousePosition(e);
      setAllowDraw(true);
      // console.log('onMouseDown.getMousePosition(e)', getMousePosition(e));
      setDownPoint(position);
      drawMask(position.x, position.y);
    } else setAllowDraw(false);
  }

  function onMouseMove(e) {
    if (allowDraw) {
      setShowTrace(true);
      const p = getMousePosition(e);
      if (p.x !== downPoint.x || p.y !== downPoint.y) {
        const dx = p.x - downPoint.x;
        const dy = p.y - downPoint.y;
        const len = Math.sqrt(dx * dx + dy * dy);
        const adx = Math.abs(dx);
        const ady = Math.abs(dy);
        let sign = adx > ady ? dx / adx : dy / ady;
        sign = sign < 0 ? sign / 5 : sign / 3;
        const thres = Math.min(Math.max(colorThreshold + Math.floor(sign * len), 1), 255);
        //var thres = Math.min(colorThreshold + Math.floor(len / 3), 255);
        if (thres !== currentThreshold) {
          setCurrentThreshold(thres);
          drawMask(downPoint.x, downPoint.y);
        }
      }
    }
  }

  function onMouseUp() {
    setAllowDraw(false);
    setCurrentThreshold(colorThreshold);
  }

  function showThreshold() {
    setDivThreshold(`Threshold: ${currentThreshold}`);
  }

  function addMasks() {
    let masks = null;
    if (maskPolygon) {
      masks = maskPolygon;
      masks.bounds.maxX =
        masks.bounds.maxX > mask.bounds.maxX ? masks.bounds.maxX : mask.bounds.maxX;
      masks.bounds.maxY =
        masks.bounds.maxY > mask.bounds.maxY ? masks.bounds.maxY : mask.bounds.maxY;
      masks.bounds.minX =
        masks.bounds.minX < mask.bounds.minX ? masks.bounds.minX : mask.bounds.minX;
      masks.bounds.minY =
        masks.bounds.minY < mask.bounds.minY ? masks.bounds.minY : mask.bounds.minY;
      for (let i = 0; i < masks.data.length; i += 1) {
        masks.data[i] = masks.data[i] === 1 ? masks.data[i] : mask.data[i];
      }
    } else {
      masks = mask;
    }
    return masks;
  }

  function lessMasks() {
    let masks = null;
    if (maskPolygon) {
      masks = maskPolygon;
      masks.bounds.maxX =
        masks.bounds.maxX > mask.bounds.maxX ? masks.bounds.maxX : mask.bounds.maxX;
      masks.bounds.maxY =
        masks.bounds.maxY > mask.bounds.maxY ? masks.bounds.maxY : mask.bounds.maxY;
      masks.bounds.minX =
        masks.bounds.minX < mask.bounds.minX ? masks.bounds.minX : mask.bounds.minX;
      masks.bounds.minY =
        masks.bounds.minY < mask.bounds.minY ? masks.bounds.minY : mask.bounds.minY;
      for (let i = 0; i < masks.data.length; i += 1) {
        masks.data[i] = masks.data[i] === 1 && mask.data[i] === 1 ? 0 : masks.data[i];
      }
    } else {
      masks = mask;
    }
    return masks;
  }

  function drawMask(x, y) {
    if (!imageInfo) return;

    showThreshold();

    const image = {
      data: imageInfo.data.data,
      width: imageInfo.width,
      height: imageInfo.height,
      bytes: 4
    };

    mask = MagicWand.floodFill(image, x, y, currentThreshold, null, true);
    mask = MagicWand.gaussBlurOnlyBorder(mask, blurRadius);
    // console.log('drawMask.maskPolygon', maskPolygon);
    if (newMask === 1) {
      mask = addMasks();
    }
    if (newMask === -1) {
      mask = lessMasks();
    }
    // console.log('drawMask.mask', mask);
    setMaskPolygon(mask);
    drawBorder();
  }

  function hatchTick() {
    setHatchOffset((hatchOffset + 1) % (hatchLength * 2));
    drawBorder(true);
  }

  function drawBorder(noBorder) {
    if (!mask) return;

    let x;
    let y;
    let i;
    let j;
    let k;
    const w = imageInfo.width;
    const h = imageInfo.height;
    const ctx = imageInfo.context;
    const imgData = ctx.createImageData(w, h);
    const res = imgData.data;

    if (!noBorder) cacheInd = MagicWand.getBorderIndices(mask);
    if (newMask === 0) {
      ctx.clearRect(0, 0, w, h);
    }
    const len = cacheInd.length;
    for (j = 0; j < len; j += 1) {
      i = cacheInd[j];
      x = i % w; // calc x by index
      y = (i - x) / w; // calc y by index
      k = (y * w + x) * 4;
      if ((x + y + hatchOffset) % (hatchLength * 2) < hatchLength) {
        // detect hatch color
        res[k + 3] = 255; // black, change only alpha
      } else {
        res[k] = 255; // white
        res[k + 1] = 255;
        res[k + 2] = 255;
        res[k + 3] = 255;
      }
    }

    ctx.putImageData(imgData, 0, 0);
  }

  async function finishPolygon(newTag) {
    await postTagPolygon(selectedImage.id, newTag);
  }

  function randomHex() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  async function onSubmit() {
    const color = !tag.tagId && randomHex();
    const newTag =
      !tag.tagId &&
      (await addTags({
        color,
        description: tag.description
      }));
    const tagId = newTag.id ? newTag.id : tag.tagId;

    setHandlePosition(null);
    setShowEdit('none');
    tracePolygon(tagId);
  }

  function tracePolygon(tagId) {
    let cs = MagicWand.traceContours(maskPolygon);
    cs = MagicWand.simplifyContours(cs, simplifyTolerant, simplifyCount);
    setMaskPolygon(null);
    let polygon = {
      points: [],
      tagId
    };

    // draw contours
    const ctx = imageInfo.context;
    ctx.clearRect(0, 0, imageInfo.width, imageInfo.height);
    // //inner
    // ctx.beginPath();
    // for (let i = 0; i < cs.length; i += 1) {
    //   if (!cs[i].inner) continue;
    //   let ps = cs[i].points;
    //   ctx.moveTo(ps[0].x, ps[0].y);
    //   for (let j = 1; j < ps.length; j += 1) {
    //     ctx.lineTo(ps[j].x, ps[j].y);
    //   }
    // }
    // ctx.strokeStyle = 'red';
    // ctx.stroke();
    // //outer
    // ctx.beginPath();

    for (let i = 0; i < cs.length; i += 1) {
      if (cs[i].inner) continue;
      let ps = cs[i].points;
      // ctx.moveTo(ps[0].x, ps[0].y);
      polygon.points = polygon.points.concat({
        x: (ps[0].x * 100) / imageInfo.width,
        y: (ps[0].y * 100) / imageInfo.height
      });
      for (let j = 1; j < ps.length; j += 1) {
        // ctx.lineTo(ps[j].x, ps[j].y);
        polygon.points = polygon.points.concat({
          x: (ps[j].x * 100) / imageInfo.width,
          y: (ps[j].y * 100) / imageInfo.height
        });
      }
    }
    // console.log('polygon', polygon);
    finishPolygon(polygon);
    dispatch(GetSelectImage(selectedImage.datasetId, selectedImage.id));
    // ctx.strokeStyle = 'blue';
    // ctx.stroke();
  }

  function hexToRgb(hex, alpha) {
    const int = parseInt(hex, 16);
    const r = (int >> 16) & 255;
    const g = (int >> 8) & 255;
    const b = int & 255;

    return [r, g, b, Math.round(alpha * 255)];
  }

  return (
    <div className="content" style={{ zIndex: showMask }}>
      <div className="content">
        <img
          ref={imgRef}
          className="picture"
          src={selectedImage.url}
          style={{ display: 'none' }}
          onLoad={() => {
            dispatch(loading(false));
          }}
        />
        <canvas
          ref={canvasRef}
          className="canvas"
          onMouseUp={() => onMouseUp()}
          onMouseDown={e => onMouseDown(e)}
          onMouseMove={e => onMouseMove(e)}
          style={{ zIndex: 10 }}
        />
        <canvas ref={tmpRef} className="canvas" style={{ display: 'none', zIndex: 1 }} />
      </div>
      <Editor
        key={`Editor_${Math.random()}`}
        downPoint={downPoint}
        onSubmit={onSubmit}
        style={{ display: showEdit }}
      />
    </div>
  );
}

export default MagicWandTool;
