import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { clearInference } from 'store/ducks/training';
import { loading } from 'store/ducks/general';

import Dialog from 'components/Dialog';
import { makeInference } from 'services/Api/Inference/inference';
import { t, strings } from 'language';
import { Container } from './styles';
import SelectFolder from './selectFolder';
import Step1 from './step1';

export default function NewInference(props) {
  const dispatch = useDispatch();
  const training = useSelector(state => state.training);

  const [step, setStep] = useState(0);
  const [title, setTitle] = useState(t(strings.create_inference));
  const { modal, setNewTrainingModal } = props;

  const createInference = useCallback(async () => {
    if (
      training.selectedInferenceDatasets.length === 0 ||
      training.selectedInferencePoints.length === 0
    ) {
      toast.error( t(strings.select_one_inference));
    } else {
      dispatch(loading(true));
      await makeInference(
        training.model.id,
        training.selectedInferencePoints.map(i => parseInt(i)),
        training.selectedInferenceDatasets.map(i => i.id),
        training.selectedInferenceConfidence
      );
      setNewTrainingModal(false);
      dispatch(clearInference());
      dispatch(loading(false));
    }
  }, [dispatch, setNewTrainingModal, training]);

  const getDialogButtons = useCallback(() => {
    const buttons = [
      {
        text: t(strings.cancel),
        action: () => {
          setNewTrainingModal(false);
          dispatch(clearInference());
        }
      },
      {
        text: t(strings.infer),
        action: () => {
          createInference();
        },
        gold: true
      }
    ];

    return buttons;
  }, [step, createInference, dispatch, setNewTrainingModal]);

  return (
    <Container>
      {training.openInferenceDatasetSelector && <SelectFolder nextStep={() => createInference()} />}
      <Dialog title={title} open={modal} width="100%" smallButtons actions={getDialogButtons()}>
        <Step1 />
      </Dialog>
    </Container>
  );
}
