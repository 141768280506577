import { combineReducers } from 'redux';

import auth from './auth';
import general from './general';
import theme from './theme';
import annotate from './annotate';
import annotateTag from './annotateTag';
import annotateEdit from './contentEdit';
import uploadQueue from './uploadQueue';
import training from './training';
import tooltip from './tooltip';
import breadcrumb from './breadcrumb';
import inference from './inference';
import inferenceDetails from './inferenceDetails';
import filter from './filter';
import parameters from './parameters';
import language from './language';

export default combineReducers({
  auth,
  general,
  annotate,
  annotateTag,
  annotateEdit,
  uploadQueue,
  training,
  tooltip,
  breadcrumb,
  inference,
  inferenceDetails,
  filter,
  parameters,
  theme,
  language
});
