import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default function Tooltip({ active, payload, label, labelText }) {
  const theme = useContext(ThemeContext);
  const containerStyle = {
    background: theme.primary,
    opacity: 0.9,
    borderRadius: '10px',
    padding: '15px 10px 5px'
  };
  const textStyle = { color: theme.white };

  if (active) {
    return (
      <div style={containerStyle}>
        <p>
          {labelText}:{label}
        </p>
        {payload?.map(item => {
          if (item.name === 'Recall') {
            return <p style={textStyle}>{`Recall : ${item.value.toFixed(2)}%`}</p>;
          }
          if (item.name === 'Precision') {
            return <p style={textStyle}>{`Precision : ${item.value.toFixed(2)}%`}</p>;
          }
          return <p style={textStyle}>{`${item.name ?? 'Valor'} : ${item.value.toFixed(2)}`}</p>;
        })}
      </div>
    );
  }

  return Tooltip;
}
