import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dialog from 'components/Dialog';
import FolderTooltip from 'components/GlobalTooltip/folderTooltip';

import { DatasetAPI } from 'services/Api/Dataset';
import { loading } from 'store/ducks/general';
import { showTooltip } from 'store/ducks/tooltip';
import { Label, InputField } from 'components/FolderBrowser/styles';
import { ReactComponent as Folder } from 'assets/images/folder.svg';
import { ReactComponent as ClassIcon } from 'assets/images/tag.svg';

import imageIcon from 'assets/images/icon_image.png';
import {
  Container,
  Name,
  Image,
  InternalContainer,
  EditButton,
  Tags,
  ImageIcon,
  ContainerTop,
  TextItemDetail,
  TextContainer,
  Markers,
  StarContainer
} from './styles';
import Favorite from 'components/Favorite';
import { TypeItem } from 'utils/constants';
import Marker from 'components/Marker';
import { setMarkers } from 'store/ducks/filter';
import { Icon } from 'components/Icon';
import { t, strings } from 'language';

function FolderItem(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const { data, children, markers, updateMarkers, onResetData, onChangeFilter } = props;

  const [folderName, setFolderName] = useState(children);
  const [tooltipInterval, setTooltipInterval] = useState();
  const [mouseOver, setmouseOver] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [editFolderName, setEditFolderName] = useState('');
  const [editFolderId, setEditFolderId] = useState('');
  const [markerList, setMarkerList] = useState([]);
  function openEditModel(event, name, id) {
    event.preventDefault();
    setShowDialog(true);
    setEditFolderName(name);
    setEditFolderId(id);
  }

  async function editName() {
    dispatch(loading(true));
    await DatasetAPI.editFolder(editFolderId, editFolderName);
    closeModal();
    setFolderName(editFolderName);
    dispatch(loading(false));
  }

  function closeModal() {
    setShowDialog(false);
  }

  function scheduleTooltip() {
    const show = showTooltip(<FolderTooltip {...data} />);
    setTooltipInterval(setTimeout(() => dispatch(show), 1000));
  }
  const onMarkerClick = (e, item) => {
    e.preventDefault();
    // setMarkerList([item.id]);
    // onChangeFilter([item.id]);
    dispatch(setMarkers([{ label: item.name, value: item.id }]));
    //onResetData();
  };

  return (
    <Container
      onMouseEnter={() => scheduleTooltip()}
      onMouseLeave={() => {
        clearInterval(tooltipInterval);
        dispatch(showTooltip(false));
      }}
      className=""
    >
      <Link
        onClick={() => clearInterval()}
        to={`datasets?folder=${data.id}&folderName=${folderName}&count=${
          data.totalImages ? data.totalImages : '0'
        }`}
        onMouseEnter={() => setmouseOver(true)}
        onMouseLeave={() => setmouseOver(false)}
      >
        <ContainerTop>
          <Markers>
            <div className="d-flex flex-row w-100 justify-content-end">
              {data?.markers?.slice(0, 2).map(item => (
                <a onClick={e => onMarkerClick(e, item)}>
                  <p title={item.name}>{item.name}</p>
                </a>
              ))}
              <StarContainer>
                <Favorite data={data} type={TypeItem.FOLDER} />
              </StarContainer>
            </div>
          </Markers>

          <Tags>
            <Icon name="folder" width="30" height="30" />
            {/* <Folder fill="#ff0" style={{width:30,height:30}} /> */}
            <div className="d-flex flex-row mr-1 justify-content-between">
              {/* <div className="leftTags d-flex flex-row justify-content-end">
              {data?.totalTags > 0 && (
                <span>
                  {data?.totalTags} <ClassIcon style={{ height: 12, width: 12 }} />
                </span>
              )}
            </div> */}
              <div className="rightTags d-flex flex-row justify-content-end">
                {data?.polygon > 0 && (
                  <span>
                    {data?.polygon} <Icon name="polygon_icon" style={{ height: 10, width: 10 }} />
                  </span>
                )}
                {data?.boundingBox > 0 && (
                  <span>
                    {data?.boundingBox} <Icon name="tag_icon" style={{ height: 10, width: 10 }} />
                  </span>
                )}
              </div>
            </div>
          </Tags>

          <TextContainer>
            <TextItemDetail>
              <strong>{t(strings.images)}</strong>: {data.totalImages ? data.totalImages : '0'}
            </TextItemDetail>

            <TextItemDetail>
              <strong>{t(strings.class)}</strong>: {data.totalTags ? data.totalTags : '0'}
            </TextItemDetail>
          </TextContainer>
        </ContainerTop>

        <Name>
          {!viewer && (
            <EditButton
              className="editButton"
              mouseOver={mouseOver || showDialog}
              onClick={event => openEditModel(event, folderName, data.id)}
            ></EditButton>
          )}
          <h3>{folderName}</h3>
          {!viewer && (
            <Marker
              markers={markers}
              onResetData={onResetData}
              updateMarkers={updateMarkers}
              data={data}
              type={TypeItem.FOLDER}
            />
          )}
        </Name>
      </Link>

      <Dialog
        title={t(strings.edit_name)}
        open={showDialog}
        close={closeModal}
        actions={[
          { text: t(strings.cancel), action: closeModal },
          { text: t(strings.save), action: () => editName() }
        ]}
      >
        <Label>{t(strings.edit_folder)}</Label>
        <InputField
          defaultValue={editFolderName}
          type="text"
          placeholder={t(strings.enter_folder_name)}
          onChange={e => setEditFolderName(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && editName()}
        />
      </Dialog>
    </Container>
  );
}

export default FolderItem;
