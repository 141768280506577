import React, { useState } from 'react';
import { t, strings } from 'language';
import useGlobal from 'store/global';
import useDimensions from 'react-use-dimensions';
import { Container, ContainerImage, Label, Image } from './styles';
import returnTags from './components/returnTags';
// import { ReactComponent as Trash } from 'assets/images/delete_icon.svg';
// import { ReactComponent as Tags } from 'assets/images/mark.svg';
import './styles.css';
import { Icon } from 'components/Icon';
import { useSelector } from 'react-redux';

function TooltipItem(props) {
  const { index, item, id, selected, showImg } = props;
  const auth = useSelector(state => state.auth);
  const subscription = auth.user.subscriptions.find(
    element => element.id === auth.user.activeSubscription
  );
  const viewer = subscription.role === 'VIEWER';
  const [ref, { x }] = useDimensions();
  const [showTrash, setShowTrash] = useState(false);
  const [, setShowToolTip] = useGlobal(
    state => state.showToolTip,
    actions => actions.setShowToolTip
  );
  const [, setModal] = useGlobal(
    state => state.modal,
    actions => actions.setModal
  );
  const [, setTitleModal] = useGlobal(
    state => state.titleModal,
    actions => actions.setTitleModal
  );
  const [, setTextModal] = useGlobal(
    state => state.textModal,
    actions => actions.setTextModal
  );
  const [, setDelItem] = useGlobal(
    state => state.delItem,
    actions => actions.setDelItem
  );
  const showDisplay = !selected ? 'block' : 'none';
  const [inferenceID] = useGlobal(state => state.inferenceID);

  function showTooltip() {
    const left = x - 12;
    const title = item.name;
    // const text = `${t(strings.dimension)}: ${item.height}x${item.width}`;
    const text = `${t(strings.dimension)}: ${item.height}x${item.width}\nID: ${item.id}`;
    const tags = returnTags(item);
    const tooltip = { left, title, text, tags };
    setShowToolTip(tooltip);
    if (!inferenceID > 0) setShowTrash(true);
    if (viewer) setShowTrash(false);
  }

  function hideTooltip() {
    const tooltip = { left: -1000, title: '', text: '', tags: '' };
    setShowToolTip(tooltip);
    setShowTrash(false);
  }

  function handleDelete(e) {
    hideTooltip();
    setTitleModal(t(strings.confirm_delete_img));
    setTextModal(`${t(strings.confirm_delete)} "${props.item.name}" ?`);
    setDelItem({ value: props.item, type: 'img' });
    setModal(true);
    e.preventDefault();
  }

  if (item) {
    return (
      <Container
        ref={ref}
        key={id}
        onMouseOver={() => showTooltip()}
        onFocus={() => showTooltip()}
        onMouseLeave={() => hideTooltip()}
        style={{ width: '85px' }}
      >
        <ContainerImage>
          <>
            <Icon
              name="delete_icon"
              width="20"
              height="20"
              style={{
                zIndex: 500,
                position: 'absolute',
                textAlign: 'right',
                right: 15,
                top: 5,
                display: showTrash ? showDisplay : 'none'
              }}
              onClick={e => handleDelete(e)}
            />
            <Icon
              name="mark"
              width="20"
              height="20"
              style={{
                zIndex: 500,
                position: 'absolute',
                textAlign: 'left',
                left: 5,
                top: 5,
                display: item.tags.length > 0 ? 'block' : 'none'
              }}
            />
            <Image
              onClick={() => {
                hideTooltip();
                showImg(item, index);
              }}
              style={{ backgroundImage: `url(${item.thumbnail})`, ...selected }}
            />
            <Label>{`${item.name}`}</Label>
          </>
        </ContainerImage>
      </Container>
    );
  }
  return null;
}
export default TooltipItem;
