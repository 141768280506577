import React, { useContext } from 'react';
import Select from 'react-select';
import { ThemeContext } from 'styled-components';

export default function MultiSelect(props) {
  const { onChange, className, options, selected } = props;
  const theme = useContext(ThemeContext);
  const parserdOptions = options.map(i => ({ label: i, value: i }));
  const parserdSelected = { label: selected, value: selected };

  return (
    <Select
      className={className}
      options={[...parserdOptions]}
      onChange={e => {
        // console.log(e);
        onChange(e.value);
      }}
      value={parserdSelected}
      styles={{
        control: e => ({
          ...e,
          backgroundColor: theme.primary,
          outline: 'none',
          border: '1px solid black',
          boxShadow: '0px 3px 6px #00000029'
        }),
        indicatorSeparator: e => ({
          ...e,
          backgroundColor: 'black'
        }),
        menu: e => ({
          ...e,
          backgroundColor: theme.primary
        }),
        singleValue: e => ({
          ...e,
          color: theme.default
        })
      }}
    />
  );
}
