import styled from 'styled-components';

export const GlobalContainer = styled.div`
  background: ${props => props.theme.primary};
  color: ${props => props.theme.default};
  height: 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, left top;
  background-attachment: fixed;
  min-height: -webkit-fill-available;
  height: 100%;
`;
export const GlobalFluid = styled.div`
  background: ${props => props.theme.background};
`;
