import React, { useState, useEffect } from 'react';
import image_loading from 'assets/images/image_loading.png';

export default function ImageFallback(props) {
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    if (props.src) setCurrentImage(props.src);
    else setCurrentImage(image_loading);
  }, [props]);

  function onImageError() {
    setCurrentImage(image_loading);
    setTimeout(() => {
      setCurrentImage(props.src);
    }, 4000);
  }

  return <img className={props.className} alt={props.alt} onError={onImageError} src={currentImage}></img>;
}
