import styled from 'styled-components';

export const TagCard = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: ${props => props.theme.default};
  margin: 10px;
  background: ${props => props.theme.secondary};
  border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  cursor: default;
`;

export const Box = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 3px;
`;

export const TagName = styled.div`
  text-align: left;
  letter-spacing: 0;
  color: #a2a2a7;
  background: ${props => props.theme.secondary};
  margin-left: 25px;
  margin-top: -5px;
  word-break: break-word;
`;

export const TagDetails = styled.div`
  margin-left: 25px;
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: ${props => props.theme.default};
  word-break: break-word;
`;

export const InputField = styled.input`
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.white};
  width: 100%;
  padding-bottom: 5px;
  color: ${props => props.theme.white};

  ::placeholder {
    color: ${props => props.theme.secondary};
    opacity: 0.5;
  }
  :focus {
    outline: 0;
    border-bottom: 1px solid ${props => props.theme.white};
  }
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.text};
`;

export const ContainerColor = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
`;

export const ContainerText = styled.div`
  color: ${props => props.theme.text};
  padding-left: 40px;
`;

export const ContainerPickers = styled.div`
  margin: auto;
  display: flex;
`;

export const ContainerPicker = styled.div`
  overflow-y: hidden;
  height: 154px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ContainerColorPicker = styled.div`
  overflow: hidden;
  height: 154px;
  width: 29px;
  border-radius: 3px;
  background: cyan;
`;

export const ColorPicker = styled.div`
  min-height: 77px;
  width: 29px;
`;

export const HotKeysNum = styled.div`
  position: absolute;
  background: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.white};
  border-radius: 3px;
  text-align: center;
  padding: 5px;
  width: 85px;
  cursor: default;
`;
