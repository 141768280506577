import styled from 'styled-components';
import RangeSlider from 'react-rangeslider';

export const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  max-width: 100%;
  background-color: ${props => props.backgroundColor};
  overflow: ${props => (props.noscroll ? 'hidden' : 'auto')};
  border: solid 1px black;
`;

export const MatrixTabContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
`;

export const MatrixTab = styled.button`
  background-color: ${props =>
    props.active ? props => props.theme.primary : props => props.theme.secondary};
  border: 1px solid black;
  border-bottom: 0px;
  min-width: 100px;
  box-sizing: border-box;
  :focus {
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.secondary};
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
`;

export const Matrix = styled.table`
  display: inline-block;
  margin: auto;
  & > tr > td {
    font-size: 10px;
    border: 1px solid black;
    height: ${props => props.zoom}px;
    min-width: ${props => props.zoom}px;
    text-align: center;
    background-color: ${props => props.theme.secondary};
  }
`;

export const ColorCell = styled.td`
  background-color: ${props => props.backgroundColor} !important;
  color: ${props => props.textColor};
`;

export const HorizontalLabel = styled.th`
  max-width: 100px;
  padding-top: 5px;
  padding-right: ${props => props.zoom / 5}px;
  font-size: 10px;
  text-align: end;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: ${props => props.textColor};
`;

export const VerticalLabel = styled.th`
  text-align: start;
  padding: 5px 5px;
  font-size: 10px;
  vertical-align: top;

  & > span {
    max-height: 100px;
    writing-mode: vertical-lr;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    white-space: nowrap;
    color: ${props => props.textColor};
  }
`;

export const Slider = styled(RangeSlider)`
  height: 5px !important;
  border-radius: 5px !important;

  & > .rangeslider__fill.rangeslider__fill {
    background-color: ${props => props.theme.default};
  }

  & > .rangeslider__handle {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;

    ::after {
      display: none;
    }

    & > .rangeslider__handle-tooltip {
      width: 116px !important;
      background-color: ${props => props.theme.secondary} !important;
      border: 1px solid ${props => props.theme.text};
      ::after {
        border-top: 8px solid ${props => props.theme.secondary} !important;
      }
    }
  }
`;

export const SliderContainer = styled.div`
  width: 300px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepInput = styled.input`
  background: #fff;
  border: none;
  display: block;
  padding: 2px 3px;
  border-radius: 5px;
  width: 100px;
  margin-left: 30px;
  text-align: center;
`;

export const ProgressBar = styled.div`
  width: 150px;
  height: 15px;
  background: #715f4c 0% 0% no-repeat padding-box;
  border: 2px solid ${props => props.theme.default};
  border-radius: 5px;
  opacity: 1;
  position: relative;
  & > span {
    position: absolute;
    left: 40%;
    top: -1px;
    font-size: 10px;
    color: black;
  }
`;

export const ProgressLoad = styled.div`
  width: ${props => props.progress}%;
  background: transparent linear-gradient(180deg, #ffcc6f 0%, ${props => props.theme.default} 100%)
    0% 0% no-repeat padding-box;
  transition: width 1.5s ease;
  border-radius: 5px;
  opacity: 1;
  height: 14px;
  margin-top: -1.5px;
`;

export const NextButton = styled.button`
  background-color: ${props => props.theme.primary};
  outline: none;
  border: 0;
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
