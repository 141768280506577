import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { colors as darkColor } from './dark/colors';
import { colors as lightColor } from './light/colors';
import { useSelector } from 'react-redux';

export default function Theme(props) {
  const [colors, setColors] = useState(darkColor);
  const { theme } = useSelector(state => state.theme);

  useEffect(() => {
    if (theme === 'dark') {
      setColors(darkColor);
    } else {
      setColors(lightColor);
    }
  }, [theme]);

  return <ThemeProvider theme={colors}>{props.children}</ThemeProvider>;
}
